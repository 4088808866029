import React, { FC, ReactNode, useCallback, useContext, useEffect, useRef, useState } from 'react';
import {
    Grid,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from '@mui/material';
import { LanguageContext } from 'contexts/languageContext';
import { useErrorHandler } from 'components/hooks/useErrorHandler';
import axios, { CancelTokenSource } from 'axios';
import MemberTagService from 'services/memberTagService';
import { Membership } from 'types/sp-api';
import WaitScreen from 'components/common/WaitScreen';
import { TableHeadCell } from 'components/common/TableHeadCell';
import Utils from 'utils/personHelper';
import RowMenu from 'components/common/RowMenu';
import { useNavigate } from 'react-router-dom';

interface MemberTagMembersProps {
    organizationId: number;
    tagId: number;
}

const MemberTagMembers: FC<MemberTagMembersProps> = ({ organizationId, tagId }: MemberTagMembersProps) => {
    const { T } = useContext(LanguageContext);
    const [tagListMembers, setTagListMembers] = useState<Membership[]>();
    const { handleError } = useErrorHandler();
    const cancelRef = useRef<CancelTokenSource | null>(null);
    const navigate = useNavigate();

    const getMemberTagMembers = useCallback(
        async (tagTitleId: number, orgId: number): Promise<void> => {
            try {
                !cancelRef.current && (cancelRef.current = axios.CancelToken.source());
                const members = await MemberTagService.getMembersByTagIdAsync(
                    tagTitleId,
                    orgId,
                    cancelRef.current.token
                );
                if (cancelRef.current) {
                    setTagListMembers(members);
                }
            } catch (error) {
                handleError(error, T('Error_MemberTagQueryFailed'));
            }
        },
        [handleError, T]
    );

    useEffect(() => {
        getMemberTagMembers(tagId, organizationId);

        return () => {
            cancelRef.current && cancelRef.current.cancel();
            cancelRef.current = null;
        };
    }, [getMemberTagMembers, tagId, organizationId]);

    return (
        <>
            <Grid item xs={12}>
                <TableContainer component={Paper}>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableHeadCell>{T('Common_Name')}</TableHeadCell>
                                <TableHeadCell>{T('Person_FieldPhoneHome')}</TableHeadCell>
                                <TableHeadCell sx={{ width: '5%' }} />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {tagListMembers &&
                                tagListMembers.length > 0 &&
                                tagListMembers.map(
                                    (item): ReactNode => (
                                        <TableRow key={item.id}>
                                            <TableCell>
                                                <Typography>{Utils.FullName(item.person)}</Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography>{item.person?.phoneHome}</Typography>
                                            </TableCell>
                                            <TableCell sx={{ width: '5%' }}>
                                                <RowMenu onEdit={() => navigate(`/persons/${item.personId}`)} />
                                            </TableCell>
                                        </TableRow>
                                    )
                                )}
                            {tagListMembers && tagListMembers.length === 0 && (
                                <TableRow>
                                    <TableCell>
                                        <Typography variant="body1" color="textSecondary">
                                            {T('SettingsTabMemberTag_NoTagMembers')}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            )}
                            {!tagListMembers && (
                                <TableRow>
                                    <TableCell>
                                        <WaitScreen />
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </>
    );
};

export default MemberTagMembers;
