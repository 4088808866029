import React, { FC, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Button, FormHelperText, Grid, Typography } from '@mui/material';
import { Formik, Form, FormikProps } from 'formik';
import { LanguageContext } from 'contexts/languageContext';
import LoadButton from 'components/common/LoadButton';
import FormFieldText from 'components/common/FormFieldText';
import { useErrorHandler } from 'components/hooks/useErrorHandler';
import { useSuccessHandler } from 'components/hooks/useSuccessHandler';
import axios, { CancelTokenSource } from 'axios';
import MemberTagService from 'services/memberTagService';
import { Tag } from 'types/sp-api';
import DialogConfirmation from 'components/common/DialogConfirmation';
import { DeleteDialogState } from 'types/common';
import { getValidationSchema } from './SettingsTabMemberTagTitles';
import CommunityTagService from 'services/communityTagService';

interface EditTagTitleProps {
    organizationId?: number;
    tagId: number;
    selectTagId: (id?: number) => void;
}

const EditTagTitle: FC<EditTagTitleProps> = ({ organizationId, tagId, selectTagId }: EditTagTitleProps) => {
    const { T } = useContext(LanguageContext);
    const { handleError } = useErrorHandler();
    const { handleSuccess } = useSuccessHandler();
    const [sending, setSending] = useState(false);
    const [deleting, setDeleting] = useState(false);
    const [tagUsage, setTagUsage] = useState<number>();
    const [tag, setTag] = useState<Tag>();
    const cancelRef = useRef<CancelTokenSource | null>(null);
    const [deleteDialogState, setDeleteDialogState] = useState<DeleteDialogState>();

    const getTag = useCallback(
        async (id: number, orgId?: number): Promise<void> => {
            !cancelRef.current && (cancelRef.current = axios.CancelToken.source());

            if (orgId) {
                try {
                    const tagTitle = await MemberTagService.getMemberTagTitleAsync(orgId, id, cancelRef.current.token);
                    if (cancelRef.current) {
                        setTag(tagTitle);
                    }
                    const usage = await MemberTagService.getMemberTagTitleUsageAsync(
                        orgId,
                        id,
                        cancelRef.current.token
                    );
                    if (cancelRef.current) {
                        setTagUsage(undefined);
                        setTagUsage(usage);
                    }
                } catch (error) {
                    handleError(error, T('Error_OrganizationTagQueryFailed'));
                }
            } else {
                try {
                    const tagTitle = await CommunityTagService.getCommunityTagTitleAsync(id, cancelRef.current.token);
                    if (cancelRef.current) {
                        setTag(tagTitle);
                    }
                    const usage = await CommunityTagService.getCommunityTagTitleUsageAsync(id, cancelRef.current.token);
                    if (cancelRef.current) {
                        setTagUsage(undefined);
                        setTagUsage(usage);
                    }
                } catch (error) {
                    handleError(error, T('Error_CommunityTagQueryFailed'));
                }
            }
        },
        [handleError, T]
    );

    useEffect(() => {
        getTag(tagId, organizationId);

        return () => {
            cancelRef.current && cancelRef.current.cancel();
            cancelRef.current = null;
        };
    }, [getTag, organizationId, tagId]);

    const saveTag = async (values: Tag): Promise<void> => {
        setSending(true);

        try {
            if (organizationId) {
                await MemberTagService.saveMemberTagTitleAsync(organizationId, values);
            } else {
                await CommunityTagService.saveCommunityTagTitleAsync(values);
            }
            setSending(false);
            handleSuccess(T('Settings_TagSaveSuccess'));
            selectTagId(tagId);
        } catch (error) {
            setSending(false);
            handleError(error, T('Settings_TagSaveFailed'));
        }
    };

    const deleteTag = async (): Promise<void> => {
        if (!tag) return;

        setDeleting(true);

        try {
            if (organizationId) {
                await MemberTagService.deleteMemberTagTitleAsync(organizationId, tag.id);
            } else {
                await CommunityTagService.deleteCommunityTagTitleAsync(tag.id);
            }
            setDeleting(false);
            handleSuccess(T('Settings_TagDeleteSuccess'));
            selectTagId(undefined);
        } catch (error) {
            setDeleting(false);
            handleError(error, T('Settings_TagDeleteFailed'));
        }
    };

    if (!tag) return null;

    return (
        <>
            <Formik initialValues={tag} enableReinitialize validationSchema={getValidationSchema(T)} onSubmit={saveTag}>
                {({ dirty, isValid, resetForm }: FormikProps<Tag>) => (
                    <Form>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <hr />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography>{T('SettingsTabTag_EditTitle')}</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <FormFieldText name="name" label={T('Settings_TagTitle')} />
                                {tagUsage != undefined && tag.lock ? (
                                    <FormHelperText>{T('SettingsTabTag_TagLocked')}</FormHelperText>
                                ) : tagUsage === 0 ? (
                                    <FormHelperText>{T('SettingsTabTag_TagNotInUse')}</FormHelperText>
                                ) : (
                                    <FormHelperText>
                                        {T('SettingsTabTag_TagInUse').replace('{0}', tagUsage?.toString() ?? '-')}
                                    </FormHelperText>
                                )}
                            </Grid>
                            <Grid item container xs={12} justifyContent="space-between">
                                <Grid item>
                                    <LoadButton loading={sending} disabled={!dirty || !isValid} type="submit">
                                        {T('Common_Save')}
                                    </LoadButton>
                                    <Button
                                        disabled={!dirty}
                                        style={{ marginLeft: 16 }}
                                        onClick={() => {
                                            resetForm();
                                        }}
                                        color="secondary"
                                    >
                                        {T('Common_Cancel')}
                                    </Button>
                                </Grid>
                                <Grid item>
                                    {!tag.lock && (
                                        <LoadButton
                                            loading={deleting}
                                            variant="outlined"
                                            color="error"
                                            onClick={() =>
                                                setDeleteDialogState({
                                                    show: true,
                                                    message: T('SettingsTabTag_ConfirmDeleteMessage').replace(
                                                        '{0}',
                                                        tag.name ?? ''
                                                    ),
                                                    id: tagId
                                                })
                                            }
                                            disabled={tagUsage !== 0}
                                        >
                                            {T('Common_Delete')}
                                        </LoadButton>
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Form>
                )}
            </Formik>
            <DialogConfirmation
                title={T('SettingsTabTag_ConfirmDelete')}
                message={deleteDialogState?.message}
                show={deleteDialogState?.show}
                onClose={() => setDeleteDialogState(undefined)}
                onOk={deleteTag}
                warning
            />
        </>
    );
};

export default EditTagTitle;
