import { createContext } from 'react';

export interface ApplicationContextInterface {
    showHeaders: boolean;
    setShowHeaders: (state: boolean) => void;
}

const initialState: ApplicationContextInterface = {
    showHeaders: true,
    setShowHeaders: () => null
};

const ctxt = createContext<ApplicationContextInterface>(initialState);
ctxt.displayName = 'ApplicationContext';

export const ApplicationDefaultState = initialState;
export const ApplicationContext = ctxt;

export const ApplicationProvider = ctxt.Provider;
export const ApplicationConsumer = ctxt.Consumer;
