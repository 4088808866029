import React, { CSSProperties, FC, ReactNode, useContext } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Typography,
    Grid,
    Box,
    SxProps,
    Theme
} from '@mui/material';
import { LanguageContext } from 'contexts/languageContext';
import LoadButton from 'components/common/LoadButton';
import WarningIcon from '@mui/icons-material/Warning';

interface DialogBaseProps {
    children: ReactNode;
    title: string;
    show?: boolean;
    onCancel?: () => void;
    onOk: () => void;
    onClose?: () => void;
    okDisabled?: boolean;
    loading?: boolean;
    okTitle?: string;
    cancelTitle?: string;
    warning?: boolean;
    hideCancel?: boolean;
    hideOk?: boolean;
    size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
    titleHelpText?: string;
    style?: CSSProperties;
    titleClass?: string;
    modal?: boolean;
    extraButton?: ReactNode;
    sx?: SxProps<Theme>;
    fullScreen?: boolean;
    actionBarClassName?: string;
}

const DialogBase: FC<DialogBaseProps> = ({
    children,
    title,
    show,
    onCancel,
    onOk,
    onClose,
    okDisabled,
    loading,
    okTitle,
    cancelTitle,
    warning,
    hideCancel,
    hideOk,
    size,
    titleHelpText,
    style,
    titleClass,
    modal,
    extraButton,
    sx,
    fullScreen,
    actionBarClassName
}: DialogBaseProps) => {
    const { T } = useContext(LanguageContext);

    const actionMargin = fullScreen ? 1 : 2;
    const titlePadding = fullScreen ? 1 : undefined;
    const contentOverflow = fullScreen ? 'auto' : 'hidden';

    return (
        <Dialog
            open={show ?? false}
            onClose={(event, reason) => {
                if (modal && (reason == 'escapeKeyDown' || reason == 'backdropClick')) {
                    // in modal with ecape or backdrop click should not close dialog
                    return;
                }
                onClose?.();
            }}
            onKeyDown={({ key }) => {
                if (key === 'Enter') {
                    onOk();
                    onClose?.();
                }
            }}
            fullWidth
            maxWidth={size}
            style={style}
            sx={sx}
            fullScreen={fullScreen}
        >
            <DialogTitle className={titleClass} sx={{ backgroundColor: 'primary.main', padding: titlePadding }}>
                {warning && (
                    <Grid container alignItems="center">
                        <Grid item xs={1} style={{ marginRight: 8, paddingTop: 5 }}>
                            {warning && <WarningIcon fontSize="large" className="warning-icon" />}
                        </Grid>
                        <Grid item xs={10}>
                            <Typography variant="h5" component="div" style={{ color: 'white' }}>
                                {title}
                            </Typography>
                        </Grid>
                    </Grid>
                )}
                {!warning && (
                    <>
                        <Typography variant="h5" component="div" style={{ color: 'white' }}>
                            {title}
                        </Typography>
                        {titleHelpText && (
                            <Typography variant="body2" component="div" style={{ color: 'silver' }}>
                                {titleHelpText}
                            </Typography>
                        )}
                    </>
                )}
            </DialogTitle>
            <DialogContent
                sx={{
                    marginTop: '16px',
                    overflow: contentOverflow // hide scroll bar
                }}
            >
                {children}
            </DialogContent>
            {!(hideOk && hideCancel) && (
                <DialogActions sx={{ justifyContent: 'flex-start' }} className={actionBarClassName}>
                    <Box ml={2} mb={actionMargin}>
                        {hideOk !== true && (
                            <LoadButton
                                loading={loading}
                                disabled={okDisabled}
                                onClick={() => {
                                    onOk();
                                    onClose?.();
                                }}
                                style={{ marginRight: 16 }}
                                color={warning ? 'error' : 'primary'}
                            >
                                {okTitle ? T(okTitle) : T('Common_Ok')}
                            </LoadButton>
                        )}
                        {hideCancel !== true && (
                            <Button
                                type="button"
                                onClick={() => {
                                    onCancel?.();
                                    onClose?.();
                                }}
                                color="secondary"
                            >
                                {cancelTitle ? T(cancelTitle) : T('Common_Cancel')}
                            </Button>
                        )}
                        {extraButton}
                    </Box>
                </DialogActions>
            )}
        </Dialog>
    );
};

export default DialogBase;
