import React, { FC, useContext, useEffect, useState } from 'react';
import { Button, Container, Grid, Typography } from '@mui/material';
import { LanguageContext, LanguageTextFunc } from 'contexts/languageContext';
import { useErrorHandler } from 'components/hooks/useErrorHandler';
import { useSuccessHandler } from 'components/hooks/useSuccessHandler';
import { Form, Formik, FormikProps } from 'formik';
import * as Yup from 'yup';
import FormFieldText from 'components/common/FormFieldText';
import { useNavigate, useParams } from 'react-router-dom';
import { ResetPassword } from 'types/common';
import PublicService from 'services/publicService';
import LanguageSelectionFlags from 'components/common/LanguageSelectionFlags';
import { RegisterUser } from 'types/sp-api';
import LoadButton from 'components/common/LoadButton';
import LightTooltip from 'components/common/LightTooltip';
import PasswordTooltip from 'components/registration/PasswordTooltip';
import InfoIcon from '@mui/icons-material/Info';
import { StatusCodes } from 'http-status-codes';
import Validation from 'utils/validationHelper';

const getValidationSchema = (T: LanguageTextFunc, values: RegisterUser) => {
    return Yup.object().shape({
        password: Yup.string()
            .test('password-length', T('Registration_PasswordLength'), (value) =>
                Validation.isValidPasswordLength(value)
            )
            .test('password-chars', T('Registration_PasswordCharacters'), (value) => Validation.isValidPassword(value))
            .test('password-not-username', T('Registration_NotUsername'), (value) => values.name !== value)
            .required(T('Common_Required')),
        passwordConfirm: Yup.string()
            .required(T('Common_Required'))
            .test('match-to-password', T('Registration_PasswordMismatch'), (value) => values.password === value)
    });
};

const ResetPasswordView: FC = () => {
    const { T } = useContext(LanguageContext);
    const [sending, setSending] = useState(false);
    const { handleError } = useErrorHandler();
    const { handleSuccess } = useSuccessHandler();
    const [reseted, setReseted] = useState(false);
    const navigate = useNavigate();
    const [tokenStatus, setTokenStatus] = useState<StatusCodes>();
    const { validationToken } = useParams<'validationToken'>();

    const initialValues: ResetPassword = {
        username: '',
        password: '',
        passwordConfirm: '',
        token: validationToken ?? ''
    };

    const resetPassword = async (values: ResetPassword): Promise<void> => {
        setSending(true);

        try {
            await PublicService.resetPasswordAsync(values);
            setSending(false);
            handleSuccess(T('ResetPassword_SaveSuccess'));
            setReseted(true);
        } catch (error) {
            setSending(false);
            handleError(error, T('ResetPassword_SaveFailed'));
        }
    };

    const validateActionToken = async (token: string): Promise<void> => {
        const status = await PublicService.validateTokenAsync(token);
        setTokenStatus(status);
    };

    useEffect(() => {
        validateActionToken(validationToken ?? '');
    }, [validationToken]);

    return (
        <Container sx={{ paddingTop: '56px' }}>
            <Formik
                initialValues={initialValues}
                enableReinitialize
                onSubmit={resetPassword}
                validationSchema={() => Yup.lazy((values) => getValidationSchema(T, values))}
            >
                {({ dirty, isValid }: FormikProps<ResetPassword>) => {
                    return (
                        <Form>
                            {tokenStatus && tokenStatus != StatusCodes.OK && (
                                <Grid item xs={12} container justifyContent="center" className="error-border">
                                    <Typography variant="h5" color="error">
                                        {T(`ResetPassword_TokenStatus${tokenStatus}`)}
                                    </Typography>
                                </Grid>
                            )}
                            <Grid container spacing={4} justifyContent="center">
                                <Grid item xs={12} container justifyContent="center">
                                    <Typography variant="h4">{T('ResetPassword_Title')}</Typography>
                                </Grid>
                                <Grid item xs={12} container justifyContent="center">
                                    <Grid item xs={5} sx={{ pl: 1 }}>
                                        <Typography>{T('ResetPassword_HelpText')}</Typography>
                                    </Grid>
                                </Grid>
                                <Grid item xs={5} container spacing={3}>
                                    <Grid item xs={10}>
                                        <FormFieldText
                                            name="password"
                                            label={T('Registration_FieldPassword')}
                                            password
                                            readonly={reseted}
                                        />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <LightTooltip title={<PasswordTooltip />}>
                                            <InfoIcon color="info" />
                                        </LightTooltip>
                                    </Grid>
                                    <Grid item xs={10}>
                                        <FormFieldText
                                            name="passwordConfirm"
                                            label={T('Registration_FieldPasswordConfirm')}
                                            password
                                            readonly={reseted}
                                        />
                                    </Grid>
                                    <Grid item xs={12} container justifyContent="center">
                                        <Grid item sx={{ pt: 1, pb: 3 }}>
                                            <LoadButton
                                                loading={sending}
                                                disabled={!dirty || !isValid || reseted}
                                                type="submit"
                                            >
                                                {T('ResetPassword_SendButton')}
                                            </LoadButton>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Form>
                    );
                }}
            </Formik>
            {reseted && (
                <Grid container spacing={4} justifyContent="center" sx={{ paddingBottom: 24 }}>
                    <Grid item xs={12} container justifyContent="center">
                        <Grid item xs={5}>
                            <Typography>{T('ResetPassword_ResetedHelpText')}</Typography>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} container justifyContent="center">
                        <Grid item sx={{ paddingTop: 8, paddingBottom: 24 }}>
                            <Button variant="contained" color="secondary" onClick={() => navigate('/')}>
                                {T('Common_ReturnToDashboard')}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            )}
            <LanguageSelectionFlags />
        </Container>
    );
};

export default ResetPasswordView;
