import { SvgIconComponent } from '@mui/icons-material';
import { ReactNode } from 'react';
import { UserRole } from './auth';
import { Membership, ReportFilter, ReportInclude } from './sp-api';

export interface LanguageText {
    key: string;
    value: string;
}

export interface KeyValue {
    key?: string | number; // key -> field key
    text?: string; // -> directly shown text
    value?: string; // value -> language text key value
    tooltip?: string; // tooltip -> show for selection line
}

export type DbCodeSets =
    | 'nationality'
    | 'maritalStatus'
    | 'membershipPrerequisite'
    | 'terminationReason'
    | 'membershipType'
    | 'organizationType'
    | 'religiousInfo'
    | 'position'
    | 'closeRelative'
    | 'contractType'
    | 'credentialType'
    | 'credentialAttachment'
    | 'credentialNote'
    | 'credentialStatus'
    | 'credentialMarriageLicense';

export enum ChurchType {
    Unknown = 0,
    SHK = 1,
    Local = 2
}

export enum OrganizationType {
    Unknown = 0,
    ReligiousCommunity = 1,
    LocalCommunity = 2,
    Association = 3,
    ContactInfo = 4
}

export enum AddressType {
    Unknown = 0,
    Domestic = 1,
    Foreign = 2
}

export enum MembershipType {
    Unknown = 0,
    FullMember = 1,
    MemberNoVote = 2,
    Child = 3,
    RegistryMember = 4,
    Interested = 5,
    RegisterContact = 6
}

export enum ReligiousInfoType {
    Unknown = 0,
    Conversion = 1,
    Baptism = 2
}

export enum Position {
    Unknown = 0,
    Pastori = 1,
    Vanhin = 2,
    Puheenjohtaja = 3,
    Varapuheenjohtaja = 4,
    Jasen = 5,
    Taloudenhoitaja = 6,
    Sihteeri = 7
}

export enum TerminationReason {
    Unknown = 0,
    OwnOral = 1,
    OwnWritten = 2,
    Transfered = 3,
    DVV = 4,
    Terminated = 5,
    Deceased = 6,
    Lost = 7
}

export enum MemberPrerequisite {
    Unknown = 0,
    Babtized = 1,
    Recognition = 2,
    Transfer = 3,
    Child = 4,
    Interested = 5
}

export interface MembershipListItem extends Membership {
    organizationType?: string;
    valid: boolean;
}

export enum AuditType {
    Unknown = 0,
    UI = 1,
    API = 2,
    IDP = 3
}

export interface UrlRedirectParams {
    userId: string;
    validationToken: string;
}

export interface Registration {
    userId: number;
    username: string;
    password: string;
    passwordConfirm: string;
    token: string;
    otpToken: string;
    termsAccepted: boolean;
    passwordAgreement: boolean;
}

export interface ResetPassword {
    username: string;
    password: string;
    passwordConfirm: string;
    token: string;
}

export interface MailData {
    personId: number;
    subject?: string;
    content?: string;
    contentType?: string;
    templateId?: string;
    templateData?: unknown;
    validationToken?: string;
}

export interface SaveResult {
    new: boolean;
    id: number;
    membershipEnded?: boolean;
}

export interface ConsentList {
    nameConsent?: boolean;
    phoneConsent?: boolean;
    addressConsent?: boolean;
    emailConsent?: boolean;
    tagConsent?: boolean;
}

export interface TabItem {
    id: number;
    title: string;
    content: ReactNode;
    hidden?: boolean;
}

export type Order = 'asc' | 'desc';

export enum AppLanguage {
    Finnish = 'fi',
    Swedish = 'sv',
    English = 'en'
}

export interface QueryParams {
    query?: string;
    sort?: string;
    page?: string;
    size?: string;
}

export type DialogOpenState = boolean | 'add' | 'edit' | undefined;

export enum InfoType {
    Baptism = 'baptism',
    Conversion = 'conversion'
}

export enum PersonCloseRelativeType {
    Unknown = 0,
    Spouse = 1,
    Guardian = 2,
    Child = 3,
    Caregiver = 4,
    GuardianOfInterest = 5
}

export interface ReportBase {
    filename: string;
}

export interface TransferCertificate extends ReportBase {
    membershipId: number;
    senderOrganizationId: number;
    endMembership: boolean;
    targeOrganizationId?: number;
}

export interface BaptismCertificate extends ReportBase {
    membershipId: number;
}

export interface MembershipReport extends ReportBase {
    membershipId: number;
    addSignatureRow: boolean;
    addSsn: boolean;
    addCertificateText: boolean;
}

export interface PersonalInformationReport extends ReportBase {
    personId: number;
}

export enum AuditInfoType {
    Unknown = 0,
    UI = 1,
    API = 2,
    IDP = 3
}

export interface MembersListReportForm extends ReportBase {
    include: ReportInclude;
    filter: ReportFilter;
    groupName?: string;
    filterText?: string;
    globalGroupName?: string;
    order?: 'name' | 'age' | 'birthday' | 'zipcode';
}

export interface MembersListWithAgeReportForm extends ReportBase {
    year: number;
    order?: 'name' | 'age' | 'birthday';
    allAfterAge?: number;
}

export interface SmsUsageListReportForm extends ReportBase {
    beginDate?: Date;
    endDate?: Date;
}

export const MembershipType_FakeQueryResultMember = 2147483647;

export interface DeleteDialogState {
    show: boolean;
    message: string;
    id: number;
}

export interface Sort {
    empty: boolean;
    sorted: boolean;
    unsorted: boolean;
}

export interface Pageable {
    offset: number;
    pageNumber: number;
    pageSize: number;
    paged: boolean;
    sort: Sort;
    unpaged: boolean;
}

export interface PagedResult<T> {
    content: T;
    empty: boolean;
    first: boolean;
    last: boolean;
    number: number;
    numberOfElements: number;
    pageable: Pageable;
    size: number;
    sort: Sort;
    totalElements: number;
    totalPages: number;
}

export type ReportType = 'pdf' | 'csv' | 'sticker3x8' | 'sticker3x10';

export interface MainMenuItem {
    title: string;
    url: string;
    role?: UserRole | UserRole[];
    class?: string;
    submenu?: SubMenuItem[];
    hidden?: boolean;
    iconMenu?: boolean;
    icon?: SvgIconComponent;
    badgeContent?: number;
}

export interface SubMenuItem {
    title: string;
    url: string;
    role?: UserRole | UserRole[];
    class?: string;
}

export type errorHandlerFunc = (error: Error | unknown, title?: string, titleKey?: string) => void;
export type ErrorHandler = errorHandlerFunc;

export type GlobalGroupRegisterType = 'senior' | 'prisonEvangelist' | 'webinarMember';

export interface GlobalGroupRegisterReportForm extends ReportBase {
    type: GlobalGroupRegisterType;
    include?: ReportInclude;
    filter: ReportFilter;
    groupName?: string;
    filterText?: string;
    globalGroupName?: string;
    order?: 'name' | 'age' | 'birthday' | 'zipcode';
}

export interface CongregationRegisterReportForm extends ReportBase {
    groupName?: string;
    filterText?: string;
    include?: ReportInclude;
    filter: ReportFilter;
}

export interface LegacyReport {
    organization: string;
    contact: string;
    file1?: File;
    file2?: File;
}
