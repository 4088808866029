import { UserRole } from 'types/auth';
import { OrganizationType } from 'types/common';

type typeToNameFunc = (type?: number) => string;
type roleToIdFunc = (role?: UserRole) => number;
type isNumberFunc = (value?: unknown) => boolean;
type allowedRoleFunc = (role?: UserRole, userRole?: UserRole) => boolean;
type allowedRoleByIdFunc = (roleId?: number, userRoleId?: number) => boolean;
type roleIdToRoleFunc = (roleId?: number) => UserRole;
type isLocalYhdyskuntaFunc = (type?: number) => boolean;
type isYhdyskuntaFunc = (type?: number) => boolean;
type isYhdistysFunc = (type?: number) => boolean;
type isContactInfoFunc = (type?: number) => boolean;
type isSpouseFunc = (type?: number) => boolean;
type isFormerSpouseFunc = (type?: number) => boolean;
type languageToNameFunc = (lang?: string) => string;

interface UtilsContext {
    OrganizationTypeToName: typeToNameFunc;
    ContractTypeToName: typeToNameFunc;
    RoleIdToName: typeToNameFunc;
    RoleToId: roleToIdFunc;
    IsNumber: isNumberFunc;
    AllowedRole: allowedRoleFunc;
    AllowedRoleById: allowedRoleByIdFunc;
    RoleIdToRole: roleIdToRoleFunc;
    IsLocalYhdyskunta: isLocalYhdyskuntaFunc;
    IsYhdyskunta: isYhdyskuntaFunc;
    IsYhdistys: isYhdistysFunc;
    IsContactInfo: isContactInfoFunc;
    IsSpouse: isSpouseFunc;
    IsFormerSpouse: isFormerSpouseFunc;
    AttachmentTypeToName: typeToNameFunc;
    CredentialApplicationTypeToName: typeToNameFunc;
    CredentialApplicationNoteTypeToName: typeToNameFunc;
    CredentialStatusToName: typeToNameFunc;
    CredentialMarriageLicenseStatusToName: typeToNameFunc;
    LanguageToName: languageToNameFunc;
}

const organizationTypeToName = (type?: number): string => {
    if (!type) return '';

    switch (type) {
        case OrganizationType.ReligiousCommunity:
            return 'CodeSet_organizationType_yhdyskunta';
        case OrganizationType.LocalCommunity:
            return 'CodeSet_organizationType_yhdyskuntaseurakunta';
        case OrganizationType.Association:
            return 'CodeSet_organizationType_yhdistys';
    }

    return 'Common_Unknown';
};

const isLocalYhdyskunta = (type?: number): boolean => {
    return type === OrganizationType.LocalCommunity;
};

const isYhdyskunta = (type?: number): boolean => {
    return type === OrganizationType.ReligiousCommunity;
};

const isYhdistys = (type?: number): boolean => {
    return type === OrganizationType.Association;
};

const isContactInfo = (type?: number): boolean => {
    return type === OrganizationType.ContactInfo;
};

const contractTypeToName = (type?: number): string => {
    if (!type) return '';

    switch (type) {
        case 1:
            return 'CodeSet_contractType_sp_käyttösopimus';
        case 2:
            return 'CodeSet_contractType_sp_pääkäyttäjäsopimus';
        case 3:
            return 'CodeSet_contractType_sp_vaitiolosopimus';
    }

    return 'Common_Unknown';
};

const attachmentTypeToName = (type?: number): string => {
    if (!type) return '';

    switch (type) {
        case 1:
            return 'CodeSet_credentialAttachment_valokuva';
        case 2:
            return 'CodeSet_credentialAttachment_ansioluettelo';
        case 3:
            return 'CodeSet_credentialAttachment_todistusopinnoista';
        case 4:
            return 'CodeSet_credentialAttachment_todistusturvakoulutus';
        case 5:
            return 'CodeSet_credentialAttachment_todistusvihkikoulutus';
        case 6:
            return 'CodeSet_credentialAttachment_suostumusvihkijaksi';
        case 7:
            return 'CodeSet_credentialAttachment_muu';
        case 8:
            return 'CodeSet_credentialAttachment_kortinkuva';
    }

    return 'Common_Unknown';
};

const credentialApplicationTypeToName = (type?: number): string => {
    if (!type) return '';

    switch (type) {
        case 1:
            return 'CodeSet_credentialType_seurakunta';
        case 2:
            return 'CodeSet_credentialType_lapsijanuoriso';
        case 3:
            return 'CodeSet_credentialType_pastori';
        case 4:
            return 'CodeSet_credentialType_lahetystyo';
        case 5:
            return 'CodeSet_credentialType_lahetystyopastori';
    }

    return 'Common_Unknown';
};

const credentialApplicationNoteTypeToName = (type?: number): string => {
    if (!type) return '';

    switch (type) {
        case 1:
            return 'CodeSet_credentialNote_valtakirja';
        case 2:
            return 'CodeSet_credentialNote_vihkioikeus';
        case 3:
            return 'CodeSet_credentialNote_puutteet';
        case 4:
            return 'CodeSet_credentialNote_valtakirjavaihdos';
    }

    return 'Common_Unknown';
};

const credentialStatusToName = (type?: number): string => {
    if (!type) return '';

    switch (type) {
        case 1:
            return 'CodeSet_credentialStatus_uusi';
        case 2:
            return 'CodeSet_credentialStatus_kesken';
        case 3:
            return 'CodeSet_credentialStatus_ehdollisestihyvaksytty';
        case 4:
            return 'CodeSet_credentialStatus_hyvaksytty';
        case 5:
            return 'CodeSet_credentialStatus_hylatty';
    }

    return 'Common_Unknown';
};

const credentialMarriageLicenseStatusToName = (type?: number): string => {
    if (!type) return '';

    switch (type) {
        case 1:
            return 'CodeSet_credentialMarriageLicense_haettu';
        case 2:
            return 'CodeSet_credentialMarriageLicense_kasitelty';
        case 3:
            return 'CodeSet_credentialMarriageLicense_lahetetty';
        case 4:
            return 'CodeSet_credentialMarriageLicense_myonnetty';
        case 5:
            return 'CodeSet_credentialMarriageLicense_hylatty';
    }

    return 'Common_Unknown';
};

const roleIdToName = (type?: number): string => {
    if (!type) return '';

    switch (type) {
        case 1:
            return 'RoleType_ROLE_ADMIN';
        case 2:
            return 'RoleType_ROLE_SUPERUSER';
        case 3:
            return 'RoleType_ROLE_USER';
        case 4:
            return 'RoleType_ROLE_CLERK';
        case 5:
            return 'RoleType_ROLE_PERSON';
    }

    return 'Common_Unknown';
};

const roleToId = (role?: UserRole): number => {
    if (!role) return 0;

    switch (role) {
        case UserRole.Admin:
            return 1;
        case UserRole.SuperUser:
            return 2;
        case UserRole.User:
            return 3;
        case UserRole.Clerk:
            return 4;
        case UserRole.Person:
            return 5;
    }

    return 0;
};

const roleIdToRole = (roleId?: number): UserRole => {
    if (!roleId) return UserRole.Unknown;

    switch (roleId) {
        case 1:
            return UserRole.Admin;
        case 2:
            return UserRole.SuperUser;
        case 3:
            return UserRole.User;
        case 4:
            return UserRole.Clerk;
        case 5:
            return UserRole.User;
    }
    return UserRole.Unknown;
};

const allowedRoleById = (roleId?: number, userRoleId?: number): boolean => {
    return allowedRole(roleIdToRole(roleId), roleIdToRole(userRoleId));
};

const allowedRole = (role?: UserRole, userRole?: UserRole): boolean => {
    if (!role) return false;
    if (!UserRole) return false;

    return roleHierarchyLevel(role) <= roleHierarchyLevel(userRole);
};

const roleHierarchyLevel = (role?: UserRole): number => {
    if (!role) return 0;

    switch (role) {
        case UserRole.Admin:
            return 4;
        case UserRole.Clerk:
            return 3;
        case UserRole.SuperUser:
            return 2;
        case UserRole.User:
            return 1;
        default:
            return 0;
    }
};

const isNumber = (value: unknown): boolean => {
    return typeof value === 'number' && isFinite(value);
};

const isSpouse = (type?: number): boolean => {
    return type === 1;
};

const isFormerSpouse = (type?: number): boolean => {
    return type === 7;
};

const languageToName = (lang?: string): string => {
    if (!lang) return '';

    switch (lang) {
        case 'fi':
            return 'Language_Finnish';
        case 'sv':
            return 'Language_Swedish';
        case 'en':
            return 'Language_English';
    }

    return 'Common_Unknown';
};

const ctx: UtilsContext = {
    OrganizationTypeToName: organizationTypeToName,
    ContractTypeToName: contractTypeToName,
    RoleIdToName: roleIdToName,
    RoleToId: roleToId,
    IsNumber: isNumber,
    AllowedRole: allowedRole,
    AllowedRoleById: allowedRoleById,
    RoleIdToRole: roleIdToRole,
    IsLocalYhdyskunta: isLocalYhdyskunta,
    IsYhdyskunta: isYhdyskunta,
    IsYhdistys: isYhdistys,
    IsContactInfo: isContactInfo,
    IsSpouse: isSpouse,
    IsFormerSpouse: isFormerSpouse,
    AttachmentTypeToName: attachmentTypeToName,
    CredentialApplicationTypeToName: credentialApplicationTypeToName,
    CredentialApplicationNoteTypeToName: credentialApplicationNoteTypeToName,
    CredentialStatusToName: credentialStatusToName,
    CredentialMarriageLicenseStatusToName: credentialMarriageLicenseStatusToName,
    LanguageToName: languageToName
};

export default ctx;
