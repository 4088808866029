import { createContext } from 'react';

export interface NotificationsContextInterface {
    notificationCount: number;
    setNotificationCount: (count: number) => void;
}

const initialState: NotificationsContextInterface = {
    notificationCount: 0,
    setNotificationCount: () => null
};

const ctxt = createContext<NotificationsContextInterface>(initialState);
ctxt.displayName = 'NotificationsContext';

export const NotificationsDefaultState = initialState;
export const NotificationsContext = ctxt;

export const NotificationsProvider = ctxt.Provider;
export const NotificationsConsumer = ctxt.Consumer;
