import axios, { AxiosRequestConfig, AxiosResponse, CancelToken } from 'axios';
import { LegacyReport, ResetPassword } from 'types/common';
import Config from 'config/config';
import {
    CredentialApplication,
    CredentialApplicationInput,
    CredentialApplicationReportTemplate,
    ForgotPassword,
    PdfParams,
    RegisterUser
} from 'types/sp-api';
import { StatusCodes } from 'http-status-codes';

class PublicService {
    private authAxiosInstance = axios.create(); // create separate instance to avoid interceptors

    public async saveUsernameAndPasswordAsync(registerUser: RegisterUser): Promise<void> {
        const data: RegisterUser = {
            name: registerUser.name,
            password: registerUser.password,
            otpPassword: registerUser.otpPassword,
            actionToken: registerUser.actionToken
        };

        await this.authAxiosInstance.post(
            `${Config.API_BASE_URL}/public/registration`,
            data,
            this.getAxiosConfig(registerUser.actionToken ?? '')
        );
    }

    public async sendPasswordResetAsync(forgotPassword: ForgotPassword): Promise<void> {
        const data: ForgotPassword = {
            username: forgotPassword.username,
            callbackUrl: `${Config.APP_URL}/resetPassword/`,
            templateId: Config.SGID_RESETPASSWORD
        };

        await this.authAxiosInstance.post(`${Config.API_BASE_URL}/public/forgotPassword`, data);
    }

    public async resetPasswordAsync(resetPassword: ResetPassword): Promise<void> {
        const data = {
            password: resetPassword.password,
            actionToken: resetPassword.token
        };

        await this.authAxiosInstance.post(
            `${Config.API_BASE_URL}/public/resetPassword`,
            data,
            this.getAxiosConfig(resetPassword.token)
        );
    }

    public async validateTokenAsync(token: string): Promise<StatusCodes> {
        let response;
        try {
            response = await this.authAxiosInstance.get<string>(`${Config.API_BASE_URL}/public/validateToken/${token}`);
        } catch (error) {
            if (axios.isAxiosError(error)) {
                return error.response?.status as StatusCodes;
            }
        }
        return response?.status as StatusCodes;
    }

    public async saveCredentialApplicationAsync(
        applicationInput: CredentialApplicationInput,
        attachments: File[]
    ): Promise<CredentialApplication> {
        const sendPromises: Promise<AxiosResponse<unknown, unknown>>[] = new Array<
            Promise<AxiosResponse<unknown, unknown>>
        >();
        const appSend = this.authAxiosInstance.post<CredentialApplication>(
            `${Config.API_BASE_URL}/public/credentialApplication`,
            applicationInput
        );
        sendPromises.push(appSend);

        applicationInput.attachmentList?.forEach((attachment, index) => {
            const fileSend = this.uploadCredentialApplicationAttachmentFileAsync(
                attachment.document?.blobName ?? '',
                attachments[index]
            );
            sendPromises.push(fileSend);
        });

        await Promise.all(sendPromises);
        return (await appSend).data;
    }

    private async uploadCredentialApplicationAttachmentFileAsync(
        filename: string,
        file: File,
        cancelToken?: CancelToken
    ): Promise<AxiosResponse<unknown, unknown>> {
        const formData = new FormData();
        formData.append('file', file);

        return this.authAxiosInstance.post(
            `${Config.API_BASE_URL}/public/credentialApplication/attachments/file/${encodeURI(filename)}`,
            formData,
            {
                headers: { 'content-type': 'multipart/form-data' },
                cancelToken: cancelToken
            }
        );
    }

    public async generateCredentialApplicationReportAsync(
        reportToken: string,
        filename: string,
        language: string,
        cancelToken?: CancelToken
    ): Promise<AxiosResponse> {
        const template: CredentialApplicationReportTemplate = {
            language: language,
            outputFilename: filename,
            templateName: 'CredentialApplicationReport',
            applicationId: 0,
            organizationId: 0
        };

        const pdfParams: PdfParams = {
            template: template
        };

        return this.authAxiosInstance.put(
            `${Config.API_BASE_URL}/public/credentialApplication/report/${reportToken}`,
            pdfParams,
            {
                responseType: 'blob',
                cancelToken: cancelToken
            }
        );
    }

    public async saveLegacyReportAsync(
        report: LegacyReport,
        cancelToken?: CancelToken
    ): Promise<AxiosResponse<unknown, unknown>> {
        const formData = new FormData();
        if (report.file1) {
            formData.append('file1', report.file1);
        }
        if (report.file2) {
            formData.append('file2', report.file2);
        }

        formData.append('organization', report.organization);
        formData.append('contact', report.contact);

        return this.authAxiosInstance.post(`${Config.API_BASE_URL}/public/legacyReport/file`, formData, {
            headers: { 'content-type': 'multipart/form-data' },
            cancelToken: cancelToken
        });
    }

    private getAxiosConfig(token: string): AxiosRequestConfig {
        return {
            headers: {
                [Config.ACTION_TOKEN_HEADER]: token
            }
        };
    }
}

export default new PublicService();
