import { createContext } from 'react';
import { AxiosResponse } from 'axios';
import { AppLanguage } from 'types/common';

export interface LangString {
    key: string;
    value: string;
}

export interface CountryCode {
    code: string;
    value: string;
}

export interface Language {
    lan: string;
    value: string;
}

export type LanguageTextFunc = (key?: string) => string;

export type LanguageTextExtFunc = (key: string, lang: AppLanguage) => Promise<string>;

export type LoadLanguageStrings = () => Promise<AxiosResponse<LangString>>;

export interface LanguageContextInterface {
    langStrings: LangString[];
    languageCodes: Array<Language>;
    countryCodes: Array<CountryCode>;
    T: LanguageTextFunc;
    TExt: LanguageTextExtFunc;
    setLanguageStrings: (strings: LangString[]) => void;
    setLanguage: (language: AppLanguage) => Promise<void>;
}

const initialState: LanguageContextInterface = {
    langStrings: Array<LangString>(),
    languageCodes: Array<Language>(),
    countryCodes: Array<CountryCode>(),
    T: () => '',
    TExt: () => Promise.resolve(''),
    setLanguageStrings: () => null,
    setLanguage: () => Promise.resolve()
};

const ctxt = createContext<LanguageContextInterface>(initialState);
ctxt.displayName = 'LanguageContext';

export const LanguageDefaultState = initialState;
export const LanguageContext = ctxt;

export const LanguageProvider = ctxt.Provider;
export const LanguageConsumer = ctxt.Consumer;
