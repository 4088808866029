import React, { FC, useContext } from 'react';
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { LanguageContext } from 'contexts/languageContext';
import { Tag } from 'types/sp-api';

interface SelectTagTitleProps {
    selectedTagId?: number;
    tagTitles: Tag[];
    selectTagId: (id?: number) => void;
}

const SelectTagTitle: FC<SelectTagTitleProps> = ({ selectedTagId, tagTitles, selectTagId }: SelectTagTitleProps) => {
    const { T } = useContext(LanguageContext);

    return (
        <FormControl size="small" variant="outlined" fullWidth>
            <InputLabel id={`select-tag-title`} variant="outlined">
                {T('SettingsTabTag_TagTitle')}
            </InputLabel>
            <Select
                style={{ minWidth: 150 }}
                labelId={`select-tag_title`}
                variant="outlined"
                onChange={(event: SelectChangeEvent<number>) => {
                    selectTagId(event.target.value as number);
                }}
                value={selectedTagId ?? 0}
                label={T('SettingsTabTag_TagTitle')}
            >
                <MenuItem disabled key={0} value={0}>
                    {T('SettingsTabTag_SelectTitle')}
                </MenuItem>
                {tagTitles
                    .map((item) => ({ key: item.id, text: item.name }))
                    .map((item, index) => (
                        <MenuItem key={index} value={item.key}>
                            {item.text}
                        </MenuItem>
                    ))}
            </Select>
        </FormControl>
    );
};

export default SelectTagTitle;
