import React, { FC, useContext } from 'react';
import { Typography, Grid, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';
import { LanguageContext } from 'contexts/languageContext';
import WaitScreen from 'components/common/WaitScreen';
import { MembershipTypes } from 'types/sp-api';
import config from 'config/config';
import constants from 'config/constants';

interface MembershipCountsProps {
    counts?: MembershipTypes;
}

const MembershipCounts: FC<MembershipCountsProps> = ({ counts }: MembershipCountsProps) => {
    const { T } = useContext(LanguageContext);
    if (!counts) return <WaitScreen />;

    return (
        <Grid item xs={12}>
            <TableContainer>
                <Table size="small">
                    <TableBody>
                        <TableRow>
                            <TableCell>
                                <Typography color="textSecondary">
                                    {T('CodeSet_membershipType_jäsen_täysivaltainen')}
                                </Typography>
                            </TableCell>
                            <TableCell align="right">
                                <Typography color="textSecondary">{counts.type1}</Typography>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                <Typography color="textSecondary">{T('CodeSet_membershipType_jäsen')}</Typography>
                            </TableCell>
                            <TableCell align="right">
                                <Typography color="textSecondary">{counts.type2}</Typography>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                <Typography color="textSecondary">{T('CodeSet_membershipType_lapsijäsen')}</Typography>
                            </TableCell>
                            <TableCell align="right">
                                <Typography color="textSecondary">{counts.type3}</Typography>
                            </TableCell>
                        </TableRow>
                        {config.CUSTOMER === constants.Customers.Shk && (
                            <TableRow>
                                <TableCell>
                                    <Typography color="textSecondary">
                                        {T('CodeSet_membershipType_rekisterijäsen')}
                                    </Typography>
                                </TableCell>
                                <TableCell align="right">
                                    <Typography color="textSecondary">{counts.type4}</Typography>
                                </TableCell>
                            </TableRow>
                        )}
                        {config.CUSTOMER === constants.Customers.Advk && (
                            <TableRow>
                                <TableCell>
                                    <Typography color="textSecondary">
                                        {T('CodeSet_membershipType_kiinnostunut')}
                                    </Typography>
                                </TableCell>
                                <TableCell align="right">
                                    <Typography color="textSecondary">{counts.type5}</Typography>
                                </TableCell>
                            </TableRow>
                        )}
                        <TableRow>
                            <TableCell>
                                <Typography color="textSecondary">
                                    <strong>{T('Common_Total')}</strong>
                                </Typography>
                            </TableCell>
                            <TableCell align="right">
                                <Typography color="textSecondary">
                                    <strong>{counts.total}</strong>
                                </Typography>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </Grid>
    );
};

export default MembershipCounts;
