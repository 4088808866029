import React, { FC } from 'react';
import { ReactComponent as ChurchIconSvg } from './church.svg';
import { getThemeColor } from 'config/theme';

interface ChurchIconProps {
    fontSize?: 'large' | 'small' | 'default';
    color?: 'primary' | 'secondary' | 'default' | 'alert';
    fixMargin?: boolean;
    titleAccess?: string;
}

const ChurchIcon: FC<ChurchIconProps> = ({ fontSize, color, fixMargin, titleAccess }: ChurchIconProps) => {
    let width = 24;

    if (fontSize === 'large') {
        width = 35;
    } else if (fontSize === 'small') {
        width = 20;
    }

    let marginTop = -9;

    if (fixMargin) {
        marginTop = -5;
    }

    return (
        <div style={{ maxWidth: width, maxHeight: width, width: width, height: width, marginTop: marginTop }}>
            <ChurchIconSvg fill={getThemeColor(color)} title={titleAccess} />
        </div>
    );
};

export default ChurchIcon;
