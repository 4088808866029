import React, { FC, useContext } from 'react';
import { Button, Container, Grid, Typography } from '@mui/material';
import { LanguageContext } from 'contexts/languageContext';
import BreadcrumbBar from 'components/common/BreadcrumbBar';
import { useNavigate } from 'react-router-dom';
import config from 'config/config';
import constants from 'config/constants';

const MobileAuthPolicyView: FC = () => {
    const { T } = useContext(LanguageContext);
    const navigate = useNavigate();

    return (
        <Container>
            <Grid container>
                <BreadcrumbBar />
            </Grid>
            <Grid container direction="column" spacing={3}>
                <Grid item>
                    <Typography variant="h4">Sähköpaimen sÄppi mobiilisovelluksen tietosuojakäytäntö</Typography>
                </Grid>
                <Grid item>
                    <Typography variant="body1">
                        Tervetuloa tutustumaan Sähköpaimen sÄppi (&quot;sovellus&quot;) mobiilisovelluksen
                        tietosuojakäytäntöön. Sovellus on osa Sähköpaimen-jäsenrekisteriä.
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant="h5">1. Mihin sovellusta käytetään</Typography>
                </Grid>
                <Grid item>
                    <Typography variant="body1">
                        Sovellus aktivoidaan Sähköpaimen-jäsenrekisterin käyttäjätunnuksen rekisteröinnin yhteydessä
                        lukemalla qr-koodi Sähköpaimen-jäsenrekisterin rekisteröintisivulta. Sovellus tuottaa käyttäjän
                        henkilökohtaisia vahvistuskoodeja sisäänkirjautumista varten. Koodit ovat voimassa yhden
                        minuutin, jonka jälkeen luodaan uusi koodi.
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant="h5">2. Mitä tietoja sovelluksessa kerätään</Typography>
                </Grid>
                <Grid item>
                    <Typography variant="body1">
                        Sovellus tallentaa aktivointivaiheessa käyttäjätunnuksen ja qr-koodista saamansa satunnaisen
                        merkkijonon. Muita tietoja käyttäjästä tai sovelluksen käytöstä tai toiminnasta ei tallenneta.
                        Käyttäjätunnus on käyttäjän itse valitsema, se voi sisältää henkilön nimen, mikäli käyttäjä on
                        sen sisällöksi kirjoittanut.
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant="h5">3. Miten tiedot on tallennettu</Typography>
                </Grid>
                <Grid item>
                    <Typography variant="body1">
                        Sovellus tallentaa keräämänsä tiedot mobiililaitteen suojattu tietovarastoon. Suojattu
                        tietovarasto on vain sovelluksen käytettävissä, muut sovellukset eivät pääse lukemaan tai
                        kirjoittamaan ko. tietoihin.
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant="h5">4. Tietojen jakaminen</Typography>
                </Grid>
                <Grid item>
                    <Typography variant="body1">
                        Sovelluksesta ei jaeta tietoja mihinkään. Vahvistuskoodin käyttäjä itse kirjoittaa
                        Sähköpaimen-jäsenrekisteriin kirjautuessaan. Kirjautumisessakaan vahvistuskoodia ei tallenneta,
                        sen oikeellisuus tarkistetaan ja sitten se unohdetaan.
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant="h4">Lisätietoja</Typography>
                </Grid>
                <MobileAuthPolicyInfo />
                <Grid item>
                    <Typography variant="body2" color="textSecondary">
                        Käytäntö päivitetty 13.4.2021
                    </Typography>
                </Grid>
                <Grid item>
                    <Button variant="contained" color="secondary" onClick={() => navigate('/')}>
                        {T('Common_ReturnToDashboard')}
                    </Button>
                </Grid>
            </Grid>
        </Container>
    );
};

const MobileAuthPolicyInfoShk: FC = () => {
    return (
        <Grid item>
            <Typography variant="body1">
                Voit kysyä halutessasi lisätietoja sovelluksesta ja sen tietosuojakäytännöstä Suomen Helluntaikirkosta.
            </Typography>
            <br />
            <Typography variant="body1">
                <b>Suomen Helluntaikirkko</b>
            </Typography>
            <Typography variant="body1">Puhelin: 045 632 7848</Typography>
            <Typography variant="body1">Sähköpostiosoite: toimisto@helluntaikirkko.fi</Typography>
        </Grid>
    );
};

const MobileAuthPolicyInfoAdvk: FC = () => {
    return (
        <Grid item>
            <Typography variant="body1">
                Voit kysyä halutessasi lisätietoja sovelluksesta ja sen tietosuojakäytännöstä Suomen Adventtikirkosta.
            </Typography>
            <br />
            <Typography variant="body1">
                <b>Suomen Adventtikirkko</b>
            </Typography>
            <Typography variant="body1">Puhelin: 03 3611 111</Typography>
            <Typography variant="body1">Sähköpostiosoite: jasenrekisteri@adventtikirkko.fi</Typography>
        </Grid>
    );
};

const MobileAuthPolicyInfo: FC = () => {
    if (config.CUSTOMER == constants.Customers.Advk) return <MobileAuthPolicyInfoAdvk />;

    return <MobileAuthPolicyInfoShk />;
};

export default MobileAuthPolicyView;
