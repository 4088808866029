import React, { ChangeEvent, FC, useContext, useEffect, useState } from 'react';
import { Container, Grid, Paper, Tab, Tabs } from '@mui/material';
import { Settings } from '@mui/icons-material';
import BreadcrumbBar from '../common/BreadcrumbBar';
import ViewTitle from '../common/ViewTitle';
import { LanguageContext } from 'contexts/languageContext';
import TabPanel from 'components/common/TabPanel';
import { TabItem } from 'types/common';
import { useParams } from 'react-router-dom';
import AdminToolsTabDvvAddressImport from './AdminToolsTabDvvAddressImport';

const AdminToolsView: FC = () => {
    const { page } = useParams<'page'>();
    const [selectedTab, setSelectedTab] = useState(page === 'DvvAddressImport' ? 1 : 0);
    const { T } = useContext(LanguageContext);

    const handleChange = (_: ChangeEvent<unknown>, newValue: number) => {
        setSelectedTab(newValue);
    };

    const tabs: TabItem[] = [
        {
            id: 0,
            title: 'DvvAddressImport',
            hidden: false,
            content: <AdminToolsTabDvvAddressImport />
        }
    ];

    useEffect(() => {
        let tab;
        switch (page) {
            case 'DvvAddressImport':
                tab = 0;
                break;
            default:
                tab = 0;
                break;
        }
        setSelectedTab(tab);
    }, [page]);

    return (
        <Container>
            <Grid container>
                <BreadcrumbBar />
                <ViewTitle title={T('Common_AdminTools')} icon={Settings} />
            </Grid>
            <Paper elevation={3}>
                <Tabs
                    value={selectedTab}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="scrollable"
                    scrollButtons="auto"
                    onChange={handleChange}
                >
                    {tabs
                        .filter((tab) => !tab.hidden)
                        .map((item) => (
                            <Tab label={T(`AdminToolsTab_${item.title}`)} key={item.id} value={item.id} />
                        ))}
                </Tabs>
                {tabs.map((item) => (
                    <TabPanel key={item.id} value={selectedTab} index={item.id}>
                        {item.content}
                    </TabPanel>
                ))}
            </Paper>
        </Container>
    );
};

export default AdminToolsView;
