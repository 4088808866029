import React, { FC, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { KeyValue } from 'types/common';
import axios, { CancelTokenSource } from 'axios';
import { LanguageContext } from 'contexts/languageContext';
import FormFieldDropDown from './FormFieldDropDown';
import { useErrorHandler } from '../hooks/useErrorHandler';
import WaitScreen from './WaitScreen';
import ContactService from 'services/contactService';
import ChurchesService from 'services/churchesService';
import { AuthContext } from 'contexts/authContext';
import TypeUtils from 'utils/typeHelper';

interface FormFieldDropDownOrganizationProps {
    label: string;
    name: string;
    placeholder?: string;
    autoFocus?: boolean;
    showAllAccessable?: boolean;
    showChurches?: boolean;
    showContacts?: boolean;
    includeShk?: boolean;
    disabledValues?: number | string | (number | string)[];
    readonly?: boolean;
}

const FormFieldDropDownOrganization: FC<FormFieldDropDownOrganizationProps> = ({
    label,
    name,
    placeholder,
    autoFocus,
    showAllAccessable,
    showChurches,
    showContacts,
    includeShk,
    disabledValues,
    readonly
}: FormFieldDropDownOrganizationProps) => {
    const [values, setValues] = useState<KeyValue[]>([]);
    const { T } = useContext(LanguageContext);
    const { handleError } = useErrorHandler();
    const cancelRef = useRef<CancelTokenSource | null>(null);
    const { user } = useContext(AuthContext);

    const alphabeticalOrder = (a: KeyValue, b: KeyValue) => {
        if (!a || !b || !a.text || !b.text) return 0;
        if (a.text.toLocaleLowerCase() > b.text.toLocaleLowerCase()) return 1;
        if (a.text.toLocaleLowerCase() < b.text.toLocaleLowerCase()) return -1;
        return 0;
    };

    const getOrganizations = useCallback(async (): Promise<void> => {
        try {
            cancelRef.current = axios.CancelToken.source();

            if (showContacts) {
                const organizationContacts = await ContactService.getOrganizationsAsync(
                    undefined,
                    cancelRef.current.token,
                    false,
                    true,
                    false
                );
                if (cancelRef.current) {
                    const orgs = organizationContacts.map((item) => ({ key: item.id, text: item.name }));
                    orgs.sort(alphabeticalOrder);
                    setValues(orgs);
                }
            } else if (showChurches) {
                const allOrganization = await ContactService.getOrganizationsAsync(
                    undefined,
                    cancelRef.current.token,
                    includeShk,
                    false,
                    false
                );
                if (cancelRef.current) {
                    const churchOrganizations = allOrganization
                        .filter(
                            (item) => item.memberTransferAllowed || (TypeUtils.IsYhdyskunta(item.type) && includeShk)
                        )
                        .map((item) => ({ key: item.id, text: item.name }));
                    churchOrganizations.sort(alphabeticalOrder);
                    setValues(churchOrganizations);
                }
            } else if (showAllAccessable) {
                if (cancelRef.current) {
                    if (user) {
                        const userOrgs = user?.organizations.map((org) => ({ key: org.id ?? 0, text: org.name }));
                        userOrgs.sort(alphabeticalOrder);
                        setValues(userOrgs);
                    }
                }
            } else {
                const organizations = await ChurchesService.getChurchOrganizationsAsync(
                    user?.churchId,
                    cancelRef.current.token
                );
                if (cancelRef.current) {
                    const orgs = organizations.map((item) => ({ key: item.id, text: item.name }));
                    orgs.sort(alphabeticalOrder);
                    setValues(orgs);
                }
            }
        } catch (error) {
            handleError(error, T('Error_CodeSetQueryFailed'));
        }
    }, [handleError, T]);

    useEffect(() => {
        getOrganizations();

        return () => {
            cancelRef.current && cancelRef.current.cancel();
            cancelRef.current = null;
        };
    }, [getOrganizations]);

    if (values.length === 0) return <WaitScreen />;

    return (
        <FormFieldDropDown
            name={name}
            label={label}
            values={values}
            placeholder={placeholder}
            autoFocus={autoFocus}
            disabledValues={disabledValues}
            readonly={readonly}
        />
    );
};

export default FormFieldDropDownOrganization;
