import React, { FC, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { LanguageContext } from 'contexts/languageContext';
import { Membership, MembershipWithTransferInfo, OrganizationContact, Person } from 'types/sp-api';
import FormFieldDate from 'components/common/FormFieldDate';
import FormFieldDropDownCodeSet from 'components/common/FormFieldDropDownCodeSet';
import FormFieldText from 'components/common/FormFieldText';
import { useFormikContext } from 'formik';
import LastModified from 'components/common/LastModified';
import FormFieldReadOnly from 'components/common/FormFieldReadonly';
import TypeUtils from 'utils/typeHelper';
import { AppLanguage, MemberPrerequisite } from 'types/common';
import { useErrorHandler } from 'components/hooks/useErrorHandler';
import { useSuccessHandler } from 'components/hooks/useSuccessHandler';
import MembershipsService from 'services/membershipsService';
import LoadButton from 'components/common/LoadButton';
import DialogConfirmation from 'components/common/DialogConfirmation';
import PersonUtils from 'utils/personHelper';
import axios, { CancelTokenSource } from 'axios';
import ContactService from 'services/contactService';
import MailService from 'services/mailService';
import { StatusCodes } from 'http-status-codes';
import { useWarningHandler } from 'components/hooks/useWarningHandler';
import constants from 'config/constants';
import FormFieldDropDownOrganization from 'components/common/FormFieldDropDownOrganization';
import Divider from '@mui/material/Divider';

interface MembershipFormTransferOutProps {
    membership: MembershipWithTransferInfo;
    cancelTransfer: () => void;
    person: Person;
    allowedEdit: boolean;
}

const MembershipFormTransferOut: FC<MembershipFormTransferOutProps> = ({
    membership,
    cancelTransfer,
    person,
    allowedEdit
}: MembershipFormTransferOutProps) => {
    const { T, TExt } = useContext(LanguageContext);
    const [cancelling, setCancelling] = useState(false);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const { handleError } = useErrorHandler();
    const { handleSuccess } = useSuccessHandler();
    const { handleWarning } = useWarningHandler();
    const { values } = useFormikContext<Membership>();
    const cancelRef = useRef<CancelTokenSource | null>(null);
    const [organization, setOrganization] = useState<OrganizationContact>();

    const getOrganization = useCallback(
        async (id: number): Promise<void> => {
            try {
                cancelRef.current = axios.CancelToken.source();
                const orgData = await ContactService.getOrganizationAsync(id, cancelRef.current.token);
                if (cancelRef.current) {
                    setOrganization(orgData);
                }
            } catch (error) {
                handleError(error, T('Error_OrganizationQueryFailed'));
            }
        },
        [handleError, T]
    );

    useEffect(() => {
        getOrganization(membership.organizationId);

        return () => {
            cancelRef.current && cancelRef.current.cancel();
            cancelRef.current = null;
        };
    }, [membership.organizationId]);

    const transferMembershipCancelled = async (newValues: Membership): Promise<void> => {
        setCancelling(true);
        try {
            await MembershipsService.transferMembershipCancelledAsync(person.id, newValues);

            cancelTransfer();
            setCancelling(false);
            handleSuccess(T('Membership_TransferCancelSuccess'));
        } catch (error) {
            setCancelling(false);
            handleError(error, T('Membership_TransferCancelFailed'));
        }

        try {
            // notify user
            await MailService.sendTransferCancelledMailAsync(
                person.id ?? 0,
                TExt,
                (person.language as AppLanguage) ?? AppLanguage.Finnish
            );
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (error: any) {
            if (error.response?.status === StatusCodes.NOT_FOUND) {
                handleWarning(T('Membership_TransferCancelEmailMissing'));
            } else {
                handleError(error, T('Membership_TransferCancelEmailFailed'));
            }
        }
    };

    return (
        <>
            <Grid item xs={12}>
                <Typography variant="h6">{T('Membership_TransferOutTitle')}</Typography>
                <Typography variant="body2" color="textSecondary">
                    {T('Membership_TransferHelpText')}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <FormFieldReadOnly
                    label={T('Membership_TransferOutTarget')}
                    value={membership?.targetOrganization?.name}
                />
            </Grid>
            {membership.targetOrganization?.id == constants.RegionalCommunity.RegisterChurchId && (
                <Grid item xs={12}>
                    <FormFieldDropDownOrganization
                        name="homeOrganizationId"
                        label={T('Membership_FieldRegisterHomeChurch')}
                        placeholder={T('Membership_SelectRegisterHomeChurch')}
                        showContacts
                        readonly
                    />
                </Grid>
            )}
            <Grid item xs={12}>
                <Divider />
            </Grid>
            <Grid item xs={12}>
                <FormFieldReadOnly label={T('Membership_FieldOrganization')} value={membership?.organization?.name} />
            </Grid>
            {TypeUtils.IsLocalYhdyskunta(membership.organization?.type) && (
                <Grid item xs={12}>
                    <FormFieldDropDownCodeSet
                        name="type"
                        label={T('Membership_FieldMembershipType')}
                        codeSet="membershipType"
                        placeholder={T('Membership_SelectType')}
                        readonly
                    />
                </Grid>
            )}
            <Grid item xs={12}>
                <FormFieldDropDownCodeSet
                    name="prerequisite"
                    label={T('Membership_FieldPrerequisite')}
                    codeSet="membershipPrerequisite"
                    placeholder={T('Membership_SelectPrerequisite')}
                    readonly
                />
            </Grid>
            {values.prerequisite == MemberPrerequisite.Recognition && (
                <Grid item xs={12}>
                    <FormFieldText name="prerequisiteText" label={T('Membership_FieldPrerequisiteText')} readonly />
                </Grid>
            )}
            <Grid item xs={6}>
                <FormFieldDate label={T('Membership_FieldBeginDate')} name="beginDate" readonly />
            </Grid>
            <Grid item xs={6}>
                <FormFieldDate label={T('Membership_FieldEndDate')} name="endDate" readonly />
            </Grid>
            <Grid item xs={6}>
                <FormFieldDropDownCodeSet
                    name="terminationReason"
                    label={T('Membership_FieldTerminationReason')}
                    codeSet="terminationReason"
                    placeholder={T('Membership_SelectTerminationReason')}
                    readonly
                />
            </Grid>
            <Grid item xs={6}>
                <FormFieldText
                    name="terminationReasonText"
                    label={T('Membership_FieldTerminationReasonText')}
                    readonly
                />
            </Grid>
            <Grid item xs={12} container justifyContent="space-between">
                <Grid item>
                    {allowedEdit && (
                        <LoadButton color="secondary" loading={cancelling} onClick={() => setShowConfirmation(true)}>
                            {T('Membership_TransferCancel')}
                        </LoadButton>
                    )}
                </Grid>
                <Grid item xs={12}>
                    <Box margin={2} />
                    <LastModified by={values.lastModifiedBy} date={values.lastModifiedDate} />
                </Grid>
            </Grid>
            <DialogConfirmation
                title={T(`Membership_ConfirmTransferCancel`)}
                message={T(`Membership_ConfirmTransferCancelText`)
                    .replace('{0}', PersonUtils.FullName(person))
                    .replace('{1}', organization?.name ?? '')}
                show={showConfirmation}
                onClose={() => setShowConfirmation(false)}
                onOk={() => transferMembershipCancelled(values)}
                warning
                okTitle={T('Membership_TransferCancel')}
            />
        </>
    );
};

export default MembershipFormTransferOut;
