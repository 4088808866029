import React, { FC, useRef, useState } from 'react';
import { Button, Grid, Grow, MenuList, Paper, Popper, ButtonGroup, ClickAwayListener, MenuItem } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { createFlag } from './FlagMenu';

interface FlaggedMenuProps {
    type?: number;
    title?: string;
    onClick: (type?: number) => void;
}

const FlaggedMenu: FC<FlaggedMenuProps> = ({ type, title, onClick }: FlaggedMenuProps) => {
    return (
        <MenuItem onClick={() => onClick(type)}>
            {createFlag(type)}
            <div style={{ marginLeft: 8 }}>{title}</div>
        </MenuItem>
    );
};

const FilterDropDown: FC<{ selectedFlag?: number }> = ({ selectedFlag }: { selectedFlag?: number }) => {
    const anchorRef = useRef(null);
    const [menuOpen, setMenuOpen] = useState(false);
    const navigate = useNavigate();

    const filterFlag = (type?: number) => {
        // needs to be comparison to undefined because !type is true for type value 0
        if (type === undefined) {
            navigate(`/registers/credentials`);
        } else {
            navigate(`/registers/credentials/flag/${type}`);
        }
        setMenuOpen(false);
    };

    return (
        <Grid container>
            <Grid item xs={12}>
                <ButtonGroup variant="outlined" color="primary" ref={anchorRef} sx={{ height: 40 }}>
                    <Button color="secondary" variant="outlined" disabled>
                        Suodata
                    </Button>
                    <Button color="secondary" variant="outlined">
                        {Number.isNaN(selectedFlag) ? '--' : createFlag(selectedFlag)}
                    </Button>
                    <Button color="secondary" onClick={() => setMenuOpen((prevOpen) => !prevOpen)}>
                        <ArrowDropDownIcon />
                    </Button>
                </ButtonGroup>
                <Popper
                    open={menuOpen}
                    anchorEl={anchorRef.current}
                    role={undefined}
                    transition
                    disablePortal
                    placement="bottom-end"
                    style={{ zIndex: 999 }}
                    nonce={undefined}
                >
                    {({ TransitionProps, placement }) => (
                        <Grow
                            {...TransitionProps}
                            style={{
                                transformOrigin: placement === 'bottom' ? 'top-end' : 'bottom-end'
                            }}
                        >
                            <Paper>
                                <ClickAwayListener onClickAway={() => setMenuOpen(false)}>
                                    <MenuList id="split-button-menu" sx={{ borderRadius: 1 }}>
                                        <FlaggedMenu type={0} title="ei lippua" onClick={filterFlag} />
                                        <FlaggedMenu type={1} title="valittu" onClick={filterFlag} />
                                        <FlaggedMenu type={2} title="toiminta" onClick={filterFlag} />
                                        <FlaggedMenu type={3} title="varoitus" onClick={filterFlag} />
                                        <FlaggedMenu type={4} title="virhe" onClick={filterFlag} />
                                        <FlaggedMenu type={5} title="valmis" onClick={filterFlag} />
                                        <FlaggedMenu title="poista suodatus" onClick={filterFlag} />
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>
            </Grid>
        </Grid>
    );
};

export default FilterDropDown;
