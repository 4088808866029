import React, { FC, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Container, Grid } from '@mui/material';
import { LanguageContext } from 'contexts/languageContext';
import BadgeIcon from '@mui/icons-material/Badge';
import BreadcrumbBar from 'components/common/BreadcrumbBar';
import ViewTitle from 'components/common/ViewTitle';
import ViewSearchBar from 'components/common/ViewSearchBar';
import axios, { AxiosResponse, CancelTokenSource } from 'axios';
import { useErrorHandler } from 'components/hooks/useErrorHandler';
import ReportService from 'services/reportService';
import LoadButton from 'components/common/LoadButton';
import DownloadFile from 'components/common/DownloadFile';
import moment from 'moment';
import { Person, PersonWithMembership, ReportFilter, ReportInclude, Tag } from 'types/sp-api';
import { GlobalGroupRegisterReportForm, GlobalGroupRegisterType } from 'types/common';
import GlobalGroupRegisterList from 'components/globalGroupRegister/GlobalGroupRegisterList';
import CommunityTagService from 'services/communityTagService';
import constants from 'config/constants';

interface GlobalGroupRegisterViewProps {
    registerType: GlobalGroupRegisterType;
    filter?: string;
}

const GlobalGroupRegisterView: FC<GlobalGroupRegisterViewProps> = ({
    registerType,
    filter
}: GlobalGroupRegisterViewProps) => {
    const { T } = useContext(LanguageContext);
    const [personList, setPersonList] = useState<PersonWithMembership[]>();
    const [tagTitles, setTagTitles] = useState<Tag[]>();
    const { handleError } = useErrorHandler();
    const cancelRef = useRef<CancelTokenSource | null>(null);
    const clearDownload = { type: undefined, filename: '' };
    const [download, setDownload] = useState<{ type?: number; filename: string }>(clearDownload);

    const getPersons = useCallback(
        async (type: GlobalGroupRegisterType, filterStr?: string): Promise<void> => {
            try {
                cancelRef.current = axios.CancelToken.source();
                const tagTitles = await CommunityTagService.getCommunityTagTitlesAsync(cancelRef.current.token);

                const persons = await CommunityTagService.getPersonsByTagIdAsync(
                    getRegisterTagId(type, tagTitles),
                    filterStr,
                    cancelRef.current.token
                );

                if (cancelRef.current) {
                    const listOrder = (a: Person, b: Person): number => {
                        if (a.new === true && b.new === false) return -1;
                        if (a.new === false && b.new === true) return 1;

                        if (a.lastName.toLocaleLowerCase() < b.lastName.toLocaleLowerCase()) return -1;
                        if (a.lastName.toLocaleLowerCase() > b.lastName.toLocaleLowerCase()) return 1;
                        if (a.firstName.toLocaleLowerCase() < b.firstName.toLocaleLowerCase()) return -1;
                        if (a.firstName.toLocaleLowerCase() > b.firstName.toLocaleLowerCase()) return 1;

                        return 0;
                    };

                    persons.sort(listOrder);

                    setPersonList(persons);
                    setTagTitles(tagTitles);
                }
            } catch (error) {
                handleError(error, T(`Error_GlobalGroupRegisterQueryFailed_${type}`));
            }
        },
        [handleError, T]
    );

    useEffect(() => {
        getPersons(registerType, filter);

        return () => {
            cancelRef.current && cancelRef.current.cancel();
            cancelRef.current = null;
        };
    }, [getPersons, filter]);

    const getRegisterTagId = (type: string, tagTitles?: Tag[]) => {
        let tagId: number | undefined;
        if (!tagTitles) return 0;

        switch (type) {
            case 'senior':
                tagId = tagTitles.find((t) => t.name === constants.RegisterGroupName.Senior)?.id;
                break;
            case 'prisonEvangelist':
                tagId = tagTitles.find((t) => t.name === constants.RegisterGroupName.PrisonEvangelist)?.id;
                break;
            case 'webinarMember':
                tagId = tagTitles.find((t) => t.name === constants.RegisterGroupName.WebinarMembers)?.id;
                break;
        }
        return tagId;
    };

    const fetchCsvReport = async (
        type: GlobalGroupRegisterType,
        filename?: string,
        filterText?: string
    ): Promise<AxiosResponse> => {
        !cancelRef.current && (cancelRef.current = axios.CancelToken.source());

        const tagId = getRegisterTagId(type, tagTitles) ?? 0;

        const reportFilter: ReportFilter = {
            communityTags: [tagId],
            familyContacts: false,
            membershipTypes: [],
            memberTags: [],
            gender: ''
        };

        const reportInclude: ReportInclude = {
            age: false,
            birthDate: false,
            email: true,
            phone: true
        };

        const form: GlobalGroupRegisterReportForm = {
            type: type,
            filename: filename ?? '',
            filterText: filterText,
            filter: reportFilter,
            include: reportInclude
        };

        return ReportService.globalGroupRegisterCsvReportAsync(form, cancelRef.current.token);
    };

    return (
        <Container>
            <Grid container>
                <BreadcrumbBar />
                <ViewTitle title={T(`GlobalGroupRegister_Title_${registerType}`)} icon={BadgeIcon} />
                <Grid container item xs={6}>
                    <ViewSearchBar
                        filter={filter ?? undefined}
                        baseUrl={`/registers/${registerType}s/search`}
                        clearUrl={`/registers/${registerType}s`}
                        searchTitle={T(`Placeholder_GlobalGroupRegisterSearch_${registerType}`)}
                        auditMsg={`global group register search ${registerType}`}
                        minWidth={380}
                    />
                </Grid>
                <Grid item xs={6} sx={{ textAlign: 'right' }}>
                    <LoadButton
                        loading={download.filename != ''}
                        type="button"
                        onClick={() => {
                            const filename = T(`GlobalGroupRegister_CsvFilename_${registerType}`).replace(
                                '{0}',
                                moment().format('L LT')
                            );
                            setDownload({ type: -1, filename: filename });
                        }}
                        color="secondary"
                    >
                        {T(`GlobalGroupRegister_PrintCsvReport_${registerType}`)}
                    </LoadButton>
                </Grid>
            </Grid>
            <GlobalGroupRegisterList personList={personList} />
            {download.filename != '' && (
                <DownloadFile
                    fetchFile={() => fetchCsvReport(registerType, filter, download.filename)}
                    filename={download.filename}
                    onSuccess={() => setDownload(clearDownload)}
                />
            )}
        </Container>
    );
};

export default GlobalGroupRegisterView;
