import React, { FC, useContext } from 'react';
import { Button, Container, Grid, Typography } from '@mui/material';
import { LanguageContext } from 'contexts/languageContext';
import BreadcrumbBar from 'components/common/BreadcrumbBar';
import { useNavigate } from 'react-router-dom';
import config from 'config/config';
import constants from 'config/constants';

const CookiePolicyView: FC = () => {
    const { T } = useContext(LanguageContext);
    const navigate = useNavigate();

    return (
        <Container>
            <Grid container>
                <BreadcrumbBar hideChurchName />
            </Grid>
            <Grid container direction="column" spacing={3}>
                <Grid item>
                    <Typography variant="h4">Evästekäytäntö</Typography>
                </Grid>
                <Grid item>
                    <Typography variant="body1">
                        Sähköpaimen-jäsenrekisteri käyttää evästeitä. Tämä sivu kuvaa miten ja miksi evästeitä
                        käytetään.
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant="h5">Miksi evästeitä käytetään</Typography>
                </Grid>
                <Grid item>
                    <Typography variant="body1">
                        Tekniset ratkaisut vaativat evästeiden käyttöä. Tämä on normaali alan standardi, jota ei pysty
                        ohittamaan rajoittamatta sivuston toimintaa oleellisesti. Käytössä ovat vain välttämättömät
                        evästeet. Markkinointi-, personointi-, analysointi- tai tilastointikäyttöön tarkoitettuja
                        evästeitä ei käytetä.
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant="h5">Evästeiden estäminen</Typography>
                </Grid>
                <Grid item>
                    <Typography variant="body1">
                        Evästeiden käytön voi estää selaimen asetuksista, mutta estämisellä on vaikutuksia sivuston
                        toimintaan. Ominaisuudet voivat olla rajoittuneempia tai sivustolla voi toistuvasti näkyä
                        ilmoituksia. Evästeiden sallinta on suositeltavaa, jotta sivuston käyttö olisi suunnitellun
                        mukaista.
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant="h4">Käytössä olevat evästeet</Typography>
                </Grid>
                <Grid item>
                    <Typography variant="h5">Kirjautumiseen liittyvät evästeet</Typography>
                </Grid>
                <Grid item>
                    <Typography variant="body1">
                        Sähköpaimen voi tallentaa evästeitä kirjautumiseen liittyen, jotta kirjautuminen säilyy voimassa
                        ja käyttäjä pystyy työskentelemään keskeytyksettä.
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant="h5">Evästekäytännön eväste</Typography>
                </Grid>
                <Grid item>
                    <Typography variant="body1">
                        Tallennamme evästekäytännön informoinnista evästeen, kun käyttäjä kuittaa ko. viestin
                        ymmärretyksi.
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant="h4">Lisätietoja</Typography>
                </Grid>
                <CookiePolicyInfo />
                <Grid item>
                    <Typography variant="body2" color="textSecondary">
                        Käytäntö päivitetty 30.6.2021
                    </Typography>
                </Grid>
                <Grid item>
                    <Button variant="contained" color="secondary" onClick={() => navigate('/')}>
                        {T('Common_ReturnToDashboard')}
                    </Button>
                </Grid>
            </Grid>
        </Container>
    );
};

const CookiePolicyInfoShk: FC = () => {
    return (
        <Grid item>
            <Typography variant="body1">Lisätietoja evästekäytännöstä antaa Suomen Helluntaikirkko.</Typography>
            <br />
            <Typography variant="body1">
                <b>Suomen Helluntaikirkko</b>
            </Typography>
            <Typography variant="body1">Puhelin: 045 632 7848</Typography>
            <Typography variant="body1">Sähköpostiosoite: toimisto@helluntaikirkko.fi</Typography>
        </Grid>
    );
};

const CookiePolicyInfoAdvk: FC = () => {
    return (
        <Grid item>
            <Typography variant="body1">Lisätietoja evästekäytännöstä antaa Suomen Adventtikirkko.</Typography>
            <br />
            <Typography variant="body1">
                <b>Suomen Adventtikirkko</b>
            </Typography>
            <Typography variant="body1">Puhelin: 03 3611 111</Typography>
            <Typography variant="body1">Sähköpostiosoite: jasenrekisteri@adventtikirkko.fi</Typography>
        </Grid>
    );
};

const CookiePolicyInfo: FC = () => {
    if (config.CUSTOMER == constants.Customers.Advk) return <CookiePolicyInfoAdvk />;

    return <CookiePolicyInfoShk />;
};

export default CookiePolicyView;
