import React, { ChangeEventHandler, FC, ReactNode, useEffect, useState } from 'react';
import { Box, Grid, InputAdornment, TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { useNavigate } from 'react-router-dom';
import LightTooltip from './LightTooltip';
import { useAudit } from '../hooks/useAudit';
import { CSSProperties } from '@mui/styles';

interface ViewSearchBarProps {
    filter?: string;
    baseUrl: string;
    clearUrl: string;
    auditMsg: string;
    searchTitle?: string;
    iconTooltip?: ReactNode;
    minWidth?: number;
    center?: boolean;
}

const ViewSearchBar: FC<ViewSearchBarProps> = ({
    searchTitle,
    filter,
    baseUrl,
    clearUrl,
    auditMsg,
    iconTooltip,
    minWidth,
    center
}: ViewSearchBarProps) => {
    const navigate = useNavigate();
    const [searchFilter, setSearchFilter] = useState(filter ?? '');
    const { audit } = useAudit();

    useEffect(() => {
        setSearchFilter(filter ?? '');
        return () => {
            setSearchFilter('');
        };
    }, [filter]);

    const justify = center ? 'center' : undefined;

    return (
        <Grid container justifyContent={justify} style={{ marginBottom: 20 }}>
            <SearchBar
                style={{ minWidth: minWidth }}
                value={searchFilter}
                onChange={(newValue) => setSearchFilter(newValue.target.value)}
                onRequestSearch={() => {
                    if (searchFilter.length > 0) {
                        const fullUrl = `${baseUrl}/${searchFilter}`;
                        audit(auditMsg, fullUrl);
                        navigate(fullUrl);
                    } else {
                        navigate(clearUrl);
                    }
                }}
                placeholder={searchTitle}
                searchIcon={
                    <>
                        {iconTooltip ? (
                            <LightTooltip title={iconTooltip}>
                                <SearchIcon htmlColor="silver" />
                            </LightTooltip>
                        ) : (
                            <SearchIcon htmlColor="silver" />
                        )}
                    </>
                }
            />
        </Grid>
    );
};

interface SearchBarProps {
    style?: CSSProperties;
    value?: string;
    onChange?: ChangeEventHandler<HTMLInputElement>;
    placeholder?: string;
    searchIcon?: unknown;
    onRequestSearch: () => void;
}

const SearchBar: FC<SearchBarProps> = ({
    style,
    value,
    onChange,
    placeholder,
    searchIcon,
    onRequestSearch
}: SearchBarProps) => {
    return (
        <TextField
            style={style}
            label={placeholder}
            size="small"
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <Box onClick={() => onRequestSearch()} sx={{ cursor: 'pointer', pt: 1 }}>
                            {searchIcon as JSX.Element}
                        </Box>
                    </InputAdornment>
                )
            }}
            value={value}
            onChange={onChange}
            onKeyUp={(event) => {
                if (event.key === 'Enter') {
                    onRequestSearch();
                }
            }}
        />
    );
};

export default ViewSearchBar;
