import React, { useState, useCallback, useEffect, useContext, useRef, FC } from 'react';
import axios, { CancelTokenSource } from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import OrganizationsService from 'services/organizationsService';
import PersonList from './PersonList';
import { Membership } from 'types/sp-api';
import { Container, Grid } from '@mui/material';
import { LanguageContext } from 'contexts/languageContext';
import ViewTitle from 'components/common/ViewTitle';
import { PeopleAlt } from '@mui/icons-material';
import BreadcrumbBar from 'components/common/BreadcrumbBar';
import ViewSearchBar from 'components/common/ViewSearchBar';
import { useErrorHandler } from 'components/hooks/useErrorHandler';
import { TooltipPersonSearch } from 'components/common/TooltipPersonSearch';
import { AuthContext } from 'contexts/authContext';
import config from 'config/config';
import constants from 'config/constants';

const PersonView: FC = () => {
    const { T } = useContext(LanguageContext);
    const { user, isAdmin } = useContext(AuthContext);
    const [loading, setLoading] = useState(false);
    const [membershipList, setMembershipList] = useState<Membership[]>();
    const { handleError } = useErrorHandler();
    const navigate = useNavigate();
    const cancelRef = useRef<CancelTokenSource | null>(null);
    const [message, setMessage] = useState('');
    const { filter } = useParams<'filter'>();

    const getPersons = useCallback(
        async (filterStr: string): Promise<void> => {
            try {
                setLoading(true);
                cancelRef.current = axios.CancelToken.source();
                const personList = await OrganizationsService.getMembersAsync(
                    user?.homeOrganizationId,
                    filterStr,
                    true,
                    cancelRef.current.token,
                    isAdmin()
                );
                if (cancelRef.current) {
                    setLoading(false);
                    if (personList.length === 0) {
                        setMembershipList([]);
                        setMessage(T('Person_SearchResultEmpty'));
                    } else {
                        setMembershipList(personList);
                    }
                }
            } catch (error) {
                setLoading(false);
                handleError(error, T('Error_PersonQueryFailed'));
            }
        },
        [handleError, T, isAdmin, user?.homeOrganizationId]
    );

    useEffect(() => {
        if (filter && filter.length >= 3) {
            setMessage('');
            getPersons(filter);
        } else {
            setMembershipList([]);
            setMessage(T('Person_SearchFilterMissing'));
        }

        return () => {
            cancelRef.current && cancelRef.current.cancel();
            cancelRef.current = null;
        };
    }, [getPersons, filter, isAdmin, T]);

    return (
        <Container>
            <Grid container>
                <BreadcrumbBar />
                <ViewTitle
                    title={T('Common_PersonsTitle')}
                    onAddClick={
                        config.CUSTOMER === constants.Customers.Shk ||
                        (config.CUSTOMER === constants.Customers.Advk && isAdmin())
                            ? () => navigate('/persons/new')
                            : undefined
                    }
                    onMenuBtnClick={
                        config.CUSTOMER === constants.Customers.Shk && isAdmin()
                            ? () => navigate('/persons/newContact')
                            : undefined
                    }
                    menuBtnTitle={T('Person_AddRegistryContactPerson')}
                    icon={PeopleAlt}
                />
                <ViewSearchBar
                    filter={filter ?? undefined}
                    baseUrl="/persons/search"
                    clearUrl="/persons"
                    searchTitle={T('Placeholder_PersonSearch')}
                    auditMsg="person search"
                    iconTooltip={<TooltipPersonSearch />}
                    minWidth={380}
                />
            </Grid>
            <PersonList memberList={membershipList} message={message} loading={loading} />
        </Container>
    );
};

export default PersonView;
