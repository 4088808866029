import React, { FC, useContext } from 'react';
import { Typography, Grid, List, ListItem, ListItemText, ListItemIcon } from '@mui/material';
import { LanguageContext } from 'contexts/languageContext';
import EmojiObjectsIcon from '@mui/icons-material/EmojiObjects';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

export const TooltipPersonSearch: FC = () => {
    const { T } = useContext(LanguageContext);

    return (
        <Grid container>
            <Grid item style={{ padding: 4 }}>
                <EmojiObjectsIcon />
            </Grid>
            <Grid item style={{ padding: 4 }}>
                <Typography variant="subtitle1" color="inherit">
                    {T('Person_SearchTipTitle')}
                </Typography>
            </Grid>
            <Grid item>
                <List dense>
                    <ListItem>
                        <ListItemIcon>
                            <ChevronRightIcon />
                        </ListItemIcon>
                        <ListItemText
                            primary={T('Person_SearchTipByName')}
                            secondary={T('Person_SearchTipByNameExample')}
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <ChevronRightIcon />
                        </ListItemIcon>
                        <ListItemText
                            primary={T('Person_SearchTipByNamePart')}
                            secondary={T('Person_SearchTipByNamePartExample')}
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <ChevronRightIcon />
                        </ListItemIcon>
                        <ListItemText
                            primary={T('Person_SearchTipByEmail')}
                            secondary={T('Person_SearchTipByEmailExample')}
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <ChevronRightIcon />
                        </ListItemIcon>
                        <ListItemText
                            primary={T('Person_SearchTipBySsn')}
                            secondary={T('Person_SearchTipBySsnExample')}
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <ChevronRightIcon />
                        </ListItemIcon>
                        <ListItemText
                            primary={T('Person_SearchTipByBirthday')}
                            secondary={T('Person_SearchTipByBirthdayExample')}
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <ChevronRightIcon />
                        </ListItemIcon>
                        <ListItemText
                            primary={T('Person_SearchTipByPhone')}
                            secondary={T('Person_SearchTipByPhoneExample')}
                        />
                    </ListItem>
                </List>
            </Grid>
        </Grid>
    );
};
