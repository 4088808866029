import axios, { CancelToken } from 'axios';
import LocalCache from 'services/localCache';
import { Membership, MemberTag, Tag } from 'types/sp-api';

class MemberTagService {
    public async getMemberTagTitlesAsync(
        organizationId: number,
        cancelToken?: CancelToken,
        forceUpdate?: boolean
    ): Promise<Tag[]> {
        const tagUrl = `organizations/${organizationId}/tags`;

        if (forceUpdate) {
            LocalCache.forceExpire(tagUrl);
        }

        return LocalCache.cachedRequest<Tag[]>(tagUrl, async () => {
            const response = await axios.get(tagUrl, {
                cancelToken: cancelToken
            });
            return (response.data as Tag[]).filter((item) => item.organizationId === organizationId);
        });
    }

    public async addMemberTagTitleAsync(organizationId: number, tag?: Tag): Promise<void> {
        if (!tag) return;

        const tagUrl = `organizations/${organizationId}/tags`;
        await axios.post(tagUrl, tag);

        // Reload cache
        LocalCache.forceExpire(tagUrl);
    }

    public async getMemberTagsAsync(
        personId: number,
        membershipId: number,
        cancelToken?: CancelToken
    ): Promise<MemberTag[]> {
        const response = await axios.get(`persons/${personId}/memberships/${membershipId}/tags`, {
            cancelToken: cancelToken
        });
        return response.data;
    }

    public async saveMemberTagAsync(personId: number, membershipId: number, tag: MemberTag): Promise<void> {
        if (tag?.id && tag.id > 0) {
            await axios.put(`persons/${personId}/memberships/${membershipId}/tags/${tag?.id}`, tag);
        } else {
            await axios.post(`persons/${personId}/memberships/${membershipId}/tags`, tag);
        }
    }

    public async deleteMemberTagAsync(personId: number, membershipId: number, tagId?: number): Promise<void> {
        if (!tagId) return;

        await axios.delete(`persons/${personId}/memberships/${membershipId}/tags/${tagId}`);
    }

    public async getMemberTagTitleAsync(
        organizationId: number,
        tagId: number,
        cancelToken?: CancelToken
    ): Promise<Tag> {
        const response = await axios.get(`organizations/${organizationId}/tags/${tagId}`, {
            cancelToken: cancelToken
        });
        return response.data;
    }

    public async getMemberTagTitleUsageAsync(
        organizationId: number,
        tagId: number,
        cancelToken?: CancelToken
    ): Promise<number> {
        const response = await axios.get(`organizations/${organizationId}/tags/${tagId}/usage`, {
            cancelToken: cancelToken
        });
        return response.data;
    }

    public async saveMemberTagTitleAsync(organizationId: number, tag: Tag): Promise<void> {
        if (tag?.id && tag.id > 0) {
            await axios.put(`organizations/${organizationId}/tags/${tag?.id}`, tag);
        } else {
            await axios.post(`organizations/${organizationId}/tags/`, tag);
        }
    }

    public async deleteMemberTagTitleAsync(organizationId: number, tagId?: number): Promise<void> {
        if (!tagId) return;

        await axios.delete(`organizations/${organizationId}/tags/${tagId}`);
    }

    public async getMembersByTagIdAsync(
        tagId: number,
        organizationId: number,
        cancelToken?: CancelToken
    ): Promise<Membership[]> {
        const response = await axios.get(`organizations/${organizationId}/tags/${tagId}/members`, {
            cancelToken: cancelToken
        });
        return response.data;
    }
}

export default new MemberTagService();
