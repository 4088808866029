import React, { FC } from 'react';
import { Typography, Card, CardContent } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { SecureMessage } from 'types/sp-api';
import moment from 'moment';

interface SecureMessageCardProps {
    message: SecureMessage;
}

const SecureMessageCard: FC<SecureMessageCardProps> = ({ message }: SecureMessageCardProps) => {
    const navigate = useNavigate();

    return (
        <Card
            sx={{ cursor: 'pointer', p: 1, maxWidth: '345px', minWidth: '345px', backgroundColor: 'transparent' }}
            onClick={() => {
                navigate(`/communication/securemessages/${message.id}`);
            }}
        >
            <CardContent sx={{ padding: '0 !important' }}>
                <Typography sx={{ fontSize: '12px' }} color="textSecondary" noWrap>
                    {`${moment(message.createdDate).format('l')}: ${message.sender?.name}`}
                </Typography>
                <Typography noWrap color="primary" title={message.title}>
                    {message.title}
                </Typography>
            </CardContent>
        </Card>
    );
};

export default SecureMessageCard;
