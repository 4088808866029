import React, { FC, useContext, useEffect, useState } from 'react';
import { Box, Container, Grid, Typography, Link, Button, Stepper, Step, StepLabel, StepContent } from '@mui/material';
import { LanguageContext, LanguageTextFunc } from 'contexts/languageContext';
import { useErrorHandler } from 'components/hooks/useErrorHandler';
import { useSuccessHandler } from 'components/hooks/useSuccessHandler';
import { Form, Formik, FormikProps } from 'formik';
import * as Yup from 'yup';
import FormFieldText from 'components/common/FormFieldText';
import LightTooltip from 'components/common/LightTooltip';
import InfoIcon from '@mui/icons-material/Info';
import { useNavigate, useParams } from 'react-router-dom';
import PublicService from 'services/publicService';
import { v4 as uuidv4 } from 'uuid';
import PasswordTooltip from './PasswordTooltip';
import QRCodeImage from './QRCodeImage';
import StepActions from './StepActions';
import UsernameTooltip from './UsernameTooltip';
import LanguageSelectionFlags from 'components/common/LanguageSelectionFlags';
import { RegisterUser } from 'types/sp-api';
import AppStoreBadge from 'assets/images/AppStore_badge.png';
import AppStoreQRCode from 'assets/images/AppStore_qrcode.png';
import GooglePlayBadge from 'assets/images/GooglePlay_badge.png';
import GooglePlayQRCode from 'assets/images/GooglePlay_qrcode.png';
import SPMobileLogo from 'assets/images/sp_mobile_logo.png';
import DialogTermsAndConditions from './DialogTermsAndConditions';
import { StatusCodes } from 'http-status-codes';
import Validation from 'utils/validationHelper';

const getValidationSchema = (T: LanguageTextFunc, values: RegisterUser) => {
    return Yup.object().shape({
        name: Yup.string()
            .test('username-notemail', T('Registration_UsernameTipNotEmail'), (value) => !value || !value.includes('@'))
            .test('username-chars', T('Registration_UsernameDotDash'), (value) => Validation.isValidUsername(value))
            .test('username-length', T('Registration_UsernameTipLength'), (value) =>
                Validation.isValidUsernameLength(value)
            )
            .required(T('Common_Required')),
        password: Yup.string()
            .test('password-length', T('Registration_PasswordLength'), (value) => !value || value.length >= 8)
            .test('password-chars', T('Registration_PasswordCharacters'), (value) => Validation.isValidPassword(value))
            .test('password-not-username', T('Registration_NotUsername'), (value) => values.name !== value)
            .required(T('Common_Required')),
        passwordConfirm: Yup.string()
            .required(T('Common_Required'))
            .test('match-to-password', T('Registration_PasswordMismatch'), (value) => values.password === value),
        termsAccepted: Yup.boolean().test('accepted-terms', T('Common_Required'), (value) => value === true),
        passwordAgreement: Yup.boolean().test(
            'accepted-password-agreement',
            T('Common_Required'),
            (value) => value === true
        )
    });
};

interface RegistrationViewExt {
    passwordConfirm: string;
    termsAccepted: boolean;
    passwordAgreement: boolean;
}

const RegistrationView: FC = () => {
    const { T } = useContext(LanguageContext);
    const [activeStep, setActiveStep] = useState(0);
    const [sending, setSending] = useState(false);
    const { handleError } = useErrorHandler();
    const { handleSuccess } = useSuccessHandler();
    const [openTermsDialog, setOpenTermsDialog] = useState(false);
    const navigate = useNavigate();
    const [tokenStatus, setTokenStatus] = useState<StatusCodes>();
    const { validationToken } = useParams<'validationToken'>();

    const initialValues: RegisterUser & RegistrationViewExt = {
        name: '',
        password: '',
        passwordConfirm: '',
        actionToken: validationToken,
        otpPassword: '',
        termsAccepted: false,
        passwordAgreement: false
    };

    const saveRegistration = async (values: RegisterUser): Promise<void> => {
        setSending(true);

        try {
            // Generate random otp token
            values.otpPassword = uuidv4().substring(0, 13);

            await PublicService.saveUsernameAndPasswordAsync(values);
            setSending(false);
            handleSuccess(T('User_SaveSuccess'));
        } catch (error) {
            setSending(false);
            handleError(error, T('User_SaveFailed'));
        }
    };

    const validateActionToken = async (token: string): Promise<void> => {
        const status = await PublicService.validateTokenAsync(token);
        setTokenStatus(status);
    };

    useEffect(() => {
        validateActionToken(validationToken ?? '');
    }, [validationToken]);

    return (
        <Container sx={{ pt: 5 }}>
            <Grid container spacing={3}>
                {tokenStatus && tokenStatus != StatusCodes.OK && (
                    <Grid item xs={12} container justifyContent="center" className="error-border" sx={{ m: 3, p: 2 }}>
                        <Typography variant="h5" color="error">
                            {T(`Registration_TokenStatus${tokenStatus}`)}
                        </Typography>
                    </Grid>
                )}
                <Grid item xs={12} container justifyContent="center">
                    <Typography variant="h4">{T('Registration_Title')}</Typography>
                </Grid>
                <Grid item xs={12} container justifyContent="center">
                    <Formik
                        initialValues={initialValues}
                        enableReinitialize
                        onSubmit={saveRegistration}
                        validationSchema={() => Yup.lazy((values) => getValidationSchema(T, values))}
                    >
                        {({ values, dirty, isValid }: FormikProps<RegisterUser & RegistrationViewExt>) => {
                            return (
                                <Form>
                                    <Stepper activeStep={activeStep} orientation="vertical">
                                        <Step>
                                            <StepLabel>
                                                <Typography variant="h5">
                                                    {T('Registration_AgreeTermsAndConditionsTitle')}
                                                </Typography>
                                            </StepLabel>
                                            <StepContent>
                                                <Grid item sx={{ mt: 2 }}>
                                                    <Button
                                                        variant="contained"
                                                        color="secondary"
                                                        onClick={() => setOpenTermsDialog(true)}
                                                        disabled={tokenStatus && tokenStatus != StatusCodes.OK}
                                                    >
                                                        {T('Registration_TermsAndConditionsLinkTitle')}
                                                    </Button>
                                                </Grid>
                                                <DialogTermsAndConditions
                                                    show={openTermsDialog}
                                                    onOk={() => setActiveStep(activeStep + 1)}
                                                    onClose={() => setOpenTermsDialog(false)}
                                                    disabled={
                                                        !dirty || !values.termsAccepted || !values.passwordAgreement
                                                    }
                                                />
                                            </StepContent>
                                        </Step>
                                        <Step>
                                            <StepLabel>
                                                <Typography variant="h5">
                                                    {T('Registration_CreatePasswordTitle')}
                                                </Typography>
                                            </StepLabel>
                                            <StepContent>
                                                <Grid container spacing={3} sx={{ maxWidth: '400px', mt: 1 }}>
                                                    <Grid item xs={10}>
                                                        <FormFieldText
                                                            name="name"
                                                            label={T('Registration_FieldUsername')}
                                                            autoFocus
                                                        />
                                                    </Grid>
                                                    <Grid item xs={2}>
                                                        <LightTooltip title={<UsernameTooltip />}>
                                                            <InfoIcon color="info" />
                                                        </LightTooltip>
                                                    </Grid>
                                                    <Grid item xs={10}>
                                                        <FormFieldText
                                                            name="password"
                                                            label={T('Registration_FieldPassword')}
                                                            password
                                                        />
                                                    </Grid>
                                                    <Grid item xs={2}>
                                                        <LightTooltip title={<PasswordTooltip />}>
                                                            <InfoIcon color="info" />
                                                        </LightTooltip>
                                                    </Grid>
                                                    <Grid item xs={10}>
                                                        <FormFieldText
                                                            name="passwordConfirm"
                                                            label={T('Registration_FieldPasswordConfirm')}
                                                            password
                                                        />
                                                    </Grid>
                                                </Grid>
                                                <StepActions
                                                    activeStep={activeStep}
                                                    setActiveStep={setActiveStep}
                                                    nextDisabled={!dirty || !isValid}
                                                    loading={sending}
                                                    type="submit"
                                                />
                                            </StepContent>
                                        </Step>
                                        <Step>
                                            <StepLabel>
                                                <Typography variant="h5">
                                                    {T('Registration_InstallAppTitle')}
                                                </Typography>
                                            </StepLabel>
                                            <StepContent sx={{ mt: 3 }}>
                                                <Grid container style={{ width: 500 }} spacing={3}>
                                                    <Grid item xs={12} container>
                                                        <Grid item xs={2}>
                                                            <img
                                                                className="sp-mobile-logo"
                                                                src={SPMobileLogo}
                                                                alt="Sähköpaimen sÄppi logo"
                                                            />
                                                        </Grid>
                                                        <Grid item xs={10}>
                                                            <Typography variant="body1">
                                                                {T('Registration_InstallAppText1')}
                                                            </Typography>
                                                            <Box m={1} />
                                                            <Typography variant="body1">
                                                                {T('Registration_InstallAppText2')}
                                                            </Typography>
                                                            <Box m={2} />
                                                            <Typography variant="body2" color="textSecondary">
                                                                {T('Registration_AppProviderText')}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={4} container>
                                                        <Grid item xs={12} style={{ textAlign: 'center' }}>
                                                            <img
                                                                className="qr-code"
                                                                src={AppStoreQRCode}
                                                                alt="AppStore linkki"
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} style={{ textAlign: 'center' }}>
                                                            <Link href="https://apple.co/3xKNlWS" target="_blank">
                                                                <img
                                                                    className="store-badge"
                                                                    src={AppStoreBadge}
                                                                    alt="Lataa AppStoresta"
                                                                />
                                                            </Link>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={4}></Grid>
                                                    <Grid item xs={4}>
                                                        <Grid item xs={12} style={{ textAlign: 'center' }}>
                                                            <img
                                                                className="qr-code"
                                                                src={GooglePlayQRCode}
                                                                alt="Google Play linkki"
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} style={{ textAlign: 'center' }}>
                                                            <Link
                                                                href="https://ply.gl/fi.datacodex.sp_mobile"
                                                                target="_blank"
                                                            >
                                                                <img
                                                                    className="store-badge"
                                                                    src={GooglePlayBadge}
                                                                    alt="Lataa Google Playstä"
                                                                />
                                                            </Link>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Box m={4} />
                                                <StepActions activeStep={activeStep} setActiveStep={setActiveStep} />
                                            </StepContent>
                                        </Step>
                                        <Step>
                                            <StepLabel>
                                                <Typography variant="h5">
                                                    {T('Registration_ActivateAppTitle')}
                                                </Typography>
                                            </StepLabel>
                                            <StepContent>
                                                <Grid container style={{ width: 500 }} sx={{ mt: 3 }}>
                                                    <Grid item xs={4}>
                                                        <QRCodeImage
                                                            username={values.name}
                                                            otpToken={values.otpPassword}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={8}>
                                                        <Typography variant="body1">
                                                            {T('Registration_ActivateAppText')}
                                                        </Typography>
                                                        <Box m={2} />
                                                        <Typography variant="body2">
                                                            {T('Registration_ActivateAppHelpText')}
                                                        </Typography>
                                                        <Box m={2} />
                                                        <Typography variant="body2" color="textSecondary">
                                                            {T('Registration_ActivateAppRightsText')}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                                <StepActions activeStep={activeStep} setActiveStep={setActiveStep} />
                                            </StepContent>
                                        </Step>
                                    </Stepper>
                                    {activeStep === 4 && (
                                        <>
                                            <Grid
                                                item
                                                xs={12}
                                                container
                                                justifyContent="center"
                                                spacing={4}
                                                sx={{ mt: 1, mb: 3 }}
                                                style={{ width: 500 }}
                                            >
                                                <Grid item>
                                                    {T('Register_ActivationFinished').replace('{0}', values.name ?? '')}
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12} container justifyContent="center" spacing={3}>
                                                <Grid item>
                                                    <Button
                                                        variant="contained"
                                                        color="secondary"
                                                        onClick={() => navigate('/')}
                                                    >
                                                        {T('Common_ReturnToDashboard')}
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </>
                                    )}
                                </Form>
                            );
                        }}
                    </Formik>
                </Grid>
                <LanguageSelectionFlags />
            </Grid>
        </Container>
    );
};

export default RegistrationView;
