import React, { FC, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { LanguageContext } from 'contexts/languageContext';
import { SecureMessage } from 'types/sp-api';
import axios, { CancelTokenSource } from 'axios';
import { useErrorHandler } from 'components/hooks/useErrorHandler';
import SecureMessagesService from 'services/secureMessagesService';
import { Box, Button, Card, CardActions, CardContent, Grid, Typography } from '@mui/material';
import moment from 'moment';
import WaitScreen from 'components/common/WaitScreen';
import { useNavigate } from 'react-router-dom';
import { DeleteDialogState } from 'types/common';
import DialogConfirmation from 'components/common/DialogConfirmation';
import { useSuccessHandler } from 'components/hooks/useSuccessHandler';
import LoadButton from 'components/common/LoadButton';

interface SecureMessageItemProps {
    messageId: number;
    closeMessage: () => void;
    updateList: () => Promise<void>;
    sentList?: boolean;
}

const SecureMessageItem: FC<SecureMessageItemProps> = ({
    messageId,
    closeMessage,
    updateList,
    sentList
}: SecureMessageItemProps) => {
    const { T } = useContext(LanguageContext);
    const [message, setMessage] = useState<SecureMessage>();
    const { handleError } = useErrorHandler();
    const { handleSuccess } = useSuccessHandler();
    const cancelRef = useRef<CancelTokenSource | null>(null);
    const navigate = useNavigate();
    const [deleting, setDeleting] = useState(false);
    const [deleteDialogState, setDeleteDialogState] = useState<DeleteDialogState>();

    const getMessage = useCallback(
        async (id: number): Promise<void> => {
            try {
                cancelRef.current = axios.CancelToken.source();
                const msg = await SecureMessagesService.getMessageAsync(id, cancelRef.current.token);
                if (cancelRef.current) {
                    setMessage(msg);

                    // store info that this message is read
                    if (msg.unread && sentList !== true) {
                        msg.unread = false;
                        await SecureMessagesService.saveMessageAsync(msg);
                        await updateList();
                    }
                }
            } catch (error) {
                handleError(error, T('Error_SecureMessageQueryFailed'));
            }
        },
        [handleError, T, updateList, sentList]
    );

    useEffect(() => {
        getMessage(messageId);

        return () => {
            cancelRef.current && cancelRef.current.cancel();
            cancelRef.current = null;
        };
    }, [getMessage, messageId]);

    const replyMessage = () => {
        navigate(`/communication/securemessages/new/${messageId}/${message?.senderId}`);
    };

    const deleteMessage = async () => {
        if (deleteDialogState?.id) {
            try {
                setDeleting(true);
                await SecureMessagesService.deleteMessageAsync(deleteDialogState?.id);
                updateList();
                handleSuccess(T('SecureMessage_DeleteSuccess'));
                setDeleting(false);
                navigate('/communication/securemessages');
            } catch (error) {
                setDeleting(false);
                handleError(error, T('SecureMessage_DeleteFailed'));
            }
        }
    };

    return (
        <Grid item xs={12}>
            {!message && <WaitScreen />}
            {message && (
                <Card className="message-item">
                    <CardContent
                        sx={{
                            backgroundColor: 'white',
                            borderWidth: 0,
                            borderBottomWidth: 1,
                            borderBottomColor: 'primary.main',
                            borderBottomStyle: 'solid'
                        }}
                    >
                        <Grid container>
                            <Grid container justifyContent="space-between">
                                <Grid container justifyContent="flex-start" item xs={8}>
                                    <Typography variant="subtitle1" color="textSecondary" noWrap>
                                        {sentList && message.recipient?.name}
                                        {!sentList && message.sender?.name}
                                    </Typography>
                                </Grid>
                                <Grid container justifyContent="flex-end" item xs={4}>
                                    <Typography variant="subtitle2" color="textSecondary" noWrap>
                                        {`${moment(message.createdDate).format('l')} ${moment(
                                            message.createdDate
                                        ).format('LT')}`}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container item>
                                <Typography variant="h6" sx={{ backgroundColor: 'white' }} noWrap color="primary">
                                    {message.title}
                                </Typography>
                            </Grid>
                        </Grid>
                    </CardContent>
                    <CardContent sx={{ minHeight: 150 }}>
                        <Box m={1}>
                            <Typography variant="body1" component="div">
                                {message?.message?.split('\n\n').map((paragraph, index) => (
                                    <p key={index}>
                                        {paragraph.split('\n').map((line, lineIndex) => (
                                            <span style={{ display: 'block' }} key={lineIndex}>
                                                {line}
                                            </span>
                                        ))}
                                    </p>
                                ))}
                            </Typography>
                        </Box>
                    </CardContent>
                    <CardActions style={{ paddingLeft: 16, paddingBottom: 16 }}>
                        <Grid container spacing={3}>
                            {!sentList && (
                                <Grid item>
                                    <Button onClick={replyMessage} color="primary" variant="contained">
                                        {T('SecureMessage_Reply')}
                                    </Button>
                                </Grid>
                            )}
                            <Grid item>
                                <Button onClick={closeMessage} color="secondary" variant="contained">
                                    {T('Common_Close')}
                                </Button>
                            </Grid>
                        </Grid>
                        <Grid container justifyContent="flex-end" style={{ marginRight: 8 }}>
                            <LoadButton
                                loading={deleting}
                                variant="outlined"
                                color="error"
                                onClick={() =>
                                    setDeleteDialogState({
                                        show: true,
                                        message: T('SecureMessage_ConfirmDeleteMessage').replace(
                                            '{0}',
                                            message.sender?.name ?? ''
                                        ),
                                        id: messageId
                                    })
                                }
                            >
                                {T('Common_Delete')}
                            </LoadButton>
                        </Grid>
                        <DialogConfirmation
                            title={T('SecureMessage_ConfirmDeleteTitle')}
                            message={deleteDialogState?.message}
                            show={deleteDialogState?.show}
                            onClose={() => setDeleteDialogState(undefined)}
                            onOk={deleteMessage}
                            warning
                            okTitle={T('Common_Delete')}
                        />
                    </CardActions>
                </Card>
            )}
        </Grid>
    );
};

export default SecureMessageItem;
