import React, { FC, useContext } from 'react';
import { LanguageContext } from 'contexts/languageContext';
import FormFieldDropDown from './FormFieldDropDown';
import { KeyValue } from 'types/common';
import { UserRole } from 'types/auth';
import Utils from 'utils/typeHelper';
import { AuthContext } from 'contexts/authContext';
import config from 'config/config';
import constants from 'config/constants';

interface FormFieldDropDownUserRoleProps {
    label: string;
    name: string;
    autoFocus?: boolean;
}

const FormFieldDropDownUserRole: FC<FormFieldDropDownUserRoleProps> = ({
    label,
    name,
    autoFocus
}: FormFieldDropDownUserRoleProps) => {
    const { T } = useContext(LanguageContext);
    const { user } = useContext(AuthContext);

    const filterFunc = (item: UserRole) => {
        if (config.CUSTOMER === constants.Customers.Shk) {
            return item !== UserRole.Unknown && item !== UserRole.Person;
        }
        if (config.CUSTOMER === constants.Customers.Advk) {
            return (
                item !== UserRole.Unknown &&
                item !== UserRole.Person &&
                item !== UserRole.Clerk &&
                item != UserRole.SuperUser
            );
        }
    };

    const values: KeyValue[] = Object.values(UserRole)
        .filter((role) => Utils.AllowedRole(role, user?.role))
        .filter(filterFunc)
        .sort((a: UserRole, b: UserRole) => {
            if (`RoleType_${a}` > `RoleType_${b}`) return 1;
            if (`RoleType_${a}` < `RoleType_${b}`) return -1;

            return 0;
        })
        .map((item) => ({
            key: Utils.RoleToId(item),
            value: `RoleType_${item}`
        }));

    return (
        <FormFieldDropDown
            name={name}
            label={label}
            values={values}
            placeholder={T('Common_SelectRole')}
            autoFocus={autoFocus}
        />
    );
};

export default FormFieldDropDownUserRole;
