import React, { FC } from 'react';
import { useParams } from 'react-router-dom';
import { GlobalGroupRegisterType } from 'types/common';
import GlobalGroupRegisterView from './GlobalGroupRegisterView';

const SeniorRegisterView: FC = () => {
    const { filter } = useParams<'filter'>();

    const registerType: GlobalGroupRegisterType = 'senior';

    return <GlobalGroupRegisterView registerType={registerType} filter={filter} />;
};

export default SeniorRegisterView;
