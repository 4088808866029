import React, { FC, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { AuthContext } from 'contexts/authContext';
import OwnChurchItem from './OwnChurchItem';
import { PublicChurchItem } from './PublicChurchItem';
import { UserRole } from 'types/auth';

const ChurchItem: FC = () => {
    const { user, hasRole } = useContext(AuthContext);
    const { churchId } = useParams<'churchId'>();

    if (user?.organizations.some((item) => item.churchId === parseInt(churchId ?? '')) || hasRole(UserRole.Admin)) {
        return <OwnChurchItem churchId={churchId ?? ''} />;
    } else {
        return <PublicChurchItem churchId={churchId ?? ''} />;
    }
};

export default ChurchItem;
