import React, { FC, useContext, useState } from 'react';
import { Container, Paper, createTheme, Typography, ThemeProvider, Box } from '@mui/material';
import Header from './Header';
import Footer from './Footer';
import MainMenu from './MainMenu';
import AppRoutes from './AppRoutes';
import { NotificationsProvider } from 'contexts/notificationsContext';
import { SecureMessagesProvider } from 'contexts/secureMessagesContext';
import AppIdleTimer from './AppIdleTimer';
import { getThemeLocale, theme } from 'config/theme';
import { AppLanguage } from 'types/common';
import * as MuiThemeLocales from '@mui/material/locale';
import AppCookieConsent from './AppCookieConsent';
import versionInfo from 'config/versionInfo.json';
import BadgeDiagonal from 'components/common/BadgeDiagonal';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import fiLocale from 'date-fns/locale/fi';
import { ApplicationContext } from 'contexts/applicationContext';
import constants from 'config/constants';
import config from 'config/config';

interface AppLayoutProps {
    locale: AppLanguage;
}

const AppLayout: FC<AppLayoutProps> = ({ locale }: AppLayoutProps) => {
    const [notificationCount, setNotificationCount] = useState(0);
    const [messageCount, setMessageCount] = useState(0);
    const { showHeaders } = useContext(ApplicationContext);

    return (
        <ThemeProvider theme={createTheme(theme, MuiThemeLocales[getThemeLocale(locale)])}>
            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={fiLocale}>
                <NotificationsProvider value={{ notificationCount, setNotificationCount }}>
                    <SecureMessagesProvider value={{ messageCount, setMessageCount }}>
                        <div className="app-content-container">
                            <Container maxWidth="lg" className="app-content-wrapper">
                                {!showHeaders && <Box pt={2} />}
                                {showHeaders && <BadgeDiagonal testOnly />}
                                <Paper>
                                    {showHeaders && (
                                        <>
                                            <Header />
                                            <MainMenu />
                                        </>
                                    )}
                                    <Container sx={{ minHeight: '70vh', pb: 3 }}>
                                        <AppRoutes />
                                    </Container>
                                </Paper>
                                <Container>
                                    <Typography variant="caption" style={{ color: 'silver' }}>
                                        {`Versio ${versionInfo.versiontag} (build: ${versionInfo.buildtag})`}
                                    </Typography>
                                </Container>
                            </Container>
                            <AppIdleTimer
                                idleTimeout={
                                    config.CUSTOMER == constants.Customers.Advk
                                        ? constants.Settings.LongerIdleTimeoutSeconds
                                        : constants.Settings.IdleTimeoutSeconds
                                }
                                logoutTimeout={constants.Settings.IdleTimeoutLogoutSeconds}
                            />
                            <AppCookieConsent />
                            <Footer />
                        </div>
                    </SecureMessagesProvider>
                </NotificationsProvider>
            </LocalizationProvider>
        </ThemeProvider>
    );
};

export default AppLayout;
