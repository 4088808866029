import { useCallback, useContext } from 'react';
import { AuthContext } from 'contexts/authContext';
import AuditService from 'services/auditService';
import { KeyValue } from 'types/sp-api';

type auditFunc = (message: string, url: string, data?: KeyValue[]) => void;

export interface AuditContext {
    audit: auditFunc;
}

export const useAudit = (): AuditContext => {
    const { user } = useContext(AuthContext);
    const writeAuditInternal = (message: string, url: string, data?: KeyValue[]) => {
        AuditService.sendAuditAsync(message, url, data, user);
    };

    return { audit: useCallback(writeAuditInternal, []) };
};
