import React, { FC, useContext, useState } from 'react';
import { Container, Grid, Typography } from '@mui/material';
import { LanguageContext, LanguageTextFunc } from 'contexts/languageContext';
import { Form, Formik, FormikProps } from 'formik';
import * as Yup from 'yup';
import PublicService from 'services/publicService';
import { useErrorHandler } from 'components/hooks/useErrorHandler';
import { useSuccessHandler } from 'components/hooks/useSuccessHandler';
import FormFieldText from 'components/common/FormFieldText';
import LoadButton from 'components/common/LoadButton';
import LanguageSelectionFlags from 'components/common/LanguageSelectionFlags';
import { ForgotPassword } from 'types/sp-api';

const getValidationSchema = (T: LanguageTextFunc) => {
    return Yup.object().shape({
        username: Yup.string().required(T('Common_Required'))
    });
};

const ForgottenPasswordView: FC = () => {
    const { T } = useContext(LanguageContext);
    const [sending, setSending] = useState(false);
    const { handleError } = useErrorHandler();
    const { handleSuccess } = useSuccessHandler();

    const sendPasswordReset = async (values: ForgotPassword): Promise<void> => {
        setSending(true);

        try {
            await PublicService.sendPasswordResetAsync(values);
            setSending(false);
            handleSuccess(T('ForgottenPassword_SaveSuccess'));
        } catch (error) {
            setSending(false);
            handleError(error, T('ForgottenPassword_SaveFailed'));
        }
    };

    return (
        <Container sx={{ paddingTop: 4 }}>
            <Formik
                initialValues={{ username: '' }}
                onSubmit={sendPasswordReset}
                validationSchema={getValidationSchema(T)}
            >
                {({ dirty, isValid }: FormikProps<ForgotPassword>) => {
                    return (
                        <Form>
                            <Grid container spacing={4} justifyContent="center">
                                <Grid item xs={12} container justifyContent="center">
                                    <Typography variant="h4">{T('ForgottenPassword_Title')}</Typography>
                                </Grid>
                                <Grid item xs={12} container justifyContent="center">
                                    <Grid item xs={5}>
                                        <Typography>{T('ForgottenPassword_HelpText')}</Typography>
                                    </Grid>
                                </Grid>
                                <Grid item xs={5} container justifyContent="center">
                                    <Grid item container>
                                        <FormFieldText
                                            name="username"
                                            label={T('ForgottenPassword_FieldUsername')}
                                            autoFocus
                                        />
                                    </Grid>
                                    <Grid item sx={{ paddingTop: 3, paddingBottom: 2 }}>
                                        <LoadButton loading={sending} disabled={!dirty || !isValid} type="submit">
                                            {T('ForgottenPassword_SendButton')}
                                        </LoadButton>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Form>
                    );
                }}
            </Formik>
            <LanguageSelectionFlags />
        </Container>
    );
};

export default ForgottenPasswordView;
