import React, { FC } from 'react';
import { Grid } from '@mui/material';
import { PersonTabProps } from './PersonTabBasic';
import AdditionalInfo from './AdditionalInfo';
import { InfoType } from 'types/common';

const PersonTabAdditionalInfo: FC<PersonTabProps> = ({ person }: PersonTabProps) => {
    return (
        <Grid container spacing={5}>
            <Grid container item xs={6} spacing={2} alignContent="flex-start">
                <AdditionalInfo person={person} type={InfoType.Conversion} />
            </Grid>
            <Grid container item xs={6} spacing={2} alignContent="flex-start">
                <AdditionalInfo person={person} type={InfoType.Baptism} />
            </Grid>
        </Grid>
    );
};

export default PersonTabAdditionalInfo;
