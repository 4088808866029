import React, { FC, useContext, useEffect, useRef } from 'react';
import axios, { AxiosResponse, CancelToken, CancelTokenSource } from 'axios';
import { LanguageContext } from 'contexts/languageContext';

interface DownloadFileProps {
    fetchFile: (cancelToken: CancelToken) => Promise<AxiosResponse>;
    filename: string;
    filetype?: string;
    showLoading?: boolean;
    onSuccess?: () => void;
}

const DownloadFile: FC<DownloadFileProps> = ({
    fetchFile,
    filename,
    filetype = 'application/pdf',
    showLoading = false,
    onSuccess
}: DownloadFileProps) => {
    const { T } = useContext(LanguageContext);
    const cancelRef = useRef<CancelTokenSource | null>(null);

    useEffect(() => {
        const download = async () => {
            cancelRef.current = axios.CancelToken.source();
            const response = await fetchFile(cancelRef.current.token);

            if (cancelRef.current) {
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(new Blob([response.data], { type: filetype }));
                link.download = filename;
                link.click();
            }
        };

        download().then(() => {
            onSuccess && onSuccess();
        });

        return () => {
            cancelRef.current && cancelRef.current.cancel();
            cancelRef.current = null;
        };
    }, [filename, filetype, fetchFile, onSuccess]);

    return <>{showLoading && T('Common_DownloadingFile')}</>;
};

export default DownloadFile;
