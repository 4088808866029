import React, { FC, useContext } from 'react';
import { Typography } from '@mui/material';
import { LanguageContext } from 'contexts/languageContext';
import moment from 'moment';

interface LastModifiedProps {
    by?: string;
    date?: Date | string;
    prefix?: string;
}

const LastModified: FC<LastModifiedProps> = ({ by, date, prefix = 'LastModifiedBy_Prefix' }: LastModifiedProps) => {
    const { T } = useContext(LanguageContext);

    return (
        <Typography className="last-modified-info" variant="inherit">
            {T(prefix)} {by} {moment(date).format('l LT')}
        </Typography>
    );
};

export default LastModified;
