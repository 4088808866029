import React, { FC, useCallback, useContext, useEffect, useState } from 'react';
import { Button, Container, Grid, Typography } from '@mui/material';
import BreadcrumbBar from '../common/BreadcrumbBar';
import ViewTitle from '../common/ViewTitle';
import { LanguageContext } from 'contexts/languageContext';
import { AccountCircle } from '@mui/icons-material';
import { AuthContext } from 'contexts/authContext';
import FormFieldReadOnly from 'components/common/FormFieldReadonly';
import FormFieldDropDownLanguage from 'components/common/FormFieldDropDownLanguage';
import { Form, Formik, FormikProps } from 'formik';
import { AuthenticatedUser } from 'types/auth';
import LoadButton from 'components/common/LoadButton';
import ProfileService from 'services/profileService';
import { useErrorHandler } from 'components/hooks/useErrorHandler';
import Utils from 'utils/typeHelper';
import { AppLanguage } from 'types/common';
import defaults from 'config/defaults';
import PersonsService from 'services/personsService';
import { useSuccessHandler } from 'components/hooks/useSuccessHandler';
import { useNavigate } from 'react-router-dom';
import QRCodeImage from 'components/registration/QRCodeImage';
import { UserOtpInfo } from 'types/sp-api';

interface ProfileViewUser extends AuthenticatedUser {
    language?: AppLanguage;
}

const ProfileView: FC = () => {
    const { T, setLanguage } = useContext(LanguageContext);
    const { user } = useContext(AuthContext);
    const [sending, setSending] = useState(false);
    const { handleError } = useErrorHandler();
    const { handleSuccess } = useSuccessHandler();
    const [profileUser, setProfileUser] = useState<ProfileViewUser>();
    const [otpCode, setOtpCode] = useState<UserOtpInfo>();
    const navigate = useNavigate();

    const saveProfile = async (values: ProfileViewUser): Promise<void> => {
        setSending(true);

        try {
            await ProfileService.saveUserLanguageAsync(user?.personId, values.language);
            if (values.language) setLanguage(values.language);
            setSending(false);
            handleSuccess(T('Profile_SaveSuccess'));
        } catch (error) {
            handleError(error, T('Profile_SaveFailed'));
        }
    };

    const getUserLanguage = useCallback(async (personId: number): Promise<AppLanguage> => {
        const person = await PersonsService.getPersonAsync(personId);
        return (person.language as AppLanguage) ?? defaults.Language;
    }, []);

    const toggleOtpInfo = useCallback(async (): Promise<void> => {
        if (otpCode == undefined) {
            const info = await ProfileService.getUserOtpInfoAsync();
            setOtpCode(info);
        } else {
            setOtpCode(undefined);
        }
    }, [otpCode]);

    useEffect(() => {
        if (user) {
            getUserLanguage(user.personId).then((langugage) => setProfileUser({ ...user, language: langugage }));
        }
    }, [user]);

    if (!user || !profileUser) return null;

    return (
        <Formik initialValues={profileUser} enableReinitialize onSubmit={saveProfile}>
            {({ dirty }: FormikProps<ProfileViewUser>) => {
                return (
                    <Form>
                        <Container>
                            <Grid container>
                                <BreadcrumbBar />
                                <ViewTitle title={T('Common_Profile')} icon={AccountCircle} />
                            </Grid>
                            <Grid container spacing={3}>
                                <Grid container spacing={3} item xs={6}>
                                    <Grid item xs={12}>
                                        <FormFieldReadOnly
                                            label={T('Profile_FieldUserName')}
                                            value={user?.username}
                                            tooltip={`User ID: ${user?.userId}`}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormFieldReadOnly
                                            label={T('Profile_FieldFullName')}
                                            value={user?.fullname}
                                            tooltip={`Person ID: ${user?.personId}`}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormFieldReadOnly label={T('Profile_FieldEmail')} value={user?.email} />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormFieldDropDownLanguage name="language" label={T('Common_Language')} />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormFieldReadOnly
                                            label={T('Profile_FieldRole')}
                                            value={T(Utils.RoleIdToName(Utils.RoleToId(user.role)))}
                                        />
                                    </Grid>
                                    <Grid container item xs={12} spacing={3}>
                                        <Grid item>
                                            <LoadButton loading={sending} disabled={!dirty} type="submit">
                                                {T('Common_Save')}
                                            </LoadButton>
                                        </Grid>
                                        <Grid item>
                                            <Button
                                                onClick={() => navigate(`/persons/${user.personId}`)}
                                                variant="text"
                                                color="secondary"
                                            >
                                                {T('Profile_MoveToPerson')}
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={1} item xs={4} marginLeft={2}>
                                    <Grid item xs={12}>
                                        <Typography>{T('Profile_ShowOtpCodeTitle')}</Typography>
                                        <br />
                                        <Button onClick={toggleOtpInfo} color="secondary" variant="contained">
                                            {T(otpCode ? 'Profile_HideOtpCode' : 'Profile_ShowOtpCode')}
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12} sx={{ minHeight: 180 }}>
                                        {otpCode && (
                                            <QRCodeImage username={otpCode.name} otpToken={otpCode.otpPassword} />
                                        )}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Container>
                    </Form>
                );
            }}
        </Formik>
    );
};

export default ProfileView;
