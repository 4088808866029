import axios, { CancelToken } from 'axios';
import { OrganizationType } from 'types/common';
import { OrganizationContact, PersonContact } from 'types/sp-api';
import LocalCache from './localCache';
import Utils from 'utils/typeHelper';

class ContactService {
    public async getOrganizationsAsync(
        filter?: string,
        cancelToken?: CancelToken,
        includeShk?: boolean,
        includeContacts?: boolean,
        includeExtraAssociations?: boolean
    ): Promise<OrganizationContact[]> {
        const url = 'contacts/organizations';

        // get always all organizations
        const organizations = await this.cachedGetRequest<OrganizationContact[]>(
            url,
            undefined,
            undefined,
            cancelToken
        );

        const allowedTypes = [OrganizationType.LocalCommunity, OrganizationType.Association];

        if (includeShk) {
            allowedTypes.push(OrganizationType.ReligiousCommunity);
        }
        if (includeContacts) {
            allowedTypes.push(OrganizationType.ContactInfo);
        }

        let filteredOrganizations = organizations.filter((item) => allowedTypes.includes(item.type));
        const businessId = /(\d{7})-\d/;

        if (filter) {
            if (businessId.test(filter)) {
                filteredOrganizations = organizations.filter((item) => item.businessId == filter);
            } else {
                filteredOrganizations = organizations.filter(
                    (item) =>
                        item.name.toLocaleLowerCase().includes(filter.toLocaleLowerCase()) ||
                        item.city?.toLocaleLowerCase().includes(filter.toLocaleLowerCase())
                );
            }
        }

        if (!includeExtraAssociations) {
            filteredOrganizations = filteredOrganizations.filter(
                (item) => !(Utils.IsYhdistys(item.type) && !item.memberTransferAllowed)
            );
        }

        return filteredOrganizations.sort((a, b) => {
            if (a.type < b.type) return -1;
            if (a.type > b.type) return 1;

            if (a.name < b.name) return -1;
            if (a.name > b.name) return 1;
            return 0;
        });
    }

    public async refreshOrganizationsAsync(): Promise<void> {
        const url = 'contacts/organizations';

        LocalCache.forceExpire(`${url}`);
    }

    public async getOrganizationAsync(id: number, cancelToken?: CancelToken): Promise<OrganizationContact> {
        const organizations = await this.getOrganizationsAsync(undefined, cancelToken, true, true, true);

        return organizations.find((item) => item.id === id) ?? { id: 0, name: '', churchId: 0, type: 0 };
    }

    public async getPersonsAsync(filter?: string, cancelToken?: CancelToken): Promise<PersonContact[]> {
        const url = 'contacts/persons';

        return this.cachedGetRequest(url, undefined, filter, cancelToken);
    }

    private async cachedGetRequest<T>(
        url: string,
        params?: URLSearchParams,
        filter?: string,
        cancelToken?: CancelToken
    ): Promise<T> {
        const axiosInstance = axios.create();
        if (!filter) {
            return LocalCache.cachedRequest<T>(url, async () => {
                const response = await axiosInstance.get<T>(url, {
                    params: params,
                    cancelToken: cancelToken
                });

                return response.data;
            });
        } else {
            if (!params) params = new URLSearchParams();
            params.append('query', `name==*${filter}*`);
            const response = await axiosInstance.get(url, {
                params: params,
                cancelToken: cancelToken
            });

            return response.data;
        }
    }
}

export default new ContactService();
