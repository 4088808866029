import React, { FC, useContext } from 'react';
import logo from 'assets/sp_logo.png';
import { LanguageContext } from 'contexts/languageContext';
import { Link } from 'react-router-dom';
import { Grid } from '@mui/material';

const Header: FC = () => {
    const { T } = useContext(LanguageContext);

    return (
        <header className="header">
            <Grid container justifyContent="center" alignItems="center">
                <Grid item>
                    <Link to="/" style={{ color: 'white', textDecoration: 'none' }}>
                        {T('Common_ApplicationTitle')}
                    </Link>
                </Grid>
                <Grid item>
                    <Link to="/">
                        <img className="logo" style={{ marginTop: 15 }} src={logo} alt="logo" />
                    </Link>
                </Grid>
            </Grid>
        </header>
    );
};

export default Header;
