import React, { FC, ReactNode } from 'react';
import DialogBase from './DialogBase';

interface DialogConfirmationProps {
    title: string;
    message?: string | ReactNode;
    show?: boolean;
    onCancel?: () => void;
    onOk: () => void;
    onClose?: () => void;
    okTitle?: string;
    cancelTitle?: string;
    warning?: boolean;
}

const DialogConfirmation: FC<DialogConfirmationProps> = ({
    message,
    okTitle = 'Common_Yes',
    cancelTitle = 'Common_Cancel',
    ...props
}: DialogConfirmationProps) => {
    return (
        <DialogBase okTitle={okTitle} cancelTitle={cancelTitle} {...props}>
            {message}
        </DialogBase>
    );
};

export default DialogConfirmation;
