import React, { ChangeEvent, FC, useContext, useState } from 'react';
import { LanguageContext, LanguageTextFunc } from 'contexts/languageContext';
import {
    TableContainer,
    Paper,
    Table,
    TableBody,
    TableRow,
    TableCell,
    TableHead,
    Grid,
    Typography,
    Button
} from '@mui/material';
import WaitScreen from 'components/common/WaitScreen';
import ButtonAdd from 'components/common/ButtonAdd';
import { Contract } from 'types/sp-api';
import DialogConfirmation from 'components/common/DialogConfirmation';
import OrganizationsService from 'services/organizationsService';
import { useSuccessHandler } from 'components/hooks/useSuccessHandler';
import DialogBase from 'components/common/DialogBase';
import { Formik, FormikProps, Form } from 'formik';
import * as Yup from 'yup';
import ButtonDownload from 'components/common/ButtonDownload';
import DownloadFile from 'components/common/DownloadFile';
import Utils from 'utils/typeHelper';
import FormFieldDropDownCodeSet from 'components/common/FormFieldDropDownCodeSet';
import FormFieldDate from 'components/common/FormFieldDate';
import FormFieldDropDownOrganization from 'components/common/FormFieldDropDownOrganization';
import FormFieldReadOnly from 'components/common/FormFieldReadonly';
import { TableHeadCell } from 'components/common/TableHeadCell';
import { DeleteDialogState, DialogOpenState } from 'types/common';
import moment from 'moment';
import ButtonDelete from 'components/common/ButtonDelete';
import { useErrorHandler } from 'components/hooks/useErrorHandler';

const FILE_SIZE = 10 * 1024 * 1024;
const SUPPORTED_FORMATS = [
    'image/jpg',
    'image/jpeg',
    'image/png',
    'application/pdf',
    'application/rtf',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.ms-powerpoint',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation'
];

const getValidationSchema = (T: LanguageTextFunc) => {
    return Yup.object().shape({
        contractType: Yup.number().min(1, T('Common_Required')),
        otherOrganizationId: Yup.number().min(1, T('Common_Required')),
        file: Yup.mixed()
            .test('fileSize', 'File Size is too large', (value) => !value || value.size <= FILE_SIZE)
            .test('fileType', 'Unsupported File Format', (value) => !value || SUPPORTED_FORMATS.includes(value.type))
            .test('fileExists', T('Common_Required'), (value) => value)
    });
};

interface ContractForm extends Contract {
    file?: File;
}

interface ContractListProps {
    organizationId: number;
    contractsList?: Contract[];
    updateContractsList: () => void;
}

const ContractList: FC<ContractListProps> = ({
    organizationId,
    contractsList,
    updateContractsList
}: ContractListProps) => {
    const { T } = useContext(LanguageContext);
    const [openAddDialog, setOpenAddDialog] = useState<DialogOpenState>(false);
    const [deleteDialogState, setDeleteDialogState] = useState<DeleteDialogState>();
    const { handleSuccess } = useSuccessHandler();
    const { handleError } = useErrorHandler();
    const [selectedContract, setSelectedContract] = useState<Contract>();
    const [downloadFile, setDownloadFile] = useState<Contract>();
    const [selectedFile, setSelectedFile] = useState<File>();

    const getNewContract = (): Contract => ({
        contractType: 0,
        otherOrganizationId: 0,
        beginDate: new Date()
    });

    const deleteContract = async () => {
        try {
            await OrganizationsService.deleteContractAsync(organizationId, deleteDialogState?.id);
            handleSuccess(T('Contract_DeleteSuccess'));
            updateContractsList();
        } catch (error) {
            handleError(error, T('Contract_DeleteFailed'));
        }
    };

    const saveContract = async (contract: Contract) => {
        try {
            await OrganizationsService.saveContractAsync(organizationId, contract, selectedFile);
            handleSuccess(T('Contract_SaveSuccess'));
            updateContractsList();
        } catch (error) {
            handleError(error, T('Contract_SaveFailed'));
        }
    };

    const addContract = () => {
        setSelectedContract(getNewContract());
        setOpenAddDialog(true);
    };

    const downloadContract = (contract: Contract) => {
        setDownloadFile(contract);
    };

    const fetchFile = (contractId: number) => {
        return OrganizationsService.getContractFileAsync(organizationId, contractId);
    };

    return (
        <>
            <Grid item xs={12}>
                <TableContainer component={Paper}>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableHeadCell sx={{ width: '35%' }}>{T('Contract_Type')}</TableHeadCell>
                                <TableHeadCell sx={{ width: '35%' }}>{T('Contract_OtherOrganization')}</TableHeadCell>
                                <TableHeadCell sx={{ width: 100 }}>{T('Contract_FieldBeginDate')}</TableHeadCell>
                                <TableHeadCell sx={{ width: 30, textAlign: 'center' }} />
                                <TableHeadCell sx={{ width: 30, textAlign: 'center' }} />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {!contractsList && (
                                <TableRow>
                                    <TableCell>
                                        <WaitScreen />
                                    </TableCell>
                                </TableRow>
                            )}
                            {contractsList &&
                                contractsList.length > 0 &&
                                contractsList.map((row) => (
                                    <TableRow key={row.id}>
                                        <TableCell>{T(Utils.ContractTypeToName(row.contractType))}</TableCell>
                                        <TableCell>
                                            {row.otherOrganization?.name ?? T('Common_ReligionalCommunity')}
                                        </TableCell>
                                        <TableCell>{moment(row.beginDate).format('l')}</TableCell>
                                        <TableCell sx={{ width: 30, textAlign: 'center' }}>
                                            <ButtonDownload onClick={() => downloadContract(row)} small />
                                        </TableCell>
                                        <TableCell sx={{ width: 30, textAlign: 'center' }}>
                                            <ButtonDelete
                                                small
                                                onClick={() =>
                                                    setDeleteDialogState({
                                                        show: true,
                                                        message: T('Contract_ConfirmDeleteMessage').replace(
                                                            '{0}',
                                                            T(Utils.ContractTypeToName(row.contractType))
                                                        ),
                                                        id: row.id ?? 0
                                                    })
                                                }
                                            >
                                                {T('Common_Delete')}
                                            </ButtonDelete>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            {contractsList && contractsList.length === 0 && (
                                <TableRow>
                                    <TableCell>
                                        <Typography variant="body1" color="textSecondary">
                                            {T('Contract_NoContracts')}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
            <Grid item xs={12} container justifyContent="flex-end">
                <ButtonAdd
                    small
                    title={T('Contract_AddNew')}
                    variant="text"
                    onClick={() => addContract()}
                    color="secondary"
                />
            </Grid>
            {selectedContract && (
                <Formik
                    initialValues={selectedContract}
                    enableReinitialize
                    validationSchema={getValidationSchema(T)}
                    onSubmit={saveContract}
                >
                    {({
                        dirty,
                        isValid,
                        isSubmitting,
                        submitForm,
                        errors,
                        getFieldHelpers
                    }: FormikProps<ContractForm>) => {
                        const selectFile = (event: ChangeEvent<HTMLInputElement>): void => {
                            if (!event.currentTarget.files) return;

                            setSelectedFile(event.currentTarget.files[0]);
                            const helper = getFieldHelpers('file');
                            helper.setValue(event.currentTarget.files[0]);
                        };

                        return (
                            <DialogBase
                                show={!!openAddDialog}
                                title={
                                    openAddDialog === 'edit'
                                        ? T('Contract_EditDialogTitle')
                                        : T('Contract_AddDialogTitle')
                                }
                                onOk={submitForm}
                                onClose={() => setOpenAddDialog(false)}
                                okDisabled={isSubmitting || !dirty || !isValid}
                                okTitle={T('Common_Save')}
                            >
                                <Form>
                                    <Grid container spacing={3} sx={{ pt: 1 }}>
                                        <Grid item xs={12}>
                                            <FormFieldDropDownCodeSet
                                                name="contractType"
                                                label={T('Contract_FieldType')}
                                                codeSet="contractType"
                                                placeholder={T('Contract_SelectContractType')}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <FormFieldDropDownOrganization
                                                name="otherOrganizationId"
                                                label={T('Contract_FieldOtherOrganization')}
                                                placeholder={T('Contract_SelectOtherOrganization')}
                                                showAllAccessable
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <FormFieldDate name="beginDate" label={T('Contract_FieldBeginDate')} />
                                        </Grid>
                                        <Grid item xs={12} container spacing={1}>
                                            <Grid item xs={4}>
                                                <label htmlFor="btn-upload">
                                                    <input
                                                        id="btn-upload"
                                                        name="btn-upload"
                                                        style={{ display: 'none' }}
                                                        type="file"
                                                        onChange={selectFile}
                                                    />
                                                    <Button
                                                        className="btn-choose"
                                                        variant="contained"
                                                        color="secondary"
                                                        component="span"
                                                        style={{ height: 40 }}
                                                    >
                                                        {T('Common_Browse')}
                                                    </Button>
                                                </label>
                                            </Grid>
                                            <Grid item xs={8}>
                                                <FormFieldReadOnly
                                                    label={'' /*T('Contract_FieldSelectedFile')*/}
                                                    value={selectedFile?.name ?? T('Contract_SelectedFile')}
                                                />
                                                {errors.file && (
                                                    <Typography
                                                        color="error"
                                                        variant="caption"
                                                        style={{ marginLeft: 14 }}
                                                    >
                                                        {errors.file}
                                                    </Typography>
                                                )}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Form>
                            </DialogBase>
                        );
                    }}
                </Formik>
            )}
            <DialogConfirmation
                title={T('Contract_ConfirmDeleteTitle')}
                message={deleteDialogState?.message}
                show={deleteDialogState?.show}
                onClose={() => setDeleteDialogState(undefined)}
                onOk={deleteContract}
                warning
            />
            {downloadFile && (
                <DownloadFile
                    fetchFile={() => fetchFile(downloadFile.id ?? 0)}
                    filename={downloadFile.document?.fileName ?? ''}
                    onSuccess={() => setDownloadFile(undefined)}
                />
            )}
        </>
    );
};

export default ContractList;
