import axios from 'axios';
import { ValidationResult } from 'types/sp-api';

class ValidationService {
    public async isValidUsernameAsync(username?: string): Promise<boolean> {
        if (!username) return true;

        const response = await axios.get<ValidationResult>(`validation/username/${username}`);
        return response.data.valid;
    }

    public async isValidEmailAsync(email?: string): Promise<boolean> {
        if (!email) return true;

        const response = await axios.get<ValidationResult>(`validation/email/${email}`);
        return response.data.valid;
    }

    public async isValidSsnAsync(ssn?: string): Promise<boolean> {
        if (!ssn) return true;

        const response = await axios.get<ValidationResult>(`validation/ssn/${ssn}`);
        return response.data.valid;
    }

    public async isValidBusinessIdAsync(businessId?: string): Promise<boolean> {
        if (!businessId) return true;

        const response = await axios.get<ValidationResult>(`validation/businessid/${businessId}`);
        return response.data.valid;
    }
}

export default new ValidationService();
