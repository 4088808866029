import React, { FC, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { LanguageContext } from 'contexts/languageContext';
import { Grid, Typography } from '@mui/material';
import { Membership, Person } from 'types/sp-api';
import { useErrorHandler } from 'components/hooks/useErrorHandler';
import axios, { CancelTokenSource } from 'axios';
import PersonsService from 'services/personsService';
import { format } from 'date-fns';
import LastModified from 'components/common/LastModified';

interface SelectedPersonInfoProps {
    personId: number;
    persons: Person[];
}

const SelectedPersonInfo: FC<SelectedPersonInfoProps> = ({ personId, persons }: SelectedPersonInfoProps) => {
    const { T } = useContext(LanguageContext);
    const [person, setPerson] = useState<Person>();
    const cancelRef = useRef<CancelTokenSource | null>(null);
    const [memberships, setMemberships] = useState<Membership[]>();
    const { handleError } = useErrorHandler();

    const sortMemberships = (a: Membership, b: Membership) => {
        if (!a || !b) return 0;
        if (a.beginDate < b.beginDate) return 1;
        if (a.beginDate > b.beginDate) return -1;
        return 0;
    };

    const getMembershipList = useCallback(
        async (id?: number): Promise<void> => {
            try {
                !cancelRef.current && (cancelRef.current = axios.CancelToken.source());
                const personMemberships = await PersonsService.getPersonMembershipsAsync(id, cancelRef.current.token);
                if (cancelRef.current) {
                    setMemberships(personMemberships.sort(sortMemberships));
                }
            } catch (error) {
                handleError(error, T('Error_PersonMembersipQueryFailed'));
            }
        },
        [handleError, T]
    );

    useEffect(() => {
        const listPerson = persons.find((p) => p.id === personId);
        if (listPerson) {
            setPerson(listPerson);
            getMembershipList(personId);
        }

        return () => {
            cancelRef.current && cancelRef.current.cancel();
            cancelRef.current = null;
        };
    }, [personId]);

    if (!person) return null;

    return (
        <Grid container spacing={1}>
            <Grid item xs={12} sx={{ mb: 1 }}>
                <Typography variant="h6" color="primary">
                    {T('CredentialApplication_ApplicationSelectedPersonInfoTitle')}
                </Typography>
            </Grid>
            <Grid container item xs={12}>
                <Grid item xs={6}>
                    <Grid>{`${person.lastName} ${person.firstName}`}</Grid>
                    <Grid>{person.address?.street}</Grid>
                    <Grid>{`${person.address?.zipCode} ${person.address?.city}`}</Grid>
                </Grid>
                <Grid item xs={6}>
                    <Grid>{person.phoneHome}</Grid>
                    <Grid>{person.ssn}</Grid>
                    <Grid>{person.email}</Grid>
                </Grid>
                <Grid item xs={12} sx={{ mt: 1 }}>
                    <LastModified by={person.lastModifiedBy} date={person.lastModifiedDate} />
                </Grid>
            </Grid>
            <Grid container item xs={12} sx={{ mt: 1 }}>
                <Typography variant="body1" color="primary">
                    {T('Membership_SubTitle_Organizations')}
                </Typography>
            </Grid>
            <Grid container item xs={12}>
                <Grid container item xs={12}>
                    <Grid item xs={6}>
                        <Typography variant="body2" color="secondary">
                            {T('Membership_Organization')}
                        </Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography variant="body2" color="secondary">
                            {T('Membership_FieldBeginDate')}
                        </Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography variant="body2" color="secondary">
                            {T('Membership_FieldEndDate')}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container item xs={12}>
                {memberships &&
                    memberships.length > 0 &&
                    memberships.map((member, index) => (
                        <>
                            <Grid key={index} container item xs={12}>
                                <Grid item xs={6}>
                                    {member.organization?.name}
                                </Grid>
                                <Grid item xs={3}>
                                    {format(new Date(member.beginDate), 'dd.MM.yyyy')}
                                </Grid>
                                <Grid item xs={3}>
                                    {member.endDate ? format(new Date(member.endDate), 'dd.MM.yyyy') : '-'}
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sx={{ mt: 1 }}>
                                <LastModified by={member.lastModifiedBy} date={member.lastModifiedDate} />
                            </Grid>
                        </>
                    ))}
                {memberships && memberships.length == 0 && (
                    <Typography variant="body2" color="inherit">
                        {T('Membership_NoMemberships')}
                    </Typography>
                )}
            </Grid>
        </Grid>
    );
};

export default SelectedPersonInfo;
