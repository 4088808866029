import React, { FC, useContext, useEffect, useRef, useState } from 'react';
import { LanguageContext } from 'contexts/languageContext';
import { Grid, Link, Typography } from '@mui/material';
import { CredentialApplicationAttachment } from 'types/sp-api';
import { useErrorHandler } from 'components/hooks/useErrorHandler';
import { useSuccessHandler } from 'components/hooks/useSuccessHandler';
import axios, { AxiosResponse, CancelTokenSource } from 'axios';
import CredentialApplicationService from 'services/credentialApplicationService';
import Utils from 'utils/typeHelper';
import DownloadFile from 'components/common/DownloadFile';
import ButtonDelete from 'components/common/ButtonDelete';

interface CredentialApplicationAttachmentLineProps {
    applicationId?: number;
    attachment: CredentialApplicationAttachment;
    updateContext: () => void;
}

const CredentialApplicationAttachmentLine: FC<CredentialApplicationAttachmentLineProps> = ({
    applicationId,
    attachment,
    updateContext
}: CredentialApplicationAttachmentLineProps) => {
    const { T } = useContext(LanguageContext);
    const [download, setDownload] = useState(false);
    const cancelRef = useRef<CancelTokenSource | null>(null);
    const { handleSuccess } = useSuccessHandler();
    const { handleError } = useErrorHandler();

    const fetchFile = async (appId: number): Promise<AxiosResponse> => {
        !cancelRef.current && (cancelRef.current = axios.CancelToken.source());
        return CredentialApplicationService.getAttachmentFileAsync(appId, attachment.id, cancelRef.current.token);
    };

    useEffect(() => {
        return () => {
            cancelRef.current && cancelRef.current.cancel();
            cancelRef.current = null;
        };
    }, [attachment]);

    const deleteAttachment = async (appId: number, attId?: number) => {
        try {
            !cancelRef.current && (cancelRef.current = axios.CancelToken.source());
            await CredentialApplicationService.deleteAttachmentAsync(appId, attId, cancelRef.current.token);
            handleSuccess(T('CredentialApplication_AttachmentDeleteSuccess'));
            updateContext();
        } catch (error) {
            handleError(error, T('CredentialApplication_AttachmentDeleteFailed'));
        }
    };

    if (!applicationId) return null;

    return (
        <Grid container item xs={12}>
            <Grid item xs={6}>
                <Link
                    onClick={() => {
                        setDownload(true);
                    }}
                    sx={{ cursor: 'pointer' }}
                    underline="hover"
                >
                    <Typography color="black" variant="body2">
                        {T(Utils.AttachmentTypeToName(attachment.type))}
                    </Typography>
                </Link>
                {download && (
                    <DownloadFile
                        fetchFile={() => fetchFile(applicationId)}
                        filename={attachment.document?.fileName ?? ''}
                        onSuccess={() => setDownload(false)}
                    />
                )}
            </Grid>
            <Grid item xs={5} alignSelf="center">
                <Typography color="secondary" variant="body2" fontSize={10}>
                    {attachment.document?.fileName}
                </Typography>
            </Grid>
            <Grid item xs={1} alignSelf="center">
                {attachment.type == 8 && (
                    <ButtonDelete
                        onClick={() => {
                            deleteAttachment(applicationId, attachment.id);
                            updateContext();
                        }}
                        small
                    />
                )}
            </Grid>
        </Grid>
    );
};

export default CredentialApplicationAttachmentLine;
