import React, { FC, ReactNode, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { LanguageContext } from 'contexts/languageContext';
import { Notification } from 'types/sp-api';
import axios, { CancelTokenSource } from 'axios';
import { useErrorHandler } from 'components/hooks/useErrorHandler';
import NotificationsService from 'services/notificationsService';
import { Box, Button, Card, CardActions, CardContent, Grid, Typography } from '@mui/material';
import moment from 'moment';
import { Link } from 'react-router-dom';
import WaitScreen from 'components/common/WaitScreen';
import { AuthContext } from 'contexts/authContext';

interface NotificationItemProps {
    notificationId?: number;
    closeNotification: () => void;
    updateList: () => Promise<void>;
}

const NotificationItem: FC<NotificationItemProps> = ({
    notificationId,
    closeNotification,
    updateList
}: NotificationItemProps) => {
    const { T } = useContext(LanguageContext);
    const { isAdmin } = useContext(AuthContext);
    const [notification, setNotification] = useState<Notification>();
    const { handleError } = useErrorHandler();
    const cancelRef = useRef<CancelTokenSource | null>(null);

    const getNotification = useCallback(
        async (id: number): Promise<void> => {
            try {
                cancelRef.current = axios.CancelToken.source();
                const notif = await NotificationsService.getNotificationAsync(id, cancelRef.current.token);
                if (cancelRef.current) {
                    setNotification(notif);

                    // store info that this notification is read
                    if (notif.unread) {
                        notif.unread = false;
                        await NotificationsService.saveNotificationAsync(notif);
                        await updateList();
                    }
                }
            } catch (error) {
                handleError(error, T('Error_NotificationQueryFailed'));
            }
        },
        [handleError, T, updateList]
    );

    useEffect(() => {
        if (notificationId) {
            getNotification(notificationId);
        }

        return () => {
            cancelRef.current && cancelRef.current.cancel();
            cancelRef.current = null;
        };
    }, [getNotification, notificationId]);

    const crateParagraph = (text: string): ReactNode => {
        if (text.includes('{{person=')) {
            const start = text.indexOf('{{person=') + 9;
            const end = text.indexOf('}}');
            const id = parseInt(text.substring(start, end));
            const name = text.substring(end + 2);
            return <Link to={`/persons/${id}`}>{name}</Link>;
        }
        return text;
    };

    if (!notificationId) return null;

    return (
        <Grid item xs={12}>
            {!notification && <WaitScreen />}
            {notification && (
                <Card className="notification-item">
                    <CardContent
                        sx={{
                            backgroundColor: 'white',
                            borderWidth: 0,
                            borderBottomWidth: 1,
                            borderBottomColor: 'primary.main',
                            borderBottomStyle: 'solid'
                        }}
                    >
                        <Typography color="textSecondary" gutterBottom>
                            {moment(notification?.createdDate).format('l')}
                            {isAdmin() && ` (${T('Notifications_Recipient')}: ${notification.organization?.name})`}
                        </Typography>
                        <Typography variant="h5" component="h3">
                            {notification?.title}
                        </Typography>
                    </CardContent>
                    <CardContent sx={{ minHeight: 150 }}>
                        <Box m={1}>
                            <Typography variant="body1" component="div">
                                {notification?.message?.split('<br/>').map((paragraph, index) => (
                                    <p key={index}>{crateParagraph(paragraph)}</p>
                                ))}
                            </Typography>
                        </Box>
                    </CardContent>
                    <CardContent
                        sx={{
                            borderWidth: 0,
                            borderBottomWidth: 1,
                            borderBottomColor: 'primary.main',
                            borderBottomStyle: 'solid'
                        }}
                    >
                        <Box m={1}>
                            {notification?.url && (
                                <Typography variant="body2" component="p">
                                    <Link to={notification?.url}>{T('Notification_MoveToTarget')}</Link>
                                </Typography>
                            )}
                        </Box>
                    </CardContent>
                    <CardActions>
                        <Box m={1}>
                            <Button onClick={closeNotification} size="small" color="secondary" variant="contained">
                                {T('Common_Close')}
                            </Button>
                        </Box>
                    </CardActions>
                </Card>
            )}
        </Grid>
    );
};

export default NotificationItem;
