import axios, { CancelToken } from 'axios';
import { PagedResult } from 'types/common';
import { SecureMessage } from 'types/sp-api';

class SecureMessagesService {
    public async getUnreadCountAsync(organizationId?: number, cancelToken?: CancelToken): Promise<number> {
        const params = new URLSearchParams();
        params.append('query', 'unread==true');

        const response = await axios.get<number>(`securemessages/count/${organizationId ?? 0}`, {
            params: params,
            cancelToken: cancelToken
        });
        return response.data;
    }

    public async getAllAsync(organizationId: number, cancelToken?: CancelToken): Promise<SecureMessage[]> {
        const response = await axios.get<PagedResult<SecureMessage[]>>(`securemessages/received/${organizationId}`, {
            params: { sort: 'createdDate,desc' },
            cancelToken: cancelToken
        });
        return response.data.content;
    }

    public async getAllSentAsync(organizationId: number, cancelToken?: CancelToken): Promise<SecureMessage[]> {
        const response = await axios.get<PagedResult<SecureMessage[]>>(`securemessages/sent/${organizationId}`, {
            params: { sort: 'createdDate,desc' },
            cancelToken: cancelToken
        });
        return response.data.content;
    }

    public async getPreviewUnreadAsync(organizationId?: number, cancelToken?: CancelToken): Promise<SecureMessage[]> {
        const params = new URLSearchParams();
        params.append('query', 'unread==true');
        params.append('size', '5');
        params.append('sort', 'createdDate,desc');

        const response = await axios.get<PagedResult<SecureMessage[]>>(
            `securemessages/received/${organizationId ?? 0}`,
            {
                params: params,
                cancelToken: cancelToken
            }
        );
        return response.data.content;
    }

    public async getMessageAsync(messageId: number, cancelToken?: CancelToken): Promise<SecureMessage> {
        const response = await axios.get<SecureMessage>(`securemessages/${messageId}`, {
            cancelToken: cancelToken
        });
        return response.data;
    }

    public async saveMessageAsync(message: SecureMessage): Promise<void> {
        if (message?.id && message.id > 0) {
            await axios.put<SecureMessage>(`securemessages/${message?.id}`, message);
        } else {
            await axios.post<SecureMessage>(`securemessages/`, message);
        }
    }

    public async deleteMessageAsync(messageId: number): Promise<void> {
        await axios.delete<void>(`securemessages/${messageId}`);
    }
}

export default new SecureMessagesService();
