import React, { FC, useContext } from 'react';
import { MenuItem } from '@mui/material';
import { LanguageContext } from 'contexts/languageContext';
import { createFlag } from './FlagMenu';
import moment from 'moment';

interface FlaggedMenuItemProps {
    type?: number;
    title?: string;
    setDownload: (
        value: React.SetStateAction<{
            type?: number;
            filename: string;
        }>
    ) => void;
}

const FlaggedMenuItem: FC<FlaggedMenuItemProps> = ({ type, title, setDownload }: FlaggedMenuItemProps) => {
    const { T } = useContext(LanguageContext);

    return (
        <MenuItem
            onClick={() => {
                const filename = T('CredentialApplication_ApplicationCsvFilename')
                    .replace('{0}', title ?? '')
                    .replace('{1}', moment().format('L LT'));
                setDownload({ type: type, filename: filename });
            }}
        >
            {createFlag(type)}
            <div style={{ marginLeft: 8 }}>
                {(type ?? 0) > 0
                    ? T('CredentialApplication_PrintNotesCsvFlag')
                    : T('CredentialApplication_PrintNotesCsvNoFlag')}
            </div>
        </MenuItem>
    );
};

export default FlaggedMenuItem;
