import React from 'react';
import ReactDOM from 'react-dom';
import 'assets/index.scss';
import App from 'components/app/App';
import * as serviceWorker from './serviceWorker';
import config from 'config/config';
import Axios from 'axios';
import { BrowserRouter } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';

// Set axios base
Axios.defaults.baseURL = config.API_BASE_URL;

const rootNode = document.getElementById('root');

ReactDOM.render(
    <React.StrictMode>
        <BrowserRouter>
            <SnackbarProvider maxSnack={3} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
                <App />
            </SnackbarProvider>
        </BrowserRouter>
    </React.StrictMode>,
    rootNode
);

serviceWorker.unregister();
