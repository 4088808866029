import React, { FC } from 'react';
import { InputAdornment, TextField } from '@mui/material';
import { useField } from 'formik';
import { SvgIconComponent } from '@mui/icons-material';

interface FormFieldNumberProps {
    label: string;
    name: string;
    autoFocus?: boolean;
    endIcon?: unknown;
    endIconColor?:
        | 'error'
        | 'primary'
        | 'secondary'
        | 'info'
        | 'success'
        | 'warning'
        | 'inherit'
        | 'disabled'
        | 'action';
    endIconTooltip?: string;
}

const FormFieldNumber: FC<FormFieldNumberProps> = ({
    label,
    name,
    autoFocus,
    endIcon,
    endIconColor,
    endIconTooltip
}: FormFieldNumberProps) => {
    const [field, meta] = useField(name);

    const EndIcon = endIcon as SvgIconComponent;

    return (
        <TextField
            id={name}
            label={label}
            error={!!meta.error}
            helperText={!!meta.error && meta.error}
            fullWidth
            variant="outlined"
            size="small"
            value={field.value ?? ''}
            onBlur={field.onBlur}
            onChange={field.onChange}
            name={field.name}
            autoFocus={autoFocus}
            InputProps={
                endIcon
                    ? {
                          endAdornment: (
                              <InputAdornment position="end">
                                  <EndIcon color={endIconColor ?? 'inherit'} titleAccess={endIconTooltip} />
                              </InputAdornment>
                          )
                      }
                    : undefined
            }
        />
    );
};

export default FormFieldNumber;
