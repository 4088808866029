import React, { FC, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { KeyValue } from 'types/common';
import axios, { CancelTokenSource } from 'axios';
import { LanguageContext } from 'contexts/languageContext';
import { useErrorHandler } from '../hooks/useErrorHandler';
import OrganizationsService from 'services/organizationsService';
import PersonUtils from 'utils/personHelper';
import { Autocomplete, TextField } from '@mui/material';
import { useField } from 'formik';
import CircularProgress from '@mui/material/CircularProgress';
import debounce from 'lodash/debounce';

interface FormFieldDropDownMemberProps {
    organizationId: number;
    label: string;
    name: string;
    placeholder?: string;
    autoFocus?: boolean;
    usePersonId?: boolean;
}

const FormFieldDropDownMember: FC<FormFieldDropDownMemberProps> = ({
    organizationId,
    label,
    name,
    placeholder,
    autoFocus,
    usePersonId
}: FormFieldDropDownMemberProps) => {
    const [options, setOptions] = useState<KeyValue[]>([]);
    const { T } = useContext(LanguageContext);
    const { handleError } = useErrorHandler();
    const [inputValue, setInputValue] = useState('');
    const [, , helpers] = useField(name);
    const cancelRef = useRef<CancelTokenSource | null>(null);
    const [value, setValue] = useState<KeyValue | null>(null);
    const [loading, setLoading] = useState(false);

    const getMembers = useCallback(
        debounce(async (filter) => getMembersCallback(filter), 500, {
            leading: true,
            trailing: true
        }),
        [handleError, T, organizationId, usePersonId]
    );

    const getMembersCallback = async (filter: string) => {
        try {
            cancelRef.current = axios.CancelToken.source();
            setLoading(true);
            const members = await OrganizationsService.getMembersAsync(
                organizationId,
                filter,
                false,
                cancelRef.current.token
            );
            if (cancelRef.current) {
                let newOptions = [] as KeyValue[];

                if (value) {
                    newOptions = [value];
                }

                members.forEach(async (element) => {
                    newOptions.push({
                        key: usePersonId ? element?.personId : element?.id,
                        text: PersonUtils.FullName(element?.person)
                    });
                });

                setOptions(newOptions);
                setLoading(false);
            }
        } catch (error) {
            setLoading(false);
            handleError(error, T('Error_OrganizationMemberQueryFailed'));
        }
    };

    useEffect(() => {
        if (inputValue === '') {
            setOptions(value ? [value] : []);
            return undefined;
        }

        if (inputValue.length > 2) {
            getMembers(inputValue);
        }

        return () => {
            cancelRef.current && cancelRef.current.cancel();
            cancelRef.current = null;
        };
    }, [value, inputValue, getMembers]);

    return (
        <Autocomplete
            filterOptions={(x) => x}
            options={options}
            autoComplete
            includeInputInList
            filterSelectedOptions
            value={value}
            onChange={(_, newValue: KeyValue | null) => {
                setValue(newValue);
                helpers.setValue(newValue?.key);
            }}
            onInputChange={(_, newInputValue) => {
                setInputValue(newInputValue);
            }}
            open={(options.length > 0 || loading) && !value}
            loading={loading}
            loadingText={T('Common_Loading')}
            noOptionsText={T('Person_SearchResultEmpty')}
            getOptionDisabled={(option) => option.key === 0}
            getOptionLabel={(option: KeyValue) => option.text ?? ''}
            isOptionEqualToValue={(option: KeyValue, curValue: KeyValue) => option.key === curValue.key}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={label}
                    variant="outlined"
                    autoFocus={autoFocus}
                    size="small"
                    fullWidth
                    placeholder={placeholder}
                    helperText={T('Common_MemberSelectHelpText')}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <>
                                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </>
                        )
                    }}
                />
            )}
        />
    );
};

export default FormFieldDropDownMember;
