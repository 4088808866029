import React, { FC, useCallback, useContext, useEffect, useRef, useState } from 'react';
import {
    Container,
    Grid,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from '@mui/material';
import { AccountBox } from '@mui/icons-material';
import BreadcrumbBar from './common/BreadcrumbBar';
import ViewTitle from './common/ViewTitle';
import { LanguageContext } from 'contexts/languageContext';
import { User } from 'types/sp-api';
import axios, { CancelTokenSource } from 'axios';
import { useErrorHandler } from './hooks/useErrorHandler';
import WaitScreen from './common/WaitScreen';
import Utils from 'utils/typeHelper';
import UsersService from 'services/usersService';
import { useNavigate } from 'react-router-dom';
import PersonUtils from 'utils/personHelper';
import { TableHeadCell } from './common/TableHeadCell';
import { AuthContext } from 'contexts/authContext';

const AccessRightsView: FC = () => {
    const { T } = useContext(LanguageContext);
    const { isAdmin } = useContext(AuthContext);
    const [usersList, setUsersList] = useState<User[]>();
    const { handleError } = useErrorHandler();
    const navigate = useNavigate();
    const cancelRef = useRef<CancelTokenSource | null>(null);

    const getUsers = useCallback(async (): Promise<void> => {
        try {
            cancelRef.current = axios.CancelToken.source();
            const users = await UsersService.getAllUsersAsync(cancelRef.current.token);
            if (cancelRef.current) {
                setUsersList(users);
            }
        } catch (error) {
            handleError(error, T('Error_UsersQueryFailed'));
        }
    }, [handleError, T]);

    useEffect(() => {
        getUsers();

        return () => {
            cancelRef.current && cancelRef.current.cancel();
            cancelRef.current = null;
        };
    }, [getUsers]);

    return (
        <Container>
            <Grid container>
                <BreadcrumbBar />
                <ViewTitle title={T('Common_AccessRights')} icon={AccountBox} />
            </Grid>
            <Grid container spacing={5}>
                <Grid item xs={isAdmin() ? 12 : 8}>
                    {!usersList && <WaitScreen />}
                    {usersList && (
                        <TableContainer component={Paper}>
                            <Table size="small">
                                <TableHead>
                                    <TableRow>
                                        {isAdmin() && (
                                            <TableHeadCell sx={{ width: '35%' }}>
                                                {T('UserOrganization_Organization')}
                                            </TableHeadCell>
                                        )}
                                        <TableHeadCell sx={{ width: '25%' }}>{T('AccessRights_Role')}</TableHeadCell>
                                        <TableHeadCell sx={{ minWidth: 200, width: '20%' }}>
                                            {T('AccessRights_Person')}
                                        </TableHeadCell>
                                        <TableHeadCell sx={{ width: '20%' }}>
                                            {T('AccessRights_Username')}
                                        </TableHeadCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {usersList.map((row) => (
                                        <TableRow
                                            key={row.id}
                                            onClick={() => {
                                                navigate(`/persons/${row.personId}`);
                                            }}
                                            sx={{ cursor: 'pointer' }}
                                            hover
                                        >
                                            {isAdmin() && (
                                                <TableCell>{row.organizations && row.organizations[0].name}</TableCell>
                                            )}
                                            <TableCell>{T(Utils.RoleIdToName(row.roleId))}</TableCell>
                                            <TableCell>{PersonUtils.FullName(row.person)}</TableCell>
                                            <TableCell>
                                                {row.name.startsWith('random_username_') || row.name.startsWith('NS_')
                                                    ? T('AccessRights_UserNotActivated')
                                                    : T(row.name)}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    )}
                </Grid>
            </Grid>
        </Container>
    );
};

export default AccessRightsView;
