import React, { useState, useCallback, useEffect, useContext, useRef, FC } from 'react';
import axios, { CancelTokenSource } from 'axios';
import WaitScreen from 'components/common/WaitScreen';
import { useParams } from 'react-router-dom';
import ContactService from 'services/contactService';
import ChurchList from './ChurchList';
import { OrganizationContact } from 'types/sp-api';
import { Container, FormControl, FormControlLabel, Grid, Switch } from '@mui/material';
import { LanguageContext } from 'contexts/languageContext';
import BreadcrumbBar from 'components/common/BreadcrumbBar';
import ChurchIcon from 'components/icons/ChurchIcon';
import ViewTitle from 'components/common/ViewTitle';
import { useErrorHandler } from 'components/hooks/useErrorHandler';
import ViewSearchBar from 'components/common/ViewSearchBar';
import { TooltipChurchSearch } from 'components/common/TooltipChurchSearch';
import config from 'config/config';
import constants from 'config/constants';
import settingsService from 'services/settingsService';

const ChurchView: FC = () => {
    const { T } = useContext(LanguageContext);
    const [organizationList, setOrganizationList] = useState<OrganizationContact[]>();
    const { handleError } = useErrorHandler();
    const cancelRef = useRef<CancelTokenSource | null>(null);
    const { filter } = useParams<'filter'>();
    const [includeContactInfos, setIncludeContactInfos] = useState(
        settingsService.getState(constants.StateKeys.ShowContactOrganizations) === 'true'
    );

    const getOrganizations = useCallback(
        async (filterStr?: string, includeContacts?: boolean): Promise<void> => {
            try {
                cancelRef.current = axios.CancelToken.source();
                const orgList = await ContactService.getOrganizationsAsync(
                    filterStr,
                    cancelRef.current.token,
                    true,
                    includeContacts,
                    false
                );
                if (cancelRef.current) {
                    setOrganizationList(orgList);
                }
            } catch (error) {
                handleError(error, T('Error_ChurchQueryFailed'));
            }
        },
        [handleError, T]
    );

    useEffect(() => {
        getOrganizations(filter, includeContactInfos);

        return () => {
            cancelRef.current && cancelRef.current.cancel();
            cancelRef.current = null;
        };
    }, [getOrganizations, filter, includeContactInfos]);

    return (
        <Container>
            <Grid container>
                <BreadcrumbBar />
                <ViewTitle title={T('Common_ChurchesTitle')} icon={ChurchIcon} />
                <Grid item sx={{ mr: 3 }}>
                    <ViewSearchBar
                        filter={filter}
                        baseUrl="/churches/search"
                        clearUrl="/churches"
                        searchTitle={T('Placeholder_ChurchSearch')}
                        auditMsg="church search"
                        iconTooltip={<TooltipChurchSearch />}
                        minWidth={380}
                    />
                </Grid>
                {config.CUSTOMER === constants.Customers.Shk && (
                    <Grid item>
                        <FormControl>
                            <FormControlLabel
                                id="searchIncludeContactInfos"
                                control={
                                    <Switch
                                        checked={includeContactInfos}
                                        onChange={() => {
                                            setIncludeContactInfos(!includeContactInfos);
                                            settingsService.saveState(
                                                constants.StateKeys.ShowContactOrganizations,
                                                (!includeContactInfos).toString()
                                            );
                                        }}
                                        name="searchIncludeContactInfos"
                                        color="primary"
                                    />
                                }
                                label={T('Church_SearchIncludeContactInfos')}
                            />
                        </FormControl>
                    </Grid>
                )}
            </Grid>
            {!organizationList && <WaitScreen />}
            {organizationList && <ChurchList organizationList={organizationList} />}
        </Container>
    );
};

export default ChurchView;
