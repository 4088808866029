import axios, { CancelToken } from 'axios';
import { Settings } from 'types/sp-api';

class SettingsService {
    public async getSettingsAsync(organizationId: number, cancelToken?: CancelToken): Promise<Settings[]> {
        const response = await axios.get(`organizations/${organizationId}/settings`, { cancelToken: cancelToken });
        return response.data;
    }

    public async getSettingAsync(organizationId: number, key: string, cancelToken?: CancelToken): Promise<Settings> {
        const response = await axios.get(`organizations/${organizationId}/settings/${key}`, {
            cancelToken: cancelToken
        });

        return response.data.length > 0 ? response.data[0] : undefined;
    }

    public async saveSettingsAsync(organizationId: number, setting: Settings): Promise<void> {
        if (setting?.id && setting.id > 0) {
            await axios.put(`organizations/${organizationId}/settings/${setting.id}`, setting);
        } else {
            await axios.post(`organizations/${organizationId}/settings`, setting);
        }
    }

    public async deleteSettingsAsync(organizationId: number, settingId: number): Promise<void> {
        await axios.delete(`organizations/${organizationId}/settings/${settingId}`);
    }

    public saveState(key: string, value: string) {
        localStorage.setItem(key, value);
    }

    public getState(key: string): string | null {
        return localStorage.getItem(key);
    }

    public deleteState = (key: string) => {
        localStorage.removeItem(key);
    };
}

export default new SettingsService();
