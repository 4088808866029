import React, { FC, useContext } from 'react';
import { Grid, List, ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material';
import { LanguageContext } from 'contexts/languageContext';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

const UsernameTooltip: FC = () => {
    const { T } = useContext(LanguageContext);

    return (
        <Grid container>
            <Grid item style={{ padding: 4 }}>
                <Typography variant="subtitle1" color="inherit">
                    {T('Registration_UsernameTooltipTitle')}
                </Typography>
            </Grid>
            <Grid item>
                <List dense>
                    <ListItem>
                        <ListItemIcon>
                            <ChevronRightIcon />
                        </ListItemIcon>
                        <ListItemText primary={T('Registration_UsernameDotDash')} />
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <ChevronRightIcon />
                        </ListItemIcon>
                        <ListItemText primary={T('Registration_UsernameTipLength')} />
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <ChevronRightIcon />
                        </ListItemIcon>
                        <ListItemText primary={T('Registration_UsernameTipNotEmail')} />
                    </ListItem>
                </List>
            </Grid>
        </Grid>
    );
};

export default UsernameTooltip;
