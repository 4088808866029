import { AppLanguage } from 'types/common';
import { UserOtpInfo } from 'types/sp-api';
import PersonsService from './personsService';
import UsersService from './usersService';

class ProfileService {
    public async saveUserLanguageAsync(personId?: number, lang?: AppLanguage): Promise<void> {
        if (!personId) return;

        const person = await PersonsService.getPersonAsync(personId);
        person.language = lang;
        await PersonsService.savePersonAsync(person);
    }

    public async getUserOtpInfoAsync(): Promise<UserOtpInfo> {
        return await UsersService.getUserOtpInfoAsync();
    }
}

export default new ProfileService();
