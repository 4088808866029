import axios, { CancelToken } from 'axios';
import { DbCodeSets, KeyValue } from 'types/common';
import LocalCache from './localCache';

class CodeSetService {
    public async getCodeSetAsync(codeSet: DbCodeSets, cancelToken?: CancelToken): Promise<KeyValue[]> {
        const url = `codes/${codeSet}/`;

        return LocalCache.cachedRequest<KeyValue[]>(url, async () => {
            const axiosInstance = axios.create(); // create separate instance to avoid interceptors
            const response = await axiosInstance.get(url, {
                cancelToken: cancelToken
            });

            return response.data;
        });
    }

    public async getCoutriesAsync(cancelToken?: CancelToken): Promise<KeyValue[]> {
        const url = 'public/countries';

        const duration = 60 * 24 * 30; // minutes, fetch data only once in the month

        return LocalCache.cachedRequest<KeyValue[]>(
            url,
            async () => {
                const axiosInstance = axios.create(); // create separate instance to avoid interceptors
                const response = await axiosInstance.get<KeyValue[]>(url, {
                    cancelToken: cancelToken
                });

                return response.data.sort((a, b) => {
                    if ((a.value ?? '') > (b.value ?? '')) return 1;
                    if ((a.value ?? '') < (b.value ?? '')) return -1;
                    return 0;
                });
            },
            duration
        );
    }
}

export default new CodeSetService();
