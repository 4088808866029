import React, { FC } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import config from 'config/config';
import constants from 'config/constants';

const TermsAndConditionsTextShk: FC = () => {
    return (
        <Grid container spacing={3}>
            <Grid item md={12}>
                <Typography variant="h5">1. Soveltaminen</Typography>
            </Grid>
            <Grid item md={12}>
                <Typography variant="body1">
                    Näitä käyttöehtoja sovelletaan Suomen Helluntaikirkon (“Helluntaikirkko”) jäsenseurakunnilleen
                    (“Seurakunta”) tarjoamaan Sähköpaimen-jäsenrekisteripalveluun (“Jäsenrekisteri”) ja sen käyttöön.
                    <br />
                    <br />
                    Jäsenrekisterin käyttö edellyttää Helluntaikirkon ja Seurakunnan välistä sopimusta sekä
                    käyttöehtojen hyväksyntää. Seurakunta nimeää henkilötietojen käsittelijät (“Käyttäjä”), joiden
                    vastuulla on Jäsenrekisterin käyttö.
                </Typography>
            </Grid>
            <Grid item md={12}>
                <Box m={1} />
                <Typography variant="h5">2. Termit</Typography>
            </Grid>
            <Grid item md={12}>
                <Typography variant="body1">
                    <b>Henkilötietoja</b> ovat kaikki tiedot, jotka liittyvät tunnistettuun tai tunnistettavissa olevaan
                    henkilöön. Henkilötietoja ovat esimerkiksi nimi, puhelinnumero ja jäsenyystiedot.
                    <br />
                    <br />
                    <b>Rekisterinpitäjä</b> on seurakunta, joka käsittelee henkilötietoja Sähköpaimenella.
                    <br />
                    <br />
                    <b>Henkilötietojen käsittelijä</b> on Seurakunnan henkilötietoja käsittelevä henkilö, joka
                    käsittelee ja ylläpitää jäsenrekisterin henkilötietoja rekisterinpitäjän puolesta.
                    <blockquote>
                        <b>Pääkäyttäjä</b> on seurakunnan henkilötietojen käsittelijä, jolla on oikeus luoda myös uusia
                        käyttäjätunnuksia seurakunnan käyttöön. Pääkäyttäjä on myös yhteyshenkilö Helluntaikirkkoon
                        jäsenrekisteriasioissa.
                        <br />
                        <br />
                        <b>Käyttäjä</b> on Seurakunnan jäsenten henkilötietojen käsittelijä.
                    </blockquote>
                    <b>Lokitieto</b> on jäsenrekisterin käyttötapahtumasta tallennettava tieto, josta selviää kuka teki
                    ja mitä, milloin ja mihin tietoon toimenpide kohdistui, esim. henkilön tietojen selaaminen ja
                    avaaminen tarkasteluun.
                </Typography>
            </Grid>
            <Grid item md={12}>
                <Box m={1} />
                <Typography variant="h5">3. Palvelun lokitiedot</Typography>
            </Grid>
            <Grid item md={12}>
                <Typography variant="body1">
                    Kaikista Jäsenrekisterin Käyttäjän tekemistä toimenpiteistä tallennetaan lokitiedot järjestelmään.
                    Tämä on lakisääteinen velvoite, jotta henkilöiden tietosuoja voidaan taata myös erikoistapauksissa.
                    <br />
                    <br />
                    Lokitietoja seurataan automaattisella järjestelmällä tietoturvaloukkauksien tai murtautumisyritysten
                    havaitsemiseksi. Tarvittaessa Helluntaikirkko voi tarkastella lokitietoja, esim. ongelman
                    selvittelyn tai viranomaispyynnön takia.
                </Typography>
            </Grid>
            <Grid item md={12}>
                <Box m={1} />
                <Typography variant="h5">4. Henkilötietojen käyttö ja käsittely</Typography>
            </Grid>
            <Grid item md={12}>
                <Typography variant="body1">
                    Jäsenrekisterin tarkoituksena on palvella Seurakunnan toimintaa samalla suojellen henkilöiden
                    yksityisyyttä. Uskonnollisen vakaumuksen osoittava tieto on EU-tietosuoja-asetuksen mukaan erityisen
                    salassapidon alaista henkilötietoa. Sitä tulee käsitellä huolellisesti eikä luovuttaa eteenpäin
                    ilman henkilön suostumusta.
                    <br />
                    <br />
                    Tietosuojaperiaatteiden mukaan henkilötietoja on
                    <ul>
                        <li>käsiteltävä lainmukaisesti, asianmukaisesti ja rekisteröidyn kannalta läpinäkyvästi</li>
                        <li>kerättävä vain tarpeellinen määrä</li>
                        <li>epätarkat ja virheelliset henkilötiedot on poistettava tai korjattava viipymättä</li>
                        <li>käsiteltävä luottamuksellisesti ja turvallisesti</li>
                    </ul>
                    Jäsenrekisteriin tallennettuja henkilötietoja kuten yhteystietoja, voi etsiä ja käyttää vain
                    Seurakunnan toiminnan tarpeisiin. Henkilötietoja voi etsiä ja käsitellä vain rekisterinpitäjän
                    ohjeiden mukaisiin tehtäviin.
                    <br />
                    <br />
                    Jäsenellä on oikeus nähdä ja korjata hänestä tallennetut tiedot.
                </Typography>
            </Grid>
            <Grid item md={12}>
                <Box m={1} />
                <Typography variant="h5">5. Tietojen luovutus</Typography>
            </Grid>
            <Grid item md={12}>
                <Typography variant="body1">
                    Kaikkiin yksityistarpeisiin liittyvät kyselyt ohjataan esim. numerotiedusteluun. Henkilötietoja ei
                    tule koskaan luovuttaa yksityistarpeisiin.
                    <br />
                    <br />
                    Tietoja ei tule luovuttaa Seurakunnan ulkopuoliselle taholle missään tilanteessa.
                </Typography>
            </Grid>
            <Grid item md={12}>
                <Box m={1} />
                <Typography variant="h5">6. Käyttäjän vastuut ja velvollisuudet</Typography>
            </Grid>
            <Grid item md={12}>
                <Grid item md={12}>
                    <Typography variant="h6">6.1 Käyttäjätunnus ja käyttöympäristö</Typography>
                </Grid>
                <Grid item md={12}>
                    <Box m={1} />
                    <Typography variant="body1">
                        Kaikilla Jäsenrekisterin Käyttäjillä on omalta osaltaan vastuu huolehtia Jäsenrekisterin
                        tietoturvasta ja henkilötietojen tietosuojasta. Jäsenrekisteriä on käytettävä vain Seurakunnan
                        toimintaan.
                        <br />
                        <br />
                        <b>Käyttäjätunnus on henkilökohtainen:</b>
                        <ul>
                            <li>käyttäjätunnusta ei saa luovuttaa kenellekään missään tilanteessa</li>
                        </ul>
                        Käyttäjän tulee huolehtia oman <b>käyttöympäristönsä tietoturvasta</b>:
                        <ul>
                            <li>tietokoneen tietoturvapäivitykset ja virustorjunta tulee olla ajan tasalla</li>
                            <li>
                                jäsenrekisteriä tulee käyttää vain suojatussa verkossa, ei esim. hotellien julkisista
                                verkoista
                            </li>
                            <li>jäsenrekisterin käytön päättyessä tulee siitä kirjautua ulos</li>
                            <li>varmistaen etteivät ulkopuoliset näe henkilötietoja</li>
                        </ul>
                        Mikäli epäillään tai tiedetään, että käyttäjätunnukset ovat joutuneet toisen henkilön haltuun,
                        Käyttäjän tulee välittömästi vaihtaa tunnuksensa salasana ja tehdä asiasta ilmoitus
                        Helluntaikirkolle.
                    </Typography>
                    <Box m={2} />
                </Grid>
                <Grid item md={12}>
                    <Typography variant="h6">6.2 Oikeudettoman tiedon käyttö</Typography>
                </Grid>
                <Grid item md={12}>
                    <Box m={1} />
                    <Typography variant="body1">
                        Tietojen oikeudeton hankkiminen tai sen yrittäminen on kielletty.
                        <br />
                        <br />
                        Mikäli Käyttäjä saa vahingossa haltuunsa muille osoitettua tai kuuluvaa tietoa, on niiden käyttö
                        kielletty. Tällaisesta tapahtumasta tai muista tietoturvan puutteista tai niiden epäilyistä on
                        myös tehtävä viipymättä ilmoitus Helluntaikirkolle.
                    </Typography>
                    <Box m={2} />
                </Grid>
                <Grid item md={12}>
                    <Typography variant="h6">6.3 Vaitiolovelvollisuus</Typography>
                </Grid>
                <Grid item md={12}>
                    <Box m={1} />
                    <Typography variant="body1">
                        Käyttäjällä on vaitiolovelvollisuus järjestelmän tietosisällöstä, suojausmenetelmistä ja
                        ominaisuuksista.
                        <br />
                        <br />
                        Vaitiolovelvollisuus on voimassa käyttöoikeuden päättymisen jälkeenkin.
                    </Typography>
                    <Box m={2} />
                </Grid>
                <Grid item md={12}>
                    <Typography variant="h6">6.4 Väärinkäytösten seuraamukset</Typography>
                </Grid>
                <Grid item md={12}>
                    <Box m={1} />
                    <Typography variant="body1">
                        Mikäli Käyttäjä ei noudata näitä käyttöehtoja tai toiminnallaan vaarantaa Jäsenrekisterin
                        tietoturvan, hänen käyttöoikeutensa voidaan poistaa.
                        <br />
                        <br />
                        Väärinkäytöksistä, kuten käyttäjätunnuksen ja salasanan luovuttamisesta toiselle, on myös
                        rikosoikeudellinen vastuu lakien ja asetusten mukaisesti.
                    </Typography>
                    <Box m={2} />
                </Grid>
            </Grid>
            <Grid item md={12}>
                <Typography variant="h5">7. Käyttöoikeuden voimassaolo</Typography>
            </Grid>
            <Grid item md={12}>
                <Typography variant="body1">
                    Käyttöoikeus Jäsenrekisteriin päättyy, jos:
                    <ul>
                        <li>Seurakunnan ja Helluntaikirkon välinen sopimus päättyy</li>
                        <li>Käyttäjän tehtävä Seurakunnan henkilötietojen käsittelijänä päättyy</li>
                        <li>
                            Käyttäjän toiminnasta johtuen Jäsenrekisterin tietoturva on vaarantunut ja käyttöoikeus
                            joudutaan poistamaan
                        </li>
                    </ul>
                    Käyttöoikeuden päättyessä vaitiolovelvollisuus säilyy voimassa.
                </Typography>
            </Grid>
            <Grid item md={12}>
                <Box m={1} />
                <Typography variant="h5">8. Liittyvät lait ja lisätietoa</Typography>
            </Grid>
            <Grid item md={12} style={{ paddingTop: 0 }}>
                <Typography variant="body1">
                    <ul style={{ marginTop: 0 }}>
                        <li>Laki uskontokuntien jäsenrekistereistä (614/1998)</li>
                        <li>Tietosuojalaki (1050/2018)</li>
                        <li>EU:n yleinen tietosuoja-asetus (2016/679)</li>
                        <li>Uskonnonvapauslaki (6.6.2003/453)</li>
                        <li>Rikoslaki (39/1889, luku 35:1,2 §; luku 38:2 §, luku 38:3−4 §; luku 38:8 §)</li>
                        <li>Suomen perustuslaki (731/1999, 10-12§)</li>
                    </ul>
                    Lisätietoa henkilötietojen käsittelystä löytyy Tietosuojavaltuutetun toimistosta:
                    <a href="https://tietosuoja.fi" target="_blank" rel="noreferrer">
                        https://tietosuoja.fi
                    </a>
                    .
                    <br />
                    <br />
                    Lisätietoa Jäsenrekisterin käytöstä saa Helluntaikirkon toimistosta.
                </Typography>
            </Grid>
            <Grid item md={12}>
                <Box m={1} />
                <Typography variant="h5">9. LUE VÄHINTÄÄN TÄMÄ - Käytännön vinkit</Typography>
            </Grid>
            <Grid item md={12} style={{ paddingTop: 0 }}>
                <Typography variant="body1">
                    <ul style={{ marginTop: 0 }}>
                        <li>Tietoja saa katsella ja käsitellä vain tehtäviensä suorittamista varten</li>
                        <li>Käyttäjätunnusta ei saa luovuttaa kenellekään missään tilanteessa</li>
                        <li>
                            Jos seurakunnan työntekijä tai kuka tahansa pyytää henkilökohtaisia tunnuksiasi älä anna
                            niitä, vaan ohjaa pyytämään Pääkäyttäjältä omat tunnukset
                        </li>
                        <li>Aina kun poistut tietokoneelta, kirjaudu ulos Jäsenrekisteristä</li>
                        <li>
                            Älä säilytä käyttäjätunnustasi ja salasanaasi näkyvillä, samassa paikassa tai muuten
                            helposti saatavilla. Mieluiten vain päässäsi!
                        </li>
                        <li>
                            Henkilötietoja ei saa kertoa, seurakuntaan kuuluminen on erityisen salassapidon alaista
                            tietoa
                        </li>
                    </ul>
                </Typography>
            </Grid>
        </Grid>
    );
};

const TermsAndConditionsTextAdvk: FC = () => {
    return (
        <Grid container spacing={3}>
            <Grid item md={12}>
                <Typography variant="h5">1. Soveltaminen</Typography>
            </Grid>
            <Grid item md={12}>
                <Typography variant="body1">
                    Näitä käyttöehtoja sovelletaan Suomen Adventtikirkon (“Adventtikirkko”) jäsenseurakunnilleen
                    (“Seurakunta”) tarjoamaan Sähköpaimen-jäsenrekisteripalveluun (“Jäsenrekisteri”) ja sen käyttöön.
                    <br />
                    <br />
                    Jäsenrekisterin käyttö edellyttää Adventtikirkon ja Seurakunnan välistä sopimusta sekä käyttöehtojen
                    hyväksyntää. Seurakunta nimeää henkilötietojen käsittelijät (“Käyttäjä”), joiden vastuulla on
                    Jäsenrekisterin käyttö.
                </Typography>
            </Grid>
            <Grid item md={12}>
                <Box m={1} />
                <Typography variant="h5">2. Termit</Typography>
            </Grid>
            <Grid item md={12}>
                <Typography variant="body1">
                    <b>Henkilötietoja</b> ovat kaikki tiedot, jotka liittyvät tunnistettuun tai tunnistettavissa olevaan
                    henkilöön. Henkilötietoja ovat esimerkiksi nimi, puhelinnumero ja jäsenyystiedot.
                    <br />
                    <br />
                    <b>Rekisterinpitäjä</b> on Suomen Adventtikirkko, paikallisseurakunnalla on oikeus käyttää oman
                    paikallisseurakuntansa tietoja koskevaa jäsenrekisteriotetta, jota kutsumme Jäsenluetteloksi
                    <br />
                    <br />
                    <b>Henkilötietojen käsittelijä</b> on Seurakunnan henkilötietoja käsittelevä henkilö, joka
                    käsittelee ja ylläpitää jäsenrekisterin henkilötietoja rekisterinpitäjän puolesta.
                    <blockquote>
                        <b>Pääkäyttäjä</b> on seurakunnan henkilötietojen käsittelijä, jolla on oikeus luoda myös uusia
                        käyttäjätunnuksia seurakunnan käyttöön. Pääkäyttäjä on myös yhteyshenkilö Adventtikirkkoon
                        jäsenrekisteriasioissa.
                        <br />
                        <br />
                        <b>Käyttäjä</b> on Seurakunnan jäsenten henkilötietojen käsittelijä.
                    </blockquote>
                    <b>Lokitieto</b> on jäsenrekisterin käyttötapahtumasta tallennettava tieto, josta selviää kuka teki
                    ja mitä, milloin ja mihin tietoon toimenpide kohdistui, esim. henkilön tietojen selaaminen ja
                    avaaminen tarkasteluun.
                </Typography>
            </Grid>
            <Grid item md={12}>
                <Box m={1} />
                <Typography variant="h5">3. Palvelun lokitiedot</Typography>
            </Grid>
            <Grid item md={12}>
                <Typography variant="body1">
                    Kaikista Jäsenrekisterin Käyttäjän tekemistä toimenpiteistä tallennetaan lokitiedot järjestelmään.
                    Tämä on lakisääteinen velvoite, jotta henkilöiden tietosuoja voidaan taata myös erikoistapauksissa.
                    <br />
                    <br />
                    Lokitietoja seurataan automaattisella järjestelmällä tietoturvaloukkauksien tai murtautumisyritysten
                    havaitsemiseksi. Tarvittaessa Adventtikirkko voi tarkastella lokitietoja, esim. ongelman selvittelyn
                    tai viranomaispyynnön takia.
                </Typography>
            </Grid>
            <Grid item md={12}>
                <Box m={1} />
                <Typography variant="h5">4. Henkilötietojen käyttö ja käsittely</Typography>
            </Grid>
            <Grid item md={12}>
                <Typography variant="body1">
                    Jäsenrekisterin tarkoituksena on palvella Seurakunnan toimintaa samalla suojellen henkilöiden
                    yksityisyyttä. Uskonnollisen vakaumuksen osoittava tieto on EU-tietosuoja-asetuksen mukaan erityisen
                    salassapidon alaista henkilötietoa. Sitä tulee käsitellä huolellisesti eikä luovuttaa eteenpäin
                    ilman henkilön suostumusta.
                    <br />
                    <br />
                    Tietosuojaperiaatteiden mukaan henkilötietoja on
                    <ul>
                        <li>käsiteltävä lainmukaisesti, asianmukaisesti ja rekisteröidyn kannalta läpinäkyvästi</li>
                        <li>kerättävä vain tarpeellinen määrä</li>
                        <li>epätarkat ja virheelliset henkilötiedot on poistettava tai korjattava viipymättä</li>
                        <li>käsiteltävä luottamuksellisesti ja turvallisesti</li>
                    </ul>
                    Jäsenrekisteriin tallennettuja henkilötietoja kuten yhteystietoja, voi etsiä ja käyttää vain
                    Seurakunnan toiminnan tarpeisiin. Henkilötietoja voi etsiä ja käsitellä vain rekisterinpitäjän
                    ohjeiden mukaisiin tehtäviin.
                    <br />
                    <br />
                    Jäsenellä on oikeus nähdä ja korjata hänestä tallennetut tiedot.
                </Typography>
            </Grid>
            <Grid item md={12}>
                <Box m={1} />
                <Typography variant="h5">5. Tietojen luovutus</Typography>
            </Grid>
            <Grid item md={12}>
                <Typography variant="body1">
                    Kaikkiin yksityistarpeisiin liittyvät kyselyt ohjataan esim. numerotiedusteluun. Henkilötietoja ei
                    tule koskaan luovuttaa yksityistarpeisiin.
                    <br />
                    <br />
                    Tietoja ei tule luovuttaa Seurakunnan ulkopuoliselle taholle missään tilanteessa.
                </Typography>
            </Grid>
            <Grid item md={12}>
                <Box m={1} />
                <Typography variant="h5">6. Käyttäjän vastuut ja velvollisuudet</Typography>
            </Grid>
            <Grid item md={12}>
                <Grid item md={12}>
                    <Typography variant="h6">6.1 Käyttäjätunnus ja käyttöympäristö</Typography>
                </Grid>
                <Grid item md={12}>
                    <Box m={1} />
                    <Typography variant="body1">
                        Kaikilla Jäsenrekisterin Käyttäjillä on omalta osaltaan vastuu huolehtia Jäsenrekisterin
                        tietoturvasta ja henkilötietojen tietosuojasta. Jäsenrekisteriä on käytettävä vain Seurakunnan
                        toimintaan.
                        <br />
                        <br />
                        <b>Käyttäjätunnus on henkilökohtainen:</b>
                        <ul>
                            <li>käyttäjätunnusta ei saa luovuttaa kenellekään missään tilanteessa</li>
                        </ul>
                        Käyttäjän tulee huolehtia oman <b>käyttöympäristönsä tietoturvasta</b>:
                        <ul>
                            <li>tietokoneen tietoturvapäivitykset ja virustorjunta tulee olla ajan tasalla</li>
                            <li>
                                jäsenrekisteriä tulee käyttää vain suojatussa verkossa, ei esim. hotellien julkisista
                                verkoista
                            </li>
                            <li>jäsenrekisterin käytön päättyessä tulee siitä kirjautua ulos</li>
                            <li>varmistaen etteivät ulkopuoliset näe henkilötietoja</li>
                        </ul>
                        Mikäli epäillään tai tiedetään, että käyttäjätunnukset ovat joutuneet toisen henkilön haltuun,
                        Käyttäjän tulee välittömästi vaihtaa tunnuksensa salasana ja tehdä asiasta ilmoitus
                        Adventtikirkolle.
                    </Typography>
                    <Box m={2} />
                </Grid>
                <Grid item md={12}>
                    <Typography variant="h6">6.2 Oikeudettoman tiedon käyttö</Typography>
                </Grid>
                <Grid item md={12}>
                    <Box m={1} />
                    <Typography variant="body1">
                        Tietojen oikeudeton hankkiminen tai sen yrittäminen on kielletty.
                        <br />
                        <br />
                        Mikäli Käyttäjä saa vahingossa haltuunsa muille osoitettua tai kuuluvaa tietoa, on niiden käyttö
                        kielletty. Tällaisesta tapahtumasta tai muista tietoturvan puutteista tai niiden epäilyistä on
                        myös tehtävä viipymättä ilmoitus Adventtikirkolle.
                    </Typography>
                    <Box m={2} />
                </Grid>
                <Grid item md={12}>
                    <Typography variant="h6">6.3 Vaitiolovelvollisuus</Typography>
                </Grid>
                <Grid item md={12}>
                    <Box m={1} />
                    <Typography variant="body1">
                        Käyttäjällä on vaitiolovelvollisuus järjestelmän tietosisällöstä, suojausmenetelmistä ja
                        ominaisuuksista.
                        <br />
                        <br />
                        Vaitiolovelvollisuus on voimassa käyttöoikeuden päättymisen jälkeenkin.
                    </Typography>
                    <Box m={2} />
                </Grid>
                <Grid item md={12}>
                    <Typography variant="h6">6.4 Väärinkäytösten seuraamukset</Typography>
                </Grid>
                <Grid item md={12}>
                    <Box m={1} />
                    <Typography variant="body1">
                        Mikäli Käyttäjä ei noudata näitä käyttöehtoja tai toiminnallaan vaarantaa Jäsenrekisterin
                        tietoturvan, hänen käyttöoikeutensa voidaan poistaa.
                        <br />
                        <br />
                        Väärinkäytöksistä, kuten käyttäjätunnuksen ja salasanan luovuttamisesta toiselle, on myös
                        rikosoikeudellinen vastuu lakien ja asetusten mukaisesti.
                    </Typography>
                    <Box m={2} />
                </Grid>
            </Grid>
            <Grid item md={12}>
                <Typography variant="h5">7. Käyttöoikeuden voimassaolo</Typography>
            </Grid>
            <Grid item md={12}>
                <Typography variant="body1">
                    Käyttöoikeus Jäsenrekisteriin päättyy, jos:
                    <ul>
                        <li>Seurakunnan ja Adventtikirkon välinen sopimus päättyy</li>
                        <li>Käyttäjän tehtävä Seurakunnan henkilötietojen käsittelijänä päättyy</li>
                        <li>
                            Käyttäjän toiminnasta johtuen Jäsenrekisterin tietoturva on vaarantunut ja käyttöoikeus
                            joudutaan poistamaan
                        </li>
                    </ul>
                    Käyttöoikeuden päättyessä vaitiolovelvollisuus säilyy voimassa.
                </Typography>
            </Grid>
            <Grid item md={12}>
                <Box m={1} />
                <Typography variant="h5">8. Liittyvät lait ja lisätietoa</Typography>
            </Grid>
            <Grid item md={12} style={{ paddingTop: 0 }}>
                <Typography variant="body1">
                    <ul style={{ marginTop: 0 }}>
                        <li>Laki uskontokuntien jäsenrekistereistä (614/1998)</li>
                        <li>Tietosuojalaki (1050/2018)</li>
                        <li>EU:n yleinen tietosuoja-asetus (2016/679)</li>
                        <li>Uskonnonvapauslaki (6.6.2003/453)</li>
                        <li>Rikoslaki (39/1889, luku 35:1,2 §; luku 38:2 §, luku 38:3−4 §; luku 38:8 §)</li>
                        <li>Suomen perustuslaki (731/1999, 10-12§)</li>
                    </ul>
                    Lisätietoa henkilötietojen käsittelystä löytyy Tietosuojavaltuutetun toimistosta:
                    <a href="https://tietosuoja.fi" target="_blank" rel="noreferrer">
                        https://tietosuoja.fi
                    </a>
                    .
                    <br />
                    <br />
                    Lisätietoa Jäsenrekisterin käytöstä saa Adventtikirkon toimistosta.
                </Typography>
            </Grid>
            <Grid item md={12}>
                <Box m={1} />
                <Typography variant="h5">9. LUE VÄHINTÄÄN TÄMÄ - Käytännön vinkit</Typography>
            </Grid>
            <Grid item md={12} style={{ paddingTop: 0 }}>
                <Typography variant="body1">
                    <ul style={{ marginTop: 0 }}>
                        <li>Tietoja saa katsella ja käsitellä vain tehtäviensä suorittamista varten</li>
                        <li>Käyttäjätunnusta ei saa luovuttaa kenellekään missään tilanteessa</li>
                        <li>
                            Jos seurakunnan työntekijä tai kuka tahansa pyytää henkilökohtaisia tunnuksiasi älä anna
                            niitä, vaan ohjaa pyytämään Pääkäyttäjältä omat tunnukset
                        </li>
                        <li>Aina kun poistut tietokoneelta, kirjaudu ulos Jäsenrekisteristä</li>
                        <li>
                            Älä säilytä käyttäjätunnustasi ja salasanaasi näkyvillä, samassa paikassa tai muuten
                            helposti saatavilla. Mieluiten vain päässäsi!
                        </li>
                        <li>
                            Henkilötietoja ei saa kertoa, seurakuntaan kuuluminen on erityisen salassapidon alaista
                            tietoa
                        </li>
                    </ul>
                </Typography>
            </Grid>
        </Grid>
    );
};

const TermsAndConditionsText: FC = () => {
    if (config.CUSTOMER == constants.Customers.Advk) return <TermsAndConditionsTextAdvk />;

    return <TermsAndConditionsTextShk />;
};

export default TermsAndConditionsText;
