import React, { FC, useContext } from 'react';
import { LanguageContext } from 'contexts/languageContext';
import { Grid, Typography } from '@mui/material';
import { CredentialApplication } from 'types/sp-api';
import DialogBase from 'components/common/DialogBase';
import { Formik, FormikProps, Form } from 'formik';
import FormFieldDropDownCodeSet from 'components/common/FormFieldDropDownCodeSet';
import { useErrorHandler } from 'components/hooks/useErrorHandler';
import { useSuccessHandler } from 'components/hooks/useSuccessHandler';
import CredentialApplicationService from 'services/credentialApplicationService';
import FormFieldText from 'components/common/FormFieldText';

interface DialogChangeTypeProps {
    applicationId: number;
    application?: CredentialApplication;
    show?: boolean;
    onClose: () => void;
    onUpdate: () => void;
}

const DialogChangeType: FC<DialogChangeTypeProps> = ({
    applicationId,
    application,
    show,
    onClose,
    onUpdate
}: DialogChangeTypeProps) => {
    const { T } = useContext(LanguageContext);
    const { handleSuccess } = useSuccessHandler();
    const { handleError } = useErrorHandler();

    const changeApplicationType = async (app: CredentialApplication) => {
        try {
            await CredentialApplicationService.changeApplicationTypeAsync(applicationId, app.type, app.title);
            // refresh application
            onUpdate();
            handleSuccess(T('CredentialApplication_TypeSaveSuccess'));
        } catch (error) {
            handleError(T('CredentialApplication_TypeSaveFailed'));
        }
    };

    return (
        <Formik
            initialValues={
                application ?? {
                    type: 0,
                    organizationId: 0,
                    firstName: '',
                    lastName: ''
                }
            }
            onSubmit={() => {
                // DO nothing
            }}
            enableReinitialize
        >
            {({ dirty, isValid, isSubmitting, resetForm, values }: FormikProps<CredentialApplication>) => (
                <DialogBase
                    show={show}
                    title={T('CredentialApplication_ChangeApplicationTypeTitle')}
                    onOk={async () => {
                        changeApplicationType(values);
                        resetForm();
                        onClose();
                    }}
                    onClose={onClose}
                    okDisabled={isSubmitting || !dirty || !isValid}
                    okTitle={T('Common_Save')}
                >
                    <Form>
                        <Grid container spacing={3} sx={{ pt: 1 }} style={{ paddingBottom: 0 }}>
                            <Grid item xs={10}>
                                <Typography>{T('CredentialApplication_NewTypeHelpText')}</Typography>
                            </Grid>
                            <Grid item xs={10}>
                                <FormFieldDropDownCodeSet
                                    codeSet="credentialType"
                                    name="type"
                                    label={T('CredentialApplication_NewType')}
                                    placeholder={T('CredentialApplication_SelectType')}
                                />
                            </Grid>
                            <Grid item xs={10}>
                                <FormFieldText name="title" label={T('CredentialApplication_TypeTitle')} />
                            </Grid>
                        </Grid>
                    </Form>
                </DialogBase>
            )}
        </Formik>
    );
};

export default DialogChangeType;
