import React, { FC, useContext, MouseEventHandler } from 'react';
import { Grid, Typography } from '@mui/material';
import { LanguageContext } from 'contexts/languageContext';
import { SvgIconComponent } from '@mui/icons-material';
import ButtonAdd from './ButtonAdd';
import ButtonEdit from './ButtonEdit';

interface ViewSubtitleProps {
    title: string;
    onAddClick?: MouseEventHandler<HTMLButtonElement>;
    onEditClick?: MouseEventHandler<HTMLButtonElement>;
    icon?: unknown;
    buttonTitle?: string;
    small?: boolean;
    noMargin?: boolean;
    tooltip?: string;
}

export const ViewSubtitle: FC<ViewSubtitleProps> = ({
    title,
    onAddClick,
    onEditClick,
    icon,
    buttonTitle,
    small,
    noMargin,
    tooltip
}: ViewSubtitleProps) => {
    const { T } = useContext(LanguageContext);
    const Icon = icon as SvgIconComponent;
    const titleXs = onAddClick || onEditClick ? 9 : 12;
    const buttonXs = 3;
    const leftMargin = noMargin ? 0 : 16;
    const topMargin = noMargin ? 0 : 8;
    const bottomMargin = noMargin ? 0 : 8;

    return (
        <Grid container justifyContent="space-between" style={{ marginBottom: bottomMargin, marginTop: topMargin }}>
            <Grid container justifyContent="flex-start" item xs={titleXs} alignItems="center">
                {!!icon && (
                    <Grid item>
                        {small && (
                            <Typography variant="h6" component="h6" style={{ marginTop: 8 }}>
                                <Icon fontSize="small" color="primary" />
                            </Typography>
                        )}
                        {!small && (
                            <Typography variant="h5" component="h5" style={{ marginTop: 8 }}>
                                <Icon color="primary" />
                            </Typography>
                        )}
                    </Grid>
                )}
                <Grid item>
                    {small && (
                        <Typography variant="h6" component="h6" style={{ fontSize: 18, marginLeft: leftMargin }}>
                            {title}
                        </Typography>
                    )}
                    {!small && (
                        <Typography variant="h5" component="h5" style={{ fontSize: 24, marginLeft: leftMargin }}>
                            {title}
                        </Typography>
                    )}
                </Grid>
            </Grid>
            {onAddClick && (
                <Grid container justifyContent="flex-end" item xs={buttonXs}>
                    <Grid item>
                        <ButtonAdd
                            small
                            title={buttonTitle ?? T('Common_Add')}
                            variant="contained"
                            onClick={onAddClick}
                            color="secondary"
                            tooltip={tooltip}
                        />
                    </Grid>
                </Grid>
            )}
            {onEditClick && (
                <Grid container justifyContent="flex-end" item xs={buttonXs}>
                    <Grid item>
                        <ButtonEdit
                            small
                            title={buttonTitle ?? T('Common_Edit')}
                            variant="contained"
                            onClick={onEditClick}
                            color="secondary"
                            tooltip={tooltip}
                        />
                    </Grid>
                </Grid>
            )}
        </Grid>
    );
};

export default ViewSubtitle;
