import React, { FC, useContext } from 'react';
import { Typography, Grid, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';
import { LanguageContext } from 'contexts/languageContext';
import WaitScreen from 'components/common/WaitScreen';
import { ChurchTypes } from 'types/sp-api';
import config from 'config/config';
import constants from 'config/constants';

interface OrganizationCountsProps {
    counts?: ChurchTypes;
}

const OrganizationCounts: FC<OrganizationCountsProps> = ({ counts }: OrganizationCountsProps) => {
    const { T } = useContext(LanguageContext);
    if (!counts) return <WaitScreen />;

    return (
        <Grid item xs={12}>
            <TableContainer>
                <Table size="small">
                    <TableBody>
                        <TableRow>
                            <TableCell>
                                <Typography color="textSecondary">
                                    {T('CodeSet_organizationType_yhdyskuntaseurakunta')}
                                </Typography>
                            </TableCell>
                            <TableCell align="right">
                                <Typography color="textSecondary">{counts.type2}</Typography>
                            </TableCell>
                        </TableRow>
                        {config.CUSTOMER === constants.Customers.Shk && (
                            <>
                                <TableRow>
                                    <TableCell>
                                        <Typography color="textSecondary">{T('Common_ChurchAssosiations')}</Typography>
                                    </TableCell>
                                    <TableCell align="right">
                                        <Typography color="textSecondary">{counts.type3}</Typography>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>
                                        <Typography color="textSecondary">{T('Common_OtherAssosiations')}</Typography>
                                    </TableCell>
                                    <TableCell align="right">
                                        <Typography color="textSecondary">{counts.others}</Typography>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>
                                        <Typography color="textSecondary">
                                            {T('Organization_Type_ContactChurch')}
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="right">
                                        <Typography color="textSecondary">{counts.type4}</Typography>
                                    </TableCell>
                                </TableRow>
                            </>
                        )}
                        <TableRow>
                            <TableCell>
                                <Typography color="textSecondary">
                                    <strong>{T('Common_Total')}</strong>
                                </Typography>
                            </TableCell>
                            <TableCell align="right">
                                <Typography color="textSecondary">
                                    <strong>{counts.total}</strong>
                                </Typography>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </Grid>
    );
};

export default OrganizationCounts;
