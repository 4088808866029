import React, { FC, useContext, useState } from 'react';
import { LanguageContext } from 'contexts/languageContext';
import { Grid, List, ListItem, Typography } from '@mui/material';
import { CredentialApplicationNote } from 'types/sp-api';
import Utils from 'utils/typeHelper';
import moment from 'moment';
import ButtonEdit from 'components/common/ButtonEdit';
import ButtonDelete from 'components/common/ButtonDelete';
import { DeleteDialogState } from 'types/common';
import DialogConfirmation from 'components/common/DialogConfirmation';

interface CredentialApplicationNotesProps {
    notes?: CredentialApplicationNote[];
    editNote: (id: number) => void;
    deleteNote: (id: number) => void;
}

const CredentialApplicationNotes: FC<CredentialApplicationNotesProps> = ({
    notes,
    editNote,
    deleteNote
}: CredentialApplicationNotesProps) => {
    const { T } = useContext(LanguageContext);
    const [deleteDialogState, setDeleteDialogState] = useState<DeleteDialogState>();

    if (!notes) return null;

    if (notes.length == 0) {
        return <>{T('CredentialApplication_NoNotes')}</>;
    }

    return (
        <>
            <List
                sx={{
                    width: '100%',
                    overflow: 'auto',
                    maxWidth: 560,
                    pt: 0
                }}
            >
                {notes?.map((note, index) => (
                    <ListItem key={index} sx={{ mb: 1, p: 0 }}>
                        <Grid container>
                            <Grid
                                container
                                item
                                xs={12}
                                sx={{ backgroundColor: '#868FA0', borderRadius: 1, pl: 1 }}
                                justifyContent="space-between"
                            >
                                <Grid container item xs={8} justifyContent="flex-start">
                                    <Grid item xs={3} alignSelf="center">
                                        <Typography variant="body2" color="whitesmoke">
                                            {moment(note.notesDate).format('l')}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4} alignSelf="center">
                                        <Typography variant="body2" color="whitesmoke">
                                            {T(Utils.CredentialApplicationNoteTypeToName(note.type))}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={'auto'} alignSelf="center">
                                        <Typography variant="body2" color="silver" fontSize={10}>
                                            {note.lastModifiedBy}
                                        </Typography>
                                        <Typography variant="body2" color="silver" fontSize={8}>
                                            {moment(note.lastModifiedDate).format('L LT')}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid container item xs={'auto'} justifyContent="flex-end">
                                    <Grid item>
                                        <ButtonEdit small onClick={() => editNote(note.id ?? 0)}>
                                            {T('Common_Edit')}
                                        </ButtonEdit>
                                    </Grid>
                                    <Grid item>
                                        <ButtonDelete
                                            small
                                            onClick={() =>
                                                setDeleteDialogState({
                                                    show: true,
                                                    message: T(
                                                        'CredentialApplication_ConfirmDeleteNoteMessage'
                                                    ).replace(
                                                        '{0}',
                                                        `${T(
                                                            Utils.CredentialApplicationNoteTypeToName(note.type)
                                                        )} ${moment(note.notesDate).format('l')}`
                                                    ),
                                                    id: note.id ?? 0
                                                })
                                            }
                                            sx={{ color: '#d32f2f80' }}
                                        >
                                            {T('Common_Delete')}
                                        </ButtonDelete>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sx={{ mt: 1, mb: 1 }}>
                                <Typography variant="body2">{note.notes}</Typography>
                            </Grid>
                        </Grid>
                    </ListItem>
                ))}
            </List>
            <DialogConfirmation
                title={T('CredentialApplication_ConfirmDeleteNoteTitle')}
                message={deleteDialogState?.message}
                show={deleteDialogState?.show}
                onClose={() => setDeleteDialogState(undefined)}
                onOk={() => deleteNote(deleteDialogState?.id ?? 0)}
                warning
                okTitle={T('Common_Delete')}
            />
        </>
    );
};

export default CredentialApplicationNotes;
