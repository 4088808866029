import React, { FC, useContext } from 'react';
import { Grid, List, ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material';
import { LanguageContext } from 'contexts/languageContext';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

const PasswordTooltip: FC = () => {
    const { T } = useContext(LanguageContext);

    return (
        <Grid container>
            <Grid item style={{ padding: 4 }}>
                <Typography variant="subtitle1" color="inherit">
                    {T('Registration_PasswordTooltipTitle')}
                </Typography>
            </Grid>
            <Grid item>
                <List dense>
                    <ListItem>
                        <ListItemIcon>
                            <ChevronRightIcon />
                        </ListItemIcon>
                        <ListItemText primary={T('Registration_PasswordCharacters')} />
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <ChevronRightIcon />
                        </ListItemIcon>
                        <ListItemText primary={T('Registration_PasswordLength')} />
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <ChevronRightIcon />
                        </ListItemIcon>
                        <ListItemText primary={T('Registration_PasswordNonDictionary')} />
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <ChevronRightIcon />
                        </ListItemIcon>
                        <ListItemText primary={T('Registration_NotUsername')} />
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <ChevronRightIcon />
                        </ListItemIcon>
                        <ListItemText primary={T('Registration_PasswordSingleUse')} />
                    </ListItem>
                </List>
            </Grid>
        </Grid>
    );
};

export default PasswordTooltip;
