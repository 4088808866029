import React, { useCallback, useContext } from 'react';
import { LanguageContext } from 'contexts/languageContext';
import { useSnackbar } from 'notistack';
import SuccessToast from '../common/SuccessToast';

type successHandlerFunc = (title?: string, message?: string, key?: string) => void;

export interface SuccesssHandlerContext {
    handleSuccess: successHandlerFunc;
}

export const useSuccessHandler = (): SuccesssHandlerContext => {
    const { T } = useContext(LanguageContext);
    const { enqueueSnackbar } = useSnackbar();

    const handleSuccessInternal = (title?: string, message?: string, key?: string) => {
        const titleMessage = title ?? T('Query_Success');

        enqueueSnackbar(<SuccessToast title={titleMessage} message={message} />, {
            variant: 'success',
            preventDuplicate: true,
            key: key
        });
    };

    return { handleSuccess: useCallback(handleSuccessInternal, []) };
};
