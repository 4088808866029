import axios, { AxiosResponse, CancelToken } from 'axios';
import { Membership, MembershipTransfer, MembershipWithTransferInfo } from 'types/sp-api';

class MembershipService {
    public async getPersonMembershipAsync(
        personId: number,
        id: number,
        cancelToken?: CancelToken
    ): Promise<MembershipWithTransferInfo> {
        const response = await axios.get(`persons/${personId}/memberships/${id}`, {
            cancelToken: cancelToken
        });
        return response.data;
    }

    public async saveMembershipAsync(personId?: number, membership?: Membership): Promise<void> {
        if (!personId) return;

        if (membership && membership?.id && membership.id > 0) {
            await axios.put(`persons/${personId}/memberships/${membership?.id}`, membership);
        } else {
            await axios.post(`persons/${personId}/memberships`, membership);
        }
    }

    public async deletePersonMembershipAsync(personId?: number, membershipId?: number): Promise<void> {
        if (!personId || !membershipId) return;

        await axios.delete(`persons/${personId}/memberships/${membershipId}`);
    }

    public async transferMembershipAsync(personId?: number, transfer?: MembershipTransfer): Promise<void> {
        if (!personId || !transfer) return;

        await axios.post(`persons/${personId}/memberships/transfer`, transfer);
    }

    public async transferMembershipAcceptedAsync(personId?: number, membership?: Membership): Promise<AxiosResponse> {
        if (!personId || !membership || !membership?.id || membership.id <= 0) {
            const error: Error = { name: 'Invalid request', message: 'empty parameters' };
            return Promise.reject(error);
        }

        return axios.put(`persons/${personId}/memberships/${membership?.id}/accepted`, membership);
    }

    public async transferMembershipCancelledAsync(personId?: number, membership?: Membership): Promise<AxiosResponse> {
        if (!personId || !membership || !membership?.id || membership.id <= 0) {
            const error: Error = { name: 'Invalid request', message: 'empty parameters' };
            return Promise.reject(error);
        }

        return axios.put(`persons/${personId}/memberships/${membership?.id}/canceled`, membership);
    }
}

export default new MembershipService();
