import React, { FC, useContext, useEffect, useState } from 'react';
import { Box, Button, Grid, InputAdornment } from '@mui/material';
import { Formik, Form, FormikProps } from 'formik';
import { LanguageContext, LanguageTextFunc } from 'contexts/languageContext';
import { Organization, PostOffice } from 'types/sp-api';
import * as Yup from 'yup';
import OrganizationsService from 'services/organizationsService';
import LoadButton from 'components/common/LoadButton';
import FormFieldText from 'components/common/FormFieldText';
import FormFieldNumber from 'components/common/FormFieldNumber';
import LastModified from 'components/common/LastModified';
import FormFieldReadonly from 'components/common/FormFieldReadonly';
import FormFieldDropDownLanguage from 'components/common/FormFieldDropDownLanguage';
import FormFieldDropDownCodeSet from 'components/common/FormFieldDropDownCodeSet';
import FormFieldDate from 'components/common/FormFieldDate';
import { useErrorHandler } from 'components/hooks/useErrorHandler';
import PostalcodeService from 'services/postalcodeService';
import { useSuccessHandler } from 'components/hooks/useSuccessHandler';
import OrganizationPositions from './OrganizationPositions';
import ValidationService from 'services/validationService';
import { OrganizationType } from 'types/common';
import debounce from 'lodash/debounce';
import AddressHelper from 'utils/addressHelper';
import { AuthContext } from 'contexts/authContext';
import { UserRole } from 'types/auth';
import FormFieldTextArea from 'components/common/FormFieldTextArea';
import OrganizationMainUsers from './OrganizationMainUsers';
import ViewSubtitle from 'components/common/ViewSubtitle';
import config from 'config/config';
import constants from 'config/constants';
import FormFieldDropDownCoOperationArea from 'components/common/FormFieldDropDownCoOperationArea';
import FormFieldSwitch from 'components/common/FormFieldSwitch';

const validateBusinessId = debounce(async (value) => ValidationService.isValidBusinessIdAsync(value), 1000, {
    leading: true,
    trailing: true
});

const getValidationSchema = (
    T: LanguageTextFunc,
    postalCodes: PostOffice[],
    orginalBusinessId?: string,
    organizationType?: OrganizationType
) => {
    const requiredString = Yup.string().required(T('Common_Required'));
    const phoneValidation = Yup.string()
        .test('phone-validity', T('Error_InvalidPhone'), (value) => /^[+0-9\-()\s]*$/.exec(value ?? '') !== null)
        .nullable();
    const typeValidation = Yup.number().test('not-0-value', T('Common_Required'), (value) => value !== 0);
    const addressValidation = Yup.object().shape({
        street: requiredString,
        zipCode: Yup.string()
            .test('valid-zipCode', T('Error_InvalidZipCode'), (value) =>
                AddressHelper.validateZipCode(value, postalCodes)
            )
            .required(T('Common_Required'))
    });
    const businessIdValidation = Yup.string()
        .test(
            'businessId-validity',
            T('Error_InvalidBusinessId'),
            (value) => /^[+0-9]{7}-\d$/.exec(value ?? '') !== null
        )
        .test('businessid-exists', T('Error_BusinessIdExist'), async (value) => {
            let validEmail = true;
            if (value && value != orginalBusinessId) {
                const result = await validateBusinessId(value);
                validEmail = result !== undefined ? result : true;
            }
            return validEmail;
        })
        .required(T('Common_Required'));

    if (organizationType === OrganizationType.ContactInfo) {
        return Yup.object().shape({
            name: requiredString,
            phone: phoneValidation,
            type: typeValidation,
            address: addressValidation,
            foundationDate: requiredString
        });
    }

    return Yup.object().shape({
        name: requiredString,
        phone: phoneValidation,
        businessId: businessIdValidation,
        type: typeValidation,
        address: addressValidation,
        foundationDate: requiredString
    });
};

export interface ChurchTabOrganizationProps {
    organization: Organization;
    updateOrganizations: (resetTabs?: boolean) => void;
}

const ChurchTabOrganization: FC<ChurchTabOrganizationProps> = ({
    organization,
    updateOrganizations
}: ChurchTabOrganizationProps) => {
    const { T } = useContext(LanguageContext);
    const [sending, setSending] = useState(false);
    const { handleError } = useErrorHandler();
    const { handleSuccess } = useSuccessHandler();
    const [postalCodes, setPostalCodes] = useState<PostOffice[]>([]);
    const { hasRole } = useContext(AuthContext);

    useEffect(() => {
        PostalcodeService.getAllPostalCodesAsync().then((result) => {
            setPostalCodes(result);
        });
    }, []);

    const saveOrganization = async (values: Organization): Promise<void> => {
        setSending(true);

        if ((values.businessId?.length ?? 0) == 0 && values.type == 4) {
            values.businessId = '';
        }

        try {
            await OrganizationsService.saveOrganizationAsync(values);
            setSending(false);
            handleSuccess(T('Organization_SaveSuccess'));
            updateOrganizations();
        } catch (error) {
            setSending(false);
            handleError(error, T('Organization_SaveFailed'));
        }
    };

    return (
        <Grid container spacing={10} alignItems="flex-start">
            <Grid container item xs={6}>
                <Formik
                    initialValues={organization}
                    enableReinitialize
                    onSubmit={saveOrganization}
                    validationSchema={getValidationSchema(T, postalCodes, organization.businessId, organization.type)}
                >
                    {({ values, dirty, isValid, resetForm }: FormikProps<Organization>) => {
                        return (
                            <Form>
                                <Grid container item xs={12} spacing={3}>
                                    <Grid item xs={6}>
                                        <FormFieldText
                                            name="businessId"
                                            label={T('Organization_FieldBusinessId')}
                                            autoFocus
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <FormFieldDropDownCodeSet
                                            name="type"
                                            label={T('Organization_FieldOrganizationType')}
                                            codeSet="organizationType"
                                            readonly
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormFieldText name="name" label={T('Organization_FieldName')} />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormFieldText
                                            name="address.street"
                                            label={T('Organization_FieldAddressStreet')}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <FormFieldNumber
                                            name="address.zipCode"
                                            label={T('Organization_FieldAddressZipCode')}
                                        />
                                    </Grid>
                                    <Grid item xs={9}>
                                        <FormFieldReadonly
                                            label={T('Organization_FieldAddressCity')}
                                            value={
                                                (values.address?.zipCode &&
                                                    postalCodes.find((item) => item.zipCode === values.address?.zipCode)
                                                        ?.city) ??
                                                T('Error_InvalidZipCode')
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <FormFieldText
                                            name="address.country"
                                            label={T('Organization_FieldAddressCountry')}
                                        />
                                    </Grid>
                                    <Grid item xs={6} />
                                    <Grid item xs={6}>
                                        <FormFieldNumber name="phone" label={T('Organization_FieldPhone')} />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormFieldText name="email" label={T('Organization_FieldEmail')} />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormFieldText name="www" label={T('Organization_FieldWww')} />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormFieldText
                                            name="youtube"
                                            label={T('Organization_FieldYoutube')}
                                            inputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        https://youtube.com/
                                                    </InputAdornment>
                                                )
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormFieldText
                                            name="facebook"
                                            label={T('Organization_FieldFacebook')}
                                            inputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        https://facebook.com/
                                                    </InputAdornment>
                                                )
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <FormFieldDropDownLanguage
                                            name="language"
                                            label={T('Organization_FieldLanguage')}
                                        />
                                    </Grid>
                                    <Grid item xs={6} />
                                    <Grid item xs={6}>
                                        <FormFieldDate
                                            name="foundationDate"
                                            label={T('Organization_FieldFoundationDate')}
                                            disableFuture
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <FormFieldDate
                                            name="conclusionDate"
                                            label={T('Organization_FieldConclusionDate')}
                                            disableFuture
                                        />
                                    </Grid>
                                    {config.CUSTOMER === constants.Customers.Shk && (
                                        <>
                                            <Grid item xs={12}>
                                                <FormFieldDropDownCoOperationArea
                                                    name="cooperationArea"
                                                    label={T('Organization_FieldCoOperationArea')}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <FormFieldSwitch
                                                    name="shkMember"
                                                    label={T('Organization_FieldShkMember')}
                                                />
                                            </Grid>
                                        </>
                                    )}
                                    {organization.type === OrganizationType.LocalCommunity && (
                                        <>
                                            <Grid item xs={12}>
                                                <FormFieldText
                                                    name="boardName"
                                                    label={T('Organization_FieldBoardName')}
                                                />
                                            </Grid>
                                            {config.CUSTOMER == constants.Customers.Shk && (
                                                <Grid item xs={12}>
                                                    <FormFieldDate
                                                        name="communityRegistrationDate"
                                                        label={T('Organization_FieldCommunityRegistrationDate')}
                                                    />
                                                </Grid>
                                            )}
                                        </>
                                    )}
                                    {organization.type === OrganizationType.LocalCommunity &&
                                        hasRole(UserRole.Admin) &&
                                        config.CUSTOMER == constants.Customers.Shk && (
                                            <>
                                                <Grid item xs={12}>
                                                    <FormFieldText
                                                        name="diaariNumber"
                                                        label={T('Organization_FieldDiaariNumber')}
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <FormFieldTextArea
                                                        name="info"
                                                        label={T('Organization_FieldInfo')}
                                                        rows={5}
                                                    />
                                                </Grid>
                                            </>
                                        )}
                                    <Grid item xs={12}>
                                        <LoadButton loading={sending} disabled={!dirty || !isValid} type="submit">
                                            {T('Common_Save')}
                                        </LoadButton>
                                        <Button
                                            disabled={!dirty && values.id !== -1}
                                            style={{ marginLeft: 16 }}
                                            onClick={() => {
                                                if (values.id !== -1) resetForm();
                                                else updateOrganizations(true);
                                            }}
                                            color="secondary"
                                        >
                                            {T('Common_Cancel')}
                                        </Button>
                                        <Box margin={2} />
                                        {organization.id !== -1 && (
                                            <LastModified by={values.lastModifiedBy} date={values.lastModifiedDate} />
                                        )}
                                    </Grid>
                                </Grid>
                            </Form>
                        );
                    }}
                </Formik>
            </Grid>
            <Grid container item xs={6}>
                <Grid container item>
                    {organization.id && (
                        <OrganizationPositions
                            organizationId={organization.id}
                            organizationType={organization.type}
                            boardName={organization.boardName}
                        />
                    )}
                </Grid>
                {config.CUSTOMER === constants.Customers.Shk && (
                    <Grid container item style={{ marginTop: 24 }}>
                        <ViewSubtitle small title={T('Organization_MainUsers')} />
                        {organization.id && <OrganizationMainUsers organizationId={organization.id} />}
                    </Grid>
                )}
            </Grid>
        </Grid>
    );
};

export default ChurchTabOrganization;
