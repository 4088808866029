import React, { useState, useCallback, useEffect, useContext, useRef, FC } from 'react';
import axios, { CancelTokenSource } from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import ArchiveService from 'services/archiveService';
import ArchiveList from './ArchiveList';
import { PersonWithMembership } from 'types/sp-api';
import { Container, Grid, Typography } from '@mui/material';
import { LanguageContext, LanguageTextFunc } from 'contexts/languageContext';
import ViewTitle from 'components/common/ViewTitle';
import { FolderShared } from '@mui/icons-material';
import BreadcrumbBar from 'components/common/BreadcrumbBar';
import ViewSearchBar from 'components/common/ViewSearchBar';
import { useErrorHandler } from 'components/hooks/useErrorHandler';
import { TooltipPersonSearch } from 'components/common/TooltipPersonSearch';
import { AuthContext } from 'contexts/authContext';
import * as Yup from 'yup';
import { Form, Formik, FormikProps } from 'formik';
import DialogBase from 'components/common/DialogBase';
import { DialogOpenState, KeyValue } from 'types/common';
import { useSuccessHandler } from 'components/hooks/useSuccessHandler';
import FormFieldText from 'components/common/FormFieldText';
import FormFieldDropDown from 'components/common/FormFieldDropDown';
import { useAudit } from 'components/hooks/useAudit';
import FormFieldReadOnly from 'components/common/FormFieldReadonly';

interface ArchiveReasoning {
    reason?: number;
    comment?: string;
}

const getValidationSchema = (T: LanguageTextFunc, values: ArchiveReasoning) => {
    return Yup.object().shape({
        reason: Yup.string().test('not-0-value', T('Common_Required'), (value) => value !== '0'),
        comment: Yup.string().test(
            'comment-for-reason-other',
            T('Common_Required'),
            (value) => values.reason !== 99 || (!!value && value?.length > 0)
        )
    });
};

const ArchiveView: FC = () => {
    const { T } = useContext(LanguageContext);
    const { isAdmin, user } = useContext(AuthContext);
    const [loading, setLoading] = useState(false);
    const [personsList, setPersonsList] = useState<PersonWithMembership[]>();
    const { filter } = useParams<{ filter?: string }>();
    const { handleError } = useErrorHandler();
    const navigate = useNavigate();
    const cancelRef = useRef<CancelTokenSource | null>(null);
    const [message, setMessage] = useState('');
    const [openAddDialog, setOpenAddDialog] = useState<DialogOpenState>(true);
    const { handleSuccess } = useSuccessHandler();
    const [reasonAdded, setReasonAdded] = useState(false);
    const { audit } = useAudit();

    const archiveReasons: KeyValue[] = [1, 2, 3, 4, 5, 99].map((value) => ({
        key: value,
        text: T(`Archive_Reason_${value}`)
    }));

    const getArchives = useCallback(
        async (filterStr: string): Promise<void> => {
            try {
                setLoading(true);
                cancelRef.current = axios.CancelToken.source();
                const archiveList = await ArchiveService.getPersonsAsync(filterStr, cancelRef.current.token);
                if (cancelRef.current) {
                    setLoading(false);
                    if (archiveList.length === 0) {
                        setPersonsList([]);
                        setMessage(T('Archive_SearchResultEmpty'));
                    } else {
                        setPersonsList(archiveList);
                    }
                }
            } catch (error) {
                setLoading(false);
                handleError(error, T('Error_ArchivePersonQueryFailed'));
            }
        },
        [handleError, T]
    );

    useEffect(() => {
        if (filter && filter.length >= 3) {
            setMessage('');
            getArchives(filter);
        } else {
            setPersonsList([]);
            setMessage(T('Archive_SearchFilterMissing'));
        }

        return () => {
            cancelRef.current && cancelRef.current.cancel();
            cancelRef.current = null;
        };
    }, [getArchives, filter]);

    const saveArchiveReason = async (reasoning: ArchiveReasoning) => {
        try {
            // store audit info
            audit('audit section opened', '/archive', [
                { key: 'reason', value: T(`Archive_Reason_${reasoning.reason}`) },
                { key: 'comment', value: reasoning.comment ?? '' }
            ]);
            handleSuccess(T('Archive_SaveSuccess'));
            setReasonAdded(true);
        } catch (error) {
            handleError(error, T('Archive_SaveFailed'));
        }
    };

    if (!isAdmin()) {
        // force non admin to dashboard
        navigate('/');
    }

    return (
        <Container className="archive-background-light">
            <Grid container>
                <BreadcrumbBar />
                <ViewTitle title={T('Archive_PersonsTitle')} icon={FolderShared} />
                {reasonAdded && (
                    <ViewSearchBar
                        filter={filter}
                        baseUrl="/archive/persons/search"
                        clearUrl="/archive/persons"
                        searchTitle={T('Placeholder_ArchiveSearch')}
                        auditMsg="archive search"
                        iconTooltip={<TooltipPersonSearch />}
                        minWidth={380}
                    />
                )}
            </Grid>
            <ArchiveList personsList={personsList} message={message} loading={loading} />
            <Formik
                initialValues={{}}
                enableReinitialize
                validationSchema={() => Yup.lazy((values) => getValidationSchema(T, values))}
                onSubmit={saveArchiveReason}
            >
                {({ dirty, isValid, isSubmitting, submitForm }: FormikProps<ArchiveReasoning>) => (
                    <DialogBase
                        show={!!openAddDialog}
                        title={T('Archive_ReasoningDialogTitle')}
                        onOk={submitForm}
                        onClose={() => setOpenAddDialog(false)}
                        onCancel={() => navigate('/')}
                        okDisabled={isSubmitting || !dirty || !isValid}
                        okTitle={T('Common_Save')}
                        titleClass={'archive-background'}
                    >
                        <Form>
                            <Grid container spacing={3} sx={{ pt: 1 }}>
                                <Grid item xs={12}>
                                    <FormFieldDropDown
                                        name="reason"
                                        label={T('Archive_ReasonToEnter')}
                                        values={archiveReasons}
                                        placeholder={T('Archive_SelectReason')}
                                    />
                                    <Typography color="textSecondary" variant="body2">
                                        {T('Archive_Reason')}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormFieldText name="comment" label={T('Archive_ReasonComment')} />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormFieldReadOnly label={T('Archive_UserName')} value={user?.fullname} />
                                </Grid>
                            </Grid>
                        </Form>
                    </DialogBase>
                )}
            </Formik>
        </Container>
    );
};

export default ArchiveView;
