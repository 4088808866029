import React, { FC, ReactNode, useCallback, useContext, useEffect, useRef, useState } from 'react';
import {
    Grid,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from '@mui/material';
import { LanguageContext, LanguageTextFunc } from 'contexts/languageContext';
import { useErrorHandler } from 'components/hooks/useErrorHandler';
import axios, { CancelTokenSource } from 'axios';
import DialogBase from 'components/common/DialogBase';
import ButtonAdd from '../common/ButtonAdd';
import { Form, Formik, FormikProps } from 'formik';
import FormFieldDropDown from 'components/common/FormFieldDropDown';
import FormFieldText from 'components/common/FormFieldText';
import * as Yup from 'yup';
import DialogConfirmation from 'components/common/DialogConfirmation';
import { useSuccessHandler } from 'components/hooks/useSuccessHandler';
import CommunityTagService from 'services/communityTagService';
import { CommunityTag, Tag } from 'types/sp-api';
import WaitScreen from 'components/common/WaitScreen';
import { DeleteDialogState, DialogOpenState } from 'types/common';
import RowMenu from 'components/common/RowMenu';
import { TableHeadCell } from 'components/common/TableHeadCell';
import constants from 'config/constants';
import FormFieldTextArea from 'components/common/FormFieldTextArea';
import { AuthContext } from 'contexts/authContext';
import config from 'config/config';
import { UserRole } from 'types/auth';

const getValidationSchema = (T: LanguageTextFunc) => {
    return Yup.object().shape({
        titleId: Yup.string().test('not-0-value', T('Common_Required'), (value) => value !== '0')
    });
};

const getNewTitleValidationSchema = (T: LanguageTextFunc) => {
    return Yup.object().shape({
        name: Yup.string().required(T('Common_Required'))
    });
};

interface CommunityTagListProps {
    personId: number;
    archive?: boolean;
}

const CommunityTagList: FC<CommunityTagListProps> = ({ personId, archive }: CommunityTagListProps) => {
    const { T } = useContext(LanguageContext);
    const [tagList, setTagList] = useState<CommunityTag[]>();
    const [openAddDialog, setOpenAddDialog] = useState<DialogOpenState>(false);
    const [deleteDialogState, setDeleteDialogState] = useState<DeleteDialogState>();
    const { handleError } = useErrorHandler();
    const [tagTitles, setTagTitles] = useState<Tag[]>([]);
    const { handleSuccess } = useSuccessHandler();
    const [openAddNewTitleDialog, setOpenAddNewTitleDialog] = useState(false);
    const cancelRef = useRef<CancelTokenSource | null>(null);
    const { user, hasRole } = useContext(AuthContext);

    const allowedEdit = () => {
        if (config.CUSTOMER === constants.Customers.Shk) {
            return user?.role == UserRole.Admin || user?.role == UserRole.Clerk;
        }

        if (config.CUSTOMER === constants.Customers.Advk && hasRole(UserRole.Admin)) {
            return true;
        }

        return false;
    };

    const getNewTag = (): CommunityTag => ({
        id: undefined,
        tagId: 0,
        value: ''
    });

    const [selectedTag, setSelectedTag] = useState<CommunityTag>(getNewTag());

    const getCommunityTagTitles = useCallback(
        async (forceUpdate?: boolean): Promise<void> => {
            try {
                !cancelRef.current && (cancelRef.current = axios.CancelToken.source());
                const orgTagTitles = await CommunityTagService.getCommunityTagTitlesAsync(
                    cancelRef.current.token,
                    forceUpdate
                );
                if (cancelRef.current) {
                    const tagSorter = (a: Tag, b: Tag): number => {
                        if (!a.name || !b.name) return 0;
                        if (a.name > b.name) return 1;
                        if (a.name < b.name) return -1;
                        return 0;
                    };

                    orgTagTitles.sort(tagSorter);
                    setTagTitles(orgTagTitles);
                }
            } catch (error) {
                handleError(error, T('Error_CommunityTagTitleQueryFailed'));
            }
        },
        [handleError, T]
    );

    const getCommunityTags = useCallback(async (): Promise<void> => {
        try {
            !cancelRef.current && (cancelRef.current = axios.CancelToken.source());
            const communityTags = await CommunityTagService.getPersonsCommunityTagsAsync(
                personId,
                cancelRef.current.token
            );
            if (cancelRef.current) {
                setTagList(communityTags);
            }
        } catch (error) {
            handleError(error, T('Error_CommunityTagQueryFailed'));
        }
    }, [handleError, T, personId]);

    useEffect(() => {
        setTagList([]); // empty so it will not be shown while titles are being loaded
        getCommunityTagTitles();
        getCommunityTags();

        return () => {
            cancelRef.current && cancelRef.current.cancel();
            cancelRef.current = null;
        };
    }, [getCommunityTags, getCommunityTagTitles]);

    const refreshCommunityTags = () => {
        // refresh list
        getCommunityTags();
    };

    const deleteCommunityTag = async () => {
        try {
            await CommunityTagService.deletePersonsCommunityTagAsync(personId, deleteDialogState?.id);
            handleSuccess(T('CommunityTag_DeleteSuccess'));
            refreshCommunityTags();
        } catch (error) {
            handleError(error, T('CommunityTag_DeleteFailed'));
        }
    };

    const saveCommunityTag = async (tag: CommunityTag) => {
        try {
            await CommunityTagService.savePersonsCommunityTagAsync(personId, tag);
            handleSuccess(T('CommunityTag_SaveSuccess'));
            refreshCommunityTags();
        } catch (error) {
            handleError(error, T('CommunityTag_SaveFailed'));
        }
    };

    const addCommunityTag = () => {
        setSelectedTag(getNewTag());
        setOpenAddDialog(true);
    };

    const editCommunityTag = (tag: CommunityTag) => {
        setSelectedTag(tag);
        setOpenAddDialog('edit');
    };

    const getTitle = (titleId?: number) => {
        return tagTitles.find((item) => item.id === titleId)?.name ?? '';
    };

    const saveNewTagTitle = async (title: Tag) => {
        try {
            await CommunityTagService.addCommunityTagTitleAsync(title);
            handleSuccess(T('AddNewCommunityTag_SaveSuccess'));
            // refresh list
            getCommunityTagTitles(true);
        } catch (error) {
            handleError(error, T('AddNewCommunityTag_SaveFailed'));
        }
    };

    return (
        <>
            <Grid item xs={12}>
                <TableContainer component={Paper}>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableHeadCell>{T('CommunityTag_ListTitle')}</TableHeadCell>
                                <TableHeadCell sx={{ width: '5%' }} />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {tagList &&
                                tagTitles &&
                                tagList.length > 0 &&
                                tagList.map(
                                    (item): ReactNode => (
                                        <TableRow key={item.id}>
                                            <TableCell>
                                                <Typography>{getTitle(item.tagId)}</Typography>
                                                <Typography color="textSecondary" variant="body2">
                                                    {item.value
                                                        ? `${T('CommunityTag_FieldValue')}: ${item.value}`
                                                        : null}
                                                </Typography>
                                            </TableCell>
                                            <TableCell sx={{ width: '5%' }}>
                                                {!archive && allowedEdit() && (
                                                    <RowMenu
                                                        onEdit={() => editCommunityTag(item)}
                                                        onDelete={() =>
                                                            setDeleteDialogState({
                                                                show: true,
                                                                message: T('CommunityTag_DeleteMessage').replace(
                                                                    '{0}',
                                                                    getTitle(item.tagId)
                                                                ),
                                                                id: item.id ?? 0
                                                            })
                                                        }
                                                    />
                                                )}
                                            </TableCell>
                                        </TableRow>
                                    )
                                )}
                            {tagList && tagTitles && tagList.length === 0 && (
                                <TableRow>
                                    <TableCell>
                                        <Typography variant="body1" color="textSecondary">
                                            {T('CommunityTag_NoTags')}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            )}
                            {(!tagList || !tagTitles) && (
                                <TableRow>
                                    <TableCell>
                                        <WaitScreen />
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
            {!archive && allowedEdit() && (
                <Grid item xs={12} container justifyContent="flex-end">
                    <ButtonAdd
                        variant="text"
                        color="secondary"
                        onClick={() => addCommunityTag()}
                        small
                        title={T('CommunityTag_AddNew')}
                        disabled={archive}
                    />
                </Grid>
            )}
            <Formik
                initialValues={selectedTag}
                enableReinitialize
                validationSchema={getValidationSchema(T)}
                onSubmit={saveCommunityTag}
            >
                {({ dirty, isValid, isSubmitting, submitForm, resetForm }: FormikProps<CommunityTag>) => (
                    <DialogBase
                        show={!!openAddDialog}
                        title={
                            openAddDialog === 'edit'
                                ? T('CommunityTag_EditDialogTitle')
                                : T('CommunityTag_AddDialogTitle')
                        }
                        onOk={async () => {
                            await submitForm();
                            resetForm();
                            setOpenAddDialog(false);
                        }}
                        onClose={() => setOpenAddDialog(false)}
                        okDisabled={isSubmitting || !dirty || !isValid}
                        okTitle={T('Common_Save')}
                    >
                        <Form>
                            <Grid container spacing={3} sx={{ pt: 1 }}>
                                <Grid item xs={10}>
                                    <FormFieldDropDown
                                        name="tagId"
                                        label={T('CommunityTag_FieldTitle')}
                                        values={tagTitles.map((item) => ({
                                            key: item.id,
                                            text: item.name,
                                            tooltip: item.description
                                        }))}
                                        placeholder={T('CommunityTag_SelectTitle')}
                                    />
                                </Grid>
                                <Grid item xs={2}>
                                    <ButtonAdd
                                        onClick={() => setOpenAddNewTitleDialog(true)}
                                        tooltip={T('AddNewCommunityTagTitle_OpenButtonTooltip')}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormFieldText name="value" label={T('CommunityTag_FieldValue')} />
                                </Grid>
                            </Grid>
                        </Form>
                    </DialogBase>
                )}
            </Formik>
            <DialogConfirmation
                title={T('CommunityTag_ConfirmDelete')}
                message={deleteDialogState?.message}
                show={deleteDialogState?.show}
                onClose={() => setDeleteDialogState(undefined)}
                onOk={deleteCommunityTag}
                warning
            />
            <Formik
                initialValues={{ organizationId: 0, name: '', type: constants.TagTypes.Community, lock: false }}
                validationSchema={getNewTitleValidationSchema(T)}
                onSubmit={(values) => saveNewTagTitle(values)}
                enableReinitialize
            >
                {({ dirty, isValid, isSubmitting, submitForm, resetForm }: FormikProps<Tag>) => (
                    <DialogBase
                        show={openAddNewTitleDialog}
                        title={T('AddNewCommunityTagTitle_AddNewTitleDialogTitle')}
                        onOk={async () => {
                            await submitForm();
                            resetForm();
                            setOpenAddNewTitleDialog(false);
                        }}
                        onClose={() => setOpenAddNewTitleDialog(false)}
                        okDisabled={isSubmitting || !dirty || !isValid}
                        okTitle={T('Common_Save')}
                    >
                        <Form>
                            <Grid container spacing={3} sx={{ pt: 1 }}>
                                <Grid item xs={10}>
                                    <FormFieldText
                                        name="name"
                                        label={T('AddNewCommunityTagTitle_FieldName')}
                                        autoFocus
                                    />
                                </Grid>
                                <Grid item xs={10}>
                                    <FormFieldTextArea
                                        name="description"
                                        label={T('AddNewCommunityTagTitle_FieldDescription')}
                                        rows={4}
                                    />
                                    <Typography variant="caption" color="GrayText">
                                        {T('AddNewCommunityTagTitle_DescriptionHelperText')}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Form>
                    </DialogBase>
                )}
            </Formik>
        </>
    );
};

export default CommunityTagList;
