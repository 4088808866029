import React, { FC, useContext } from 'react';
import { LanguageContext } from 'contexts/languageContext';
import {
    TableContainer,
    Paper,
    Table,
    TableBody,
    TableRow,
    TableCell,
    TableHead,
    Grid,
    Typography,
    IconButton
} from '@mui/material';
import WaitScreen from 'components/common/WaitScreen';
import { OrganizationContact } from 'types/sp-api';
import { TableHeadCell } from 'components/common/TableHeadCell';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { useNavigate } from 'react-router-dom';
import ChurchTypeIcon from 'components/common/ChurchTypeIcon';

interface CongregationRegisterListProps {
    organizationList?: OrganizationContact[];
}

const CongregationRegisterList: FC<CongregationRegisterListProps> = ({
    organizationList
}: CongregationRegisterListProps) => {
    const { T } = useContext(LanguageContext);
    const navigate = useNavigate();

    return (
        <Grid item xs={12}>
            <TableContainer component={Paper}>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableHeadCell sx={{ width: 60, textAlign: 'center' }} />
                            <TableHeadCell sx={{ width: '40%' }}>{T('Common_Name')}</TableHeadCell>
                            <TableHeadCell sx={{ width: '8%' }}>{T('Organization_FieldPhone')}</TableHeadCell>
                            <TableHeadCell sx={{ width: '20%' }}>{T('Organization_FieldEmail')}</TableHeadCell>
                            <TableHeadCell sx={{ width: '8%' }}>{T('Organization_FieldAddressCity')}</TableHeadCell>
                            <TableHeadCell sx={{ width: 60, textAlign: 'center' }} />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {!organizationList && (
                            <TableRow>
                                <TableCell>
                                    <WaitScreen />
                                </TableCell>
                            </TableRow>
                        )}
                        {organizationList &&
                            organizationList.length > 0 &&
                            organizationList.map((row) => (
                                <TableRow key={row.id} hover>
                                    <TableCell>
                                        <ChurchTypeIcon type={row.type} />
                                    </TableCell>
                                    <TableCell>{row.name}</TableCell>
                                    <TableCell>{(row.phone ?? row.contactPhone ?? '').replace(/[ ]/g, '')}</TableCell>
                                    <TableCell>{row.email ?? row.contactEmail}</TableCell>
                                    <TableCell>{row.city}</TableCell>
                                    <TableCell>
                                        <Grid container>
                                            <Grid item xs={3}>
                                                <IconButton
                                                    size="small"
                                                    title={T('CongregationRegistry_OpenOrganization')}
                                                    onClick={() => navigate(`/churches/${row.churchId}`)}
                                                >
                                                    <OpenInNewIcon fontSize="small" />
                                                </IconButton>
                                            </Grid>
                                        </Grid>
                                    </TableCell>
                                </TableRow>
                            ))}
                        {organizationList && organizationList.length === 0 && (
                            <TableRow>
                                <TableCell colSpan={2}>
                                    <Typography variant="body1" color="textSecondary">
                                        {T('CongregationRegistry_NoMembers')}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </Grid>
    );
};

export default CongregationRegisterList;
