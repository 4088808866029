import React, { FC, useContext, useState } from 'react';
import { Box, Grid } from '@mui/material';
import DownloadFile from 'components/common/DownloadFile';
import { LanguageContext } from 'contexts/languageContext';
import ReportService from 'services/reportService';
import { Form, Formik, FormikProps } from 'formik';
import PersonUtils from 'utils/personHelper';
import { AppLanguage, MembershipListItem, MembershipReport } from 'types/common';
import FormFieldCheckbox from 'components/common/FormFieldCheckbox';
import { Person } from 'types/sp-api';
import ButtonLanguage from 'components/common/ButtonLanguage';
import config from 'config/config';
import constants from 'config/constants';

interface ReportMembershipReportProps {
    person: Person;
    membership?: MembershipListItem;
}

const ReportMembershipReport: FC<ReportMembershipReportProps> = ({
    person,
    membership
}: ReportMembershipReportProps) => {
    const { T, TExt } = useContext(LanguageContext);
    const [download, setDownload] = useState({ language: '', filename: '' });

    if (!membership) {
        return null;
    }

    return (
        <Formik
            initialValues={{
                person: person,
                filename: '',
                membershipId: membership.id ?? 0,
                addSignatureRow: false,
                addSsn: false,
                addCertificateText: false
            }}
            onSubmit={() => {
                /* do nothing */
            }}
            enableReinitialize
        >
            {({ values }: FormikProps<MembershipReport>) => {
                const fetchFile = async () => {
                    return ReportService.generateMembershipReportAsync(values, download.language);
                };

                const handleMenuItemClick = async (lang: AppLanguage) => {
                    let filename = await TExt('Report_MembershipReportFile', lang);
                    filename = filename.replace('{0}', PersonUtils.FullNameForFilename(person));

                    setDownload({ language: lang, filename: filename });
                };

                return (
                    <Form>
                        <Grid container item xs={12} alignContent="flex-start" spacing={1}>
                            {config.CUSTOMER === constants.Customers.Shk && (
                                <>
                                    <Grid item xs={12}>
                                        <FormFieldCheckbox
                                            name="addSignatureRow"
                                            label={T('Report_MembershipReport_AddSignatureRow')}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormFieldCheckbox name="addSsn" label={T('Report_MembershipReport_AddSsn')} />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormFieldCheckbox
                                            name="addCertificateText"
                                            label={T('Report_MembershipReport_AddCertificateText')}
                                        />
                                    </Grid>
                                </>
                            )}
                            <Grid item xs={12}>
                                <Box m={2} />
                                <ButtonLanguage
                                    title={T('Report_MembershipReportBtn')}
                                    onClick={handleMenuItemClick}
                                    loading={download.language != ''}
                                />
                                {download.language != '' && (
                                    <DownloadFile
                                        fetchFile={fetchFile}
                                        filename={download.filename}
                                        onSuccess={() => {
                                            setDownload({ language: '', filename: '' });
                                        }}
                                    />
                                )}
                            </Grid>
                        </Grid>
                    </Form>
                );
            }}
        </Formik>
    );
};

export default ReportMembershipReport;
