import React, { FC, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Divider, Grid } from '@mui/material';
import { LanguageContext } from 'contexts/languageContext';
import { useErrorHandler } from 'components/hooks/useErrorHandler';
import axios, { CancelTokenSource } from 'axios';
import { Contract, Organization } from 'types/sp-api';
import OrganizationsService from 'services/organizationsService';
import ContractList from './ContractsList';

interface ChurchTabContractsProps {
    organizationList: Organization[];
}

const ChurchTabContracts: FC<ChurchTabContractsProps> = ({ organizationList }: ChurchTabContractsProps) => {
    const { T } = useContext(LanguageContext);
    const [contractsList, setContractsList] = useState<Contract[]>();
    const { handleError } = useErrorHandler();
    const cancelRef = useRef<CancelTokenSource | null>(null);

    const getContracts = useCallback(
        async (id: number): Promise<void> => {
            try {
                cancelRef.current = axios.CancelToken.source();
                const contracts = await OrganizationsService.getContractsAsync(id, cancelRef.current.token);
                if (cancelRef.current) {
                    setContractsList(contracts);
                }
            } catch (error) {
                setContractsList([]);
                handleError(error, T('Error_OrganizationContractsQueryFailed'));
            }
        },
        [handleError, T]
    );

    const updateContractsList = useCallback(() => {
        if (organizationList.length > 0 && organizationList[0].id) {
            getContracts(organizationList[0].id);
        }
    }, [getContracts]);

    useEffect(() => {
        updateContractsList();

        return () => {
            cancelRef.current && cancelRef.current.cancel();
            cancelRef.current = null;
        };
    }, [getContracts, updateContractsList]);

    // TODO multi organization usage? organization select
    if (organizationList.length == 0 || !organizationList[0].id) {
        return null;
    }

    return (
        <Grid container spacing={5}>
            <Grid container item xs={12} spacing={1} alignContent="flex-start">
                <ContractList
                    organizationId={organizationList[0].id}
                    contractsList={contractsList}
                    updateContractsList={updateContractsList}
                />
            </Grid>
            <Grid container item xs={12} spacing={0} alignContent="flex-start">
                <Grid item xs={12}>
                    <Divider variant="middle" />
                </Grid>
            </Grid>
        </Grid>
    );
};

export default ChurchTabContracts;
