import React, { FC, useContext, useState } from 'react';
import { Grid } from '@mui/material';
import { LanguageContext, LanguageTextFunc } from 'contexts/languageContext';
import { useErrorHandler } from 'components/hooks/useErrorHandler';
import MembershipsService from 'services/membershipsService';
import { Membership, MembershipWithTransferInfo, Person } from 'types/sp-api';
import { Form, Formik, FormikProps } from 'formik';
import * as Yup from 'yup';
import WaitScreen from 'components/common/WaitScreen';
import { useSuccessHandler } from 'components/hooks/useSuccessHandler';
import MemberTagList from './MemberTagList';
import { MemberPrerequisite, TerminationReason } from 'types/common';
import moment from 'moment';
import MembershipFormTransferIn from './MembershipFormTransferIn';
import MembershipFormTransferOut from './MembershipFormTransferOut';
import MembershipForm from './MembershipForm';

const getValidationSchema = (T: LanguageTextFunc, values: Membership) => {
    return Yup.object().shape({
        type: Yup.string().test('not-0-value', T('Common_Required'), (value) => value !== '0'),
        beginDate: Yup.date().typeError(T('Common_Required')).required(T('Common_Required')),
        prerequisite: Yup.string().test('not-0-value', T('Common_Required'), (value) => value !== '0'),
        organizationId: Yup.string().test('not-0-value', T('Common_Required'), (value) => value !== '0'),
        terminationReasonText: Yup.string()
            .test(
                'reason-for-termination',
                T('Membership_TerminationReasonRequired'),
                (value) =>
                    values.terminationReason !== TerminationReason.Terminated ||
                    (value !== undefined && value?.length > 0)
            )
            .nullable(),
        terminationReason: Yup.number()
            .test('end-reason-needed', T('Common_Required'), (value) => values.endDate === null || value !== null)
            .nullable(),
        endDate: Yup.date()
            .test(
                'end-date-needed',
                T('Common_Required'),
                (value) => values.terminationReason === null || value !== null
            )
            .nullable()
    });
};

interface MembershipItemProps {
    person: Person;
    membership: MembershipWithTransferInfo;
    updateMembershipList: () => void;
    cancelAdd: () => void;
    archive?: boolean;
    allowedEdit: boolean;
}

const MembershipItem: FC<MembershipItemProps> = ({
    person,
    membership,
    updateMembershipList,
    cancelAdd,
    archive,
    allowedEdit
}: MembershipItemProps) => {
    const { T } = useContext(LanguageContext);
    const { handleError } = useErrorHandler();
    const { handleSuccess } = useSuccessHandler();
    const [sending, setSending] = useState(false);

    const saveMembership = async (values: Membership): Promise<void> => {
        setSending(true);
        try {
            await MembershipsService.saveMembershipAsync(person.id, values);
            updateMembershipList();
            setSending(false);
            if (values.terminationReason === TerminationReason.Deceased) {
                handleSuccess(T('Membership_SaveSuccessDeceasedUpdated'));
            } else {
                handleSuccess(T('Membership_SaveSuccess'));
            }
        } catch (error) {
            setSending(false);
            handleError(error, T('Membership_SaveFailed'));
        }
    };

    if (!membership) return <WaitScreen />;

    const transferIn =
        moment(membership.beginDate).year() === 9999 && membership.prerequisite === MemberPrerequisite.Transfer;
    const transferOut = membership.terminationReason === TerminationReason.Transfered && membership.endDate === null;

    return (
        <>
            <Grid container item xs={7} spacing={1} alignContent="flex-start">
                <Grid item xs={12}>
                    <Formik
                        initialValues={membership}
                        enableReinitialize
                        onSubmit={saveMembership}
                        validationSchema={() => Yup.lazy((values) => getValidationSchema(T, values))}
                    >
                        {({ values, getFieldHelpers }: FormikProps<Membership>) => {
                            if (transferIn && moment(values.beginDate).year() === 9999) {
                                // set today
                                const helper = getFieldHelpers('beginDate');
                                helper.setValue(moment().toDate());
                            }

                            return (
                                <Form>
                                    <Grid item xs={12}>
                                        <Grid container spacing={3}>
                                            {transferIn && (
                                                <MembershipFormTransferIn
                                                    membership={membership}
                                                    person={person}
                                                    acceptMember={updateMembershipList}
                                                    allowedEdit={allowedEdit}
                                                />
                                            )}
                                            {transferOut && (
                                                <MembershipFormTransferOut
                                                    membership={membership}
                                                    person={person}
                                                    cancelTransfer={updateMembershipList}
                                                    allowedEdit={allowedEdit}
                                                />
                                            )}
                                            {!transferIn && !transferOut && (
                                                <MembershipForm
                                                    membership={membership}
                                                    person={person}
                                                    transferMember={updateMembershipList}
                                                    cancelAdd={cancelAdd}
                                                    sending={sending}
                                                    archive={archive}
                                                    allowedEdit={allowedEdit}
                                                />
                                            )}
                                        </Grid>
                                    </Grid>
                                </Form>
                            );
                        }}
                    </Formik>
                </Grid>
            </Grid>
            <Grid container item xs={5} spacing={1} alignContent="flex-start">
                {!transferIn && membership?.id && membership.organization?.id && (
                    <MemberTagList
                        personId={person.id ?? 0}
                        organizationId={membership.organization.id}
                        membershipId={membership.id}
                        archive={archive}
                        allowedEdit={allowedEdit}
                    />
                )}
            </Grid>
        </>
    );
};

export default MembershipItem;
