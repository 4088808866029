import React, { FC, useContext, useEffect, useState } from 'react';
import { Container, Grid, Typography, Divider, Link, Button, ThemeProvider, createTheme } from '@mui/material';
import { LanguageContext } from 'contexts/languageContext';
import { ApplicationContext } from 'contexts/applicationContext';
import shk_logo from 'assets/images/shk_logo.png';
import CredentialApplicationForm from './CredentialApplicationForm';
import { getThemeLocale, applicationTheme } from 'config/theme';
import * as MuiThemeLocales from '@mui/material/locale';
import { AppLanguage } from 'types/common';
import CredentialApplicationSent from './CredentialApplicationSent';

const CredentialApplicationView: FC = () => {
    const { T } = useContext(LanguageContext);
    const [newApplication, setNewApplication] = useState<'new' | 'renew' | undefined>();
    const { setShowHeaders } = useContext(ApplicationContext);
    const [token, setToken] = useState<string>();

    useEffect(() => {
        // hide page headers
        setShowHeaders(false);
    }, []);

    return (
        <ThemeProvider theme={createTheme(applicationTheme, MuiThemeLocales[getThemeLocale(AppLanguage.Finnish)])}>
            <Container sx={{ pt: 2 }}>
                <Grid container sx={{ mb: 1 }}>
                    <Grid item md={3}>
                        <img className="shk_logo" src={shk_logo} alt="logo" />
                    </Grid>
                    <Grid item md={6} container justifyContent="center" alignContent="center">
                        <Typography variant="h4" color="black">
                            {T('CredentialApplication_Title')}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item xs={12} sx={{ mb: 2 }}>
                    <Divider variant="fullWidth" sx={{ borderWidth: 1 }} color="#00A8DD" />
                </Grid>
                <Grid container spacing={2}>
                    {token && <CredentialApplicationSent token={token} />}
                    {!token && (
                        <>
                            <HelpLinks />
                            <Grid item xs={12} sx={{ mb: 2 }}>
                                <Divider variant="fullWidth" sx={{ borderWidth: 1 }} color="#00A8DD" />
                            </Grid>
                            {!newApplication && (
                                <Grid item xs="auto">
                                    <Button
                                        disabled={newApplication}
                                        variant="contained"
                                        onClick={() => setNewApplication('new')}
                                    >
                                        {T('CredentialApplication_CreateNewApplication')}
                                    </Button>
                                </Grid>
                            )}
                            {!newApplication && (
                                <Grid item xs="auto">
                                    <Button
                                        disabled={newApplication}
                                        variant="contained"
                                        onClick={() => setNewApplication('renew')}
                                    >
                                        {T('CredentialApplication_CreateRenewApplication')}
                                    </Button>
                                </Grid>
                            )}
                        </>
                    )}
                </Grid>
                {!token && newApplication != undefined && (
                    <CredentialApplicationForm mode={newApplication} setToken={setToken} />
                )}
            </Container>
        </ThemeProvider>
    );
};

const HelpLinks: FC = () => {
    const { T } = useContext(LanguageContext);

    return (
        <Grid item xs={12} container spacing={1}>
            <Grid item xs={12}>
                <Typography variant="h5">{T('CredentialApplication_HelpDocsTitle')}</Typography>
            </Grid>
            <Grid item xs={12}>
                <Link href={'documents/valtakirjan_esite_2022.pdf'} download color="#4471C4">
                    {T('CredentialApplication_IntroductionFile')}
                </Link>
            </Grid>
            <Grid item xs={12}>
                <Link href={'documents/valtakirjan_lomakeohje_2022.pdf'} download color="#4471C4">
                    {T('CredentialApplication_HelpDocumentationFile')}
                </Link>
            </Grid>
        </Grid>
    );
};

export default CredentialApplicationView;
