const monthToLangKey = (month?: number): string => {
    let retval = '';
    if (!month) return retval;

    switch (month) {
        case 1:
            retval = 'Common_January';
            break;
        case 2:
            retval = 'Common_February';
            break;
        case 3:
            retval = 'Common_March';
            break;
        case 4:
            retval = 'Common_April';
            break;
        case 5:
            retval = 'Common_May';
            break;
        case 6:
            retval = 'Common_June';
            break;
        case 7:
            retval = 'Common_July';
            break;
        case 8:
            retval = 'Common_August';
            break;
        case 9:
            retval = 'Common_September';
            break;
        case 10:
            retval = 'Common_October';
            break;
        case 11:
            retval = 'Common_November';
            break;
        case 12:
            retval = 'Common_December';
            break;
    }

    return retval;
};

export default { monthToLangKey };
