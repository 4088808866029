import React, { FC, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Grid } from '@mui/material';
import { useErrorHandler } from 'components/hooks/useErrorHandler';
import { LanguageContext } from 'contexts/languageContext';
import axios, { CancelTokenSource } from 'axios';
import SecureMessagesService from 'services/secureMessagesService';
import { SecureMessage } from 'types/sp-api';
import WaitScreen from 'components/common/WaitScreen';
import SecureMessageCard from './SecureMessageCard';
import { AuthContext } from 'contexts/authContext';
import constants from 'config/constants';

const SecureMessagesPreview: FC = () => {
    const { T } = useContext(LanguageContext);
    const { user, isAdmin } = useContext(AuthContext);
    const [messageList, setMessageList] = useState<SecureMessage[]>();
    const { handleError } = useErrorHandler();
    const cancelRef = useRef<CancelTokenSource | null>(null);

    const getSecureMessages = useCallback(async (): Promise<void> => {
        try {
            cancelRef.current = axios.CancelToken.source();
            const securemessages = await SecureMessagesService.getPreviewUnreadAsync(
                isAdmin() ? constants.RegionalCommunity.Id : user?.homeOrganizationId,
                cancelRef.current.token
            );
            if (cancelRef.current) {
                setMessageList(securemessages);
            }
        } catch (error) {
            handleError(error, T('Error_SecureMessagesQueryFailed'));
        }
    }, [handleError, T, isAdmin, user?.homeOrganizationId]);

    useEffect(() => {
        getSecureMessages();

        return () => {
            cancelRef.current && cancelRef.current.cancel();
            cancelRef.current = null;
        };
    }, [getSecureMessages]);

    return (
        <Grid container spacing={1} justifyContent="center" direction="column" style={{ marginTop: 4 }}>
            {!messageList && <WaitScreen />}
            {messageList?.map((item, index) => (
                <Grid item key={index} style={{ padding: 1 }} className="MuiTableRow-root MuiTableRow-hover">
                    <SecureMessageCard message={item} />
                </Grid>
            ))}
        </Grid>
    );
};

export default SecureMessagesPreview;
