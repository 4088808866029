import React, { FC, useContext, useState } from 'react';
import { Button, FormHelperText, Grid, Typography } from '@mui/material';
import DownloadFile from 'components/common/DownloadFile';
import { LanguageContext } from 'contexts/languageContext';
import ReportService from 'services/reportService';
import { Form, Formik, FormikProps } from 'formik';
import PersonUtils from 'utils/personHelper';
import { AuthContext } from 'contexts/authContext';
import { AppLanguage, TransferCertificate } from 'types/common';
import FormFieldCheckbox from 'components/common/FormFieldCheckbox';
import { useSuccessHandler } from 'components/hooks/useSuccessHandler';
import { MembershipWithTransferInfo, Person } from 'types/sp-api';
import ButtonLanguage from 'components/common/ButtonLanguage';
import config from 'config/config';
import constants from 'config/constants';
import FormFieldDropDownOrganization from 'components/common/FormFieldDropDownOrganization';

interface ReportTransferCertificateProps {
    person: Person;
    membership?: MembershipWithTransferInfo;
    onCancel?: () => void;
}

const ReportTransferCertificate: FC<ReportTransferCertificateProps> = ({
    person,
    membership,
    onCancel
}: ReportTransferCertificateProps) => {
    const { T, TExt } = useContext(LanguageContext);
    const { user } = useContext(AuthContext);
    const { handleSuccess } = useSuccessHandler();
    const [download, setDownload] = useState({ language: '', filename: '' });

    if (!membership) {
        return null;
    }

    return (
        <Formik
            initialValues={{
                person: person,
                filename: '',
                senderOrganizationId: user?.homeOrganizationId ?? 0,
                membershipId: membership.id ?? 0,
                endMembership: false
            }}
            onSubmit={() => {
                /* do nothing */
            }}
            enableReinitialize
        >
            {({ values, dirty }: FormikProps<TransferCertificate & { person: Person }>) => {
                const fetchFile = async () => {
                    return ReportService.generateTransferCertificateAsync(values, download.language);
                };

                const handleMenuItemClick = async (lang: AppLanguage) => {
                    let filename = await TExt('Report_TransferFile', lang);
                    filename = filename.replace('{0}', PersonUtils.FullNameForFilename(person));

                    setDownload({ language: lang, filename: filename });
                };

                // full member, member no vote and child only, not for register member
                let disableReport = !(membership.type == 1 || membership.type == 2 || membership.type == 3);
                if (config.CUSTOMER === constants.Customers.Advk) {
                    disableReport = disableReport || !dirty;
                }

                return (
                    <Form>
                        <Grid container item xs={12} alignContent="flex-start" spacing={3}>
                            {config.CUSTOMER === constants.Customers.Advk && (
                                <Grid item xs={8}>
                                    <FormFieldDropDownOrganization
                                        name="targeOrganizationId"
                                        label={T('Membership_FieldTargetChurch')}
                                        placeholder={T('Membership_SelectTargetChurch')}
                                        disabledValues={membership.organizationId}
                                        showChurches
                                    />
                                </Grid>
                            )}
                            <Grid item xs={12}>
                                <FormFieldCheckbox
                                    name="endMembership"
                                    label={T('Report_TransferCertificate_EndMembership')}
                                    readonly={disableReport}
                                />
                                <Typography variant="body2" color="textSecondary">
                                    {T('Report_TransferCertificate_EndMembershipHelpText')}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <ButtonLanguage
                                    title={T('Report_TransferCertificateBtn')}
                                    onClick={handleMenuItemClick}
                                    disabled={disableReport}
                                    loading={download.language != ''}
                                    direction="top-end"
                                />
                                <Button
                                    type="button"
                                    onClick={() => {
                                        onCancel?.();
                                    }}
                                    color="secondary"
                                    sx={{ marginLeft: 2 }}
                                >
                                    {T('Common_Cancel')}
                                </Button>
                                {disableReport && (
                                    <FormHelperText>{T('Report_TransferCertificateDisabled')}</FormHelperText>
                                )}
                                {download.language != '' && (
                                    <DownloadFile
                                        fetchFile={fetchFile}
                                        filename={download.filename}
                                        onSuccess={() => {
                                            setDownload({ language: '', filename: '' });
                                            if (values.endMembership) {
                                                handleSuccess(
                                                    T('Report_EndMembershipSuccess'),
                                                    undefined,
                                                    'endMembershipSuccess'
                                                );
                                            }
                                            onCancel?.(); // closes dialog
                                        }}
                                    />
                                )}
                            </Grid>
                        </Grid>
                    </Form>
                );
            }}
        </Formik>
    );
};

export default ReportTransferCertificate;
