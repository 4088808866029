import React, { FC, useContext, useState } from 'react';
import { AxiosResponse } from 'axios';
import { Box, Card, CardContent, Grid, Typography } from '@mui/material';
import DownloadFile from 'components/common/DownloadFile';
import LoadButton from 'components/common/LoadButton';
import { LanguageContext, LanguageTextFunc } from 'contexts/languageContext';
import ReportService from 'services/reportService';
import { Form, Formik, FormikProps } from 'formik';
import * as Yup from 'yup';
import { SmsUsageListReportForm } from 'types/common';
import FormFieldDate from 'components/common/FormFieldDate';

const getValidationSchema = (T: LanguageTextFunc) => {
    return Yup.object().shape({
        beginDate: Yup.date().typeError(T('Common_Required')).required(T('Common_Required')),
        endDate: Yup.date().typeError(T('Common_Required')).required(T('Common_Required'))
    });
};

const ReportUsageList: FC = () => {
    const [downloadFile, setDownloadFile] = useState(false);
    const { T } = useContext(LanguageContext);

    return (
        <Formik
            initialValues={{
                filename: T('Report_UsageListPdfFile')
            }}
            onSubmit={() => {
                /* do nothing */
            }}
            validationSchema={getValidationSchema(T)}
            enableReinitialize
        >
            {({ values, dirty, isValid }: FormikProps<SmsUsageListReportForm>) => {
                const fetchFile = async (): Promise<AxiosResponse> => {
                    return ReportService.smsUsageListAsync(values.beginDate, values.endDate);
                };

                return (
                    <Form>
                        <Grid container item xs={12} alignContent="flex-start" spacing={3}>
                            <Grid item xs={12}>
                                <Card>
                                    <CardContent>
                                        <Grid container item xs={12} alignContent="flex-start" spacing={3}>
                                            <Grid item xs={12}>
                                                <Typography color="primary" variant="h6">
                                                    {T('Report_SelectTimeRange')}
                                                </Typography>
                                            </Grid>
                                            <Grid container item xs={12}>
                                                <Grid item xs={2}>
                                                    <FormFieldDate
                                                        name="beginDate"
                                                        label={T('Report_FilterBeginDate')}
                                                    />
                                                </Grid>
                                                <Box sx={{ ml: 2, mr: 2, pt: 1 }}>{'\u0336 '}̶</Box>
                                                <Grid item xs={2}>
                                                    <FormFieldDate name="endDate" label={T('Report_FilterEndDate')} />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={12}>
                                <LoadButton
                                    onClick={() => {
                                        setDownloadFile(true);
                                    }}
                                    loading={downloadFile}
                                    disabled={!dirty || !isValid}
                                >
                                    {T('Report_UsageListBtn')}
                                </LoadButton>
                                {downloadFile && (
                                    <DownloadFile
                                        fetchFile={() => fetchFile()}
                                        filename={values.filename}
                                        onSuccess={() => setDownloadFile(false)}
                                    />
                                )}
                            </Grid>
                        </Grid>
                    </Form>
                );
            }}
        </Formik>
    );
};

export default ReportUsageList;
