import React, { FC, useContext, useEffect } from 'react';
import WaitScreen from 'components/common/WaitScreen';
import { AuthContext } from 'contexts/authContext';
import { useNavigate } from 'react-router-dom';

const LogoutView: FC = () => {
    const { logout } = useContext(AuthContext);
    const navigate = useNavigate();

    useEffect(() => {
        logout();
        navigate('/', { replace: true });
    }, [logout]);

    return <WaitScreen />;
};

export default LogoutView;
