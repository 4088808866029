import { Organization } from './sp-api';

export interface AuthContextInterface {
    user?: AuthenticatedUser;
    setUser: (newUser?: AuthenticatedUser) => void;
    hasRole: (roles: UserRole | UserRole[]) => boolean;
    isAdmin: () => boolean;
    isAdminOrClerk: () => boolean;
    isAuthenticated: () => boolean;
    logout: () => void;
}

export interface AuthenticatedUser {
    username: string;
    accessToken: string;
    refreshToken: string;
    role: UserRole;
    churchId: number;
    homeOrganizationId: number;
    email: string;
    firstname: string;
    lastname: string;
    personId: number;
    userId: number;
    fullname: string;
    authType: string;
    organizations: Organization[];
}

export enum UserRole {
    Unknown = 'ROLE_UNKNOWN',
    Admin = 'ROLE_ADMIN', // SHK:n pääkäyttäjä
    SuperUser = 'ROLE_SUPERUSER', // yhdistyksen/yhdyskunnan pääkäyttäjä
    User = 'ROLE_USER', // yhdistyksen/yhdyskunnan rekisterinpitäjä
    Person = 'ROLE_PERSON', // järjestelmään liitetty henkilö, omien tietojen katselu
    Clerk = 'ROLE_CLERK' // SHK:n toimihenkilö
}

export enum LoginPhase {
    Login = 'Login',
    Otp = 'Otp'
}

export interface LoginResult {
    otpRequired?: boolean;
    success?: boolean;
    status?: number;
    message?: string;
}

export interface LoginContext {
    username: string;
    password: string;
    otp?: string;
    phase: LoginPhase;
}

export interface JwtRealmAccess {
    roles: UserRole[];
}

export interface JwtPayload {
    acr: string;
    azp: string;
    exp: number;
    iat: number;
    iss: string;
    jti: string;
    realm_access: JwtRealmAccess;
    scope: string;
    session_state: string;
    sub: string;
    typ: string;
}

export interface JwtWithCustomAttributes extends JwtPayload {
    church_id: number;
    email: string;
    given_name: string;
    family_name: string;
    person_id: number;
    user_id: number;
    preferred_username: string;
    locale: string;
}

export interface KeycloakTokenResult {
    access_token: string;
    expires_in: number;
    refresh_expires_in: number;
    refresh_token: string;
    token_type: string;
    'not-before-policy': number;
    session_state: string;
    scope: string;
    error?: string;
}
