import React, { FC, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Grid } from '@mui/material';
import { PersonTabProps } from './PersonTabBasic';
import MembershipList from './MembershipList';
import MembershipItem from './MembershipItem';
import { MembershipListItem } from 'types/common';
import ViewSubtitle from 'components/common/ViewSubtitle';
import MembershipItemCommunity from './MembershipItemCommunity';
import { LanguageContext } from 'contexts/languageContext';
import axios, { CancelTokenSource } from 'axios';
import { Membership, MembershipWithTransferInfo } from 'types/sp-api';
import MembershipsService from 'services/membershipsService';
import { useErrorHandler } from 'components/hooks/useErrorHandler';
import PersonsService from 'services/personsService';
import TypeUtils from 'utils/typeHelper';
import ArchiveService from 'services/archiveService';
import config from 'config/config';
import constants from 'config/constants';
import { AuthContext } from 'contexts/authContext';
import { UserRole } from 'types/auth';

interface PersonMembershipTabProps extends PersonTabProps {
    refreshPerson: () => void;
    archive?: boolean;
}

const PersonTabMembership: FC<PersonMembershipTabProps> = ({
    person,
    refreshPerson,
    archive
}: PersonMembershipTabProps) => {
    const { T } = useContext(LanguageContext);
    const [selectedMembershipId, setSelectedMembershipId] = useState<number>();
    const [membershipList, setMembershipList] = useState<MembershipListItem[]>();
    const [membership, setMembership] = useState<MembershipWithTransferInfo>();
    const cancelRef = useRef<CancelTokenSource | null>(null);
    const { handleError } = useErrorHandler();
    const { user, hasRole } = useContext(AuthContext);

    const allowedEdit = () => {
        if (config.CUSTOMER === constants.Customers.Shk) {
            return user?.role == UserRole.Admin || user?.role == UserRole.SuperUser || user?.role == UserRole.User;
        }

        if (config.CUSTOMER === constants.Customers.Advk && hasRole(UserRole.Admin)) {
            return true;
        }

        return false;
    };

    const sortMemberships = (a: MembershipListItem, b: MembershipListItem) => {
        // sort local community before assosiations
        if (!a || !b) return 0;
        if (!a.organizationType || !b.organizationType) return 0;
        if (a.organizationType < b.organizationType) return 1;
        if (a.organizationType > b.organizationType) return -1;
        return 0;
    };

    const getMembershipList = useCallback(
        async (id?: number, arch?: boolean): Promise<void> => {
            try {
                cancelRef.current = axios.CancelToken.source();
                let personMemberships: Membership[];
                if (arch) {
                    personMemberships = await ArchiveService.getPersonMembershipsAsync(id, cancelRef.current.token);
                } else {
                    personMemberships = await PersonsService.getPersonMembershipsAsync(id, cancelRef.current.token);
                }
                if (cancelRef.current) {
                    setMembershipList(
                        personMemberships
                            .map(
                                (item): MembershipListItem => ({
                                    ...item,
                                    organizationType: TypeUtils.OrganizationTypeToName(item.organization?.type),
                                    valid: item.endDate === null
                                })
                            )
                            .sort(sortMemberships)
                    );
                    if (personMemberships?.length === 1 && selectedMembershipId !== -1) {
                        // preselect the only one
                        setSelectedMembershipId(personMemberships[0].id);
                    }
                }
            } catch (error) {
                handleError(error, T('Error_PersonMembersipQueryFailed'));
            }
        },
        [handleError, T]
    );

    const updateMembershipList = async (): Promise<void> => {
        await getMembershipList(person.id, archive);
        await getMembership(selectedMembershipId);
        // when last one is deleted don't touch selection
        if (membershipList && membershipList?.length > 1) {
            selectMembership();
        }
        if (membershipList && (membershipList.length == 0 || membershipList.length == 1)) {
            // open tabs when membership is created
            refreshPerson();
        }
    };

    const selectMembership = useCallback((id?: number) => {
        setSelectedMembershipId(id === -1 ? undefined : id);
    }, []);

    const getMembership = useCallback(
        async (id?: number): Promise<void> => {
            if (!id) return;
            try {
                cancelRef.current = axios.CancelToken.source();
                setMembership(undefined); // show wait before reload
                const personMembership = await MembershipsService.getPersonMembershipAsync(
                    person.id ?? 0,
                    id,
                    cancelRef.current.token
                );

                if (cancelRef.current) {
                    setMembership(personMembership);
                }
            } catch (error) {
                handleError(error, T('Error_PersonMembersipQueryFailed'));
            }
        },
        [handleError, T, person.id]
    );

    useEffect(() => {
        getMembershipList(person.id, archive);
    }, [getMembershipList, person.id, archive]);

    useEffect(() => {
        getMembership(selectedMembershipId);

        return () => {
            cancelRef.current && cancelRef.current.cancel();
            cancelRef.current = null;
        };
    }, [getMembership, selectedMembershipId]);

    return (
        <Grid container spacing={5}>
            <Grid container item xs={12} spacing={1} sx={{ mb: -3 }}>
                <ViewSubtitle title={T('Membership_SubTitle_Community')} small />
            </Grid>
            <MembershipItemCommunity
                person={person}
                refreshPerson={refreshPerson}
                archive={archive}
                allowedEdit={allowedEdit()}
            />
            <Grid container item xs={12} spacing={1} alignContent="flex-start">
                <ViewSubtitle title={T('Membership_SubTitle_Organizations')} small />
                {membershipList && (
                    <MembershipList
                        person={person}
                        membershipList={membershipList}
                        selectedMembershipId={selectedMembershipId}
                        onSelectMembership={selectMembership}
                        updateMembershipList={updateMembershipList}
                        archive={archive}
                        allowedEdit={allowedEdit()}
                    />
                )}
            </Grid>
            {selectedMembershipId && membership && (
                <MembershipItem
                    person={person}
                    membership={membership}
                    updateMembershipList={updateMembershipList}
                    cancelAdd={() => setSelectedMembershipId(undefined)}
                    archive={archive}
                    allowedEdit={allowedEdit()}
                />
            )}
        </Grid>
    );
};

export default PersonTabMembership;
