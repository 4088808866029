import React, { ChangeEvent, Dispatch, FC, useContext, useState } from 'react';
import { Button, Container, Grid, Paper, Typography } from '@mui/material';
import { LanguageContext, LanguageTextFunc } from 'contexts/languageContext';
import LockIcon from '@mui/icons-material/Lock';
import FormFieldText from 'components/common/FormFieldText';
import { Formik, Form, FormikProps } from 'formik';
import LoadButton from 'components/common/LoadButton';
import { useErrorHandler } from 'components/hooks/useErrorHandler';
import * as Yup from 'yup';
import AuthService from 'services/authService';
import LanguageSelectionFlags from 'components/common/LanguageSelectionFlags';
import FormFieldReadOnly from 'components/common/FormFieldReadonly';
import { useSuccessHandler } from 'components/hooks/useSuccessHandler';
import PublicService from 'services/publicService';
import { LegacyReport } from 'types/common';

const getValidationSchema = (T: LanguageTextFunc) => {
    return Yup.object().shape({
        password: Yup.string().required(T('Common_Required'))
    });
};

const LegacyReportView: FC = () => {
    const languageCtx = useContext(LanguageContext);
    const { T } = languageCtx;
    const [showForm, setShowForm] = useState(false);

    return (
        <Container>
            <Grid container justifyContent="center" alignItems="center" m={2}>
                <Grid item mt={3} mb={2}>
                    <Typography variant="h4">{T('LegacyReportLogin_Title')}</Typography>
                </Grid>

                <Grid container justifyContent="center" item>
                    {!showForm && <LegacyReportLogin setShowForm={setShowForm} />}
                    {showForm && <LegacyReportForm />}
                </Grid>
            </Grid>
        </Container>
    );
};

export default LegacyReportView;

interface LegacyReportLoginProps {
    setShowForm: Dispatch<React.SetStateAction<boolean>>;
}

const LegacyReportLogin: FC<LegacyReportLoginProps> = ({ setShowForm }: LegacyReportLoginProps) => {
    const { T } = useContext(LanguageContext);

    return (
        <Paper style={{ margin: 140, padding: 16 }}>
            <Formik
                initialValues={{ password: '' }}
                enableReinitialize
                onSubmit={async (values, formikHelpers) => {
                    const result = await AuthService.legacyReportLogin(values.password);
                    if (!result) {
                        formikHelpers.setErrors({ password: T('LegacyReportLogin_Failed') });
                    } else {
                        setShowForm(true);
                    }
                }}
                validationSchema={getValidationSchema(T)}
            >
                {({ dirty, isValid }: FormikProps<{ password: string }>) => {
                    return (
                        <Form>
                            <Grid container spacing={3} style={{ maxWidth: 350 }} alignItems="center">
                                <Grid item xs={12} container justifyContent="center">
                                    <Typography variant="h4">{T('Login_Title')}</Typography>
                                </Grid>
                                <Grid item xs={2} container justifyContent="flex-end">
                                    <LockIcon />
                                </Grid>
                                <Grid item xs={10}>
                                    <FormFieldText name="password" label={T('Login_Password')} password />
                                </Grid>
                                <Grid item xs={12} container justifyContent="center">
                                    <LoadButton disabled={!dirty || !isValid} type="submit">
                                        {T('Login_LoginButtonTitle')}
                                    </LoadButton>
                                </Grid>
                                <LanguageSelectionFlags />
                            </Grid>
                        </Form>
                    );
                }}
            </Formik>
        </Paper>
    );
};

const getFormValidationSchema = (T: LanguageTextFunc) => {
    return Yup.object().shape({
        contact: Yup.string().required(T('Common_Required')),
        organization: Yup.string().required(T('Common_Required')),
        file1: Yup.mixed().required(T('Common_Required'))
    });
};

const LegacyReportForm: FC = () => {
    const { T } = useContext(LanguageContext);
    const { handleSuccess } = useSuccessHandler();
    const { handleError } = useErrorHandler();
    const [selectedFile1, setSelectedFile1] = useState<File>();
    const [selectedFile2, setSelectedFile2] = useState<File>();
    const [saved, setSaved] = useState(false);
    const [sending, setSending] = useState(false);

    const saveReport = async (report: LegacyReport) => {
        try {
            setSending(true);
            await PublicService.saveLegacyReportAsync(report);
            handleSuccess(T('LegacyReport_SaveSuccess'));
            setSaved(true);
            setSending(false);
        } catch (error) {
            setSending(false);
            handleError(error, T('LegacyReport_SaveFailed'));
        }
    };

    if (saved) {
        return (
            <Grid container justifyContent="center" alignItems="center" mt={2} direction={'column'}>
                <Grid item mb={3}>
                    <Typography variant="h5">{T('LegacyReport_Saved')}</Typography>
                </Grid>
                <Grid item>
                    <Typography variant="caption" color="GrayText" fontSize={16}>
                        {T('LegacyReport_SafeToExit')}
                    </Typography>
                </Grid>
            </Grid>
        );
    }

    return (
        <>
            <Formik
                initialValues={{ organization: '', contact: '' }}
                enableReinitialize
                validationSchema={getFormValidationSchema(T)}
                onSubmit={saveReport}
            >
                {({ dirty, isValid, errors, getFieldHelpers }: FormikProps<LegacyReport>) => {
                    const selectFile1 = (event: ChangeEvent<HTMLInputElement>): void => {
                        if (!event.currentTarget.files) return;

                        setSelectedFile1(event.currentTarget.files[0]);
                        const helper = getFieldHelpers('file1');
                        helper.setValue(event.currentTarget.files[0]);
                    };

                    const selectFile2 = (event: ChangeEvent<HTMLInputElement>): void => {
                        if (!event.currentTarget.files) return;

                        setSelectedFile2(event.currentTarget.files[0]);
                        const helper = getFieldHelpers('file2');
                        helper.setValue(event.currentTarget.files[0]);
                    };

                    return (
                        <Form>
                            <Grid container spacing={3} sx={{ pt: 1 }}>
                                <Grid item xs={12}>
                                    <FormFieldText name="organization" label={T('LegacyReport_Organization')} />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormFieldText name="contact" label={T('LegacyReport_Contact')} />
                                </Grid>
                                <Grid item xs={12} container spacing={1}>
                                    <Grid item>
                                        <label htmlFor="btn-upload1">
                                            <input
                                                id="btn-upload1"
                                                name="btn-upload1"
                                                style={{ display: 'none' }}
                                                type="file"
                                                onChange={selectFile1}
                                            />
                                            <Button
                                                className="btn-choose"
                                                variant="contained"
                                                color="secondary"
                                                component="span"
                                                style={{ height: 40 }}
                                            >
                                                {T('Common_Browse')}
                                            </Button>
                                        </label>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <FormFieldReadOnly
                                            label={''}
                                            value={selectedFile1?.name ?? T('Common_Browse')}
                                        />
                                        {errors.file1 && (
                                            <Typography color="error" variant="caption" style={{ marginLeft: 14 }}>
                                                {errors.file1}
                                            </Typography>
                                        )}
                                    </Grid>
                                    <Grid item>
                                        <label htmlFor="btn-upload2">
                                            <input
                                                id="btn-upload2"
                                                name="btn-upload2"
                                                style={{ display: 'none' }}
                                                type="file"
                                                onChange={selectFile2}
                                            />
                                            <Button
                                                className="btn-choose"
                                                variant="contained"
                                                color="secondary"
                                                component="span"
                                                style={{ height: 40 }}
                                            >
                                                {T('Common_Browse')}
                                            </Button>
                                        </label>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <FormFieldReadOnly
                                            label={''}
                                            value={selectedFile2?.name ?? T('Common_Browse')}
                                        />
                                        {errors.file2 && (
                                            <Typography color="error" variant="caption" style={{ marginLeft: 14 }}>
                                                {errors.file2}
                                            </Typography>
                                        )}
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sx={{ mt: 4, mb: 2 }}>
                                    <LoadButton
                                        disabled={!dirty || !isValid}
                                        variant="contained"
                                        type="submit"
                                        loading={sending}
                                    >
                                        {T('Common_Save')}
                                    </LoadButton>
                                </Grid>
                            </Grid>
                        </Form>
                    );
                }}
            </Formik>
            {/* 
            <DialogConfirmation
                title={T('LegcyReport_ConfirmDeleteTitle')}
                message={deleteDialogState?.message}
                show={deleteDialogState?.show}
                onClose={() => setDeleteDialogState(undefined)}
                onOk={deleteContract}
                warning
            /> */}
        </>
    );
};
