const isValidUsername = (username?: string): boolean => {
    if (!username) return false;

    return /^[a-zäöA-ZÄÖ][.a-zäöA-ZÄÖ\d]*$/.test(username);
};

const isValidUsernameLength = (username?: string): boolean => {
    if (!username) return false;

    return username.length >= 8;
};

const isValidPassword = (password?: string): boolean => {
    if (!password) return false;

    return /^(?=.*?[A-ZÅÄÖ])(?=.*?[a-zåäö])(?=.*?[\d])(?=.*?[@£$!%\-*?&_#^|(){}=¤§½"'/~]).{8,}$/.test(password);
};

const isValidPasswordLength = (password?: string): boolean => {
    if (!password) return false;

    return password.length >= 8;
};

export default {
    isValidPassword,
    isValidPasswordLength,
    isValidUsername,
    isValidUsernameLength
};
