import React, { FC, useContext, useEffect, useRef, useState } from 'react';
import { Typography, Grid } from '@mui/material';
import { AuthContext } from 'contexts/authContext';
import WaitScreen from 'components/common/WaitScreen';
import ContactService from 'services/contactService';
import { LanguageContext } from 'contexts/languageContext';
import axios, { CancelTokenSource } from 'axios';

interface ChurchNameProps {
    title?: boolean;
    plainText?: boolean;
    organizationId?: number;
}

const ChurchName: FC<ChurchNameProps> = ({ title, plainText, organizationId }: ChurchNameProps) => {
    const { T } = useContext(LanguageContext);
    const { user } = useContext(AuthContext);
    const [churchName, setChurchName] = useState('');
    const cancelRef = useRef<CancelTokenSource | null>(null);

    const orgId = organizationId ?? user?.homeOrganizationId;

    useEffect(() => {
        const getChurchName = async (id: number): Promise<void> => {
            cancelRef.current = axios.CancelToken.source();
            const org = await ContactService.getOrganizationAsync(id, cancelRef.current.token);
            if (cancelRef.current) {
                setChurchName(org.name);
            }
        };

        getChurchName(orgId ?? 0);
    }, [orgId]);

    return (
        <>
            {title && (
                <Grid item style={{ marginBottom: 20, marginTop: 30 }}>
                    {churchName === '' && <WaitScreen />}
                    <Typography variant="h3">{churchName}</Typography>
                </Grid>
            )}
            {plainText && churchName}
            {!title && !plainText && (
                <>
                    <Grid item>
                        <span className="church-name title">{T('Church_OwnChurch')}:&nbsp;</span>
                        <span className="church-name church">{churchName}</span>
                    </Grid>
                </>
            )}
        </>
    );
};

export default ChurchName;
