import React, { FC, ReactNode, useCallback, useContext, useEffect, useRef, useState } from 'react';
import {
    Box,
    Grid,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from '@mui/material';
import { Formik, Form, FormikProps } from 'formik';
import { LanguageContext, LanguageTextFunc } from 'contexts/languageContext';
import { MemberPosition } from 'types/sp-api';
import { useErrorHandler } from 'components/hooks/useErrorHandler';
import { useSuccessHandler } from 'components/hooks/useSuccessHandler';
import axios, { CancelTokenSource } from 'axios';
import { DialogOpenState, KeyValue, OrganizationType } from 'types/common';
import CodeSetService from 'services/codeSetService';
import DialogConfirmation from 'components/common/DialogConfirmation';
import DialogBase from 'components/common/DialogBase';
import FormFieldDropDown from 'components/common/FormFieldDropDown';
import ViewSubtitle from 'components/common/ViewSubtitle';
import OrganizationsService from 'services/organizationsService';
import * as Yup from 'yup';
import FormFieldDropDownMember from 'components/common/FormFieldDropDownMember';
import LoadButton from 'components/common/LoadButton';
import WaitScreen from 'components/common/WaitScreen';
import Utils from 'utils/personHelper';
import FormFieldReadOnly from 'components/common/FormFieldReadonly';
import FormFieldSwitch from 'components/common/FormFieldSwitch';
import ButtonAdd from 'components/common/ButtonAdd';
import { TableHeadCell } from 'components/common/TableHeadCell';
import RowMenu from '../common/RowMenu';
import config from 'config/config';
import constants from 'config/constants';

const getValidationSchema = (T: LanguageTextFunc) => {
    return Yup.object().shape({
        position: Yup.string().test('not-0-value', T('Common_Required'), (value) => value !== '0'),
        membershipId: Yup.string().test('not-0-value', T('Common_Required'), (value) => value !== '0')
    });
};

interface DeleteDialogState {
    show: boolean;
    message: string;
    memberPosition: MemberPosition;
}

interface OrganizationPositionsProps {
    organizationId: number;
    organizationType: number;
    boardName?: string;
}

const OrganizationPositions: FC<OrganizationPositionsProps> = ({
    organizationId,
    organizationType,
    boardName
}: OrganizationPositionsProps) => {
    const { T } = useContext(LanguageContext);
    const [positionsList, setPositionsList] = useState<MemberPosition[]>();
    const [openAddDialog, setOpenAddDialog] = useState<DialogOpenState>(false);
    const [deleteDialogState, setDeleteDialogState] = useState<DeleteDialogState>();
    const { handleError } = useErrorHandler();
    const [positionTitles, setPositionTitles] = useState<KeyValue[]>([]);
    const { handleSuccess } = useSuccessHandler();
    const [sendingReport, setSendingReport] = useState(false);
    const [reportSendDialog, setReportSendDialog] = useState(false);
    const cancelRef = useRef<CancelTokenSource | null>(null);

    const getNewPosition = (): MemberPosition => ({
        id: undefined,
        membershipId: 0,
        position: 0,
        beginDate: new Date()
    });

    const [selectedPosition, setSelectedPosition] = useState<MemberPosition>(getNewPosition());

    const getPositionTitles = useCallback(async (): Promise<void> => {
        try {
            !cancelRef.current && (cancelRef.current = axios.CancelToken.source());
            const posTitles = await CodeSetService.getCodeSetAsync('position', cancelRef.current.token);
            if (cancelRef.current) {
                setPositionTitles(posTitles.map((item) => ({ key: parseInt(item.key as string), value: item.value })));
            }
        } catch (error) {
            handleError(error, T('Error_OrganizationPositionTitleQueryFailed'));
        }
    }, [handleError, T]);

    const getOrganizationPosition = useCallback(
        async (id: number): Promise<void> => {
            try {
                !cancelRef.current && (cancelRef.current = axios.CancelToken.source());
                const organizationPositions = await OrganizationsService.getOrganizationPositionsAsync(
                    id,
                    cancelRef.current.token
                );

                const sortPositions = (a: MemberPosition, b: MemberPosition): number => {
                    if (!a.person || !b.person) return 0;
                    if (a.person.lastName > b.person.lastName) return 1;
                    if (a.person.lastName < b.person.lastName) return -1;
                    return 0;
                };

                if (cancelRef.current) {
                    organizationPositions.sort(sortPositions);
                    setPositionsList(organizationPositions);
                }
            } catch (error) {
                handleError(error, T('Error_OrganizationPositionQueryFailed'));
            }
        },
        [handleError, T]
    );

    useEffect(() => {
        getPositionTitles();
        getOrganizationPosition(organizationId);

        return () => {
            cancelRef.current && cancelRef.current.cancel();
            cancelRef.current = null;
        };
    }, [getOrganizationPosition, getPositionTitles, organizationId]);

    const refreshOrganizationPositions = () => {
        setPositionsList(undefined);
        // refresh list
        getOrganizationPosition(organizationId);
    };

    const deleteOrganizationPosition = async () => {
        if (!deleteDialogState) return;
        try {
            await OrganizationsService.deletePositionAsync(organizationId, deleteDialogState.memberPosition);
            handleSuccess(T('MembershipPosition_DeleteSuccess'));
            refreshOrganizationPositions();
        } catch (error) {
            handleError(error, T('MembershipPosition_DeleteFailed'));
        }
    };

    const saveOrganizationPosition = async (membershipPosition: MemberPosition) => {
        try {
            await OrganizationsService.savePositionAsync(organizationId, membershipPosition);
            handleSuccess(T('MembershipPosition_SaveSuccess'));
            refreshOrganizationPositions();
        } catch (error) {
            handleError(error, T('MembershipPosition_SaveFailed'));
        }
    };

    const addOrganizationPosition = () => {
        setSelectedPosition(getNewPosition());
        setOpenAddDialog(true);
    };

    const editOrganizationPosition = (membershipPosition: MemberPosition) => {
        setSelectedPosition(membershipPosition);
        setOpenAddDialog('edit');
    };

    const getTitle = (titleId: number) => {
        const title = positionTitles.find((item) => item.key?.toString() === titleId.toString())?.value ?? '';
        return T(`OrganizationPosition_${title}`);
    };

    const sendPositionReport = () => {
        setSendingReport(true);
        // TODO actual send position report
        // organizationId
        setSendingReport(false);
    };

    if (organizationId === -1) return null;

    return (
        <>
            <Grid container item xs={12} spacing={1}>
                <ViewSubtitle
                    small
                    title={T('Organization_PositionsListTitle').replace(
                        '{0}',
                        !boardName ? T('Organization_DefaultBoardName') : boardName
                    )}
                />
                <Grid item xs={12}>
                    <TableContainer component={Paper}>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableHeadCell>{T('Organization_PositionPerson')}</TableHeadCell>
                                    <TableHeadCell sx={{ width: '30%' }}>
                                        {T('Organization_PositionPosition')}
                                    </TableHeadCell>
                                    <TableHeadCell sx={{ width: '5%' }} />
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {positionsList && positionsList.length == 0 && (
                                    <TableRow>
                                        <TableCell>
                                            <Typography variant="body1" color="textSecondary">
                                                {T('Organization_NoPositionsSet')}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                )}
                                {positionsList &&
                                    positionsList.length > 0 &&
                                    positionsList.map(
                                        (item): ReactNode => (
                                            <TableRow key={item.id}>
                                                <TableCell>
                                                    <Typography>{Utils.FullName(item.person)}</Typography>
                                                    <Typography color="textSecondary" variant="body2">
                                                        {item.signatory
                                                            ? T('Organization_PositionSignatoryPerson')
                                                            : null}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell>{getTitle(item.position)}</TableCell>
                                                <TableCell sx={{ width: '5%' }}>
                                                    <RowMenu
                                                        onEdit={() => editOrganizationPosition(item)}
                                                        onDelete={() =>
                                                            setDeleteDialogState({
                                                                show: true,
                                                                message: T('Organization_Position_DeleteMessage')
                                                                    .replace('{0}', Utils.FullName(item.person))
                                                                    .replace('{1}', getTitle(item.position)),
                                                                memberPosition: item
                                                            })
                                                        }
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        )
                                    )}
                                {!positionsList && (
                                    <TableRow>
                                        <TableCell>
                                            <WaitScreen />
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
                <Grid item xs={12} container justifyContent="flex-end">
                    <ButtonAdd
                        variant="text"
                        color="secondary"
                        onClick={() => addOrganizationPosition()}
                        small
                        title={T('Common_Add')}
                    />
                </Grid>
                {organizationType === OrganizationType.LocalCommunity && config.CUSTOMER == constants.Customers.Shk && (
                    <Grid item xs={12} style={{ marginTop: 24 }}>
                        <LoadButton loading={sendingReport} onClick={() => setReportSendDialog(true)} disabled>
                            {T('Organization_SendPositionReport')}
                        </LoadButton>
                    </Grid>
                )}
                <Formik
                    initialValues={selectedPosition}
                    enableReinitialize
                    validationSchema={getValidationSchema(T)}
                    onSubmit={saveOrganizationPosition}
                >
                    {({ values, dirty, isValid, isSubmitting, submitForm }: FormikProps<MemberPosition>) => {
                        return (
                            <DialogBase
                                show={!!openAddDialog}
                                title={
                                    openAddDialog === 'edit'
                                        ? T('OrganizationPosition_EditDialogTitle')
                                        : T('OrganizationPosition_AddDialogTitle')
                                }
                                onOk={submitForm}
                                onClose={() => setOpenAddDialog(false)}
                                okDisabled={isSubmitting || !dirty || !isValid}
                                okTitle={T('Common_Save')}
                            >
                                <Form>
                                    <Grid container spacing={3} sx={{ pt: 1 }}>
                                        <Grid item xs={12}>
                                            {!values.id && (
                                                <FormFieldDropDownMember
                                                    organizationId={organizationId}
                                                    name="membershipId"
                                                    label={T('OrganizationPosition_FieldPerson')}
                                                    placeholder={T('OrganizationPosition_SelectPerson')}
                                                />
                                            )}
                                            {values.id && (
                                                <FormFieldReadOnly
                                                    label={T('OrganizationPosition_FieldPerson')}
                                                    value={Utils.FullName(values.person)}
                                                />
                                            )}
                                        </Grid>
                                        <Grid item xs={12}>
                                            <FormFieldDropDown
                                                name="position"
                                                label={T('OrganizationPosition_FieldTitle')}
                                                values={positionTitles.map((item) => ({
                                                    key: item.key,
                                                    text: T(`OrganizationPosition_${item.value}`)
                                                }))}
                                                placeholder={T('OrganizationPosition_SelectTitle')}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <FormFieldSwitch
                                                name="signatory"
                                                label={T('OrganizationPosition_FieldSignatory')}
                                            />
                                        </Grid>
                                        <Box m={2} />
                                    </Grid>
                                </Form>
                            </DialogBase>
                        );
                    }}
                </Formik>
                <DialogConfirmation
                    title={T('OrganizationPosition_ConfirmDelete')}
                    message={deleteDialogState?.message}
                    show={deleteDialogState?.show}
                    onClose={() => setDeleteDialogState(undefined)}
                    onOk={deleteOrganizationPosition}
                    warning
                />
                <DialogConfirmation
                    title={T('OrganizationPosition_ConfirmReportSendingTitle')}
                    message={T('OrganizationPosition_ConfirmReportSending')}
                    show={reportSendDialog}
                    onClose={() => setReportSendDialog(false)}
                    onOk={sendPositionReport}
                    warning
                />
            </Grid>
        </>
    );
};

export default OrganizationPositions;
