import axios, { CancelToken } from 'axios';
import { Municipality } from 'types/sp-api';
import LocalCache from './localCache';

class MunicipalityService {
    public async getMunicipalityNameAsync(number?: string): Promise<string> {
        if (!number) return '';

        const allCodes = await this.getAllMunicipalitiesAsync();
        const municipality = allCodes.find((item) => item.number === number);

        return municipality?.name ?? '';
    }

    public async getAllMunicipalitiesAsync(cancelToken?: CancelToken): Promise<Municipality[]> {
        const url = `municipalities/`;

        return LocalCache.cachedRequest<Municipality[]>(
            url,
            async () => {
                const axiosInstance = axios.create(); // create separate instance to avoid interceptors
                const response = await axiosInstance.get(url, { cancelToken: cancelToken });

                return response.data;
            },
            60 * 24 * 7 // 1 week
        );
    }
}

export default new MunicipalityService();
