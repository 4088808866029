import React, { FC, useContext } from 'react';
import { Box, Container, Divider, Grid, Link, Paper, Typography } from '@mui/material';
import { AccountBox } from '@mui/icons-material';
import { LanguageContext } from 'contexts/languageContext';
import BreadcrumbBar from './common/BreadcrumbBar';
import ViewTitle from './common/ViewTitle';
import EskoMatikainen from 'assets/images/EskoMatikainen.jpg';
import SirpaMatikainen from 'assets/images/SirpaMatikainen.jpg';
import config from 'config/config';
import constants from 'config/constants';

const ContactViewAdvk: FC = () => {
    const { T } = useContext(LanguageContext);

    return (
        <Container>
            <Grid container>
                <BreadcrumbBar />
                <ViewTitle title={T('Common_Contact')} icon={AccountBox} />
            </Grid>
            <Box m={2} />
            <Paper elevation={3} style={{ padding: 24 }}>
                <Grid container spacing={3}>
                    <Grid container item xs={6} spacing={3}>
                        <Grid item xs={12}>
                            <Typography variant="h6">{T('Contact_ContractContact')}</Typography>
                            <Typography variant="body2" color="textSecondary">
                                {T('Contact_ContractContactHelpText')}
                            </Typography>
                        </Grid>
                        <Grid container item xs={12}>
                            <Grid item xs={3}>
                                <img className="concact-person-image" alt="N N" />
                            </Grid>
                            <Grid item xs={9}>
                                N N
                                <br /> 050 1234 1234
                                <br />
                                <Link href="mailto:n.n@adventtikirkko.fi">n.n@adventtikirkko.fi</Link>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container item xs={6} spacing={3}>
                        <Grid item xs={12}>
                            <Typography variant="h6">{T('Contact_UserContact')}</Typography>
                            <Typography variant="body2" color="textSecondary">
                                {T('Contact_UserContactHelpText')}
                            </Typography>
                        </Grid>
                        <Grid container item xs={12}>
                            <Grid item xs={3}>
                                <img className="concact-person-image" alt="N N" />
                            </Grid>
                            <Grid item xs={9}>
                                N N
                                <br /> 045 123 1234
                                <br />
                                <Link href="mailto:n.n@adventtikirkko.fi">n.n@adventtikirkko.fi</Link>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider />
                    </Grid>
                    <Grid container item xs={6} spacing={3}>
                        <Grid item xs={12}>
                            <Typography variant="h6">{T('Contact_SystemContact')}</Typography>
                            <Typography variant="body2" color="textSecondary">
                                {T('Contact_SystemContactHelpText')}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            DataCodex Oy
                            <br /> Sampo Antila
                            <br /> 045 111 4505
                            <br />
                            <Link href="mailto:sampo.antila@datacodex.fi">sampo.antila@datacodex.fi</Link>
                        </Grid>
                    </Grid>
                    <Grid container item xs={6} spacing={3}>
                        <Grid item xs={12}></Grid>
                        <Grid item xs={12}></Grid>
                    </Grid>
                </Grid>
            </Paper>
        </Container>
    );
};

const ContactViewShk: FC = () => {
    const { T } = useContext(LanguageContext);

    return (
        <Container>
            <Grid container>
                <BreadcrumbBar />
                <ViewTitle title={T('Common_Contact')} icon={AccountBox} />
            </Grid>
            <Box m={2} />
            <Paper elevation={3} style={{ padding: 24 }}>
                <Grid container spacing={3}>
                    <Grid container item xs={6} spacing={3}>
                        <Grid item xs={12}>
                            <Typography variant="h6">{T('Contact_ContractContact')}</Typography>
                            <Typography variant="body2" color="textSecondary">
                                {T('Contact_ContractContactHelpText')}
                            </Typography>
                        </Grid>
                        <Grid container item xs={12}>
                            <Grid item xs={3}>
                                <img className="concact-person-image" src={EskoMatikainen} alt="Esko Matikainen" />
                            </Grid>
                            <Grid item xs={9}>
                                Esko Matikainen
                                <br /> 050 325 1918
                                <br />
                                <Link href="mailto:esko.matikainen@helluntaikirkko.fi">
                                    esko.matikainen@helluntaikirkko.fi
                                </Link>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container item xs={6} spacing={3}>
                        <Grid item xs={12}>
                            <Typography variant="h6">{T('Contact_UserContact')}</Typography>
                            <Typography variant="body2" color="textSecondary">
                                {T('Contact_UserContactHelpText')}
                            </Typography>
                        </Grid>
                        <Grid container item xs={12}>
                            <Grid item xs={3}>
                                <img className="concact-person-image" src={SirpaMatikainen} alt="Sirpa Matikainen" />
                            </Grid>
                            <Grid item xs={9}>
                                Sirpa Matikainen
                                <br /> 045 632 7848 (ti-to klo 10-14)
                                <br />
                                <Link href="mailto:sirpa.matikainen@helluntaikirkko.fi">
                                    sirpa.matikainen@helluntaikirkko.fi
                                </Link>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider />
                    </Grid>
                    <Grid container item xs={6} spacing={3}>
                        <Grid item xs={12}>
                            <Typography variant="h6">{T('Contact_SystemContact')}</Typography>
                            <Typography variant="body2" color="textSecondary">
                                {T('Contact_SystemContactHelpText')}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            DataCodex Oy
                            <br /> Sampo Antila
                            <br /> 045 111 4505
                            <br />
                            <Link href="mailto:sampo.antila@datacodex.fi">sampo.antila@datacodex.fi</Link>
                        </Grid>
                    </Grid>
                    <Grid container item xs={6} spacing={3}>
                        <Grid item xs={12}></Grid>
                        <Grid item xs={12}></Grid>
                    </Grid>
                </Grid>
            </Paper>
        </Container>
    );
};

const ContactView: FC = () => {
    if (config.CUSTOMER == constants.Customers.Advk) return <ContactViewAdvk />;

    return <ContactViewShk />;
};

export default ContactView;
