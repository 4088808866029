import React, { FC } from 'react';
import { TextField } from '@mui/material';

interface FormFieldReadOnlyProps {
    label: string;
    value?: string;
    tooltip?: string;
    rows?: number;
    error?: boolean;
    helperText?: string;
}

const FormFieldReadOnly: FC<FormFieldReadOnlyProps> = ({
    label,
    value,
    tooltip,
    rows,
    error,
    helperText
}: FormFieldReadOnlyProps) => {
    return (
        <TextField
            label={label}
            fullWidth
            size="small"
            value={value}
            disabled={!error}
            title={tooltip}
            variant="outlined"
            multiline={!!rows && rows > 0}
            rows={rows}
            error={error}
            helperText={helperText}
        />
    );
};

export default FormFieldReadOnly;
