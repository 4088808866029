import React, { FC, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { KeyValue } from 'types/common';
import axios, { CancelTokenSource } from 'axios';
import { LanguageContext } from 'contexts/languageContext';
import { useErrorHandler } from '../hooks/useErrorHandler';
import WaitScreen from './WaitScreen';
import CodeSetService from 'services/codeSetService';
import FormFieldDropDownLargeList from './FormFieldDropDownLargeList';

interface FormFieldDropDownCountriesProps {
    label: string;
    name: string;
    placeholder?: string;
    disabledValues?: number | string | (number | string)[];
    hiddenValues?: (number | string)[];
    autoFocus?: boolean;
    readonly?: boolean;
}

const FormFieldDropDownCountries: FC<FormFieldDropDownCountriesProps> = ({
    label,
    name,
    placeholder,
    disabledValues,
    hiddenValues,
    autoFocus,
    readonly
}: FormFieldDropDownCountriesProps) => {
    const [values, setValues] = useState<KeyValue[]>();
    const { T } = useContext(LanguageContext);
    const { handleError } = useErrorHandler();
    const cancelRef = useRef<CancelTokenSource | null>(null);

    const getCountries = useCallback(async (): Promise<void> => {
        try {
            !cancelRef.current && (cancelRef.current = axios.CancelToken.source());
            const countries = await CodeSetService.getCoutriesAsync(cancelRef.current.token);
            if (cancelRef.current) {
                setValues(countries);
            }
        } catch (error) {
            handleError(error, T('Error_CodeSetQueryFailed'));
        }
    }, [handleError, T]);

    useEffect(() => {
        getCountries();

        return () => {
            cancelRef.current && cancelRef.current.cancel();
            cancelRef.current = null;
        };
    }, [getCountries]);

    if (!values) return <WaitScreen />;

    return (
        <FormFieldDropDownLargeList
            name={name}
            label={label}
            values={values}
            placeholder={placeholder}
            disabledValues={disabledValues}
            hiddenValues={hiddenValues}
            autoFocus={autoFocus}
            readonly={readonly}
            listWidth={450}
        />
    );
};

export default FormFieldDropDownCountries;
