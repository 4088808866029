import React, { FC, useContext } from 'react';
import { Box, Grid, Link, Typography } from '@mui/material';
import { LanguageContext } from 'contexts/languageContext';
import { OrganizationContact } from 'types/sp-api';
import moment from 'moment';
import YoutubeLogo from 'assets/images/yt_logo.png';
import FacebookLogo from 'assets/images/f_logo.png';

export interface ChurchTabPublicProps {
    organization?: OrganizationContact;
}

const ChurchTabPublic: FC<ChurchTabPublicProps> = ({ organization }: ChurchTabPublicProps) => {
    const { T } = useContext(LanguageContext);

    if (!organization) {
        return null;
    }

    return (
        <Grid container spacing={3} alignItems="flex-start">
            <Grid container item xs={12} spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="h6">{organization.name}</Typography>
                </Grid>
                <Grid container item xs={12} spacing={0}>
                    <Grid item xs={12}>
                        <Typography variant="body1">{organization.street}</Typography>
                    </Grid>
                    <Grid item sx={{ mr: 1 }}>
                        <Typography variant="body1">{organization.zipCode}</Typography>
                    </Grid>
                    <Grid item xs={9}>
                        <Typography variant="body1">{organization.city}</Typography>
                        <Box m={2} />
                    </Grid>
                </Grid>
            </Grid>
            <Grid container item xs={12} alignItems="flex-start">
                <Grid container item xs={6} spacing={1}>
                    <Grid container item xs={12}>
                        <Grid item xs={3}>
                            <Typography variant="body1" color="textSecondary">
                                {T('Organization_FieldPhone')}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="body1">{organization.phone}</Typography>
                        </Grid>
                    </Grid>
                    <Grid container item xs={12}>
                        <Grid item xs={3}>
                            <Typography variant="body1" color="textSecondary">
                                {T('Organization_FieldEmail')}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="body1">
                                <Link href={`mailto:${organization.email}`}>{organization.email}</Link>
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container item xs={12}>
                        <Grid item xs={3}>
                            <Typography variant="body1" color="textSecondary">
                                {T('Organization_FieldWww')}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="body1">
                                <Link
                                    href={
                                        organization.www?.startsWith('http')
                                            ? organization.www
                                            : `http://${organization.www}`
                                    }
                                    target="_blank"
                                >
                                    {organization.www}
                                </Link>
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container item xs={12}>
                        <Grid item xs={3}>
                            <Typography variant="body1" color="textSecondary">
                                {T('Organization_FieldBusinessId')}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="body1">{organization.businessId}</Typography>
                        </Grid>
                    </Grid>
                    <Grid container item xs={12}>
                        <Grid item xs={3}>
                            <Typography variant="body1" color="textSecondary">
                                {T('Organization_FieldFounded')}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="body1">
                                {moment(organization.foundationDate).year() > 1900
                                    ? moment(organization.foundationDate).year()
                                    : ''}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container item xs={12} spacing={1}>
                        <Grid item xs={3}>
                            <Typography variant="body1" color="textSecondary">
                                {T('Organization_FieldMembers')}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="body1">{organization.memberCount}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container item xs={6} spacing={1}>
                    <Grid container item xs={12}>
                        <Grid item xs={3}>
                            <Typography variant="body1" color="textSecondary">
                                {T('Organization_FieldContactPerson')}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="body1">{organization.contactPerson}</Typography>
                        </Grid>
                    </Grid>
                    <Grid container item xs={12}>
                        <Grid item xs={3}>
                            <Typography variant="body1" color="textSecondary">
                                {T('Organization_FieldPhone')}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="body1">{organization.contactPhone}</Typography>
                        </Grid>
                    </Grid>
                    <Grid container item xs={12}>
                        <Grid item xs={3}>
                            <Typography variant="body1" color="textSecondary">
                                {T('Organization_FieldEmail')}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Link href={`mailto:${organization.contactEmail}`}>{organization.contactEmail}</Link>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container item xs={12}>
                <Grid container item xs={12} spacing={3} alignItems="center">
                    {organization.youtube && (
                        <Grid item xs={2}>
                            <Link href={`https://youtube.com/${organization.youtube}`} target="_blank">
                                <img className="concact-youtube-logo" src={YoutubeLogo} alt="YouTube" />
                            </Link>
                        </Grid>
                    )}
                    {organization.facebook && (
                        <Grid item xs={2}>
                            <Link href={`https://facebook.com/${organization.facebook}`} target="_blank">
                                <img className="concact-facebook-logo" src={FacebookLogo} alt="Facebook" />
                            </Link>
                        </Grid>
                    )}
                </Grid>
            </Grid>
        </Grid>
    );
};

export default ChurchTabPublic;
