import React, { ChangeEvent, FC, useContext, useEffect, useState } from 'react';
import { LanguageContext } from 'contexts/languageContext';
import { Button, Grid, Typography } from '@mui/material';
import { CredentialApplicationAttachment } from 'types/sp-api';
import DialogBase from 'components/common/DialogBase';
import { Formik, FormikProps, Form } from 'formik';
import FormFieldDropDownCodeSet from 'components/common/FormFieldDropDownCodeSet';
import { useErrorHandler } from 'components/hooks/useErrorHandler';
import { useSuccessHandler } from 'components/hooks/useSuccessHandler';
import CredentialApplicationService from 'services/credentialApplicationService';

interface DialogAddAttachmentProps {
    applicationId: number;
    show?: boolean;
    onClose: () => void;
    onUpdate: () => void;
}

const DialogAddAttachment: FC<DialogAddAttachmentProps> = ({
    applicationId,
    show,
    onClose,
    onUpdate
}: DialogAddAttachmentProps) => {
    const { T } = useContext(LanguageContext);
    const [attachmentFiles] = useState<File[]>([]);
    const [refresh, setRefresh] = useState(false);
    const { handleSuccess } = useSuccessHandler();
    const { handleError } = useErrorHandler();

    const saveAttachment = async (attachment: CredentialApplicationAttachment) => {
        try {
            await CredentialApplicationService.saveAttachmentAsync(applicationId, attachment, attachmentFiles);
            onUpdate();
            handleSuccess(T('CredentialApplication_AttachmentAddSuccess'));
        } catch (error) {
            handleError(error, T('CredentialApplication_AttachmentAddFailed'));
        }
    };

    const selectFile = (event: ChangeEvent<HTMLInputElement>): void => {
        if (!event.currentTarget.files) return;

        if (attachmentFiles.length > 0) {
            attachmentFiles[0] = event.currentTarget.files[0];
        } else {
            attachmentFiles.push(event.currentTarget.files[0]);
        }
        setRefresh(!refresh);
    };

    useEffect(() => {
        if (show) {
            attachmentFiles.length = 0;
        }
    }, [show]);

    return (
        <Formik
            initialValues={{
                type: 0,
                applicationId: applicationId
            }}
            onSubmit={() => {
                // DO nothing
            }}
            enableReinitialize
        >
            {({ dirty, isValid, isSubmitting, resetForm, values }: FormikProps<CredentialApplicationAttachment>) => (
                <DialogBase
                    show={show}
                    title={T('CredentialApplication_AddAttachmentTitle')}
                    onOk={async () => {
                        saveAttachment(values);
                        resetForm();
                        onClose();
                    }}
                    onClose={() => onClose()}
                    okDisabled={isSubmitting || !dirty || !isValid}
                    okTitle={T('Common_Save')}
                >
                    <Form>
                        <Grid container spacing={3} sx={{ pt: 1 }} style={{ paddingBottom: 0 }}>
                            <Grid item xs={6}>
                                <FormFieldDropDownCodeSet
                                    name="type"
                                    codeSet="credentialAttachment"
                                    label=""
                                    placeholder={T('CredentialAttachment_SelectType')}
                                />
                            </Grid>
                            <Grid container item xs={12} flexDirection="row" alignItems="center" wrap="nowrap">
                                <Grid item xs={4}>
                                    <label htmlFor="btn-upload">
                                        <input
                                            id="btn-upload"
                                            name="btn-upload"
                                            style={{ display: 'none' }}
                                            type="file"
                                            onChange={selectFile}
                                        />
                                        <Button
                                            className="btn-choose"
                                            variant="contained"
                                            color="secondary"
                                            component="span"
                                            size="small"
                                            disabled={!values.type}
                                        >
                                            {T('Common_Browse')}
                                        </Button>
                                    </label>
                                </Grid>
                                <Grid item xs={8} overflow="clip">
                                    <Typography
                                        variant="body2"
                                        color="secondary"
                                        title={attachmentFiles.length > 0 ? attachmentFiles[0].name : ''}
                                    >
                                        {attachmentFiles.length > 0 && attachmentFiles[0].name}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Form>
                </DialogBase>
            )}
        </Formik>
    );
};

export default DialogAddAttachment;
