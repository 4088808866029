import React, { FC } from 'react';

interface SuccessToastProps {
    title: string;
    message?: string;
}

const SuccessToast: FC<SuccessToastProps> = ({ title, message }: SuccessToastProps) => (
    <>
        <div>
            {title}
            {message && (
                <>
                    <br />
                    {message}
                </>
            )}
        </div>
    </>
);

export default SuccessToast;
