import React, { ChangeEvent, FC, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Container, Grid, Paper, Tab, Tabs } from '@mui/material';
import axios, { CancelTokenSource } from 'axios';
import PersonsService from 'services/personsService';
import WaitScreen from 'components/common/WaitScreen';
import { Person } from 'types/sp-api';
import { useParams } from 'react-router-dom';
import BreadcrumbBar from 'components/common/BreadcrumbBar';
import ViewTitle from 'components/common/ViewTitle';
import { Person as PersonIcon } from '@mui/icons-material';
import { LanguageContext } from 'contexts/languageContext';
import TabPanel from 'components/common/TabPanel';
import { useErrorHandler } from 'components/hooks/useErrorHandler';
import { AddressType, TabItem } from 'types/common';
import PersonUtils from 'utils/personHelper';

const CredentialRegisterItem: FC = () => {
    const [person, setPerson] = useState<Person>();
    const [selectedTab, setSelectedTab] = useState(0);
    const [, setHasMemberships] = useState(false);
    const { T } = useContext(LanguageContext);
    const { handleError } = useErrorHandler();
    const cancelRef = useRef<CancelTokenSource | null>(null);
    const { personId } = useParams<'personId'>();

    const getPerson = useCallback(
        async (id: number): Promise<void> => {
            try {
                cancelRef.current = axios.CancelToken.source();
                const personData = await PersonsService.getPersonAsync(id, cancelRef.current.token);
                if (cancelRef.current) {
                    setPerson(personData);
                }

                const memberships = await PersonsService.getPersonMembershipsAsync(id, cancelRef.current.token);
                if (cancelRef.current) {
                    setHasMemberships(memberships.length > 0);
                }
            } catch (error) {
                handleError(error, T('Error_PersonQueryFailed'));
            }
        },
        [handleError, T]
    );

    useEffect(() => {
        if (personId !== 'new' && personId) {
            getPerson(parseInt(personId));
        } else {
            // init new person
            setPerson({
                id: -1,
                lastName: '',
                firstName: '',
                ssn: '',
                address: { street: '', zipCode: '', country: 'Suomi', type: AddressType.Domestic }
            });
        }
        return () => {
            cancelRef.current && cancelRef.current.cancel();
            cancelRef.current = null;
        };
    }, [getPerson, personId]);

    const handleChange = (_: ChangeEvent<unknown>, newValue: number) => {
        setSelectedTab(newValue);
    };

    if (!person)
        return (
            <Container>
                <Grid container>
                    <BreadcrumbBar />
                    <ViewTitle title={T('Common_Loading')} icon={PersonIcon} />
                </Grid>
                <WaitScreen />
            </Container>
        );

    const tabs: TabItem[] = [
        { id: 0, title: 'Basic', content: <></> },
        {
            id: 1,
            title: 'Membership',
            content: <></>
        }
    ];

    return (
        <Container>
            <Grid container>
                <BreadcrumbBar />
                <ViewTitle
                    title={PersonUtils.FullName(person)}
                    iconTooltip={T('Person_IconTooltip').replace('{0}', person.id?.toString() ?? '')}
                    icon={PersonIcon}
                />
            </Grid>
            <Paper elevation={3}>
                <Tabs
                    value={selectedTab}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="scrollable"
                    scrollButtons="auto"
                    onChange={handleChange}
                >
                    {tabs
                        .filter((tab) => !tab.hidden)
                        .map((item, index) => (
                            <Tab
                                label={T(`PersonTab_${item.title}`)}
                                key={item.id}
                                value={item.id}
                                disabled={index > 0 && personId === 'new'}
                            />
                        ))}
                </Tabs>
                {tabs.map((item) => (
                    <TabPanel key={item.id} value={selectedTab} index={item.id}>
                        {item.content}
                    </TabPanel>
                ))}
            </Paper>
        </Container>
    );
};

export default CredentialRegisterItem;
