import React, { FC, useContext, useState } from 'react';
import { LanguageContext } from 'contexts/languageContext';
import {
    TableContainer,
    Paper,
    Table,
    TableBody,
    TableRow,
    TableCell,
    TableHead,
    Grid,
    Typography,
    CircularProgress,
    IconButton
} from '@mui/material';
import WaitScreen from 'components/common/WaitScreen';
import { CredentialApplication, CredentialApplicationNote, CredentialResult } from 'types/sp-api';
import CredentialService from 'services/credentialService';
import CredentialApplicationService from 'services/credentialApplicationService';
import ButtonDownload from 'components/common/ButtonDownload';
import DownloadFile from 'components/common/DownloadFile';
import Utils from 'utils/typeHelper';
import { TableHeadCell } from 'components/common/TableHeadCell';
import { DeleteDialogState } from 'types/common';
import ButtonDelete from 'components/common/ButtonDelete';
import StarBorderPurple500Icon from '@mui/icons-material/StarBorderPurple500';
import DialogConfirmation from 'components/common/DialogConfirmation';
import { useErrorHandler } from 'components/hooks/useErrorHandler';
import { useSuccessHandler } from 'components/hooks/useSuccessHandler';
import ReportService from 'services/reportService';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { useNavigate } from 'react-router-dom';
import ButtonEdit from 'components/common/ButtonEdit';
import DialogCredentialApplicationEdit from './DialogCredentialApplicationEdit';
import moment from 'moment';
import FlagOutlinedIcon from '@mui/icons-material/FlagOutlined';
import FlagMenu from './FlagMenu';
import { useWarningHandler } from 'components/hooks/useWarningHandler';

export interface EditDialogState {
    show: boolean;
    personId: number;
    applicationId: number;
    personUnsure: boolean;
}

interface CredentialApplicationListProps {
    applicationList?: CredentialApplication[];
    updateApplicationList: () => void;
    loading?: boolean;
}

const CredentialApplicationList: FC<CredentialApplicationListProps> = ({
    applicationList,
    updateApplicationList,
    loading
}: CredentialApplicationListProps) => {
    const { T } = useContext(LanguageContext);
    const [deleteDialogState, setDeleteDialogState] = useState<DeleteDialogState>();
    const [downloadFile, setDownloadFile] = useState<{ id: number; filename: string }>();
    const { handleSuccess } = useSuccessHandler();
    const { handleError } = useErrorHandler();
    const { handleWarning } = useWarningHandler();
    const navigate = useNavigate();
    const [editDialogState, setEditDialogState] = useState<EditDialogState>();

    const deleteCredentialApplication = async () => {
        try {
            await CredentialApplicationService.deleteApplicationAsync(deleteDialogState?.id);
            handleSuccess(T('CredentialApplication_DeleteSuccess'));
            updateApplicationList();
        } catch (error) {
            handleError(error, T('CredentialApplication_DeleteFailed'));
        }
    };

    const openPersonCredetialsByApplicationId = async (id?: number) => {
        try {
            const result = await CredentialService.getPersonByCredentialApplicationAsync(id);
            showPersonQueryMessage(result);
            navigate(`/persons/${result.personId}`);
            updateApplicationList();
        } catch (error) {
            handleError(error, T('Credential_PersonSearchFailed'));
        }
    };

    const editPersonCredetialsByApplicationId = async (id?: number) => {
        try {
            const result = await CredentialService.getPersonByCredentialApplicationAsync(id);
            showPersonQueryMessage(result);
            if (result.personId && id) {
                setEditDialogState({
                    show: true,
                    personId: result.personId,
                    applicationId: id,
                    personUnsure: result.multipleFound ?? false
                });
            }
        } catch (error) {
            handleError(error, T('Credential_PersonSearchFailed'));
        }
    };

    const showPersonQueryMessage = (result: CredentialResult) => {
        if (result.created) {
            handleSuccess(T('Credential_PersonCreatedByApplication'));
        }
        if (result.multipleFound) {
            handleWarning(T('Credential_MultiplePersonByApplication'));
        }
    };

    const fetchFile = (appId: number, filename: string) => {
        return ReportService.generateCredentialApplicationReportAsync(appId, filename, 'fi');
    };

    const sortNotes = (a: CredentialApplicationNote, b: CredentialApplicationNote) => {
        if (!a || !b) return 0;
        if (!a.notesDate || !b.notesDate) return 0;
        if (!a.lastModifiedDate || !b.lastModifiedDate) return 0;

        if (a.notesDate > b.notesDate) return -1;
        if (a.notesDate < b.notesDate) return 1;

        if (b.lastModifiedDate > a.lastModifiedDate) return 1;
        if (b.lastModifiedDate < a.lastModifiedDate) return -1;

        return 0;
    };

    if (!applicationList) {
        return <WaitScreen />;
    }

    return (
        <>
            <Grid item xs={12}>
                <TableContainer component={Paper}>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableHeadCell sx={{ width: 20, pr: 0, pl: 1 }}></TableHeadCell>
                                <TableHeadCell
                                    sx={{ width: 20, pr: 0, pl: 1, lineHeight: '1rem', textAlign: 'center' }}
                                >
                                    <FlagOutlinedIcon fontSize="small" titleAccess="Merkitse lipulla hakemuksia" />
                                </TableHeadCell>
                                <TableHeadCell sx={{ width: '23%', pl: 1 }}>{T('Common_Name')}</TableHeadCell>
                                <TableHeadCell sx={{ width: '23%' }}>{T('Common_Church')}</TableHeadCell>
                                <TableHeadCell sx={{ width: 100 }}>{T('CredentialApplication_Type')}</TableHeadCell>
                                <TableHeadCell sx={{ width: 40, textAlign: 'center' }}>{T('Käsitelty')}</TableHeadCell>
                                <TableHeadCell sx={{ width: 80, textAlign: 'center' }} />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {!applicationList && (
                                <TableRow>
                                    <TableCell>
                                        <WaitScreen />
                                    </TableCell>
                                </TableRow>
                            )}
                            {applicationList &&
                                applicationList.length > 0 &&
                                applicationList.map((row) => (
                                    <TableRow key={row.id} hover>
                                        <TableCell sx={{ textAlign: 'center', pr: 0, pl: 1 }}>
                                            {row.newApplication && (
                                                <StarBorderPurple500Icon
                                                    fontSize="small"
                                                    color="info"
                                                    titleAccess={T('CredentialApplication_NewApplication')}
                                                />
                                            )}
                                        </TableCell>
                                        <TableCell sx={{ textAlign: 'center', pr: 0, pl: 1 }}>
                                            <FlagMenu
                                                flag={row.flag}
                                                applicationId={row.id}
                                                updateList={updateApplicationList}
                                                loading={loading}
                                            />
                                        </TableCell>
                                        <TableCell sx={{ pl: 1 }}>{`${row.lastName} ${row.firstName}`}</TableCell>
                                        <TableCell>{row.organization?.name}</TableCell>
                                        <TableCell>{T(Utils.CredentialApplicationTypeToName(row.type))}</TableCell>
                                        <TableCell sx={{ textAlign: 'right' }}>
                                            {row.notes &&
                                                row.notes.length > 0 &&
                                                moment(row.notes.sort(sortNotes)[0].notesDate).format('l')}
                                        </TableCell>
                                        <TableCell>
                                            <Grid container>
                                                <Grid item xs={3}>
                                                    <ButtonEdit
                                                        small
                                                        onClick={() => editPersonCredetialsByApplicationId(row.id)}
                                                        tooltip={T('CredentialApplication_EditApplication')}
                                                    ></ButtonEdit>
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <IconButton
                                                        size="small"
                                                        title={T('Credential_OpenPerson')}
                                                        onClick={() => openPersonCredetialsByApplicationId(row.id)}
                                                    >
                                                        <OpenInNewIcon fontSize="small" />
                                                    </IconButton>
                                                </Grid>
                                                <Grid item xs={3}>
                                                    {downloadFile?.id == row.id ? (
                                                        <CircularProgress size={20} color="primary" />
                                                    ) : (
                                                        <ButtonDownload
                                                            onClick={() => {
                                                                let filename = T(
                                                                    'Report_CredentialApplicationReportFile'
                                                                );
                                                                filename = filename.replace(
                                                                    '{0}',
                                                                    `${row.lastName} ${row.firstName}`
                                                                );

                                                                setDownloadFile({
                                                                    id: row.id ?? 0,
                                                                    filename: filename
                                                                });
                                                            }}
                                                            small
                                                            disabled={!!downloadFile}
                                                        />
                                                    )}
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <ButtonDelete
                                                        small
                                                        className="danger-btn"
                                                        onClick={() =>
                                                            setDeleteDialogState({
                                                                show: true,
                                                                message: T(
                                                                    'CredentialApplication_ConfirmDeleteMessage'
                                                                ).replace('{0}', `${row.lastName} ${row.firstName}`),
                                                                id: row.id ?? 0
                                                            })
                                                        }
                                                    >
                                                        {T('Common_Delete')}
                                                    </ButtonDelete>
                                                </Grid>
                                            </Grid>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            {applicationList && applicationList.length === 0 && (
                                <TableRow>
                                    <TableCell>
                                        <Typography variant="body1" color="textSecondary">
                                            {T('CredentialApplication_NoCredentialApplications')}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
            <DialogCredentialApplicationEdit
                state={editDialogState}
                onClose={() => setEditDialogState(undefined)}
                updateApplicationList={updateApplicationList}
            />
            <DialogConfirmation
                title={T('CredentialApplication_ConfirmDeleteTitle')}
                message={deleteDialogState?.message}
                show={deleteDialogState?.show}
                onClose={() => setDeleteDialogState(undefined)}
                onOk={deleteCredentialApplication}
                warning
            />
            {downloadFile && (
                <DownloadFile
                    fetchFile={() => fetchFile(downloadFile.id, downloadFile.filename)}
                    filename={downloadFile.filename}
                    onSuccess={() => setDownloadFile(undefined)}
                />
            )}
        </>
    );
};

export default CredentialApplicationList;
