import axios, { CancelToken } from 'axios';

class AdminService {
    public async uploadAddressFileAsync(file?: File, cancelToken?: CancelToken): Promise<void> {
        if (!file) return;

        const formData = new FormData();
        formData.append('file', file);

        await axios.post(`admin/address-import`, formData, {
            headers: { 'content-type': 'multipart/form-data' },
            cancelToken: cancelToken
        });
    }
}

export default new AdminService();
