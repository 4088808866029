import React, { FC, useContext } from 'react';
import config from 'config/config';
import { LanguageContext } from 'contexts/languageContext';

interface BadgeDiagonalProps {
    testOnly?: boolean;
}

const BadgeDiagonal: FC<BadgeDiagonalProps> = ({ testOnly }: BadgeDiagonalProps) => {
    const { T } = useContext(LanguageContext);
    const show = testOnly ? config.STAGE != 'production' : true;

    return (
        <>
            {show && (
                <div className="project">
                    <div className="diagonal badge yellow">{T('Common_TestEnvironment')}</div>
                </div>
            )}
        </>
    );
};

export default BadgeDiagonal;
