import { createContext } from 'react';
import { AuthContextInterface } from 'types/auth';

const initialState: AuthContextInterface = {
    setUser: () => null,
    hasRole: () => false,
    isAdmin: () => false,
    isAdminOrClerk: () => false,
    isAuthenticated: () => false,
    logout: () => null
};

const ctxt = createContext<AuthContextInterface>(initialState);
ctxt.displayName = 'AuthContext';

export const AuthContext = ctxt;
export const AuthProvider = ctxt.Provider;
export const AuthConsumer = ctxt.Consumer;
