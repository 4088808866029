import React, { FC, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { DbCodeSets, KeyValue } from 'types/common';
import CodeSetService from 'services/codeSetService';
import axios, { CancelTokenSource } from 'axios';
import { LanguageContext } from 'contexts/languageContext';
import FormFieldDropDown from './FormFieldDropDown';
import { useErrorHandler } from '../hooks/useErrorHandler';
import WaitScreen from './WaitScreen';
import Utils from 'utils/typeHelper';

interface FormFieldDropDownCodeSetProps {
    label: string;
    name: string;
    codeSet: DbCodeSets;
    placeholder?: string;
    disabledValues?: number | string | (number | string)[];
    hiddenValues?: (number | string)[];
    autoFocus?: boolean;
    readonly?: boolean;
    keyOrder?: boolean;
}

const FormFieldDropDownCodeSet: FC<FormFieldDropDownCodeSetProps> = ({
    label,
    name,
    codeSet,
    placeholder,
    disabledValues,
    hiddenValues,
    autoFocus,
    readonly,
    keyOrder
}: FormFieldDropDownCodeSetProps) => {
    const [values, setValues] = useState<KeyValue[]>([]);
    const { T } = useContext(LanguageContext);
    const { handleError } = useErrorHandler();
    const cancelRef = useRef<CancelTokenSource | null>(null);

    const getCodeSet = useCallback(
        async (codes: DbCodeSets): Promise<void> => {
            try {
                !cancelRef.current && (cancelRef.current = axios.CancelToken.source());
                const codeValues = await CodeSetService.getCodeSetAsync(codes, cancelRef.current.token);
                if (cancelRef.current) {
                    const keyValues = codeValues.map((item) => ({
                        key: Utils.IsNumber(item.key) ? item.key : parseInt(item.key?.toString() ?? '0'),
                        text: T(`CodeSet_${codes}_${item.value}`)
                    }));
                    if (keyOrder) {
                        keyValues.sort((a: KeyValue, b: KeyValue): number => {
                            if (!a.key || !b.key) return 0;
                            if (a.key > b.key) return 1;
                            if (a.key < b.key) return -1;
                            return 0;
                        });
                    } else {
                        keyValues.sort((a: KeyValue, b: KeyValue): number => {
                            if (!a.text || !b.text) return 0;
                            if (a.text > b.text) return 1;
                            if (a.text < b.text) return -1;
                            return 0;
                        });
                    }
                    setValues(keyValues);
                }
            } catch (error) {
                handleError(error, T('Error_CodeSetQueryFailed'));
            }
        },
        [handleError, T]
    );

    const getCountries = useCallback(async (): Promise<void> => {
        try {
            !cancelRef.current && (cancelRef.current = axios.CancelToken.source());
            const countries = await CodeSetService.getCoutriesAsync(cancelRef.current.token);
            if (cancelRef.current) {
                setValues(countries);
            }
        } catch (error) {
            handleError(error, T('Error_CodeSetQueryFailed'));
        }
    }, [handleError, T]);

    useEffect(() => {
        if (codeSet === 'nationality') {
            getCountries();
        } else {
            getCodeSet(codeSet);
        }

        return () => {
            cancelRef.current && cancelRef.current.cancel();
            cancelRef.current = null;
        };
    }, [codeSet, getCodeSet, getCountries]);

    if (values.length === 0) return <WaitScreen />;

    return (
        <FormFieldDropDown
            name={name}
            label={label}
            values={values}
            placeholder={placeholder}
            disabledValues={disabledValues}
            hiddenValues={hiddenValues}
            autoFocus={autoFocus}
            readonly={readonly}
        />
    );
};

export default FormFieldDropDownCodeSet;
