import React, { FC, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Button, ButtonGroup, ClickAwayListener, Grid, Grow, MenuItem, MenuList, Paper, Popper } from '@mui/material';
import DownloadFile from 'components/common/DownloadFile';
import LoadButton from 'components/common/LoadButton';
import { LanguageContext } from 'contexts/languageContext';
import ReportService from 'services/reportService';
import { Form, Formik, FormikProps } from 'formik';
import axios, { CancelTokenSource, AxiosResponse } from 'axios';
import { DbCodeSets, KeyValue, MembersListReportForm, ReportType } from 'types/common';
import { useErrorHandler } from 'components/hooks/useErrorHandler';
import CodeSetService from 'services/codeSetService';
import Utils from 'utils/typeHelper';
import MemberTagService from 'services/memberTagService';
import { Tag } from 'types/sp-api';
import copy from 'copy-to-clipboard';
import { useSuccessHandler } from 'components/hooks/useSuccessHandler';
import { useWarningHandler } from 'components/hooks/useWarningHandler';
import ReportFilter from './ReportFilter';
import ReportInclude from './ReportInclude';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ReportOrder from './ReportOrder';

interface ReportMembersListProps {
    organizationId: number;
    organizationName: string;
    organizationType: number;
}

const ReportMembersList: FC<ReportMembersListProps> = ({
    organizationId,
    organizationName,
    organizationType
}: ReportMembersListProps) => {
    const [downloadFile, setDownloadFile] = useState(false);
    const [downloadFileCsv, setDownloadFileCsv] = useState(false);
    const [downloadFileSticker3x8, setDownloadFileSticker3x8] = useState(false);
    const [downloadFileSticker3x10, setDownloadFileSticker3x10] = useState(false);
    const { T } = useContext(LanguageContext);
    const [memberTagTitles, setMemberTagTitles] = useState<Tag[]>();
    const [membershipTypes, setMembershipTypes] = useState<KeyValue[]>();
    const { handleError } = useErrorHandler();
    const { handleWarning } = useWarningHandler();
    const { handleSuccess } = useSuccessHandler();
    const cancelRef = useRef<CancelTokenSource | null>(null);
    const [copyToClipboard, setCopyToClipboard] = useState(false);
    const [menuOpen, setMenuOpen] = useState(false);
    const anchorRef = useRef(null);

    const getCodeSet = useCallback(
        async (codes: DbCodeSets): Promise<void> => {
            try {
                !cancelRef.current && (cancelRef.current = axios.CancelToken.source());
                const codeValues = await CodeSetService.getCodeSetAsync(codes, cancelRef.current.token);
                if (cancelRef.current) {
                    const keyValues = codeValues.map((item) => ({
                        key: Utils.IsNumber(item.key) ? item.key : parseInt(item.key?.toString() ?? '0'),
                        text: T(`CodeSet_${codes}_${item.value}`)
                    }));
                    keyValues.sort((a: KeyValue, b: KeyValue): number => {
                        if (!a.key || !b.key) return 0;
                        if (a.key > b.key) return 1;
                        if (a.key < b.key) return -1;
                        return 0;
                    });

                    setMembershipTypes(keyValues);
                }
            } catch (error) {
                handleError(error, T('Error_CodeSetQueryFailed'));
            }
        },
        [handleError, T]
    );

    const tagSorter = (a: Tag, b: Tag): number => {
        if (!a.name || !b.name) return 0;
        if (a.name > b.name) return 1;
        if (a.name < b.name) return -1;
        return 0;
    };

    const getMemberTagTitles = useCallback(
        async (orgId: number): Promise<void> => {
            try {
                !cancelRef.current && (cancelRef.current = axios.CancelToken.source());
                const orgMemberTagTitles = await MemberTagService.getMemberTagTitlesAsync(
                    orgId,
                    cancelRef.current.token
                );
                if (cancelRef.current) {
                    orgMemberTagTitles.sort(tagSorter);
                    setMemberTagTitles(orgMemberTagTitles);
                }
            } catch (error) {
                handleError(error, T('Error_OrganizationTagTitleQueryFailed'));
            }
        },
        [handleError, T]
    );

    useEffect(() => {
        getCodeSet('membershipType');
        getMemberTagTitles(organizationId);

        return () => {
            cancelRef.current && cancelRef.current.cancel();
            cancelRef.current = null;
        };
    }, [getCodeSet, getMemberTagTitles, organizationId]);

    return (
        <Formik
            initialValues={{
                include: { phone: false, email: false, birthDate: false, age: false },
                filter: { familyContacts: false, membershipTypes: [], memberTags: [], communityTags: [], gender: '' },
                order: 'name',
                filename: ''
            }}
            onSubmit={() => {
                /* do nothing */
            }}
            enableReinitialize
        >
            {({ values, resetForm }: FormikProps<MembersListReportForm>) => {
                const fetchFile = async (type: ReportType): Promise<AxiosResponse> => {
                    return ReportService.memberListAsync(organizationId, values, type);
                };

                const membersToClipboard = async () => {
                    setCopyToClipboard(true);

                    const emails = await ReportService.memberListEmailsOnlyAsync(organizationId, values);
                    if (emails.length === 0) {
                        handleWarning(T('Report_NoEmailsToCopyClipboard'));
                    } else {
                        // copy to clipboard
                        copy(emails.join(','), {
                            format: 'text/plain',
                            onCopy: () => {
                                handleSuccess(T('Report_EmailsCopiedToClipboard'));
                            }
                        });
                    }

                    setCopyToClipboard(false);
                };

                return (
                    <Form>
                        <Grid container item xs={12} alignContent="flex-start" spacing={3}>
                            <Grid item xs={6}>
                                <ReportFilter
                                    name={'filter'}
                                    membershipTypes={membershipTypes}
                                    memberTagTitles={memberTagTitles}
                                    orgType={organizationType}
                                />
                            </Grid>
                            <Grid container item xs={6} alignContent="flex-start" spacing={3}>
                                <Grid item xs={12}>
                                    <ReportInclude name={'include'} />
                                </Grid>
                                <Grid item xs={12}>
                                    <ReportOrder name={'order'} />
                                </Grid>
                            </Grid>
                            <Grid container item xs={12} spacing={3} justifyContent="space-between">
                                <Grid item>
                                    <LoadButton
                                        onClick={() => {
                                            setDownloadFile(true);
                                        }}
                                        loading={downloadFile}
                                    >
                                        {T('Report_MembersListBtn')}
                                    </LoadButton>
                                    {downloadFile && (
                                        <DownloadFile
                                            fetchFile={() => fetchFile('pdf')}
                                            filename={T('Report_MembersListPdfFile').replace('{0}', organizationName)}
                                            onSuccess={() => setDownloadFile(false)}
                                        />
                                    )}
                                </Grid>
                                <Grid item>
                                    <LoadButton
                                        onClick={() => {
                                            setDownloadFileCsv(true);
                                        }}
                                        loading={downloadFileCsv}
                                    >
                                        {T('Report_MembersListCsvBtn')}
                                    </LoadButton>
                                    {downloadFileCsv && (
                                        <DownloadFile
                                            fetchFile={() => fetchFile('csv')}
                                            filename={T('Report_MembersListCsvFile').replace('{0}', organizationName)}
                                            onSuccess={() => setDownloadFileCsv(false)}
                                            filetype="text/csv"
                                        />
                                    )}
                                </Grid>
                                <Grid item>
                                    <ButtonGroup
                                        variant="contained"
                                        color="primary"
                                        aria-label="split button"
                                        ref={anchorRef}
                                        disabled={downloadFileSticker3x8 || downloadFileSticker3x10}
                                    >
                                        <LoadButton
                                            onClick={() => {
                                                setDownloadFileSticker3x8(true);
                                            }}
                                            loading={downloadFileSticker3x8 || downloadFileSticker3x10}
                                        >
                                            {T('Report_MembersListStickerBtn')}
                                        </LoadButton>
                                        <Button
                                            color="primary"
                                            size="small"
                                            onClick={() => setMenuOpen((prevOpen) => !prevOpen)}
                                            disabled={downloadFileSticker3x8 || downloadFileSticker3x10}
                                        >
                                            <ArrowDropDownIcon />
                                        </Button>
                                    </ButtonGroup>
                                    <Popper
                                        open={menuOpen}
                                        anchorEl={anchorRef.current}
                                        role={undefined}
                                        transition
                                        disablePortal
                                        placement="bottom-end"
                                        style={{ zIndex: 999 }}
                                        nonce={undefined}
                                    >
                                        {({ TransitionProps, placement }) => (
                                            <Grow
                                                {...TransitionProps}
                                                style={{
                                                    transformOrigin: placement === 'bottom' ? 'top-end' : 'bottom-end'
                                                }}
                                            >
                                                <Paper>
                                                    <ClickAwayListener onClickAway={() => setMenuOpen(false)}>
                                                        <MenuList id="split-button-menu">
                                                            <MenuItem onClick={() => setDownloadFileSticker3x8(true)}>
                                                                {T('Report_MembersListStickerA4_3x8')}
                                                            </MenuItem>
                                                            <MenuItem onClick={() => setDownloadFileSticker3x10(true)}>
                                                                {T('Report_MembersListStickerA4_3x10')}
                                                            </MenuItem>
                                                        </MenuList>
                                                    </ClickAwayListener>
                                                </Paper>
                                            </Grow>
                                        )}
                                    </Popper>

                                    {downloadFileSticker3x8 && (
                                        <DownloadFile
                                            fetchFile={() => fetchFile('sticker3x8')}
                                            filename={T('Report_MembersListStickerFile').replace(
                                                '{0}',
                                                organizationName
                                            )}
                                            onSuccess={() => setDownloadFileSticker3x8(false)}
                                        />
                                    )}
                                    {downloadFileSticker3x10 && (
                                        <DownloadFile
                                            fetchFile={() => fetchFile('sticker3x10')}
                                            filename={T('Report_MembersListStickerFile').replace(
                                                '{0}',
                                                organizationName
                                            )}
                                            onSuccess={() => setDownloadFileSticker3x10(false)}
                                        />
                                    )}
                                </Grid>
                                <Grid item>
                                    <LoadButton onClick={membersToClipboard} loading={copyToClipboard}>
                                        {T('Report_MembersListCopyClipboardBtn')}
                                    </LoadButton>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    variant="text"
                                    color="secondary"
                                    onClick={() => {
                                        resetForm();
                                    }}
                                >
                                    {T('Report_ClearFilter')}
                                </Button>
                            </Grid>
                        </Grid>
                    </Form>
                );
            }}
        </Formik>
    );
};

export default ReportMembersList;
