import axios from 'axios';
import config from 'config/config';
import { NavigateFunction } from 'react-router-dom';
import AuthService from 'services/authService';

export const initAxios = (navigate: NavigateFunction): void => {
    axios.defaults.baseURL = config.API_BASE_URL;

    axios.interceptors.request.use(
        async (axiosConfig) => {
            let accessToken = AuthService.getAccessToken();

            if (accessToken) {
                axiosConfig.headers['Authorization'] = `Bearer ${accessToken}`;
            } else if (!accessToken && AuthService.refreshPossible()) {
                const newUser = await AuthService.refreshUserAsync();
                accessToken = newUser?.accessToken;
                axiosConfig.headers['Authorization'] = `Bearer ${accessToken}`;
            } else {
                console.warn('no access token - redirect to login');
                navigate('/login', { replace: true });
            }

            return axiosConfig;
        },
        (error) => {
            Promise.reject(error);
        }
    );
};
