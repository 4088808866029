import React, { FC, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { LanguageContext } from 'contexts/languageContext';
import { SmsMessageInput } from 'types/sp-api';
import axios, { CancelTokenSource } from 'axios';
import { useErrorHandler } from 'components/hooks/useErrorHandler';
import SmsMessagesService from 'services/smsMessagesService';
import { Box, Button, Card, CardActions, CardContent, Grid, Typography } from '@mui/material';
import moment from 'moment';
import WaitScreen from 'components/common/WaitScreen';
import { useNavigate } from 'react-router-dom';
import { DeleteDialogState } from 'types/common';
import DialogConfirmation from 'components/common/DialogConfirmation';
import { useSuccessHandler } from 'components/hooks/useSuccessHandler';
import LoadButton from 'components/common/LoadButton';

interface SmsMessageItemProps {
    organizationId: number;
    messageId: number;
    closeMessage: () => void;
    updateList: () => Promise<void>;
}

const SmsMessageItem: FC<SmsMessageItemProps> = ({
    organizationId,
    messageId,
    closeMessage,
    updateList
}: SmsMessageItemProps) => {
    const { T } = useContext(LanguageContext);
    const [message, setMessage] = useState<SmsMessageInput>();
    const { handleError } = useErrorHandler();
    const { handleSuccess } = useSuccessHandler();
    const cancelRef = useRef<CancelTokenSource | null>(null);
    const navigate = useNavigate();
    const [deleting, setDeleting] = useState(false);
    const [deleteDialogState, setDeleteDialogState] = useState<DeleteDialogState>();

    const getMessage = useCallback(
        async (orgId: number, id: number): Promise<void> => {
            try {
                cancelRef.current = axios.CancelToken.source();
                const msg = await SmsMessagesService.getMessageAsync(orgId, id, cancelRef.current.token);
                if (cancelRef.current) {
                    setMessage(msg);
                }
            } catch (error) {
                handleError(error, T('Error_SmsMessageQueryFailed'));
            }
        },
        [handleError, T, updateList]
    );

    useEffect(() => {
        getMessage(organizationId, messageId);

        return () => {
            cancelRef.current && cancelRef.current.cancel();
            cancelRef.current = null;
        };
    }, [getMessage, messageId]);

    const copyToNewMessage = () => {
        navigate(`/communication/smsmessages/new/${messageId}`);
    };

    const deleteMessage = async () => {
        if (deleteDialogState?.id) {
            try {
                setDeleting(true);
                await SmsMessagesService.deleteMessageAsync(organizationId, deleteDialogState?.id);
                updateList();
                handleSuccess(T('SmsMessage_DeleteSuccess'));
                setDeleting(false);
                navigate('/communication/smsmessages');
            } catch (error) {
                setDeleting(false);
                handleError(error, T('SmsMessage_DeleteFailed'));
            }
        }
    };

    return (
        <Grid item xs={12}>
            {!message && <WaitScreen />}
            {message && (
                <Card className="message-item">
                    <CardContent
                        sx={{
                            backgroundColor: 'white',
                            borderWidth: 0,
                            borderBottomWidth: 1,
                            borderBottomColor: 'primary.main',
                            borderBottomStyle: 'solid'
                        }}
                    >
                        <Grid container>
                            <Grid container justifyContent="space-between">
                                <Grid container justifyContent="flex-start" item xs={8}>
                                    <Typography variant="subtitle1" color="textSecondary" noWrap>
                                        {message.title}
                                    </Typography>
                                </Grid>
                                <Grid container justifyContent="flex-end" item xs={4}>
                                    <Typography variant="subtitle2" color="textSecondary" noWrap>
                                        {`${moment(message.createdDate).format('l')} ${moment(
                                            message.createdDate
                                        ).format('LT')}`}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </CardContent>
                    <CardContent sx={{ minHeight: 150 }}>
                        <Box m={1}>
                            <Typography variant="body1" component="div">
                                {message?.message?.split('\n\n').map((paragraph, index) => (
                                    <p key={index}>
                                        {paragraph.split('\n').map((line, lineIndex) => (
                                            <span style={{ display: 'block' }} key={lineIndex}>
                                                {line}
                                            </span>
                                        ))}
                                    </p>
                                ))}
                            </Typography>
                        </Box>
                    </CardContent>
                    <CardActions style={{ paddingLeft: 30, paddingBottom: 16 }}>
                        <Grid container spacing={3}>
                            <Button onClick={closeMessage} color="secondary" variant="contained">
                                {T('Common_Close')}
                            </Button>
                        </Grid>
                        <Grid container spacing={3}>
                            <Button onClick={copyToNewMessage} color="primary" variant="contained">
                                {T('SmsMessage_CopyToNew')}
                            </Button>
                        </Grid>
                        <Grid container spacing={3} justifyContent="flex-end" style={{ marginRight: 8 }}>
                            <LoadButton
                                loading={deleting}
                                variant="outlined"
                                color="error"
                                onClick={() =>
                                    setDeleteDialogState({
                                        show: true,
                                        message: T('SmsMessage_ConfirmDeleteMessage').replace(
                                            '{0}',
                                            message.title ?? ''
                                        ),
                                        id: messageId
                                    })
                                }
                            >
                                {T('Common_Delete')}
                            </LoadButton>
                        </Grid>
                        <DialogConfirmation
                            title={T('SmsMessage_ConfirmDeleteTitle')}
                            message={deleteDialogState?.message}
                            show={deleteDialogState?.show}
                            onClose={() => setDeleteDialogState(undefined)}
                            onOk={deleteMessage}
                            warning
                            okTitle={T('Common_Delete')}
                        />
                    </CardActions>
                </Card>
            )}
        </Grid>
    );
};

export default SmsMessageItem;
