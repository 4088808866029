import React, { FC, useContext, useState } from 'react';
import { Card, CardContent, Grid, Typography } from '@mui/material';
import DownloadFile from 'components/common/DownloadFile';
import LoadButton from 'components/common/LoadButton';
import { LanguageContext, LanguageTextFunc } from 'contexts/languageContext';
import ReportService from 'services/reportService';
import { Form, Formik, FormikProps } from 'formik';
import * as Yup from 'yup';
import FormFieldDropDown from 'components/common/FormFieldDropDown';
import moment from 'moment';
import { KeyValue, ReportBase } from 'types/common';
import Utils from 'utils/dateHelper';
import FormFieldCheckbox from 'components/common/FormFieldCheckbox';

const getValidationSchema = (T: LanguageTextFunc, values: ReportOrganizationChangeForm) => {
    return Yup.object().shape({
        year: Yup.number().test('not-value', T('Common_Required'), (value) => !!value && value > 0),
        month: Yup.number().test(
            'not-both-values-month',
            T('Report_NotBothMonthQuarter'),
            (value) => !(!!value && !!values.quarter && value != 0 && values.quarter != 0)
        ),
        quarter: Yup.number().test(
            'not-both-values-quarter',
            T('Report_NotBothMonthQuarter'),
            (value) => !(!!value && !!values.month && value != 0 && values.month != 0)
        )
    });
};

interface ReportOrganizationChangeForm extends ReportBase {
    year: number;
    month?: number;
    quarter?: number;
    includeNames?: boolean;
}

interface ReportOrganizationChangesProps {
    organizationId: number;
    organizationName: string;
}

const ReportOrganizationChanges: FC<ReportOrganizationChangesProps> = ({
    organizationId,
    organizationName
}: ReportOrganizationChangesProps) => {
    const [downloadFile, setDownloadFile] = useState(false);
    const { T } = useContext(LanguageContext);

    const thisYear = moment().year();
    const years = [
        { key: thisYear, text: thisYear.toString() },
        { key: thisYear - 1, text: (thisYear - 1).toString() },
        { key: thisYear - 2, text: (thisYear - 2).toString() },
        { key: thisYear - 3, text: (thisYear - 3).toString() }
    ];
    const quarters = [
        { key: 0, text: T('Common_NoQuarter') },
        { key: 1, text: 'Q1' },
        { key: 2, text: 'Q2' },
        { key: 3, text: 'Q3' },
        { key: 4, text: 'Q4' }
    ];
    const noMonths: KeyValue[] = [{ key: 0, text: T('Common_NoMonth') }];
    const months: KeyValue[] = noMonths.concat(
        [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((item) => ({ key: item, text: T(Utils.monthToLangKey(item)) }))
    );

    return (
        <Formik
            initialValues={{
                year: 0,
                filename: T('Report_OrgChangeReportFile').replace('{0}', organizationName),
                includeNames: false
            }}
            onSubmit={() => {
                /* do nothing */
            }}
            validationSchema={() => Yup.lazy((values) => getValidationSchema(T, values))}
            enableReinitialize
        >
            {({ values, dirty, isValid }: FormikProps<ReportOrganizationChangeForm>) => {
                const fetchFile = async () => {
                    return ReportService.organizationChangesAsync(
                        organizationId,
                        values.year,
                        values.month,
                        values.quarter,
                        values.includeNames
                    );
                };

                return (
                    <Form>
                        <Grid container item xs={12} alignContent="flex-start" spacing={3}>
                            <Grid item xs={12}>
                                <Card>
                                    <CardContent>
                                        <Grid container item xs={12} alignContent="flex-start" spacing={3}>
                                            <Grid item xs={12}>
                                                <Typography color="primary" variant="h6">
                                                    {T('Report_SelectTimeRange')}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <FormFieldDropDown
                                                    name="year"
                                                    placeholder={T('Report_SelectYear')}
                                                    values={years}
                                                />
                                            </Grid>
                                            <Grid item xs={3}>
                                                <FormFieldDropDown
                                                    name="month"
                                                    placeholder={T('Report_SelectMonth')}
                                                    values={months}
                                                />
                                                <Typography variant="body2" color="textSecondary">
                                                    {T('Report_ChangeReportMonthOptional')}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <FormFieldDropDown
                                                    name="quarter"
                                                    placeholder={T('Report_SelectQuarter')}
                                                    values={quarters}
                                                />
                                                <Typography variant="body2" color="textSecondary">
                                                    {T('Report_ChangeReportQuarterOptional')}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <FormFieldCheckbox
                                                    name={'includeNames'}
                                                    label={T('Report_ChangesAddNames')}
                                                />
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={12}>
                                <LoadButton
                                    disabled={!dirty || !isValid}
                                    onClick={() => {
                                        setDownloadFile(true);
                                    }}
                                    loading={downloadFile}
                                >
                                    {T('Report_OrganizationChangesBtn')}
                                </LoadButton>
                                <Typography sx={{ mt: 1 }} variant="body2" color="textSecondary">
                                    {T('Report_OrganizationChangesBtnHelpText')}
                                </Typography>
                                {downloadFile && (
                                    <DownloadFile
                                        fetchFile={fetchFile}
                                        filename={values.filename}
                                        onSuccess={() => setDownloadFile(false)}
                                    />
                                )}
                            </Grid>
                        </Grid>
                    </Form>
                );
            }}
        </Formik>
    );
};

export default ReportOrganizationChanges;
