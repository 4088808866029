import React, { ChangeEvent, FC, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Container, Grid, Paper, Tab, Tabs } from '@mui/material';
import axios, { CancelTokenSource } from 'axios';
import ArchiveService from 'services/archiveService';
import WaitScreen from 'components/common/WaitScreen';
import { Person } from 'types/sp-api';
import PersonTabBasic from '../persons/PersonTabBasic';
import { useParams } from 'react-router-dom';
import BreadcrumbBar from 'components/common/BreadcrumbBar';
import ViewTitle from 'components/common/ViewTitle';
import { Person as PersonIcon } from '@mui/icons-material';
import { LanguageContext } from 'contexts/languageContext';
import TabPanel from 'components/common/TabPanel';
import PersonTabMembership from '../persons/PersonTabMembership';
import PersonTabAccessRights from '../persons/PersonTabAccessRights';
import PersonTabFamily from '../persons/PersonTabFamily';
import PersonTabCredentials from '../persons/PersonTabCredentials';
import PersonTabReports from '../persons/PersonTabReports';
import PersonTabDocuments from '../persons/PersonTabDocuments';
import { useErrorHandler } from 'components/hooks/useErrorHandler';
import { AddressType, TabItem } from 'types/common';
import PersonUtils from 'utils/personHelper';
import PersonTabConsents from '../persons/PersonTabConsents';
import PersonTabAdditionalInfo from '../persons/PersonTabAdditionalInfo';

const ArchivePersonItem: FC = () => {
    const { personId } = useParams<'personId'>();
    const [person, setPerson] = useState<Person>();
    const [selectedTab, setSelectedTab] = useState(0);
    const { T } = useContext(LanguageContext);
    const { handleError } = useErrorHandler();
    const cancelRef = useRef<CancelTokenSource | null>(null);
    const [hasMemberships, setHasMemberships] = useState(false);

    const getPerson = useCallback(
        async (id: number): Promise<void> => {
            try {
                cancelRef.current = axios.CancelToken.source();
                const personData = await ArchiveService.getPersonAsync(id, cancelRef.current.token);
                if (cancelRef.current) {
                    setPerson(personData);
                }
                const personMemberships = await ArchiveService.getPersonMembershipsAsync(id, cancelRef.current.token);
                if (cancelRef.current) {
                    setHasMemberships(personMemberships.length > 0);
                }
            } catch (error) {
                handleError(error, T('Error_ArchivePersonQueryFailed'));
            }
        },
        [handleError, T]
    );

    useEffect(() => {
        if (personId !== 'new' && personId) {
            getPerson(parseInt(personId));
        } else {
            // init new person
            setPerson({
                id: -1,
                lastName: '',
                firstName: '',
                ssn: '',
                address: { street: '', zipCode: '', country: 'Suomi', type: AddressType.Domestic }
            });
        }
        return () => {
            cancelRef.current && cancelRef.current.cancel();
            cancelRef.current = null;
        };
    }, [getPerson, personId]);

    const handleChange = (_: ChangeEvent<unknown>, newValue: number) => {
        setSelectedTab(newValue);
    };

    const updatePerson = () => {
        getPerson(parseInt(personId ?? '0'));
    };

    if (!person)
        return (
            <Container className="archive-background-light">
                <Grid container>
                    <BreadcrumbBar />
                    <ViewTitle title={T('Common_Loading')} icon={PersonIcon} />
                </Grid>
                <WaitScreen />
            </Container>
        );

    const tabs: TabItem[] = [
        { id: 0, title: 'Basic', content: <PersonTabBasic person={person} refreshPerson={updatePerson} /> },
        {
            id: 1,
            title: 'Membership',
            content: <PersonTabMembership person={person} refreshPerson={updatePerson} archive />
        },
        {
            id: 2,
            title: 'AdditionalInfo',
            hidden: !hasMemberships,
            content: <PersonTabAdditionalInfo person={person} />
        },
        {
            id: 3,
            title: 'Family',
            hidden: !hasMemberships,
            content: <PersonTabFamily person={person} setActiveTab={setSelectedTab} />
        },
        { id: 4, title: 'Consents', hidden: true, content: <PersonTabConsents person={person} /> },
        {
            id: 5,
            title: 'Reports',
            hidden: !hasMemberships,
            content: <PersonTabReports person={person} archive />
        },
        { id: 6, title: 'Documents', hidden: true, content: <PersonTabDocuments person={person} /> },
        { id: 7, title: 'Proxies', hidden: true, content: <PersonTabCredentials person={person} /> },
        { id: 8, title: 'AccessRights', hidden: true, content: <PersonTabAccessRights person={person} /> }
    ];

    return (
        <Container className="archive-background-light" style={{ paddingBottom: 24 }}>
            <Grid container>
                <BreadcrumbBar />
                <ViewTitle
                    title={<>{PersonUtils.FullName(person)}</>}
                    iconTooltip={T('Person_IconTooltip').replace('{0}', person.id?.toString() ?? '')}
                    icon={PersonIcon}
                />
            </Grid>
            <Paper elevation={3}>
                <Tabs
                    value={selectedTab}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="scrollable"
                    scrollButtons="auto"
                    onChange={handleChange}
                >
                    {tabs
                        .filter((tab) => !tab.hidden)
                        .map((item, index) => (
                            <Tab
                                label={T(`PersonTab_${item.title}`)}
                                key={item.id}
                                value={item.id}
                                disabled={index > 0 && personId === 'new'}
                            />
                        ))}
                </Tabs>
                {tabs.map((item) => (
                    <TabPanel key={item.id} value={selectedTab} index={item.id}>
                        {item.content}
                    </TabPanel>
                ))}
            </Paper>
        </Container>
    );
};

export default ArchivePersonItem;
