import React, { FC, ReactNode, useCallback, useContext, useEffect, useRef, useState } from 'react';
import {
    Grid,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from '@mui/material';
import { LanguageContext, LanguageTextFunc } from 'contexts/languageContext';
import PersonsService from 'services/personsService';
import { useErrorHandler } from 'components/hooks/useErrorHandler';
import axios, { CancelTokenSource } from 'axios';
import { Membership, Person, PersonCloseRelative } from 'types/sp-api';
import { DbCodeSets, DeleteDialogState, DialogOpenState, KeyValue, PersonCloseRelativeType } from 'types/common';
import CodeSetService from 'services/codeSetService';
import DialogBase from 'components/common/DialogBase';
import { Form, Formik, FormikProps } from 'formik';
import FormFieldText from 'components/common/FormFieldText';
import * as Yup from 'yup';
import ButtonDelete from 'components/common/ButtonDelete';
import DialogConfirmation from 'components/common/DialogConfirmation';
import { useSuccessHandler } from 'components/hooks/useSuccessHandler';
import PersonUtils from 'utils/personHelper';
import FormFieldDropDownCodeSet from 'components/common/FormFieldDropDownCodeSet';
import FormFieldDropDownMember from 'components/common/FormFieldDropDownMember';
import ButtonAdd from 'components/common/ButtonAdd';
import FormFieldSwitch from 'components/common/FormFieldSwitch';
import CheckIcon from '@mui/icons-material/Check';
import { TableHeadCell } from 'components/common/TableHeadCell';
import WaitScreen from 'components/common/WaitScreen';
import { useNavigate } from 'react-router-dom';
import Utils from 'utils/typeHelper';
import FormFieldDate from 'components/common/FormFieldDate';
import moment from 'moment';

const getValidationSchema = (T: LanguageTextFunc, values: PersonCloseRelative, textPrefix: string) => {
    return Yup.object().shape({
        type: Yup.string().test('not-0-value', T('Common_Required'), (value) => value !== '0'),
        relativePersonId: Yup.number()
            .test(
                'relative-person-selected',
                T(`${textPrefix}_RelativePersonMissing`),
                (value) => value !== 0 || (!!values.relativeInfo && values.relativeInfo.length > 0)
            )
            .nullable(),
        relativeInfo: Yup.string()
            .test(
                'relative-info-added',
                T(`${textPrefix}_RelativePersonMissing`),
                (value) => values.relativePersonId !== 0 || (!!value && value.length > 0)
            )
            .nullable()
    });
};

interface PersonCloseRelativesProps {
    person: Person;
    guardiansOnly?: boolean;
    textPrefix?: string;
    setActiveTab?: (tab: number) => void;
}

const PersonCloseRelatives: FC<PersonCloseRelativesProps> = ({
    person,
    guardiansOnly,
    textPrefix = 'PersonFamily',
    setActiveTab
}: PersonCloseRelativesProps) => {
    const { T } = useContext(LanguageContext);
    const [relativesList, setRelativesList] = useState<PersonCloseRelative[]>();
    const [openAddDialog, setOpenAddDialog] = useState<DialogOpenState>(false);
    const [deleteDialogState, setDeleteDialogState] = useState<DeleteDialogState>();
    const { handleError } = useErrorHandler();
    const [codesetValues, setCodeSetValues] = useState<KeyValue[]>();
    const { handleSuccess } = useSuccessHandler();
    const cancelRef = useRef<CancelTokenSource | null>(null);
    const navigate = useNavigate();
    const [membership, setMembership] = useState<Membership>();

    const getNewRelative = (): PersonCloseRelative => ({
        id: 0,
        personId: person.id ?? 0,
        type: guardiansOnly ? 2 : 0, // huoltaja
        beginDate: new Date(),
        ice: guardiansOnly ?? false
    });

    const [selectedRelative, setSelectedRelative] = useState<PersonCloseRelative>(getNewRelative());

    const getCodeSet = useCallback(
        async (codeSet: DbCodeSets): Promise<void> => {
            try {
                !cancelRef.current && (cancelRef.current = axios.CancelToken.source());

                const codeValues = await CodeSetService.getCodeSetAsync(codeSet, cancelRef.current.token);
                if (cancelRef.current) {
                    setCodeSetValues(codeValues);
                }
            } catch (error) {
                handleError(error, T('Error_CodeSetQueryFailed'));
            }
        },
        [handleError, T]
    );

    const getRelatives = useCallback(
        async (id?: number): Promise<void> => {
            if (!id) return;

            try {
                !cancelRef.current && (cancelRef.current = axios.CancelToken.source());
                const personRelatives = await PersonsService.getAllRelativesAsync(id, cancelRef.current.token);

                if (cancelRef.current) {
                    let relatives;
                    if (guardiansOnly) {
                        relatives = personRelatives.filter((item) => item.type === PersonCloseRelativeType.Guardian);
                    } else {
                        relatives = personRelatives.filter((item) => item.type !== PersonCloseRelativeType.Guardian);
                    }
                    setRelativesList(relatives);
                }
            } catch (error) {
                handleError(error, T('Error_PersonRelativesQueryFailed'));
            }
        },
        [handleError, T]
    );

    const getMemberships = useCallback(
        async (personId?: number): Promise<void> => {
            if (!personId) return;

            try {
                !cancelRef.current && (cancelRef.current = axios.CancelToken.source());

                const allUsersMemberships = await PersonsService.getPersonMembershipsAsync(
                    personId,
                    cancelRef.current.token
                );

                if (cancelRef.current) {
                    const localMembership = allUsersMemberships.find((item) =>
                        Utils.IsLocalYhdyskunta(item.organization?.type)
                    );
                    setMembership(localMembership);
                }
            } catch (error) {
                handleError(error, T('Error_PersonMembersipQueryFailed'));
            }
        },
        [handleError, T]
    );

    useEffect(() => {
        getRelatives(person.id);
        getMemberships(person.id);
        getCodeSet('closeRelative');

        return () => {
            cancelRef.current && cancelRef.current.cancel();
            cancelRef.current = null;
        };
    }, [getRelatives, getCodeSet, person.id]);

    const refreshRelatives = () => {
        // refresh list
        getRelatives(person.id);
    };

    const deleteRelative = async () => {
        try {
            await PersonsService.deleteRelativeAsync(person.id, deleteDialogState?.id);
            handleSuccess(T(`${textPrefix}_DeleteSuccess`));
            refreshRelatives();
        } catch (error) {
            handleError(error, T(`${textPrefix}_DeleteFailed`));
        }
    };

    const saveRelative = async (relative: PersonCloseRelative) => {
        try {
            await PersonsService.saveRelativeAsync(person.id, relative);
            handleSuccess(T(`${textPrefix}_SaveSuccess`));
            refreshRelatives();
        } catch (error) {
            handleError(error, T(`${textPrefix}_SaveFailed`));
        }
    };

    const addRelative = () => {
        setSelectedRelative(getNewRelative());
        setOpenAddDialog(true);
    };

    const getRelativeType = (type: number): string => {
        if (!codesetValues) return '';
        return T(`CodeSet_closeRelative_${codesetValues.find((code) => code.key === type.toString())?.value ?? ''}`);
    };

    return (
        <>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <TableContainer component={Paper}>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableHeadCell sx={{ minWidth: 200 }}>
                                        {T(`${textPrefix}_TableRelativePerson`)}
                                    </TableHeadCell>
                                    {!guardiansOnly && (
                                        <>
                                            <TableHeadCell sx={{ width: 120 }}>
                                                {T(`${textPrefix}_TableType`)}
                                            </TableHeadCell>

                                            <TableHeadCell sx={{ width: 60, textAlign: 'center' }}>
                                                {T(`${textPrefix}_TitleMarriageStartDate`)}
                                            </TableHeadCell>

                                            <TableHeadCell sx={{ width: 180, textAlign: 'center' }}>
                                                {T(`${textPrefix}_TableIce`)}
                                            </TableHeadCell>
                                        </>
                                    )}
                                    <TableHeadCell sx={{ width: 30, textAlign: 'center' }} />
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {relativesList &&
                                    codesetValues &&
                                    relativesList.length > 0 &&
                                    relativesList.map(
                                        (relative, index): ReactNode => (
                                            <TableRow
                                                className="pointer"
                                                key={index}
                                                hover={!!relative.relative}
                                                onClick={() => {
                                                    if (relative.relative) {
                                                        setActiveTab && setActiveTab(0);
                                                        navigate(`/persons/${relative.relative.id}`);
                                                    }
                                                }}
                                            >
                                                {relative.relative && (
                                                    <TableCell>
                                                        {`${PersonUtils.FullName(relative.relative)}, ${T(
                                                            'Common_PhonePrefix'
                                                        )} ${relative.relative.phoneHome}`}
                                                    </TableCell>
                                                )}
                                                {!relative.relative && <TableCell>{relative.relativeInfo}</TableCell>}
                                                {!guardiansOnly && (
                                                    <>
                                                        <TableCell>{T(getRelativeType(relative.type))}</TableCell>
                                                        <TableCell>
                                                            {Utils.IsSpouse(relative.type) ||
                                                            Utils.IsFormerSpouse(relative.type)
                                                                ? moment(relative.beginDate).format('l')
                                                                : ''}
                                                        </TableCell>
                                                        <TableCell sx={{ textAlign: 'center' }}>
                                                            {relative.ice && <CheckIcon color="primary" />}
                                                        </TableCell>
                                                    </>
                                                )}
                                                <TableCell sx={{ textAlign: 'center' }}>
                                                    <ButtonDelete
                                                        onClick={() =>
                                                            setDeleteDialogState({
                                                                show: true,
                                                                message: T(`${textPrefix}_ConfirmDeleteMessage`)
                                                                    .replace('{0}', PersonUtils.FullName(person))
                                                                    .replace('{1}', getRelativeType(relative.type)),
                                                                id: relative.id ?? 0
                                                            })
                                                        }
                                                        small
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        )
                                    )}
                                {!relativesList && (
                                    <TableRow>
                                        <TableCell>
                                            <WaitScreen />
                                        </TableCell>
                                    </TableRow>
                                )}
                                {relativesList && relativesList.length === 0 && (
                                    <TableRow>
                                        <TableCell>
                                            <Typography variant="body1" color="textSecondary">
                                                {T(`${textPrefix}_NoRelatives`)}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
                <Grid item xs={12} container justifyContent="flex-end">
                    <ButtonAdd
                        title={T(`${textPrefix}_AddNew`)}
                        variant="text"
                        color="secondary"
                        onClick={() => addRelative()}
                        small
                    />
                </Grid>
            </Grid>
            <Formik
                initialValues={selectedRelative}
                enableReinitialize
                validationSchema={() => Yup.lazy((values) => getValidationSchema(T, values, textPrefix))}
                onSubmit={saveRelative}
            >
                {({ dirty, isValid, isSubmitting, submitForm, values }: FormikProps<PersonCloseRelative>) => (
                    <DialogBase
                        show={!!openAddDialog}
                        title={
                            openAddDialog === 'edit'
                                ? T(`${textPrefix}_EditDialogTitle`)
                                : T(`${textPrefix}_AddDialogTitle`)
                        }
                        onOk={submitForm}
                        onClose={() => setOpenAddDialog(false)}
                        okDisabled={isSubmitting || !dirty || !isValid}
                        okTitle={T('Common_Save')}
                    >
                        <Form>
                            <Grid container spacing={2} sx={{ pt: 1 }}>
                                {!guardiansOnly && (
                                    <Grid item xs={12}>
                                        <FormFieldDropDownCodeSet
                                            name="type"
                                            label={T(`${textPrefix}_FieldType`)}
                                            codeSet="closeRelative"
                                            placeholder={T(`${textPrefix}_SelectRelativeType`)}
                                            hiddenValues={[2]} // huoltaja
                                        />
                                    </Grid>
                                )}
                                {!guardiansOnly && Utils.IsSpouse(values.type) && (
                                    <Grid item xs={12}>
                                        <FormFieldDate
                                            name="beginDate"
                                            label={T(`${textPrefix}_FieldMarriageStartDate`)}
                                            disableFuture
                                        />
                                    </Grid>
                                )}
                                {!guardiansOnly && Utils.IsFormerSpouse(values.type) && (
                                    <Grid item xs={12}>
                                        <FormFieldDate
                                            name="beginDate"
                                            label={T(`${textPrefix}_FieldDeceasedDate`)}
                                            disableFuture
                                        />
                                    </Grid>
                                )}
                                <Grid item xs={12}>
                                    <FormFieldDropDownMember
                                        name="relativePersonId"
                                        organizationId={membership?.organizationId ?? 0}
                                        label={T(`${textPrefix}_FieldRelativePerson`)}
                                        usePersonId
                                        placeholder={T(`${textPrefix}_SelectRelativePerson`)}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormFieldText name="relativeInfo" label={T(`${textPrefix}_FieldRelativeInfo`)} />
                                    <Typography color="textSecondary" variant="body2">
                                        {T(`${textPrefix}_RelativeInfoHelperText`)}
                                    </Typography>
                                </Grid>
                                {!guardiansOnly && (
                                    <Grid item xs={12}>
                                        <FormFieldSwitch name="ice" label={T(`${textPrefix}_FieldIce`)} />
                                    </Grid>
                                )}
                            </Grid>
                        </Form>
                    </DialogBase>
                )}
            </Formik>
            <DialogConfirmation
                title={T(`${textPrefix}_ConfirmDeleteTitle`)}
                message={deleteDialogState?.message}
                show={deleteDialogState?.show}
                onClose={() => setDeleteDialogState(undefined)}
                onOk={deleteRelative}
                warning
                okTitle={T('Common_Delete')}
            />
        </>
    );
};

export default PersonCloseRelatives;
