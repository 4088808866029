import React, { FC, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Box, Card, CardContent, Grid, Typography } from '@mui/material';
import { LanguageContext } from 'contexts/languageContext';
import { KeyValue, MembersListReportForm } from 'types/common';
import FormFieldCheckbox from 'components/common/FormFieldCheckbox';
import FormFieldText from 'components/common/FormFieldText';
import FormFieldDropDownMultiselect from 'components/common/FormFieldDropDownMultiselect';
import { Tag } from 'types/sp-api';
import { AuthContext } from 'contexts/authContext';
import CommunityTagService from 'services/communityTagService';
import axios, { CancelTokenSource } from 'axios';
import { useErrorHandler } from 'components/hooks/useErrorHandler';
import TypeHelper from 'utils/typeHelper';
import FormFieldReadOnly from 'components/common/FormFieldReadonly';
import { useFormikContext } from 'formik';
import FormFieldDropDownGender from 'components/common/FormFieldDropDownGender';
import WaitScreen from 'components/common/WaitScreen';
import FormFieldDropDown from 'components/common/FormFieldDropDown';

interface ReportFilterProps {
    name: string;
    memberTagTitles?: Tag[];
    membershipTypes?: KeyValue[];
    title?: string;
    noCardLayout?: boolean;
    orgType: number;
}

const ReportFilter: FC<ReportFilterProps> = ({ noCardLayout, ...props }: ReportFilterProps) => {
    if (noCardLayout) {
        return <InnerFilter {...props} />;
    }
    return (
        <Card>
            <CardContent>
                <InnerFilter {...props} />
            </CardContent>
        </Card>
    );
};

const InnerFilter: FC<ReportFilterProps> = ({
    name,
    memberTagTitles,
    membershipTypes,
    title,
    orgType
}: ReportFilterProps) => {
    const { T } = useContext(LanguageContext);
    const { isAdmin } = useContext(AuthContext);
    const [communityTagTitles, setCommunityTagTitles] = useState<Tag[]>([]);
    const cancelRef = useRef<CancelTokenSource | null>(null);
    const { handleError } = useErrorHandler();
    const formik = useFormikContext<MembersListReportForm>();

    const emailFilterValues: KeyValue[] = ['all', 'with', 'without'].map((item) => ({
        key: item,
        value: `ReportFilterEmail_${item}`
    }));

    const tagSorter = (a: Tag, b: Tag): number => {
        if (!a.name || !b.name) return 0;
        if (a.name > b.name) return 1;
        if (a.name < b.name) return -1;
        return 0;
    };

    const getCommunityTagTitles = useCallback(async (): Promise<void> => {
        try {
            !cancelRef.current && (cancelRef.current = axios.CancelToken.source());
            const communityTags = await CommunityTagService.getCommunityTagTitlesAsync(cancelRef.current.token);
            if (cancelRef.current) {
                communityTags.sort(tagSorter);
                setCommunityTagTitles(communityTags);
            }
        } catch (error) {
            handleError(error, T('Error_CommunityTagTitleQueryFailed'));
        }
    }, [handleError, T]);

    const createGroupName = useCallback(
        (values: MembersListReportForm): string => {
            let groupName = '';
            if (values.filter.memberTags && memberTagTitles) {
                values.filter.memberTags.forEach((tagId) => {
                    if (groupName.length > 0) {
                        groupName += ', ';
                    }
                    groupName += memberTagTitles.find((t) => t.id === tagId)?.name ?? '';
                });
            }
            return groupName;
        },
        [memberTagTitles]
    );

    const createGlobalGroupName = useCallback(
        (values: MembersListReportForm): string => {
            let globalGroupName = '';
            if (values.filter.communityTags) {
                values.filter.communityTags.forEach((tagId) => {
                    if (globalGroupName.length > 0) {
                        globalGroupName += ', ';
                    }
                    globalGroupName += communityTagTitles.find((t) => t.id === tagId)?.name ?? '';
                });
            }
            return globalGroupName;
        },
        [communityTagTitles]
    );

    const createFilterText = useCallback(
        (values: MembersListReportForm): string => {
            let filterText = '';

            let typesText = '';
            if (values.filter.membershipTypes && membershipTypes) {
                values.filter.membershipTypes.forEach((typeId) => {
                    if (typesText.length > 0) {
                        typesText += ', ';
                    }
                    typesText += T(membershipTypes.find((t) => t.key === typeId)?.value ?? '');
                });
            }

            if (typesText.length > 0) {
                filterText += T('Report_FilterTextMemberTypes').replace('{0}', typesText);
            }

            if (values.filter.familyContacts) {
                if (filterText.length > 0) {
                    filterText += '; ';
                }
                filterText += T('Report_FilterFamilyContacts');
            }

            if (values.filter.ageRangeStart) {
                if (filterText.length > 0) {
                    filterText += '; ';
                }
                filterText += T('Report_FilterTextAgeStart').replace('{0}', values.filter.ageRangeStart.toString());
            }

            if (values.filter.ageRangeEnd) {
                if (filterText.length > 0) {
                    filterText += '; ';
                }
                filterText += T('Report_FilterTextAgeEnd').replace('{0}', values.filter.ageRangeEnd.toString());
            }

            switch (values.filter.gender) {
                case 'E':
                    filterText += `${T('Person_FieldGender')}: ${T('Gender_E')}`;
                    break;
                case 'M':
                    filterText += `${T('Person_FieldGender')}: ${T('Gender_M')}`;
                    break;
                case 'N':
                    filterText += `${T('Person_FieldGender')}: ${T('Gender_N')}`;
                    break;
            }

            if ((values.filter.email?.length ?? 0 > 0) && values.filter.email !== 'all') {
                if (filterText.length > 0) {
                    filterText += '; ';
                }
                filterText += `${T('Report_FilterEmail')}: ${T(`ReportFilterEmail_${values.filter.email}`)}`;
            }
            return filterText;
        },
        [membershipTypes, T]
    );

    const updateFilterAndGroupTexts = useCallback(() => {
        // Update group name
        const newGroupName = createGroupName(formik.values);
        if (newGroupName !== formik.values.groupName) {
            formik.setFieldValue('groupName', newGroupName);
        }

        const newGlobalGroupName = createGlobalGroupName(formik.values);
        if (newGlobalGroupName !== formik.values.globalGroupName) {
            formik.setFieldValue('globalGroupName', newGlobalGroupName);
        }

        const newFilterText = createFilterText(formik.values);
        if (newFilterText !== formik.values.filterText) {
            formik.setFieldValue('filterText', newFilterText);
        }
    }, [formik, createFilterText, createGlobalGroupName, createGroupName]);

    useEffect(() => {
        getCommunityTagTitles();

        return () => {
            cancelRef.current && cancelRef.current.cancel();
            cancelRef.current = null;
        };
    }, [getCommunityTagTitles]);

    useEffect(() => {
        updateFilterAndGroupTexts();
    }, [updateFilterAndGroupTexts]);

    return (
        <Grid container item xs={12} spacing={2}>
            <Grid item xs={12}>
                <Typography color="primary" variant="h6">
                    {!title ? T('Report_FilterTitle') : title}
                </Typography>
            </Grid>
            <Grid container item xs={12} spacing={2}>
                <Grid item xs={12}>
                    <Typography>{T('Report_FilterMembershipType')}</Typography>
                </Grid>
                <Grid item xs={12}>
                    {!membershipTypes ? (
                        <WaitScreen />
                    ) : (
                        <FormFieldDropDownMultiselect
                            name={`${name}.membershipTypes`}
                            values={membershipTypes}
                            placeholder={'Report_FilterTypeEmpty'}
                        />
                    )}
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <FormFieldCheckbox name={`${name}.familyContacts`} label={T('Report_FilterFamilyContacts')} />
            </Grid>
            <Grid container item xs={12} spacing={2}>
                <Grid item xs={12}>
                    <Typography>{T('Report_FilterAge')}</Typography>
                </Grid>
                <Grid item xs={3}>
                    <FormFieldText name={`${name}.ageRangeStart`} label={T('Report_FilterAgeStart')} />
                </Grid>
                <Box sx={{ ml: 3, mr: 2, pt: 3 }}>{'\u0336 '}̶</Box>
                <Grid item xs={3}>
                    <FormFieldText name={`${name}.ageRangeEnd`} label={T('Report_FilterAgeEnd')} />
                </Grid>
            </Grid>
            <Grid container item xs={12} spacing={2}>
                <Grid item xs={12}>
                    <Typography>{T('Report_FilterGender')}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <FormFieldDropDownGender
                        name={`${name}.gender`}
                        label={''}
                        showEmptyLabel={T('Report_FilterGenderEmpty')}
                    />
                </Grid>
            </Grid>
            <Grid container item xs={12} spacing={2}>
                <Grid item xs={12}>
                    <Typography>{T('Report_FilterEmail')}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <FormFieldDropDown
                        name={`${name}.email`}
                        label={''}
                        values={emailFilterValues}
                        placeholder={T('ReportFilterEmail_all')}
                    />
                </Grid>
            </Grid>
            {!TypeHelper.IsYhdyskunta(orgType) && (
                <Grid container item xs={12} spacing={2}>
                    <Grid item xs={12}>
                        <Typography>{T('Report_FilterMemberTag')}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        {!memberTagTitles && <WaitScreen />}
                        {memberTagTitles &&
                            (memberTagTitles.length == 0 ? (
                                <FormFieldReadOnly label={T('Report_FilterMemberTagNonExists')} />
                            ) : (
                                <FormFieldDropDownMultiselect
                                    name={`${name}.memberTags`}
                                    values={memberTagTitles.map((item) => ({
                                        key: item.id,
                                        value: item.name
                                    }))}
                                    placeholder={'Report_FilterMemberTagEmpty'}
                                />
                            ))}
                    </Grid>
                </Grid>
            )}
            {isAdmin() && TypeHelper.IsYhdyskunta(orgType) && (
                <Grid container item xs={12} spacing={2}>
                    <Grid item xs={12}>
                        <Typography>{T('Report_FilterCommunityTag')}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        {communityTagTitles.length == 0 ? (
                            <FormFieldReadOnly label={T('Report_FilterCommunityTagNonExists')} />
                        ) : (
                            <FormFieldDropDownMultiselect
                                name={`${name}.communityTags`}
                                values={communityTagTitles.map((item) => ({
                                    key: item.id,
                                    value: item.name
                                }))}
                                placeholder={'Report_FilterCommunityTagEmpty'}
                            />
                        )}
                    </Grid>
                </Grid>
            )}
        </Grid>
    );
};

export default ReportFilter;
