import React, { FC, useContext } from 'react';
import { LanguageContext } from 'contexts/languageContext';
import FormFieldDropDown from './FormFieldDropDown';
import { KeyValue } from 'types/common';

interface FormFieldDropDownGenderProps {
    label: string;
    name: string;
    autoFocus?: boolean;
    placeholder?: string;
    showEmptyLabel?: string;
}

const FormFieldDropDownGender: FC<FormFieldDropDownGenderProps> = ({
    label,
    name,
    autoFocus,
    placeholder,
    showEmptyLabel
}: FormFieldDropDownGenderProps) => {
    const { T } = useContext(LanguageContext);
    const values: KeyValue[] = ['E', 'M', 'N'].map((item) => ({ key: item, value: `Gender_${item}` }));

    if (showEmptyLabel) {
        values.unshift({ key: '', value: 'Report_FilterGenderEmpty' });
        placeholder = T('Report_FilterGenderEmpty');
    }

    placeholder = placeholder ? placeholder : T('Common_SelectGender');

    return (
        <FormFieldDropDown name={name} label={label} values={values} placeholder={placeholder} autoFocus={autoFocus} />
    );
};

export default FormFieldDropDownGender;
