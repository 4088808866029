import React, { FC, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Typography, Grid, Card, CardContent } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useErrorHandler } from 'components/hooks/useErrorHandler';
import { LanguageContext } from 'contexts/languageContext';
import axios, { CancelTokenSource } from 'axios';
import NotificationsService from 'services/notificationsService';
import { Notification } from 'types/sp-api';
import WaitScreen from 'components/common/WaitScreen';
import moment from 'moment';

const NotificationsPreview: FC = () => {
    const navigate = useNavigate();
    const { T } = useContext(LanguageContext);
    const [notificationList, setNotificationList] = useState<Notification[]>();
    const { handleError } = useErrorHandler();
    const cancelRef = useRef<CancelTokenSource | null>(null);

    const getNotifications = useCallback(async (): Promise<void> => {
        try {
            cancelRef.current = axios.CancelToken.source();
            const notifications = await NotificationsService.getPreviewUnreadAsync(cancelRef.current.token);
            if (cancelRef.current) {
                setNotificationList(notifications);
            }
        } catch (error) {
            handleError(error, T('Error_NotificationsQueryFailed'));
        }
    }, [handleError, T]);

    useEffect(() => {
        getNotifications();

        return () => {
            cancelRef.current && cancelRef.current.cancel();
            cancelRef.current = null;
        };
    }, [getNotifications]);

    return (
        <Grid
            container
            spacing={1}
            justifyContent="flex-start"
            alignItems="center"
            direction="column"
            sx={{ marginTop: 4 }}
        >
            {!notificationList && <WaitScreen />}
            {notificationList &&
                notificationList.map((item, index) => (
                    <Grid item key={index} style={{ padding: 1 }} className="MuiTableRow-root MuiTableRow-hover">
                        <Card
                            sx={{
                                cursor: 'pointer',
                                p: 1,
                                maxWidth: '345px',
                                minWidth: '345px',
                                backgroundColor: 'transparent'
                            }}
                            onClick={() => {
                                navigate(`/notifications/${item.id}`);
                            }}
                        >
                            <CardContent sx={{ padding: '0 !important' }}>
                                <Typography sx={{ fontSize: 12 }} color="textSecondary">
                                    {moment(item.createdDate).format('l')}
                                </Typography>
                                <Typography noWrap color="primary" title={item.title}>
                                    {item.title}
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                ))}
        </Grid>
    );
};

export default NotificationsPreview;
