import React, { FC, useContext, useState, MouseEvent } from 'react';
import { IconButton, Menu, MenuItem } from '@mui/material';
import { LanguageContext } from 'contexts/languageContext';
import MoreVertIcon from '@mui/icons-material/MoreVert';

interface RowMenuProps {
    onEdit?: () => void;
    onDelete?: () => void;
}

const RowMenu: FC<RowMenuProps> = ({ onEdit, onDelete }: RowMenuProps) => {
    const { T } = useContext(LanguageContext);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <IconButton onClick={handleClick} size="small">
                <MoreVertIcon />
            </IconButton>
            <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
                {onEdit && (
                    <MenuItem
                        onClick={() => {
                            onEdit();
                            handleClose();
                        }}
                    >
                        {T('Common_Edit')}
                    </MenuItem>
                )}
                {onDelete && (
                    <MenuItem
                        onClick={() => {
                            onDelete();
                            handleClose();
                        }}
                    >
                        {T('Common_Delete')}
                    </MenuItem>
                )}
            </Menu>
        </>
    );
};

export default RowMenu;
