import React, { FC, Fragment, ReactNode, useContext } from 'react';
import { Box, Divider, Grid, List, ListItem, ListItemIcon, Typography } from '@mui/material';
import { Mail } from '@mui/icons-material';
import { LanguageContext } from 'contexts/languageContext';
import { SecureMessage } from 'types/sp-api';
import classnames from 'classnames';
import WaitScreen from 'components/common/WaitScreen';
import LightTooltip from 'components/common/LightTooltip';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';

interface SecureMessageListProps {
    messageList?: SecureMessage[];
    selectedId?: number;
    sentList?: boolean;
}

const SecureMessageList: FC<SecureMessageListProps> = ({
    messageList,
    selectedId,
    sentList
}: SecureMessageListProps) => {
    const { T } = useContext(LanguageContext);
    const navigate = useNavigate();

    return (
        <>
            {!messageList && <WaitScreen />}
            {messageList && messageList.length > 0 && (
                <List>
                    {messageList.map(
                        (item, index): ReactNode => (
                            <Fragment key={index}>
                                {index > 0 && <Divider variant="middle" component="li" />}
                                <ListItem
                                    className={classnames({ 'selected-row': selectedId === item.id }, 'pointer')}
                                    onClick={() => navigate(`/communication/securemessages/${item.id}`)}
                                >
                                    {!sentList && item.unread && (
                                        <ListItemIcon sx={{ minWidth: 32 }}>
                                            <LightTooltip title={T('Message_Unread')}>
                                                <Mail color="primary" />
                                            </LightTooltip>
                                        </ListItemIcon>
                                    )}
                                    <Grid container>
                                        <Grid container item justifyContent="space-between">
                                            <Grid container item justifyContent="flex-start" xs={8}>
                                                <Typography variant="subtitle2" color="textSecondary" noWrap>
                                                    {sentList && item.recipient?.name}
                                                    {!sentList && item.sender?.name}
                                                </Typography>
                                            </Grid>
                                            <Grid container item justifyContent="flex-end" xs={4}>
                                                <Typography variant="subtitle2" color="textSecondary" noWrap>
                                                    {`${moment(item.createdDate).format('l')} ${moment(
                                                        item.createdDate
                                                    ).format('LT')}`}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid container item>
                                            <Typography
                                                sx={item.unread ? { width: 330, fontWeight: 600 } : { width: 370 }}
                                                noWrap
                                                color="primary"
                                                title={item.title}
                                            >
                                                {item.title}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </ListItem>
                            </Fragment>
                        )
                    )}
                </List>
            )}
            {messageList && messageList.length === 0 && (
                <Box p={2}>
                    <Typography variant="body1" color="textSecondary">
                        {T('SecureMessages_NoMessages')}
                    </Typography>
                </Box>
            )}
        </>
    );
};

export default SecureMessageList;
