import React, { FC, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { LanguageContext } from 'contexts/languageContext';
import { SmsMessageInput, ReportFilter as ReportFilterType } from 'types/sp-api';
import { useErrorHandler } from 'components/hooks/useErrorHandler';
import SmsMessagesService from 'services/smsMessagesService';
import { Button, Grid, Typography } from '@mui/material';
import { useFormikContext } from 'formik';
import axios, { CancelTokenSource } from 'axios';
import DialogShowRecipients from './DialogShowRecipients';

interface RecipientCounterProps {
    organizationId: number;
    filter?: ReportFilterType;
    updateCount?: (count: number) => void;
}

const RecipientCounter: FC<RecipientCounterProps> = ({
    organizationId,
    filter,
    updateCount
}: RecipientCounterProps) => {
    const { T } = useContext(LanguageContext);
    const { handleError } = useErrorHandler();
    const [recipientCount, setRecipientCount] = useState(0);
    const cancelRef = useRef<CancelTokenSource | null>(null);
    const formik = useFormikContext<SmsMessageInput & { recipientCount: number }>();
    const [showRecipientsDialog, setShowRecipientsDialog] = useState(false);

    const getRecipientCount = useCallback(
        async (orgId: number, inFilter: ReportFilterType): Promise<void> => {
            try {
                !cancelRef.current && (cancelRef.current = axios.CancelToken.source());
                const count = await SmsMessagesService.getRecipientCountAsync(orgId, inFilter, cancelRef.current.token);

                if (cancelRef.current) {
                    setRecipientCount(count);
                    updateCount && updateCount(count);
                    formik.setFieldValue('recipientCount', count);
                }
            } catch (error) {
                handleError(error, T('Error_OrganizationMemberQueryFailed'));
            }
        },
        [handleError, T]
    );

    // update field to reload counter if filter changed
    useEffect(() => {
        if (filter) {
            getRecipientCount(organizationId, filter);
        }

        return () => {
            cancelRef.current && cancelRef.current.cancel();
            cancelRef.current = null;
        };
    }, [getRecipientCount, filter]);

    return (
        <Grid container alignItems="center">
            <Grid item xs={6}>
                <Typography variant="subtitle1" color="textSecondary">
                    {`${T('SmsMessage_RecipientCount').replace('{0}', recipientCount?.toString() ?? '-')}`}
                </Typography>
            </Grid>
            <Grid item xs={6}>
                {filter && (
                    <>
                        <Button variant="contained" color="secondary" onClick={() => setShowRecipientsDialog(true)}>
                            {T('SmsMessage_ShowRecipientList')}
                        </Button>
                        <DialogShowRecipients
                            show={showRecipientsDialog}
                            organizationId={organizationId}
                            filter={filter}
                            onClose={() => setShowRecipientsDialog(false)}
                        />
                    </>
                )}
            </Grid>
        </Grid>
    );
};

export default RecipientCounter;
