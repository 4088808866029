import { Settings } from 'types/sp-api';
import { AppLanguage } from 'types/common';
import constants from './constants';

const defaultLanguage: AppLanguage = AppLanguage.Finnish;

const defaultSmsSender: Settings = {
    key: constants.Settings.SmsSender,
    value: 'Sähköpaimen',
    settingsType: 0,
    beginDate: new Date()
};

export default { Language: defaultLanguage, SmsSender: defaultSmsSender };
