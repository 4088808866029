import React, { FC, useCallback, useContext, useRef, useState } from 'react';
import {
    Button,
    ButtonGroup,
    ClickAwayListener,
    Grow,
    IconButton,
    MenuItem,
    MenuList,
    Paper,
    Popper
} from '@mui/material';
import { SubMenuItem } from 'types/common';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from 'contexts/authContext';
import { SvgIconComponent } from '@mui/icons-material';

interface ButtonWithMenuProps {
    title: string;
    disabled?: boolean;
    loading?: boolean;
    submenu?: SubMenuItem[];
    titleClass?: string;
    className?: string;
    showBorder?: boolean;
    iconOnly?: boolean;
    icon?: SvgIconComponent;
}

const ButtonWithMenu: FC<ButtonWithMenuProps> = ({
    title,
    disabled,
    loading,
    submenu,
    titleClass,
    showBorder,
    iconOnly,
    icon
}: ButtonWithMenuProps) => {
    const [menuOpen, setMenuOpen] = useState(false);
    const anchorRef = useRef(null);
    const navigate = useNavigate();
    const { hasRole } = useContext(AuthContext);
    const Icon = icon as SvgIconComponent;

    const handleMenuItemClick = async (url: string) => {
        navigate(url);
        setMenuOpen(false);
    };

    const toggleMenu = useCallback(() => {
        setMenuOpen((prevOpen) => !prevOpen);
    }, []);

    const closeMenu = useCallback(() => {
        setMenuOpen(false);
    }, []);

    return (
        <>
            <ButtonGroup
                ref={anchorRef}
                disabled={disabled}
                sx={
                    iconOnly
                        ? { pl: 1, pr: 1 }
                        : showBorder
                        ? {
                              pl: 3,
                              pr: 2,
                              borderRight: '1px solid',
                              borderTopRightRadius: 0,
                              borderBottomRightRadius: 0
                          }
                        : { pl: 3, pr: 2 }
                }
                variant="text"
            >
                {iconOnly ? (
                    <>
                        <IconButton
                            color="primary"
                            size="small"
                            onClick={toggleMenu}
                            disabled={loading}
                            sx={{ pl: 0, pr: 0 }}
                            title={title}
                        >
                            <Icon htmlColor="white" />
                        </IconButton>
                    </>
                ) : (
                    <>
                        <Button
                            onClick={toggleMenu}
                            className={titleClass}
                            sx={{ border: 'none !important', pl: 0, pr: 0 }}
                        >
                            {title}
                        </Button>
                        <IconButton
                            color="primary"
                            size="small"
                            onClick={toggleMenu}
                            disabled={loading}
                            sx={{ pl: 0, pr: 0 }}
                        >
                            <ArrowDropDownIcon htmlColor="white" />
                        </IconButton>
                    </>
                )}
            </ButtonGroup>
            <Popper
                open={menuOpen}
                anchorEl={anchorRef.current}
                transition
                disablePortal
                placement="bottom-start"
                style={{ zIndex: 999 }}
                className={titleClass}
                nonce={undefined}
            >
                {({ TransitionProps }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin: 'top'
                        }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={closeMenu}>
                                <MenuList id="split-button-menu" sx={{ backgroundColor: 'primary.main' }}>
                                    {submenu
                                        ?.filter((item) => !item.role || hasRole(item.role))
                                        .map((value, index) => (
                                            <MenuItem
                                                key={index}
                                                onClick={() => handleMenuItemClick(value.url)}
                                                sx={{ color: '#ffffff', textDecoration: 'none', fontSize: 13 }}
                                            >
                                                {value.title.toUpperCase()}
                                            </MenuItem>
                                        ))}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </>
    );
};

export default ButtonWithMenu;
