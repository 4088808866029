import React, { ChangeEvent, FC, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Box, Grid, Typography } from '@mui/material';
import { PersonTabProps } from './PersonTabBasic';
import { LanguageContext } from 'contexts/languageContext';
import { MembershipListItem } from 'types/common';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import classNames from 'classnames';
import ReportBaptismCertificate from './ReportBaptismCertificate';
import ReportMembershipReport from './ReportMembershipReport';
import ViewSubtitle from 'components/common/ViewSubtitle';
import FormFieldDropDown from 'components/common/FormFieldDropDown';
import { Form, Formik, FormikProps } from 'formik';
import config from 'config/config';
import constants from 'config/constants';
import axios, { CancelTokenSource } from 'axios';
import { useErrorHandler } from 'components/hooks/useErrorHandler';
import PersonsService from 'services/personsService';
import TypeUtils from 'utils/typeHelper';
import { Membership } from 'types/sp-api';
import ArchiveService from 'services/archiveService';
import moment from 'moment';
import ReportPersonalInformationReport from './ReportPersonalInformationReport';

interface PersonTabReportsProps extends PersonTabProps {
    archive?: boolean;
}

const PersonTabReports: FC<PersonTabReportsProps> = ({ person, archive }: PersonTabReportsProps) => {
    const { T } = useContext(LanguageContext);
    const [expanded, setExpanded] = useState<string | false>(false);
    const [membershipList, setMembershipList] = useState<MembershipListItem[]>();
    const cancelRef = useRef<CancelTokenSource | null>(null);
    const { handleError } = useErrorHandler();

    const sortMemberships = (a: MembershipListItem, b: MembershipListItem) => {
        // sort local community before assosiations
        if (!a || !b) return 0;
        if (!a.organizationType || !b.organizationType) return 0;
        if (a.organizationType < b.organizationType) return 1;
        if (a.organizationType > b.organizationType) return -1;
        return 0;
    };

    const getMembershipList = useCallback(
        async (id?: number, arch?: boolean): Promise<void> => {
            try {
                cancelRef.current = axios.CancelToken.source();
                let personMemberships: Membership[];
                if (arch) {
                    personMemberships = await ArchiveService.getPersonMembershipsAsync(id, cancelRef.current.token);
                } else {
                    personMemberships = await PersonsService.getPersonMembershipsAsync(id, cancelRef.current.token);
                }
                if (cancelRef.current) {
                    setMembershipList(
                        personMemberships
                            .map(
                                (item): MembershipListItem => ({
                                    ...item,
                                    organizationType: TypeUtils.OrganizationTypeToName(item.organization?.type),
                                    valid: item.endDate === null
                                })
                            )
                            .sort(sortMemberships)
                    );
                }
            } catch (error) {
                handleError(error, T('Error_PersonMembersipQueryFailed'));
            }
        },
        [handleError, T]
    );

    useEffect(() => {
        getMembershipList(person.id, archive);

        return () => {
            cancelRef.current && cancelRef.current.cancel();
            cancelRef.current = null;
        };
    }, [getMembershipList, person.id, archive]);

    const handleChange = (panel: string) => (_: ChangeEvent<unknown>, isExpanded: boolean) => {
        setExpanded(isExpanded ? panel : false);
    };

    if (!membershipList) return null;

    return (
        <Formik
            initialValues={{ reporting_target: membershipList[0].id ?? 0 }}
            enableReinitialize
            onSubmit={() => {
                // do nothing
            }}
        >
            {({ values, setFieldValue }: FormikProps<{ reporting_target: number }>) => {
                if (membershipList.length == 1 && values.reporting_target != membershipList[0].id) {
                    setFieldValue('reporting_target', membershipList[0].id);
                }
                return (
                    <Grid container spacing={2}>
                        <Grid container item xs={6} spacing={2}>
                            <ViewSubtitle title={T('Person_ReportsTabTitle')} />
                            {membershipList.length > 1 && (
                                <>
                                    <Grid item xs={12} style={{ marginLeft: 8 }}>
                                        <Typography>{T('Person_ReportsMembershipTarget')}:</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Form>
                                            <FormFieldDropDown
                                                name="reporting_target"
                                                values={membershipList.map((value) => {
                                                    const endText =
                                                        value.endDate != null
                                                            ? `- ${T('Membership_Ended')} ${moment(
                                                                  value.endDate
                                                              ).format('L')}`
                                                            : '';
                                                    const membershipText = `${value.organization?.name} ${endText} `;
                                                    return {
                                                        key: value.id,
                                                        text: membershipText
                                                    };
                                                })}
                                            />
                                        </Form>
                                    </Grid>
                                </>
                            )}
                        </Grid>
                        <Grid item xs={12}>
                            <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                                <AccordionSummary
                                    className={classNames(
                                        { 'accordion-item': expanded !== 'panel2' },
                                        { 'accordion-selected': expanded === 'panel2' }
                                    )}
                                    expandIcon={<ExpandMoreIcon fontSize="large" />}
                                >
                                    <Grid container spacing={3}>
                                        <Grid item xs={4}>
                                            <Typography variant="h6" color="textPrimary">
                                                {T('Report_BaptismCertificate')}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Box padding={3}>
                                        <ReportBaptismCertificate
                                            person={person}
                                            membership={membershipList.find(
                                                (item) => item.id === values.reporting_target
                                            )}
                                        />
                                    </Box>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                                <AccordionSummary
                                    className={classNames(
                                        { 'accordion-item': expanded !== 'panel3' },
                                        { 'accordion-selected': expanded === 'panel3' }
                                    )}
                                    expandIcon={<ExpandMoreIcon fontSize="large" />}
                                >
                                    <Grid container spacing={3}>
                                        <Grid item xs={12}>
                                            <Typography variant="h6" color="textPrimary">
                                                {config.CUSTOMER === constants.Customers.Advk
                                                    ? T('Report_MembershipReport')
                                                    : `${T('Report_MembershipReport')} / ${T(
                                                          'Report_MembershipCertificate'
                                                      )}`}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Box padding={3}>
                                        <ReportMembershipReport
                                            person={person}
                                            membership={membershipList.find(
                                                (item) => item.id === values.reporting_target
                                            )}
                                        />
                                    </Box>
                                </AccordionDetails>
                            </Accordion>
                            {archive && (
                                <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                                    <AccordionSummary
                                        className={classNames(
                                            { 'accordion-item': expanded !== 'panel4' },
                                            { 'accordion-selected': expanded === 'panel4' }
                                        )}
                                        expandIcon={<ExpandMoreIcon fontSize="large" />}
                                    >
                                        <Grid container spacing={3}>
                                            <Grid item xs={12}>
                                                <Typography variant="h6" color="textPrimary">
                                                    {T('Report_PersonalInformationReport')}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Box padding={3}>
                                            <ReportPersonalInformationReport person={person} />
                                        </Box>
                                    </AccordionDetails>
                                </Accordion>
                            )}
                        </Grid>
                    </Grid>
                );
            }}
        </Formik>
    );
};

export default PersonTabReports;
