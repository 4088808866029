import React, { FC, ReactNode, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { LanguageContext, LanguageTextFunc } from 'contexts/languageContext';
import {
    TableContainer,
    Paper,
    Table,
    TableBody,
    TableRow,
    TableCell,
    TableHead,
    Grid,
    Typography
} from '@mui/material';
import classnames from 'classnames';
import WaitScreen from 'components/common/WaitScreen';
import ButtonAdd from 'components/common/ButtonAdd';
import { TableHeadCell } from '../common/TableHeadCell';
import DialogBase from 'components/common/DialogBase';
import { Formik, FormikProps, Form } from 'formik';
import { Membership, OrganizationContact, Person } from 'types/sp-api';
import * as Yup from 'yup';
import FormFieldDate from 'components/common/FormFieldDate';
import FormFieldDropDownCodeSet from 'components/common/FormFieldDropDownCodeSet';
import FormFieldDropDownOrganization from 'components/common/FormFieldDropDownOrganization';
import moment from 'moment';
import {
    DeleteDialogState,
    DialogOpenState,
    MemberPrerequisite,
    MembershipListItem,
    MembershipType,
    TerminationReason
} from 'types/common';
import MembershipsService from 'services/membershipsService';
import { useErrorHandler } from 'components/hooks/useErrorHandler';
import { useSuccessHandler } from 'components/hooks/useSuccessHandler';
import TypeUtils from 'utils/typeHelper';
import axios, { CancelTokenSource } from 'axios';
import ContactService from 'services/contactService';
import DialogConfirmation from 'components/common/DialogConfirmation';
import ButtonDelete from 'components/common/ButtonDelete';
import PersonUtils from 'utils/personHelper';
import TransferWithinAStationIcon from '@mui/icons-material/TransferWithinAStation';
import constants from 'config/constants';

const getValidationSchema = (T: LanguageTextFunc, values: Membership, isYhdyskunta: boolean) => {
    if (values.type === MembershipType.Interested) {
        return Yup.object().shape({
            organizationId: Yup.number().test('not-0-value', T('Common_Required'), (value) => value !== 0)
        });
    }

    return Yup.object().shape({
        type: Yup.number().test('not-0-value', T('Common_Required'), (value) => value !== 0 || isYhdyskunta === false),
        beginDate: Yup.date().typeError(T('Common_Required')).required(T('Common_Required')),
        prerequisite: Yup.number().test('not-0-value', T('Common_Required'), (value) => value !== 0),
        organizationId: Yup.number().test('not-0-value', T('Common_Required'), (value) => value !== 0)
    });
};

interface MembershipListProps {
    person: Person;
    membershipList: MembershipListItem[];
    selectedMembershipId?: number;
    onSelectMembership: (membershipId?: number) => void;
    updateMembershipList: () => void;
    archive?: boolean;
    allowedEdit: boolean;
}

const MembershipList: FC<MembershipListProps> = ({
    person,
    membershipList,
    selectedMembershipId,
    onSelectMembership,
    updateMembershipList,
    archive,
    allowedEdit
}: MembershipListProps) => {
    const { T } = useContext(LanguageContext);
    const [openAddDialog, setOpenAddDialog] = useState<DialogOpenState>(false);
    const [sending, setSending] = useState(false);
    const { handleError } = useErrorHandler();
    const { handleSuccess } = useSuccessHandler();
    const [organizations, setOrganizations] = useState<OrganizationContact[]>([]);
    const cancelRef = useRef<CancelTokenSource | null>(null);
    const [deleteDialogState, setDeleteDialogState] = useState<DeleteDialogState>();

    const getOrganizations = useCallback(async (): Promise<void> => {
        try {
            cancelRef.current = axios.CancelToken.source();
            const orgs = await ContactService.getOrganizationsAsync(
                undefined,
                cancelRef.current.token,
                false,
                false,
                true
            );
            if (cancelRef.current) {
                setOrganizations(orgs);
            }
        } catch (error) {
            handleError(error, T('Error_OrganizationQueryFailed'));
        }
    }, [handleError, T]);

    useEffect(() => {
        if (organizations.length === 0) getOrganizations();
    });

    const addMembership = () => {
        setOpenAddDialog(true);
    };

    const saveMembership = async (values: Membership): Promise<void> => {
        setSending(true);
        if (values.type === MembershipType.Unknown) {
            // for yhdistys type is not set by dialog
            values.type = MembershipType.FullMember;
        }
        if (values.type === MembershipType.Interested) {
            values.beginDate = new Date(constants.Settings.InterestedMembershipStart);
            values.prerequisite = MemberPrerequisite.Interested;
        }

        try {
            await MembershipsService.saveMembershipAsync(person.id, values);
            updateMembershipList();
            setSending(false);
            handleSuccess(T('Membership_SaveSuccess'));
        } catch (error) {
            setSending(false);
            handleError(error, T('Membership_SaveFailed'));
        }
    };

    const deleteMembership = async () => {
        if (deleteDialogState?.id) {
            try {
                await MembershipsService.deletePersonMembershipAsync(person.id, deleteDialogState?.id);
                updateMembershipList();
                handleSuccess(T('Membership_DeleteSuccess'));
            } catch (error) {
                handleError(error, T('Membership_DeleteFailed'));
            }
        }
    };

    const isYhdyskunta = (organizationId: number): boolean => {
        const organization = organizations.find((item) => item.id === organizationId);
        return TypeUtils.IsLocalYhdyskunta(organization?.type);
    };

    const isTransfer = (membership: MembershipListItem): ReactNode => {
        let message;
        if (!membership.endDate && membership.terminationReason === TerminationReason.Transfered) {
            message = T('Membership_TrasferOut');
        }
        if (moment(membership.beginDate).year() === 9999 && membership.prerequisite === MemberPrerequisite.Transfer) {
            message = T('Membership_TrasferIn');
        }

        return (
            message && (
                <>
                    <TransferWithinAStationIcon fontSize="small" style={{ marginLeft: 8 }} />
                    <Typography variant="caption" color="textSecondary" component="span">
                        {` (${message})`}
                    </Typography>
                </>
            )
        );
    };

    if (!membershipList || !person.id) return <WaitScreen />;

    return (
        <>
            <Grid item xs={12}>
                <TableContainer component={Paper}>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableHeadCell sx={{ minWidth: 200 }}>{T('Membership_Organization')}</TableHeadCell>
                                {archive && <TableHeadCell>{T('Membership_Validity')}</TableHeadCell>}
                                <TableHeadCell>{T('Membership_OrganizationType')}</TableHeadCell>
                                <TableHeadCell sx={{ width: 40 }}></TableHeadCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {membershipList.length === 0 && (
                                <TableRow>
                                    <TableCell>
                                        <Typography variant="body1" color="textSecondary">
                                            {T('Membership_NoMemberships')}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            )}
                            {membershipList.length > 0 &&
                                membershipList.map((row) => (
                                    <TableRow
                                        key={row.id}
                                        onClick={() => onSelectMembership(row.id)}
                                        className={classnames(
                                            { 'selected-row': selectedMembershipId === row.id },
                                            'pointer'
                                        )}
                                    >
                                        <TableCell>
                                            {row.organization?.name}
                                            {isTransfer(row)}
                                        </TableCell>
                                        {archive && (
                                            <TableCell>
                                                {!!row.endDate &&
                                                    `${T('Membership_Ended')} ${moment(row.endDate).format('l')}`}
                                            </TableCell>
                                        )}
                                        <TableCell>{T(row.organizationType)}</TableCell>

                                        <TableCell>
                                            {!archive && allowedEdit && (
                                                <ButtonDelete
                                                    small
                                                    onClick={() =>
                                                        setDeleteDialogState({
                                                            show: true,
                                                            message: T('Membership_ConfirmDeleteMessage')
                                                                .replace('{0}', PersonUtils.FullName(person))
                                                                .replace('{1}', row.organization?.name ?? ''),
                                                            id: row.id ?? 0
                                                        })
                                                    }
                                                >
                                                    {T('Common_Delete')}
                                                </ButtonDelete>
                                            )}
                                        </TableCell>
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
            <Grid item xs={4} container justifyContent="flex-start">
                {!selectedMembershipId && (
                    <Typography variant="body1" color="secondary" fontSize={13} paddingLeft={1}>
                        {T('Membership_SelectMembership')}
                    </Typography>
                )}
            </Grid>
            <Grid item xs={8} container justifyContent="flex-end">
                {allowedEdit && (
                    <ButtonAdd
                        small
                        title={T('MemberShip_AddNew')}
                        variant="text"
                        onClick={() => addMembership()}
                        color="secondary"
                    />
                )}
            </Grid>
            <Formik
                initialValues={{
                    id: undefined,
                    personId: person.id,
                    type: MembershipType.Unknown,
                    beginDate: moment().toDate(),
                    endDate: undefined,
                    organizationId: 0,
                    prerequisite: 0
                }}
                validationSchema={() =>
                    Yup.lazy((values) => getValidationSchema(T, values, isYhdyskunta(values.organizationId)))
                }
                onSubmit={saveMembership}
            >
                {({ dirty, isValid, isSubmitting, submitForm, resetForm, values }: FormikProps<Membership>) => (
                    <DialogBase
                        show={!!openAddDialog}
                        title={T('Membership_AddNewDialogTitle')}
                        onOk={async () => {
                            await submitForm();
                            resetForm();
                        }}
                        onClose={() => setOpenAddDialog(false)}
                        okDisabled={isSubmitting || !dirty || !isValid}
                        okTitle={T('MemberShip_AddNew')}
                        loading={sending}
                    >
                        <Form>
                            <Grid container spacing={3} sx={{ pt: 1 }}>
                                <Grid item xs={12}>
                                    <FormFieldDropDownOrganization
                                        name="organizationId"
                                        label={T('Membership_FieldOrganization')}
                                        placeholder={T('Membership_SelectOrganization')}
                                        showAllAccessable
                                    />
                                </Grid>
                                {isYhdyskunta(values.organizationId) && (
                                    <Grid item xs={12}>
                                        <FormFieldDropDownCodeSet
                                            name="type"
                                            label={T('Membership_FieldMembershipType')}
                                            codeSet="membershipType"
                                            placeholder={T('Membership_SelectType')}
                                            hiddenValues={[6]}
                                        />
                                    </Grid>
                                )}
                                {values.type !== MembershipType.Interested && (
                                    <>
                                        <Grid item xs={12}>
                                            <FormFieldDropDownCodeSet
                                                name="prerequisite"
                                                label={T('Membership_FieldPrerequisite')}
                                                codeSet="membershipPrerequisite"
                                                placeholder={T('Membership_SelectPrerequisite')}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <FormFieldDate label={T('Membership_FieldBeginDate')} name="beginDate" />
                                        </Grid>
                                    </>
                                )}
                            </Grid>
                        </Form>
                    </DialogBase>
                )}
            </Formik>
            <DialogConfirmation
                title={T('Membership_ConfirmDeleteTitle')}
                message={deleteDialogState?.message}
                show={deleteDialogState?.show}
                onClose={() => setDeleteDialogState(undefined)}
                onOk={deleteMembership}
                warning
                okTitle={T('Common_Delete')}
            />
        </>
    );
};

export default MembershipList;
