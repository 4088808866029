import React, { FC, useContext, useEffect, useState } from 'react';
import { LanguageContext } from 'contexts/languageContext';
import { SmsMessageInput } from 'types/sp-api';
import { Typography } from '@mui/material';
import { useFormikContext } from 'formik';
import constants from 'config/constants';

interface SmsMessagePriceCounterProps {
    updateSmsPerRecipient: (count: number) => void;
}

const SmsMessagePriceCounter: FC<SmsMessagePriceCounterProps> = ({
    updateSmsPerRecipient
}: SmsMessagePriceCounterProps) => {
    const { T } = useContext(LanguageContext);
    const { values } = useFormikContext<SmsMessageInput & { recipientCount: number }>();
    const [smsCounter, setSmsCounter] = useState({ smsCount: 0, smsPrice: 0 });

    useEffect(() => {
        let message = '';

        if (values.message) {
            message = values.message;
        }

        const smsCount = message.length == 0 ? 0 : message.length <= 160 ? 1 : Math.floor(message.length / 153) + 1;
        const smsPrice = smsCount * constants.Settings.SmsPrice * values.recipientCount;

        setSmsCounter({ smsCount: smsCount, smsPrice: smsPrice });
        updateSmsPerRecipient(smsCount);
    }, [values.message, values.recipientCount]);

    return (
        <Typography variant="caption" color="textSecondary">
            {T('SmsMessage_CharacterCount')
                .replace('{0}', values.message ? values.message.length?.toString() : '0')
                .replace('{1}', smsCounter.smsCount.toString())
                .replace('{2}', smsCounter.smsPrice.toFixed(2))}
        </Typography>
    );
};

export default SmsMessagePriceCounter;
