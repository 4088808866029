import React, { FC, useContext } from 'react';
import { Grid, IconButton } from '@mui/material';
import { LanguageContext } from 'contexts/languageContext';
import { AppLanguage } from 'types/common';
import FlagFI from 'components/icons/FlagFI';
import FlagSE from 'components/icons/FlagSE';
import FlagEN from 'components/icons/FlagEN';

const LanguageSelectionFlags: FC = () => {
    const { setLanguage } = useContext(LanguageContext);

    return (
        <Grid item xs={12} container justifyContent="center" spacing={2}>
            <Grid item>
                <IconButton size="small" onClick={() => setLanguage(AppLanguage.Finnish)}>
                    <FlagFI />
                </IconButton>
            </Grid>
            <Grid item>
                <IconButton size="small" onClick={() => setLanguage(AppLanguage.Swedish)}>
                    <FlagSE />
                </IconButton>
            </Grid>
            <Grid item>
                <IconButton size="small" onClick={() => setLanguage(AppLanguage.English)}>
                    <FlagEN />
                </IconButton>
            </Grid>
        </Grid>
    );
};

export default LanguageSelectionFlags;
