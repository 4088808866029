import axios, { AxiosResponse, CancelToken } from 'axios';
import { InfoType, SaveResult } from 'types/common';
import { Credential, Membership, Person, PersonCloseRelative, PersonReligiousInfo, User } from 'types/sp-api';
import PersonUtils from 'utils/personHelper';

class PersonsService {
    public async getPersonAsync(id: number, cancelToken?: CancelToken): Promise<Person> {
        const response = await axios.get(`persons/${id}`, { cancelToken: cancelToken });
        return response.data;
    }

    public async savePersonAsync(person: Person): Promise<SaveResult> {
        if (person.id && person.id > 0) {
            const result = await axios.put<Person, AxiosResponse<Person & { membershipEnded?: boolean }>>(
                `persons/${person.id}`,
                person
            );
            return { new: false, id: person.id, membershipEnded: result.data.membershipEnded };
        } else {
            const result = await axios.post(`persons/`, person);
            return { new: true, id: result.data.id ?? 0 };
        }
    }

    public async getPersonMembershipsAsync(id?: number, cancelToken?: CancelToken): Promise<Membership[]> {
        if (!id) return [];

        const response = await axios.get(`persons/${id}/memberships`, { cancelToken: cancelToken });
        return response.data;
    }

    public async getPersonReligiousInfosAsync(
        id: number,
        type: InfoType,
        cancelToken?: CancelToken
    ): Promise<PersonReligiousInfo[]> {
        const typeNumber = type === InfoType.Conversion ? 1 : 2;

        const params = new URLSearchParams();
        params.append('query', `type==${typeNumber}`);

        const response = await axios.get<PersonReligiousInfo[]>(`persons/${id}/religiousInfos`, {
            params: params,
            cancelToken: cancelToken
        });
        return response.data;
    }

    public async savePersonReligiousInfosAsync(id?: number, info?: PersonReligiousInfo): Promise<void> {
        if (!id || !info) return;

        if (info?.id && info.id > 0) {
            await axios.put(`persons/${id}/religiousInfos/${info?.id}`, info);
        } else {
            await axios.post(`persons/${id}/religiousInfos`, info);
        }
    }

    public async deleteReligiousInfoAsync(personId?: number, religiousInfoId?: number): Promise<void> {
        if (!personId || !religiousInfoId) return;

        await axios.delete(`persons/${personId}/religiousInfos/${religiousInfoId}`);
    }

    public async getUserForPersonAsync(personId?: number, cancelToken?: CancelToken): Promise<User | undefined> {
        if (!personId) return undefined;

        const response = await axios.get(`persons/${personId}/user`, { cancelToken: cancelToken });
        return response.data;
    }

    public async getAllRelativesAsync(personId: number, cancelToken?: CancelToken): Promise<PersonCloseRelative[]> {
        const response = await axios.get<PersonCloseRelative[]>(`persons/${personId}/relatives`, {
            cancelToken: cancelToken
        });

        const listOrder = (a: PersonCloseRelative, b: PersonCloseRelative): number => {
            const a_relative = a.relative ? PersonUtils.FullName(a.relative) : a.relativeInfo;
            const b_relative = b.relative ? PersonUtils.FullName(b.relative) : b.relativeInfo;

            if (!a_relative || !b_relative) return 0;

            if (a_relative > b_relative) return 1;
            if (a_relative < b_relative) return -1;

            return 0;
        };

        return response.data.sort(listOrder);
    }

    public async getRelativeAsync(
        personId: number,
        relativeId: number,
        cancelToken?: CancelToken
    ): Promise<PersonCloseRelative> {
        const response = await axios.get(`persons/${personId}/relatives/${relativeId}`, { cancelToken: cancelToken });
        return response.data;
    }

    public async saveRelativeAsync(personId?: number, relative?: PersonCloseRelative): Promise<void> {
        if (!personId) return;

        if (relative?.id && relative.id > 0) {
            await axios.put(`persons/${personId}/relatives/${relative?.id}`, relative);
        } else {
            await axios.post(`persons/${personId}/relatives`, relative);
        }
    }

    public async deleteRelativeAsync(personId?: number, relativeId?: number): Promise<void> {
        if (!personId || !relativeId) return;

        await axios.delete(`persons/${personId}/relatives/${relativeId}`);
    }

    public async deletePersonAsync(personId?: number): Promise<void> {
        if (!personId) return;

        await axios.delete(`persons/${personId}`);
    }

    public async getAllCredentialsAsync(personId: number, cancelToken?: CancelToken): Promise<Credential[]> {
        const response = await axios.get<Credential[]>(`persons/${personId}/credentials`, {
            cancelToken: cancelToken
        });

        return response.data;
    }
}

export default new PersonsService();
