import React, { FC, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Button, Grid, Typography } from '@mui/material';
import { LanguageContext, LanguageTextFunc } from 'contexts/languageContext';
import * as Yup from 'yup';
import { useErrorHandler } from 'components/hooks/useErrorHandler';
import { useSuccessHandler } from 'components/hooks/useSuccessHandler';
import axios, { CancelTokenSource } from 'axios';
import { Formik, Form, FormikProps } from 'formik';
import LoadButton from 'components/common/LoadButton';
import FormFieldText from 'components/common/FormFieldText';
import DialogConfirmation from 'components/common/DialogConfirmation';
import { DeleteDialogState } from 'types/common';
import { Settings } from 'types/sp-api';
import SettingsService from 'services/settingsService';
import defaults from 'config/defaults';
import constants from 'config/constants';

export const getValidationSchema = (T: LanguageTextFunc): Yup.AnyObjectSchema => {
    return Yup.object().shape({
        value: Yup.string().required(T('Common_Required'))
    });
};

interface SettingsTabTagTitlesProps {
    organizationId: number;
}

const SettingsTabSmsSender: FC<SettingsTabTagTitlesProps> = ({ organizationId }: SettingsTabTagTitlesProps) => {
    const { T } = useContext(LanguageContext);
    const { handleError } = useErrorHandler();
    const { handleSuccess } = useSuccessHandler();
    const [setting, setSetting] = useState<Settings>();
    const cancelRef = useRef<CancelTokenSource | null>(null);
    const [sending, setSending] = useState(false);
    const [deleting, setDeleting] = useState(false);
    const [deleteDialogState, setDeleteDialogState] = useState<DeleteDialogState>();

    const getSetting = useCallback(
        async (orgId: number, key: string): Promise<void> => {
            try {
                !cancelRef.current && (cancelRef.current = axios.CancelToken.source());
                const orgSetting = await SettingsService.getSettingAsync(orgId, key, cancelRef.current.token);

                if (cancelRef.current) {
                    if (!orgSetting || !orgSetting.key) {
                        setSetting(defaults.SmsSender);
                    } else {
                        setSetting(orgSetting);
                    }
                }
            } catch (error) {
                handleError(error, T('Error_OrganizationSettingsQueryFailed'));
            }
        },
        [handleError, T]
    );

    useEffect(() => {
        getSetting(organizationId, constants.Settings.SmsSender);

        return () => {
            cancelRef.current && cancelRef.current.cancel();
            cancelRef.current = null;
        };
    }, [getSetting, organizationId]);

    const reloadSetting = () => {
        getSetting(organizationId, constants.Settings.SmsSender);
    };

    const saveSetting = async (values: Settings): Promise<void> => {
        setSending(true);

        try {
            await SettingsService.saveSettingsAsync(organizationId, values);
            setSending(false);
            handleSuccess(T('Settings_SettingsSaveSuccess'));
        } catch (error) {
            setSending(false);
            handleError(error, T('Settings_SettingsSaveFailed'));
        }
    };

    const deleteSetting = async (orgId: number, settingId: number): Promise<void> => {
        setDeleting(true);

        try {
            await SettingsService.deleteSettingsAsync(orgId, settingId);
            setDeleting(false);
            handleSuccess(T('Settings_SettingsDeleteSuccess'));
            reloadSetting();
        } catch (error) {
            setDeleting(false);
            handleError(error, T('Settings_SettingsDeleteFailed'));
        }
    };

    return (
        <Formik
            initialValues={setting ?? { key: '', value: '', settingsType: 0, beginDate: new Date() }}
            enableReinitialize
            validationSchema={getValidationSchema(T)}
            onSubmit={saveSetting}
        >
            {({ dirty, isValid, resetForm }: FormikProps<Settings>) => (
                <Form>
                    <Grid container item alignItems="flex-start" xs={6} spacing={3}>
                        <Grid item xs={12}>
                            <Typography color="textSecondary">{T('SettingsTabSmsSender_EditTitle')}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <FormFieldText
                                name="value"
                                label={T('Settings_SmsSenderTitle')}
                                inputTagProps={{ maxLength: 11 }}
                            />
                            <Typography variant="body2" color="textSecondary">
                                {T('Settings_SmsSenderHelpText')}
                            </Typography>
                        </Grid>
                        <Grid item container xs={12} justifyContent="space-between">
                            <Grid item>
                                <LoadButton loading={sending} disabled={!dirty || !isValid} type="submit">
                                    {T('Common_Save')}
                                </LoadButton>
                                <Button
                                    disabled={!dirty}
                                    style={{ marginLeft: 16 }}
                                    onClick={() => {
                                        resetForm();
                                    }}
                                    color="secondary"
                                >
                                    {T('Common_Cancel')}
                                </Button>
                            </Grid>
                            <Grid item>
                                <LoadButton
                                    loading={deleting}
                                    variant="outlined"
                                    color="error"
                                    onClick={() =>
                                        setDeleteDialogState({
                                            show: true,
                                            message: T('SettingsTabSmsSender_ConfirmDeleteMessage'),
                                            id: setting?.id ?? 0
                                        })
                                    }
                                >
                                    {T('Common_Delete')}
                                </LoadButton>
                            </Grid>
                        </Grid>
                        <DialogConfirmation
                            title={T('SettingsTabSmsSender_ConfirmDelete')}
                            message={deleteDialogState?.message}
                            show={deleteDialogState?.show}
                            onClose={() => setDeleteDialogState(undefined)}
                            onOk={() => {
                                deleteSetting(organizationId, setting?.id ?? 0);
                            }}
                            warning
                        />
                    </Grid>
                </Form>
            )}
        </Formik>
    );
};

export default SettingsTabSmsSender;
