import React, { useCallback, useContext } from 'react';
import { LanguageContext } from 'contexts/languageContext';
import { useSnackbar } from 'notistack';
import axios, { AxiosError } from 'axios';
import ErrorToast from 'components/common/ErrorToast';
import { errorHandlerFunc } from 'types/common';

export interface ErrorHandlerContext {
    handleError: errorHandlerFunc;
}

interface CustomError {
    error: string;
    message?: string;
    path: string;
    status: number;
    timestamp: Date;
}

export const useErrorHandler = (): ErrorHandlerContext => {
    const { T } = useContext(LanguageContext);
    const { enqueueSnackbar } = useSnackbar();

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleErrorInternal = (error: any, title?: string, titleKey?: string) => {
        const titleMessage = title ? title : titleKey ? T(titleKey) : T('Query_Failed');
        let message = '';

        // Ignore cancel errors
        if (axios.isCancel(error)) return;

        const axiosError: AxiosError = error;
        if (axiosError) {
            if (error.response?.data) {
                message = error.response.data.message;
            }
            if (error.reason) {
                message = error.reason.message;
            }
        } else if (error.response === undefined) {
            // network error when no response
            message = error.message;
        } else {
            const customError: CustomError = error.response.data;

            if (!isNaN(customError.status) && customError.status >= 400 && customError.status < 500) {
                message = customError.message ?? '';
            } else if (error instanceof Error) {
                message = error.message;
            } else if (typeof error === 'string') {
                message = error;
            }
        }

        enqueueSnackbar(<ErrorToast title={titleMessage} message={message} />, {
            variant: 'error',
            persist: true
        });
    };

    return { handleError: useCallback(handleErrorInternal, []) };
};
