import React, { FC, useContext } from 'react';
import { Container, Grid, Typography } from '@mui/material';
import { AccountBox } from '@mui/icons-material';
import { LanguageContext } from 'contexts/languageContext';
import BreadcrumbBar from 'components/common/BreadcrumbBar';
import ViewTitle from 'components/common/ViewTitle';
import { useParams } from 'react-router-dom';
import { PersonConsentsGrid } from 'components/persons/PersonConsentsGrid';

const UserConsentsView: FC = () => {
    const { T } = useContext(LanguageContext);
    const { userId } = useParams<'userId'>();
    const { validationToken } = useParams<'validationToken'>();

    return (
        <Container>
            <Grid container>
                <BreadcrumbBar />
                <ViewTitle title={T('Common_UserConsentsView')} icon={AccountBox} />
            </Grid>
            <Typography>TODO ohjeteksti</Typography>
            <Typography>Näistä anna suostumuksesi tai kielto, mitä tietoja järjestelmään voidaan tallentaa</Typography>
            <PersonConsentsGrid personId={parseInt(userId ?? '')} validationToken={validationToken} />
        </Container>
    );
};

export default UserConsentsView;
