import React, { CSSProperties, FC, useContext, MouseEventHandler } from 'react';
import { Button, IconButton, SxProps, Theme } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { LanguageContext } from 'contexts/languageContext';

interface ButtonEditProps {
    title?: string;
    color?: 'primary' | 'secondary' | 'inherit' | 'success' | 'error' | 'info' | 'warning';
    small?: boolean;
    tooltip?: string;
    onClick?: MouseEventHandler<HTMLButtonElement>;
    style?: CSSProperties;
    disabled?: boolean;
    sx?: SxProps<Theme>;
    variant?: 'text' | 'outlined' | 'contained';
}

const ButtonEdit: FC<ButtonEditProps> = ({
    title,
    onClick,
    color = 'primary',
    small,
    tooltip,
    style,
    disabled,
    sx,
    variant
}: ButtonEditProps) => {
    const { T } = useContext(LanguageContext);

    return (
        <>
            {title && (
                <Button
                    startIcon={<EditIcon />}
                    variant={variant}
                    size={small === true ? 'small' : 'medium'}
                    color={color}
                    onClick={onClick}
                    disabled={disabled}
                    title={tooltip ? tooltip : T('Common_Add')}
                >
                    {title}
                </Button>
            )}
            {!title && (
                <IconButton
                    size={small === true ? 'small' : 'medium'}
                    color={color}
                    onClick={(event) => {
                        onClick && onClick(event);
                        event.preventDefault();
                        event.stopPropagation();
                    }}
                    title={tooltip ? tooltip : T('Common_Edit')}
                    style={style}
                    disabled={disabled}
                    sx={sx}
                >
                    <EditIcon fontSize={small === true ? 'small' : 'medium'} />
                </IconButton>
            )}
        </>
    );
};

export default ButtonEdit;
