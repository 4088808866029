import React, { Dispatch, FC, SetStateAction, useContext } from 'react';
import { LanguageContext } from 'contexts/languageContext';
import Button from '@mui/material/Button';
import LoadButton from 'components/common/LoadButton';
import { Box } from '@mui/material';

interface StepActionsProps {
    activeStep: number;
    setActiveStep: Dispatch<SetStateAction<number>>;
    nextDisabled?: boolean;
    loading?: boolean;
    type?: 'button' | 'submit' | 'reset';
}

const StepActions: FC<StepActionsProps> = ({
    activeStep,
    setActiveStep,
    nextDisabled = false,
    loading = false,
    type = 'button'
}: StepActionsProps) => {
    const { T } = useContext(LanguageContext);

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const getNextName = (step: number) => {
        let name = 'Common_Next';
        switch (step) {
            case 0:
                name = 'Common_Agree';
                break;
            case 1:
                name = 'Registration_CreateUser';
                break;
            case 2:
                name = 'Common_Loaded';
                break;
            case 3:
                name = 'Common_Finish';
                break;
            default:
                break;
        }

        return T(name);
    };

    return (
        <Box sx={{ mt: 2, mb: 2 }}>
            {activeStep > 2 && (
                <Button onClick={handleBack} sx={{ mr: 2 }}>
                    {T('Common_Previous')}
                </Button>
            )}
            <LoadButton onClick={handleNext} loading={loading} disabled={nextDisabled} type={type}>
                {getNextName(activeStep)}
            </LoadButton>
        </Box>
    );
};

export default StepActions;
