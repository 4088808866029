import React, { FC, useContext, MouseEventHandler, CSSProperties } from 'react';
import { IconButton, SxProps, Theme } from '@mui/material';
import DeleteIcon from '@mui/icons-material/DeleteForever';
import { LanguageContext } from 'contexts/languageContext';

interface ButtonDeleteProps {
    color?: 'primary' | 'secondary' | 'default' | 'inherit' | 'info' | 'error' | 'success' | 'warning';
    small?: boolean;
    tooltip?: string;
    onClick?: MouseEventHandler<HTMLButtonElement>;
    style?: CSSProperties;
    disabled?: boolean;
    sx?: SxProps<Theme>;
    className?: string;
}

const ButtonDelete: FC<ButtonDeleteProps> = ({
    onClick,
    color = 'default',
    small,
    tooltip,
    style,
    disabled,
    sx,
    className
}: ButtonDeleteProps) => {
    const { T } = useContext(LanguageContext);

    return (
        <IconButton
            size={small === true ? 'small' : 'medium'}
            color={color}
            onClick={(event) => {
                onClick && onClick(event);
                event.preventDefault();
                event.stopPropagation();
            }}
            title={tooltip ? tooltip : T('Common_Delete')}
            style={style}
            disabled={disabled}
            sx={sx}
            className={className}
        >
            <DeleteIcon fontSize={small === true ? 'small' : 'medium'} />
        </IconButton>
    );
};

export default ButtonDelete;
