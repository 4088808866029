import React, { FC, useContext, useState } from 'react';
import { Button, Divider, Grid } from '@mui/material';
import { Person } from 'types/sp-api';
import { LanguageContext } from 'contexts/languageContext';
import FormFieldDate from 'components/common/FormFieldDate';
import { Form, Formik, FormikProps } from 'formik';
import PersonsService from 'services/personsService';
import { useErrorHandler } from 'components/hooks/useErrorHandler';
import { useSuccessHandler } from 'components/hooks/useSuccessHandler';
import LoadButton from 'components/common/LoadButton';
import CommunityTagList from './CommunityTagList';

interface MembershipItemCommunityProps {
    person: Person;
    refreshPerson: () => void;
    archive?: boolean;
    allowedEdit: boolean;
}

const MembershipItemCommunity: FC<MembershipItemCommunityProps> = ({
    person,
    refreshPerson,
    archive,
    allowedEdit
}: MembershipItemCommunityProps) => {
    const { T } = useContext(LanguageContext);
    const [sending, setSending] = useState(false);
    const { handleError } = useErrorHandler();
    const { handleSuccess } = useSuccessHandler();

    const savePerson = async (values: Person): Promise<void> => {
        setSending(true);

        try {
            await PersonsService.savePersonAsync(values);
            // TODO show info is local church membership is also terminated
            setSending(false);
            handleSuccess(T('Membership_CommunityDatesSaveSuccess'));

            refreshPerson();
        } catch (error) {
            setSending(false);
            handleError(error, T('Membership_CommunityDates_SaveFailed'));
        }
    };

    return (
        <>
            <Grid container item xs={6} spacing={1} alignContent="flex-start">
                <Grid item xs={12}>
                    <Formik initialValues={person} enableReinitialize onSubmit={savePerson}>
                        {({ values, resetForm, dirty }: FormikProps<Person>) => {
                            return (
                                <Form>
                                    <Grid container item xs={12} spacing={3}>
                                        <Grid item xs={7}>
                                            <FormFieldDate
                                                name="accessionDate"
                                                label={T('Membership_FieldAccessionDate')}
                                            />
                                        </Grid>
                                        <Grid item xs={7}>
                                            <FormFieldDate
                                                name="resignationDate"
                                                label={T('Membership_FieldResignationDate')}
                                            />
                                        </Grid>
                                        <Grid item container xs={12} justifyContent="space-between">
                                            <Grid item>
                                                <LoadButton
                                                    loading={sending}
                                                    disabled={!dirty || !allowedEdit}
                                                    type="submit"
                                                >
                                                    {T('Common_Save')}
                                                </LoadButton>
                                                <Button
                                                    disabled={!dirty && values.id !== -1}
                                                    style={{ marginLeft: 16 }}
                                                    onClick={() => resetForm()}
                                                    color="secondary"
                                                >
                                                    {T('Common_Cancel')}
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Form>
                            );
                        }}
                    </Formik>
                </Grid>
            </Grid>
            <Grid container item xs={6} spacing={1} alignContent="flex-start">
                <CommunityTagList personId={person.id ?? 0} archive={archive} />
            </Grid>
            <Grid container item xs={12}>
                <Divider sx={{ width: '100%' }} />
            </Grid>
        </>
    );
};

export default MembershipItemCommunity;
