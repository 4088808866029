import moment from 'moment';

class LocalCache {
    expirationUrl = (url: string): string => `${url}_expires`;

    public async cachedRequest<T>(url: string, fetchData: () => Promise<T>, duration?: number): Promise<T> {
        let cachedData = this.readFromCache(url);

        if (!cachedData || this.isCacheExpired(url)) {
            const response = await fetchData();

            this.writeToCache(url, response, duration);
            cachedData = this.readFromCache(url);
        }

        return cachedData as T;
    }

    // default duration is 1440 minutes == 24 hours
    public writeToCache(url: string, data: unknown, duration = 1440): void {
        let resetTime = moment().utc().add(duration, 'minutes').format();

        if (duration === 1440) {
            const resetHour = 24 - moment().utc().hour() + 2; // next night 02
            resetTime = moment().utc().add(resetHour, 'hours').format();
        }

        localStorage.setItem(url, JSON.stringify(data));
        localStorage.setItem(this.expirationUrl(url), JSON.stringify(resetTime));
    }

    public readFromCache(url: string): unknown | undefined {
        const data = localStorage.getItem(url);

        if (data !== null) return JSON.parse(data);

        return undefined;
    }

    public isCacheExpired(url: string): boolean {
        const cacheTime = localStorage.getItem(this.expirationUrl(url));
        let expired = true;

        if (cacheTime !== null) {
            expired = JSON.parse(cacheTime) < moment().utc().format();
        }

        return expired;
    }

    public forceExpire(url: string): void {
        localStorage.setItem(this.expirationUrl(url), JSON.stringify(moment().utc().add(-10, 'minutes').format()));
    }
}

export default new LocalCache();
