import React, { FC, useContext } from 'react';
import { Typography, Link, Container } from '@mui/material';
import { LanguageContext } from 'contexts/languageContext';
import { Link as RouterLink } from 'react-router-dom';
import { AuthContext } from 'contexts/authContext';
import config from 'config/config';

const Spacer = () => <span style={{ padding: '20px 10px' }}>|</span>;

const Footer: FC = () => {
    const { T } = useContext(LanguageContext);
    const { user } = useContext(AuthContext);

    const rekisteriseloste = `/documents/rekisteriseloste_${config.CUSTOMER}.pdf`;
    const kayttoehdot = `/documents/kayttoehdot_${config.CUSTOMER}.pdf`;

    return (
        <footer className="footer">
            <Container maxWidth="sm">
                <Typography variant="body2" color="textSecondary">
                    {user && (
                        <>
                            <RouterLink to="/contact">
                                <Link color="primary" component="span">
                                    {T('Common_Contact')}
                                </Link>
                            </RouterLink>
                            <Spacer />
                        </>
                    )}
                    <Link href={rekisteriseloste} download>
                        {T('Common_PrivacyPolicy')}
                    </Link>
                    <Spacer />
                    <Link href={kayttoehdot} download>
                        {T('Common_Eula')}
                    </Link>
                    <Spacer />
                    <RouterLink to="/cookiePolicy">
                        <Link color="primary" component="span">
                            {T('Common_CookiePolicy')}
                        </Link>
                    </RouterLink>
                </Typography>
                <Typography variant="body2" color="textSecondary">
                    &copy; {new Date().getFullYear()}{' '}
                    <Link sx={{ textDecoration: 'none' }} href={T('Contact_Footer_Url')} target="_blank">
                        {T('Contact_Footer_Title')}
                    </Link>
                    {' - '}
                    <Link sx={{ textDecoration: 'none' }} href={`mailto:${T('Contact_Footer_Email')}`}>
                        {T('Contact_Footer_Email')}
                    </Link>
                </Typography>
            </Container>
        </footer>
    );
};

export default Footer;
