import axios, { AxiosResponse } from 'axios';
import config from 'config/config';
import { AppLanguage, MailData } from 'types/common';
import { v4 as uuidv4 } from 'uuid';
import UsersService from 'services/usersService';
import { LanguageTextExtFunc } from 'contexts/languageContext';

interface EmailTextData {
    subject: string;
    Email_Title: string;
    Email_Text?: string;
    Email_Text_1?: string;
    Email_Text_2?: string;
    Email_Text_3?: string;
    Email_Text_4?: string;
    Email_Text_5?: string;
    link_url?: string;
    link_title?: string;
    Email_HelpText?: string;
    button_url?: string;
    Email_Button_Title?: string;
}

class MailService {
    public async sendRegistrationMailAsync(
        personId: number,
        userId: number,
        personName: string,
        T: LanguageTextExtFunc,
        lang: AppLanguage
    ): Promise<AxiosResponse> {
        const uuid = uuidv4(); // random uuid
        const url = this.getUrl('registration', uuid);
        const mailData: MailData = {
            personId: personId,
            templateId: config.SGID_REGISTRATION,
            templateData: {
                registration_url: url,
                subject: await T('Email_REGISTRATION_Subject', lang),
                Email_REGISTRATION_Title: (await T('Email_REGISTRATION_Title', lang)).replace('{0}', personName),
                Email_REGISTRATION_Text_1: await T('Email_REGISTRATION_Text_1', lang),
                Email_REGISTRATION_Text_2: await T('Email_REGISTRATION_Text_2', lang),
                Email_REGISTRATION_Text_3: await T('Email_REGISTRATION_Text_3', lang),
                Email_REGISTRATION_Text_4: await T('Email_REGISTRATION_Text_4', lang),
                Email_REGISTRATION_ButtonTitle: await T('Email_REGISTRATION_ButtonTitle', lang),
                Email_REGISTRATION_HelpText: await T('Email_REGISTRATION_HelpText', lang)
            },
            validationToken: uuid
        };
        await UsersService.saveUserActionTokenAsync(userId, uuid);
        return axios.post(`mail/`, mailData);
    }

    public async sendResetPasswordMailAsync(
        personId: number,
        userId: number,
        T: LanguageTextExtFunc,
        lang: AppLanguage
    ): Promise<AxiosResponse> {
        const uuid = uuidv4(); // random uuid
        const url = this.getUrl('resetPassword', uuid);
        const mailData: MailData = {
            personId: personId,
            templateId: config.SGID_RESETPASSWORD,
            templateData: {
                passwordreset_url: url,
                subject: await T('Email_RESETPASSWORD_Subject', lang),
                Email_RESETPASSWORD_Title: await T('Email_RESETPASSWORD_Title', lang),
                Email_RESETPASSWORD_Text_1: await T('Email_RESETPASSWORD_Text_1', lang),
                Email_RESETPASSWORD_Text_2: await T('Email_RESETPASSWORD_Text_2', lang),
                Email_RESETPASSWORD_Text_3: await T('Email_RESETPASSWORD_Text_3', lang),
                Email_RESETPASSWORD_ButtonTitle: await T('Email_RESETPASSWORD_ButtonTitle', lang),
                Email_RESETPASSWORD_HelpText: await T('Email_RESETPASSWORD_HelpText', lang)
            },
            validationToken: uuid
        };
        await UsersService.saveUserActionTokenAsync(userId, uuid);
        return axios.post(`mail/`, mailData);
    }

    public async sendTransferAcceptedMailAsync(
        personId: number,
        T: LanguageTextExtFunc,
        lang: AppLanguage
    ): Promise<AxiosResponse> {
        const templateData = {
            subject: await T('Email_TRANSFERACCEPTED_Subject', lang),
            Email_Title: await T('Email_TRANSFERACCEPTED_Title', lang),
            Email_Text: await T('Email_TRANSFERACCEPTED_Text', lang)
        };

        return this.sendGenericMailAsync(personId, templateData);
    }

    public async sendTransferCancelledMailAsync(
        personId: number,
        T: LanguageTextExtFunc,
        lang: AppLanguage
    ): Promise<AxiosResponse> {
        const templateData = {
            subject: await T('Email_TRANSFERCANCELED_Subject', lang),
            Email_Title: await T('Email_TRANSFERCANCELED_Title', lang),
            Email_Text: await T('Email_TRANSFERCANCELED_Text', lang)
        };

        return this.sendGenericMailAsync(personId, templateData);
    }

    public async sendConsentsMailAsync(
        personId: number,
        T: LanguageTextExtFunc,
        lang: AppLanguage
    ): Promise<AxiosResponse> {
        const uuid = uuidv4(); // random uuid
        const url = this.getUrl('consents', uuid);
        const mailData: MailData = {
            personId: personId,
            templateId: config.SGID_PERSONCONSENTS,
            templateData: {
                concents_url: url,
                subject: await T('Email_PERSONCONSENTS_Subject', lang),
                Email_PERSONCONSENTS_Title: await T('Email_PERSONCONSENTS_Title', lang),
                Email_PERSONCONSENTS_Text_1: await T('Email_PERSONCONSENTS_Text_1', lang),
                Email_PERSONCONSENTS_Text_2: await T('Email_PERSONCONSENTS_Text_2', lang),
                Email_PERSONCONSENTS_Text_3: await T('Email_PERSONCONSENTS_Text_3', lang),
                Email_PERSONCONSENTS_Text_4: await T('Email_PERSONCONSENTS_Text_4', lang),
                Email_PERSONCONSENTS_ButtonTitle: await T('Email_PERSONCONSENTS_ButtonTitle', lang),
                Email_PERSONCONSENTS_HelpText: await T('Email_PERSONCONSENTS_HelpText', lang)
            }
        };

        return axios.post(`mail/`, mailData);
    }

    public async sendGenericMailAsync(personId: number, textData: EmailTextData): Promise<AxiosResponse> {
        const mailData: MailData = {
            personId: personId,
            templateId: config.SGID_GENERIC,
            templateData: {
                subject: textData.subject,
                Email_Title: textData.Email_Title,
                Email_Text: textData.Email_Text,
                Email_Text_1: textData.Email_Text_1,
                Email_Text_2: textData.Email_Text_2,
                Email_Text_3: textData.Email_Text_3,
                Email_Text_4: textData.Email_Text_4,
                Email_Text_5: textData.Email_Text_5,
                link_url: textData.link_url,
                link_title: textData.link_title,
                Email_HelpText: textData.Email_HelpText,
                button_url: textData.button_url,
                Email_Button_Title: textData.Email_Button_Title
            }
        };

        return axios.post(`mail/`, mailData);
    }

    private getUrl(base: string, validationToken: string): string {
        return `${config.APP_URL}/${base}/${validationToken}`;
    }
}

export default new MailService();
