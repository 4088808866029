import axios from 'axios';
import { PostOffice } from 'types/sp-api';
import LocalCache from './localCache';

class PostalcodeService {
    public async getCityAsync(postalcode?: string): Promise<string> {
        if (!postalcode) return '';

        const allCodes = await this.getAllPostalCodesAsync();
        const postOffice = allCodes.find((item) => item.zipCode === postalcode);

        return postOffice?.city ?? '';
    }

    public async getAllPostalCodesAsync(): Promise<PostOffice[]> {
        const url = `postOffices/`;

        return LocalCache.cachedRequest<PostOffice[]>(
            url,
            async () => {
                const axiosInstance = axios.create(); // create separate instance to avoid interceptors
                const response = await axiosInstance.get(url);

                return response.data;
            },
            60 * 24 * 7 // 1 week
        );
    }
}

export default new PostalcodeService();
