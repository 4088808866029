import React, { FC, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { PersonTabProps } from './PersonTabBasic';
import { useErrorHandler } from 'components/hooks/useErrorHandler';
import { LanguageContext } from 'contexts/languageContext';
import axios, { CancelTokenSource } from 'axios';
import { Grid, Typography } from '@mui/material';
import WaitScreen from 'components/common/WaitScreen';
import PersonsService from 'services/personsService';
import { Credential } from 'types/sp-api';
import Utils from 'utils/typeHelper';
import moment from 'moment';

const PersonTabCredentials: FC<PersonTabProps> = ({ person }: PersonTabProps) => {
    const { T } = useContext(LanguageContext);
    const [credentials, setCredentials] = useState<Credential[]>();
    const { handleError } = useErrorHandler();
    const cancelRef = useRef<CancelTokenSource | null>(null);

    const sortCredentials = (a: Credential, b: Credential) => {
        if (!a || !b) return 0;
        if (!a.beginDate || !b.beginDate) return 0;
        if (a.beginDate < b.beginDate) return 1;
        if (a.beginDate > b.beginDate) return -1;
        return 0;
    };

    const getCredentials = useCallback(
        async (personId?: number): Promise<void> => {
            if (!personId) return;

            try {
                cancelRef.current = axios.CancelToken.source();
                const credentials = await PersonsService.getAllCredentialsAsync(personId, cancelRef.current.token);
                if (cancelRef.current) {
                    credentials.sort(sortCredentials);
                    setCredentials(credentials);
                }
            } catch (error) {
                handleError(error, T('Error_CredentialsQueryFailed'));
            }
        },
        [handleError, T]
    );

    useEffect(() => {
        getCredentials(person.id);

        return () => {
            cancelRef.current && cancelRef.current.cancel();
            cancelRef.current = null;
        };
    }, [getCredentials, person.id]);

    return (
        <Grid container spacing={3}>
            {!credentials && <WaitScreen />}
            {!!credentials && credentials.length == 0 && (
                <Grid item xs={12}>
                    <Typography variant="body1" color="textSecondary">
                        {T('PersonCredential_NoneExists')}
                    </Typography>
                </Grid>
            )}
            {!!credentials && credentials.length > 0 && (
                <>
                    <Grid container item xs={12} alignContent="flex-start">
                        <Typography variant="h6" color="primary">
                            {T('PersonCredential_CredentialTitle')}
                        </Typography>
                    </Grid>
                    <Grid container item alignContent="flex-start" spacing={1}>
                        <Grid item xs={3} alignContent="flex-start">
                            <Typography variant="body1" color="secondary">
                                {T('CredentialApplication_Type')}
                            </Typography>
                        </Grid>
                        <Grid item xs={7}>
                            <Typography variant="body2">
                                {T(Utils.CredentialApplicationTypeToName(credentials[0].type))}
                            </Typography>
                        </Grid>
                        <Grid item xs={3} alignContent="flex-start">
                            <Typography variant="body1" color="secondary">
                                {T('CredentialApplication_TypeTitle')}
                            </Typography>
                        </Grid>
                        <Grid item xs={7}>
                            <Typography variant="body2">{T(credentials[0].application?.title)}</Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography variant="body1" color="secondary">
                                {T('PersonCredential_CredentialValidityTime')}
                            </Typography>
                        </Grid>
                        <Grid item xs={7}>
                            <Typography variant="body2">
                                {`${moment(credentials[0].beginDate).format('l')} - ${moment(
                                    credentials[0].endDate
                                ).format('l')}`}
                            </Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography variant="body1" color="secondary">
                                {T('PersonCredential_WeddingsRightBeginDate')}
                            </Typography>
                        </Grid>
                        <Grid item xs={7}>
                            <Typography variant="body2">
                                {credentials[0].weddingsRightBeginDate
                                    ? moment(credentials[0].weddingsRightBeginDate).format('l')
                                    : '-'}
                            </Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography variant="body1" color="secondary">
                                {T('CredentialApplication_CardNumber')}
                            </Typography>
                        </Grid>
                        <Grid item xs={7}>
                            <Typography variant="body2">{credentials[0].cardNumber}</Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography variant="body1" color="secondary">
                                {T('CredentialApplication_ApplicantChurch')}
                            </Typography>
                        </Grid>
                        <Grid item xs={7}>
                            <Typography variant="body2">{credentials[0].application?.organization?.name}</Typography>
                        </Grid>
                    </Grid>
                </>
            )}
        </Grid>
    );
};

export default PersonTabCredentials;
