import React, { FC, useContext, useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { PeopleAlt, Print, Mail, Notifications as NotificationIcon } from '@mui/icons-material';
import { LanguageContext } from 'contexts/languageContext';
import DashboardButton from './DashboardButton';
import ChurchIcon from '../icons/ChurchIcon';
import ChurchesService from 'services/churchesService';
import { AuthContext } from 'contexts/authContext';
import { TooltipChurchSearch } from 'components/common/TooltipChurchSearch';
import { TooltipPersonSearch } from 'components/common/TooltipPersonSearch';
import SecureMessagesPreview from '../securemessages/SecureMessagesPreview';
import NotificationsPreview from 'components/notifications/NotificationsPreview';
import { ChurchTypes, MembershipTypes } from 'types/sp-api';
import MembershipCounts from './MembershipCounts';
import ChurchName from './ChurchName';
import OrganizationCounts from './OrganizationCounts';
import { useErrorHandler } from 'components/hooks/useErrorHandler';

const Dashboard: FC = () => {
    const { T } = useContext(LanguageContext);
    const { user } = useContext(AuthContext);
    const [churchCount, setChurchCount] = useState<ChurchTypes>();
    const [membershipCount, setMembershipCount] = useState<MembershipTypes>();
    const { handleError } = useErrorHandler();

    useEffect(() => {
        const getChurchCount = async (): Promise<void> => {
            try {
                const typeCount = await ChurchesService.getChurchesCountAsync();
                setChurchCount(typeCount);
            } catch (error) {
                handleError(error, T('Error_ChurchQueryFailed'));
            }
        };

        const getmembershipCount = async (): Promise<void> => {
            try {
                const types = await ChurchesService.getChurchesMembersCountAsync(user?.churchId);
                setMembershipCount(types);
            } catch (error) {
                handleError(error, T('Error_ChurchQueryFailed'));
            }
        };

        if (user) {
            getChurchCount();
            getmembershipCount();
        }
    }, [user]);

    return (
        <>
            <Grid container justifyContent="center">
                <ChurchName title />
            </Grid>
            <Grid container justifyContent="center">
                <DashboardButton
                    title={T('Common_Persons')}
                    search
                    searchPlaceholder={T('Placeholder_PersonSearch')}
                    url="/persons"
                    icon={PeopleAlt}
                    iconTooltip={<TooltipPersonSearch />}
                    preview={<MembershipCounts counts={membershipCount} />}
                />
                <DashboardButton
                    title={T('Common_Churches')}
                    search
                    //infoText={T('Common_ChurchSearchInfo').replace('{0}', churchCount)}
                    searchPlaceholder={T('Placeholder_ChurchSearch')}
                    url="/churches"
                    icon={ChurchIcon}
                    iconTooltip={<TooltipChurchSearch />}
                    preview={<OrganizationCounts counts={churchCount} />}
                    fixMargin
                />
            </Grid>
            <Grid container justifyContent="center">
                <DashboardButton
                    title={T('Common_Notifications')}
                    url="/notifications"
                    icon={NotificationIcon}
                    preview={<NotificationsPreview />}
                />
                <DashboardButton
                    title={T('SecureMessages_Title')}
                    url="/communication/securemessages"
                    icon={Mail}
                    preview={<SecureMessagesPreview />}
                />
            </Grid>
            <Grid container justifyContent="center">
                <DashboardButton title={T('Common_OwnChurch')} url={`/churches/${user?.churchId}`} icon={ChurchIcon} />
                <DashboardButton title={T('Common_Reports')} url="/reports" icon={Print} />
                {/* <DashboardButton title={T('Common_AccessRights')} url="/accessrights" icon={AccountBox} /> */}
                {/* <DashboardButton title={T('Common_Settings')} url="/settings" icon={Settings} /> */}
            </Grid>

            {/* <DebugEnvs /> */}
        </>
    );
};

export default Dashboard;
