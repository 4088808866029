import React, { FC, ReactNode, useCallback, useContext, useEffect, useRef, useState } from 'react';
import {
    Grid,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from '@mui/material';
import { LanguageContext } from 'contexts/languageContext';
import { useErrorHandler } from 'components/hooks/useErrorHandler';
import axios, { CancelTokenSource } from 'axios';
import CommunityTagService from 'services/communityTagService';
import { Person } from 'types/sp-api';
import WaitScreen from 'components/common/WaitScreen';
import { TableHeadCell } from 'components/common/TableHeadCell';
import Utils from 'utils/personHelper';
import RowMenu from 'components/common/RowMenu';
import { useNavigate } from 'react-router-dom';

interface CommunityTagMembersProps {
    tagId: number;
}

const CommunityTagMembers: FC<CommunityTagMembersProps> = ({ tagId }: CommunityTagMembersProps) => {
    const { T } = useContext(LanguageContext);
    const [tagListPersons, setTagListPersons] = useState<Person[]>();
    const { handleError } = useErrorHandler();
    const cancelRef = useRef<CancelTokenSource | null>(null);
    const navigate = useNavigate();

    const getCommunityTagPersons = useCallback(
        async (tagTitleId: number): Promise<void> => {
            try {
                !cancelRef.current && (cancelRef.current = axios.CancelToken.source());
                const members = await CommunityTagService.getPersonsByTagIdAsync(
                    tagTitleId,
                    undefined,
                    cancelRef.current.token
                );
                if (cancelRef.current) {
                    setTagListPersons(members);
                }
            } catch (error) {
                handleError(error, T('Error_CommunityTagQueryFailed'));
            }
        },
        [handleError, T]
    );

    useEffect(() => {
        getCommunityTagPersons(tagId);

        return () => {
            cancelRef.current && cancelRef.current.cancel();
            cancelRef.current = null;
        };
    }, [getCommunityTagPersons, tagId]);

    return (
        <>
            <Grid item xs={12}>
                <TableContainer component={Paper}>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableHeadCell>{T('Common_Name')}</TableHeadCell>
                                <TableHeadCell>{T('Person_FieldPhoneHome')}</TableHeadCell>
                                <TableHeadCell sx={{ width: '5%' }} />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {tagListPersons &&
                                tagListPersons.length > 0 &&
                                tagListPersons.map(
                                    (item): ReactNode => (
                                        <TableRow key={item.id}>
                                            <TableCell>
                                                <Typography>{Utils.FullName(item)}</Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography>{item.phoneHome}</Typography>
                                            </TableCell>
                                            <TableCell sx={{ width: '5%' }}>
                                                <RowMenu onEdit={() => navigate(`/persons/${item.id}`)} />
                                            </TableCell>
                                        </TableRow>
                                    )
                                )}
                            {tagListPersons && tagListPersons.length === 0 && (
                                <TableRow>
                                    <TableCell>
                                        <Typography variant="body1" color="textSecondary">
                                            {T('SettingsTabCommunityTag_NoTagMembers')}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            )}
                            {!tagListPersons && (
                                <TableRow>
                                    <TableCell>
                                        <WaitScreen />
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </>
    );
};

export default CommunityTagMembers;
