import axios, { CancelToken } from 'axios';
import LocalCache from 'services/localCache';
import { CommunityTag, PersonWithMembership, Tag } from 'types/sp-api';
import PersonUtils from 'utils/personHelper';

class CommunityTagService {
    public async getCommunityTagTitlesAsync(cancelToken?: CancelToken, forceUpdate?: boolean): Promise<Tag[]> {
        const tagUrl = `communityTagTitles/`;

        if (forceUpdate) {
            LocalCache.forceExpire(tagUrl);
        }

        return LocalCache.cachedRequest<Tag[]>(tagUrl, async () => {
            const response = await axios.get(tagUrl, {
                cancelToken: cancelToken
            });
            return response.data as Tag[];
        });
    }

    public async getCommunityTagTitleAsync(tagId: number, cancelToken?: CancelToken): Promise<Tag> {
        const response = await axios.get(`communityTagTitles/${tagId}`, {
            cancelToken: cancelToken
        });
        return response.data;
    }

    public async addCommunityTagTitleAsync(tag?: Tag): Promise<void> {
        if (!tag) return;

        const tagUrl = `communityTagTitles/`;
        await axios.post(tagUrl, tag);

        // Reload cache
        LocalCache.forceExpire(tagUrl);
    }

    public async getCommunityTagTitleUsageAsync(tagId: number, cancelToken?: CancelToken): Promise<number> {
        const response = await axios.get(`communityTagTitles/${tagId}/usage`, {
            cancelToken: cancelToken
        });
        return response.data;
    }

    public async saveCommunityTagTitleAsync(tag: Tag): Promise<void> {
        if (tag?.id && tag.id > 0) {
            await axios.put(`communityTagTitles/${tag?.id}`, tag);
        } else {
            await axios.post(`communityTagTitles/`, tag);
        }
    }

    public async deleteCommunityTagTitleAsync(tagId?: number): Promise<void> {
        if (!tagId) return;

        await axios.delete(`communityTagTitles/${tagId}`);
    }

    public async getPersonsByTagIdAsync(
        tagId?: number,
        filter?: string,
        cancelToken?: CancelToken
    ): Promise<PersonWithMembership[]> {
        if (!tagId) return [];

        const params = PersonUtils.CreatePersonQuery(filter);

        const response = await axios.get(`communityTagTitles/${tagId}/persons`, {
            params: params,
            cancelToken: cancelToken
        });
        return response.data;
    }

    /* Persons community tags */

    public async getPersonsCommunityTagsAsync(personId: number, cancelToken?: CancelToken): Promise<CommunityTag[]> {
        const response = await axios.get(`persons/${personId}/communityTags`, {
            cancelToken: cancelToken
        });
        return response.data;
    }

    public async savePersonsCommunityTagAsync(personId: number, tag: CommunityTag): Promise<void> {
        if (tag?.id && tag.id > 0) {
            await axios.put(`persons/${personId}/communityTags/${tag?.id}`, tag);
        } else {
            await axios.post(`persons/${personId}/communityTags`, tag);
        }
    }

    public async deletePersonsCommunityTagAsync(personId: number, tagId?: number): Promise<void> {
        if (!tagId) return;

        await axios.delete(`persons/${personId}/communityTags/${tagId}`);
    }
}

export default new CommunityTagService();
