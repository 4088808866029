import React, { FC, useContext, useState } from 'react';
import { Grid, Typography } from '@mui/material';
import { LanguageContext } from 'contexts/languageContext';
import PublicService from 'services/publicService';
import DownloadFile from 'components/common/DownloadFile';
import moment from 'moment';
import LoadButton from 'components/common/LoadButton';

interface ApplicationSentProps {
    token?: string;
}

const CredentialApplicationSent: FC<ApplicationSentProps> = ({ token }: ApplicationSentProps) => {
    const { T } = useContext(LanguageContext);
    const [downloadFile, setDownloadFile] = useState<{ id: number; filename: string }>();
    const [lock, setLock] = useState(false);

    const fetchFile = (reportToken: string, filename: string) => {
        return PublicService.generateCredentialApplicationReportAsync(reportToken, filename, 'fi');
    };

    return (
        <>
            <Grid item xs={12} container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="h5">{T('CredentialApplication_ApplicationSent')}</Typography>
                </Grid>
                <Grid item xs={10}>
                    <Typography variant="body1">{T('CredentialApplication_ApplicationSentHelpText')}</Typography>
                </Grid>
                <Grid item xs={12}>
                    {token && (
                        <LoadButton
                            variant="contained"
                            onClick={() => {
                                let filename = T('Report_CredentialApplicationReportFile');
                                filename = filename.replace('{0}', moment().format('L_LT'));

                                setDownloadFile({
                                    id: 0,
                                    filename: filename
                                });
                            }}
                            loading={!!downloadFile}
                            disabled={lock}
                        >
                            {lock ? 'Ladattu' : 'Lataa itsellesi kopio hakemuksestasi'}
                        </LoadButton>
                    )}
                </Grid>
            </Grid>
            {downloadFile && token && (
                <DownloadFile
                    fetchFile={() => fetchFile(token, downloadFile.filename)}
                    filename={downloadFile.filename}
                    onSuccess={() => {
                        setDownloadFile(undefined);
                        setLock(true);
                    }}
                />
            )}
        </>
    );
};

export default CredentialApplicationSent;
