import React, { FC } from 'react';
import { ReactComponent as EnglishFlagSvg } from './flag_gb.svg';
import { getThemeColor } from 'config/theme';

interface FlagENProps {
    fontSize?: 'large' | 'small' | 'default';
    color?: 'primary' | 'secondary' | 'default' | 'alert';
}

const FlagEN: FC<FlagENProps> = ({ fontSize, color }: FlagENProps) => {
    let width = 24;

    if (fontSize === 'large') {
        width = 30;
    } else if (fontSize === 'small') {
        width = 20;
    }

    return (
        <div style={{ width: width, height: width }}>
            <EnglishFlagSvg fill={getThemeColor(color)} />
        </div>
    );
};

export default FlagEN;
