import React, { FC, useContext, useState } from 'react';
import { Grid, Typography } from '@mui/material';
import { LanguageContext, LanguageTextFunc } from 'contexts/languageContext';
import PersonIcon from '@mui/icons-material/Person';
import FormFieldText from 'components/common/FormFieldText';
import { Formik, Form, FormikProps } from 'formik';
import LoadButton from 'components/common/LoadButton';
import { useErrorHandler } from 'components/hooks/useErrorHandler';
import AuthService from 'services/authService';
import { AuthContext } from 'contexts/authContext';
import { LoginContext } from 'types/auth';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';

const getOtpValidationSchema = (T: LanguageTextFunc) => {
    return Yup.object().shape({
        username: Yup.string().required(T('Common_Required')),
        password: Yup.string().required(T('Common_Required')),
        otp: Yup.string().required(T('Common_Required'))
    });
};

interface LoginPhaseOtpProps {
    loginCtx: LoginContext;
}

const LoginPhaseOtp: FC<LoginPhaseOtpProps> = ({ loginCtx }: LoginPhaseOtpProps) => {
    const languageCtx = useContext(LanguageContext);
    const { T } = languageCtx;
    const authCtx = useContext(AuthContext);
    const [sending, setSending] = useState(false);
    const { handleError } = useErrorHandler();
    const navigate = useNavigate();

    return (
        <Formik
            initialValues={loginCtx}
            enableReinitialize
            onSubmit={async (values, formikHelpers) => {
                setSending(true);

                try {
                    const result = await AuthService.loginAsync(authCtx, languageCtx, values);
                    if (result.success === true) {
                        // Redirect to Dashboard by state change inside AuthService
                        navigate('/');
                    } else {
                        setSending(false);
                        formikHelpers.setErrors({ otp: T('Login_OtpFailed') });
                    }
                } catch (error) {
                    setSending(false);
                    handleError(error, T('Login_ErrorFailed'));
                }
            }}
            validationSchema={getOtpValidationSchema(T)}
        >
            {({ dirty, isValid }: FormikProps<LoginContext>) => {
                return (
                    <Form>
                        <Grid container spacing={3} style={{ maxWidth: 400 }} alignItems="center">
                            <Grid item xs={12} container justifyContent="center">
                                <Typography variant="h4">{T('Login_OtpTitle')}</Typography>
                            </Grid>
                            <Grid item xs={12} container justifyContent="center">
                                <Typography variant="body2" style={{ paddingLeft: 24, paddingRight: 24 }}>
                                    {T('Login_OtpTitleHelpText')}
                                </Typography>
                            </Grid>
                            <Grid item xs={2} container justifyContent="flex-end">
                                <PersonIcon />
                            </Grid>
                            <Grid item xs={10}>
                                <FormFieldText name="otp" label={T('Login_Otp')} autoFocus />
                            </Grid>
                            <Grid item xs={12} container justifyContent="center">
                                <LoadButton loading={sending} disabled={!dirty || !isValid} type="submit">
                                    {T('Login_LoginButtonTitle')}
                                </LoadButton>
                            </Grid>
                        </Grid>
                    </Form>
                );
            }}
        </Formik>
    );
};

export default LoginPhaseOtp;
