import React, { FC, useContext } from 'react';
import { Container, Grid, Typography } from '@mui/material';
import { Settings } from '@mui/icons-material';
import { LanguageContext } from 'contexts/languageContext';
import ViewTitle from './common/ViewTitle';

const HealthView: FC = () => {
    const { T } = useContext(LanguageContext);

    return (
        <Container data-testid="healthCheck">
            <Grid container>
                <ViewTitle title={T('Common_HealthCheck')} icon={Settings} />
            </Grid>
            <Typography>{T('Common_HealthCheckMessage')}</Typography>
        </Container>
    );
};

export default HealthView;
