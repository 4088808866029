import React, { FC, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { KeyValue } from 'types/common';
import MunicipalityService from 'services/municipalityService';
import axios, { CancelTokenSource } from 'axios';
import { LanguageContext } from 'contexts/languageContext';
import { useErrorHandler } from '../hooks/useErrorHandler';
import WaitScreen from './WaitScreen';
import FormFieldDropDownLargeList from './FormFieldDropDownLargeList';

interface FormFieldDropDownMunicipalityProps {
    label: string;
    name: string;
    autoFocus?: boolean;
    readonly?: boolean;
}

const FormFieldDropDownMunicipality: FC<FormFieldDropDownMunicipalityProps> = ({
    label,
    name,
    autoFocus,
    readonly
}: FormFieldDropDownMunicipalityProps) => {
    const [values, setValues] = useState<KeyValue[]>([]);
    const { T } = useContext(LanguageContext);
    const { handleError } = useErrorHandler();
    const cancelRef = useRef<CancelTokenSource | null>(null);

    const getMunicipalities = useCallback(async (): Promise<void> => {
        try {
            !cancelRef.current && (cancelRef.current = axios.CancelToken.source());
            const municipalities = await MunicipalityService.getAllMunicipalitiesAsync(cancelRef.current.token);
            if (cancelRef.current) {
                setValues(
                    municipalities
                        .map((item) => ({
                            key: item.id,
                            value: `${item.name} (${item.number.padStart(3, '0')})`
                        }))
                        .sort((a, b) => {
                            // sort by name
                            if (a.value > b.value) return 1;
                            if (a.value < b.value) return -1;
                            return 0;
                        })
                );
            }
        } catch (error) {
            handleError(error, T('Error_MunicipalityQueryFailed'));
        }
    }, [handleError, T]);

    useEffect(() => {
        getMunicipalities();

        return () => {
            cancelRef.current && cancelRef.current.cancel();
            cancelRef.current = null;
        };
    }, [getMunicipalities]);

    if (values.length === 0) return <WaitScreen />;

    return (
        <FormFieldDropDownLargeList
            name={name}
            label={label}
            values={values}
            placeholder={T('Person_SelectMunicipalityResidence')}
            autoFocus={autoFocus}
            readonly={readonly}
            listWidth={300}
        />
    );
};

export default FormFieldDropDownMunicipality;
