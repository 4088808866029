import axios, { CancelToken } from 'axios';
import { PagedResult } from 'types/common';
import { ReportFilter, SmsMessage, SmsMessageInput } from 'types/sp-api';

class SmsMessagesService {
    public async getCountAsync(orgId: number, cancelToken?: CancelToken): Promise<number> {
        const response = await axios.get<number>(`smsmessages/organizations/${orgId}/count`, {
            cancelToken: cancelToken
        });
        return response.data;
    }

    public async getAllAsync(orgId: number, cancelToken?: CancelToken): Promise<SmsMessage[]> {
        const response = await axios.get<PagedResult<SmsMessage[]>>(`smsmessages/organizations/${orgId}`, {
            params: { sort: 'createdDate,desc' },
            cancelToken: cancelToken
        });
        return response.data.content;
    }

    public async getMessageAsync(
        orgId: number,
        messageId: number,
        cancelToken?: CancelToken
    ): Promise<SmsMessageInput> {
        const response = await axios.get<SmsMessageInput>(`smsmessages/organizations/${orgId}/messages/${messageId}`, {
            cancelToken: cancelToken
        });
        return response.data;
    }

    public async getRecipientCountAsync(
        orgId: number,
        filter: ReportFilter,
        cancelToken?: CancelToken
    ): Promise<number> {
        const response = await axios.put<number>(`smsmessages/organizations/${orgId}/recipientCount`, filter, {
            cancelToken: cancelToken
        });
        return response.data;
    }

    public async sendMessagesAsync(orgId: number, message: SmsMessageInput): Promise<void> {
        await axios.post<SmsMessage>(`smsmessages/organizations/${orgId}`, message);
    }

    public async deleteMessageAsync(orgId: number, messageId: number): Promise<void> {
        await axios.delete<void>(`smsmessages/organizations/${orgId}/messages/${messageId}`);
    }
}

export default new SmsMessagesService();
