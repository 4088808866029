import React, { ChangeEvent, FC, SetStateAction, Dispatch, useContext, useEffect, useRef, useState } from 'react';
import {
    Box,
    Divider,
    FormControl,
    FormControlLabel,
    Grid,
    List,
    ListItem,
    Radio,
    RadioGroup,
    Typography
} from '@mui/material';
import { LanguageContext, LanguageTextFunc } from 'contexts/languageContext';
import { useErrorHandler } from 'components/hooks/useErrorHandler';
import { useSuccessHandler } from 'components/hooks/useSuccessHandler';
import { FieldArray, Form, Formik, FormikProps } from 'formik';
import * as Yup from 'yup';
import FormFieldText from 'components/common/FormFieldText';
import PublicService from 'services/publicService';
import { CredentialApplicationInput, PostOffice } from 'types/sp-api';
import FormFieldNumber from 'components/common/FormFieldNumber';
import FormFieldReadOnly from 'components/common/FormFieldReadonly';
import AddressUtils from 'utils/addressHelper';
import SsnHelper from 'utils/ssnHelper';
import PostalcodeService from 'services/postalcodeService';
import FormFieldDropDownOrganization from 'components/common/FormFieldDropDownOrganization';
import LoadButton from 'components/common/LoadButton';
import FormFieldCheckbox from 'components/common/FormFieldCheckbox';
import FormFieldDropDownCodeSet from 'components/common/FormFieldDropDownCodeSet';
import axios, { CancelTokenSource } from 'axios';
import FormFieldDate from 'components/common/FormFieldDate';
import FormFieldTextArea from 'components/common/FormFieldTextArea';
import ButtonAdd from 'components/common/ButtonAdd';
import CredentialAttachmentLine from './CredentialAttachmentLine';
import FormFieldDropDownLanguage from 'components/common/FormFieldDropDownLanguage';
import FormFieldDropDown from 'components/common/FormFieldDropDown';
import { AppLanguage } from 'types/common';
import { v4 as uuidv4 } from 'uuid';

const getNewValidationSchema = (
    T: LanguageTextFunc,
    postalCodes: PostOffice[],
    values: CredentialApplicationInput & { membershipType?: string }
) => {
    return Yup.object().shape({
        application: Yup.object().shape({
            firstName: Yup.string().required(T('Common_Required')),
            lastName: Yup.string().required(T('Common_Required')),
            email: Yup.string().email(T('Error_InvalidEmail')).required(T('Common_Required')),
            ssn: Yup.string()
                .test('ssn-validity', T('Error_InvalidSsn'), (value) => SsnHelper.isValidSsn(value))
                .length(11, T('Error_InvalidSsn'))
                .required(T('Common_Required')),
            phone: Yup.string()
                .test(
                    'phone-validity',
                    T('Error_InvalidPhone'),
                    (value) => /^[+0-9\-()\s]*$/.exec(value ?? '') !== null
                )
                .required(T('Common_Required')),
            address: Yup.object().shape({
                street: Yup.string().required(T('Common_Required')),
                zipCode: Yup.string()
                    .test('valid-zipCode', T('Error_InvalidZipCode'), (value) =>
                        AddressUtils.validateZipCode(value, postalCodes)
                    )
                    .required(T('Common_Required'))
            }),
            organizationId: Yup.number().test('not-0-value', T('Common_Required'), (value) => (value ?? 0) > 0),
            type: Yup.number().test('not-0-value', T('Common_Required'), (value) => (value ?? 0) > 0),
            commitToEthicalPrinciples: Yup.boolean().test(
                'must-be-committed',
                T('Common_Required'),
                (value) => value === true
            ),
            signatureOrganizationName: Yup.string().required(T('Common_Required')),
            signatureOrganizationPosition: Yup.string().required(T('Common_Required')),
            signatureOrganizationPhone: Yup.string().required(T('Common_Required')),
            signatureOrganizationEmail: Yup.string()
                .email(T('Error_InvalidEmail'))
                .test(
                    'srk-not-applicant-email',
                    T('CredentialApplication_NotApplicantSignature'),
                    (value) => values.application?.email != value
                )
                .required(T('Common_Required')),
            signatureHyryName: Yup.string().test(
                'name-if-hyry',
                T('Common_Required'),
                (value) => (values.application?.hyryOrganization?.length ?? 0) == 0 || (!!value && value?.length > 0)
            ),
            signatureHyryPosition: Yup.string().test(
                'position-if-hyry',
                T('Common_Required'),
                (value) => (values.application?.hyryOrganization?.length ?? 0) == 0 || (!!value && value?.length > 0)
            ),
            signatureHyryPhone: Yup.string().test(
                'phone-if-hyry',
                T('Common_Required'),
                (value) => (values.application?.hyryOrganization?.length ?? 0) == 0 || (!!value && value?.length > 0)
            ),
            signatureHyryEmail: Yup.string()
                .test(
                    'email-if-hyry',
                    T('Common_Required'),
                    (value) =>
                        (values.application?.hyryOrganization?.length ?? 0) == 0 || (!!value && value?.length > 0)
                )
                .test(
                    'hyry-not-applicant-email',
                    T('CredentialApplication_NotApplicantSignature'),
                    (value) => values.application?.email != value
                ),
            workExperience: Yup.string().test(
                'workexperience-or-cv',
                T('Common_Required'),
                (value) =>
                    values.attachmentList?.findIndex((item) => item.type == 2) != -1 || (!!value && value?.length > 0)
            ),
            jobDescription: Yup.string().required(T('Common_Required')),
            language: Yup.string().required(T('Common_Required')),
            extraLanguage: Yup.string().test(
                'extraLanguage-needed',
                T('Common_Required'),
                (value) => (values.application?.extraCard ?? false) == false || (!!value && value?.length > 0)
            ),
            registerMemberOrganizationId: Yup.number().test(
                'register-member-org-needed',
                T('Common_Required'),
                (value) => (values.membershipType ?? '') != '2' || (value ?? 0) > 0
            )
        }),
        membershipType: Yup.string().test(
            'membershipType-needed',
            T('Common_Required'),
            (value) =>
                (values.application?.askingRightToOfficiateWeddings ?? false) == false || (!!value && value?.length > 0)
        )
    });
};

const getRenewValidationSchema = (
    T: LanguageTextFunc,
    postalCodes: PostOffice[],
    values: CredentialApplicationInput & { membershipType?: string }
) => {
    return Yup.object().shape({
        application: Yup.object().shape({
            firstName: Yup.string().required(T('Common_Required')),
            lastName: Yup.string().required(T('Common_Required')),
            email: Yup.string().email(T('Error_InvalidEmail')).required(T('Common_Required')),
            ssn: Yup.string()
                .test('ssn-validity', T('Error_InvalidSsn'), (value) => SsnHelper.isValidSsn(value))
                .length(11, T('Error_InvalidSsn'))
                .required(T('Common_Required')),
            phone: Yup.string()
                .test(
                    'phone-validity',
                    T('Error_InvalidPhone'),
                    (value) => /^[+0-9\-()\s]*$/.exec(value ?? '') !== null
                )
                .required(T('Common_Required')),
            address: Yup.object().shape({
                street: Yup.string().required(T('Common_Required')),
                zipCode: Yup.string()
                    .test('valid-zipCode', T('Error_InvalidZipCode'), (value) =>
                        AddressUtils.validateZipCode(value, postalCodes)
                    )
                    .required(T('Common_Required'))
            }),
            organizationId: Yup.number().test('not-0-value', T('Common_Required'), (value) => (value ?? 0) > 0),
            type: Yup.number().test('not-0-value', T('Common_Required'), (value) => (value ?? 0) > 0),
            commitToEthicalPrinciples: Yup.boolean().test(
                'must-be-committed',
                T('Common_Required'),
                (value) => value === true
            ),
            signatureOrganizationName: Yup.string().required(T('Common_Required')),
            signatureOrganizationPosition: Yup.string().required(T('Common_Required')),
            signatureOrganizationPhone: Yup.string().required(T('Common_Required')),
            signatureOrganizationEmail: Yup.string()
                .email(T('Error_InvalidEmail'))
                .test(
                    'srk-not-applicant-email',
                    T('CredentialApplication_NotApplicantSignature'),
                    (value) => values.application?.email != value
                )
                .required(T('Common_Required')),
            signatureHyryName: Yup.string().test(
                'name-if-hyry',
                T('Common_Required'),
                (value) => (values.application?.hyryOrganization?.length ?? 0) == 0 || (!!value && value?.length > 0)
            ),
            signatureHyryPosition: Yup.string().test(
                'position-if-hyry',
                T('Common_Required'),
                (value) => (values.application?.hyryOrganization?.length ?? 0) == 0 || (!!value && value?.length > 0)
            ),
            signatureHyryPhone: Yup.string().test(
                'phone-if-hyry',
                T('Common_Required'),
                (value) => (values.application?.hyryOrganization?.length ?? 0) == 0 || (!!value && value?.length > 0)
            ),
            signatureHyryEmail: Yup.string()
                .test(
                    'email-if-hyry',
                    T('Common_Required'),
                    (value) =>
                        (values.application?.hyryOrganization?.length ?? 0) == 0 || (!!value && value?.length > 0)
                )
                .test(
                    'hyry-not-applicant-email',
                    T('CredentialApplication_NotApplicantSignature'),
                    (value) => values.application?.email != value
                ),
            language: Yup.string().required(T('Common_Required')),
            extraLanguage: Yup.string().test(
                'extraLanguage-needed',
                T('Common_Required'),
                (value) => (values.application?.extraCard ?? false) == false || (!!value && value?.length > 0)
            ),
            continuingTrainingAction: Yup.string().required(T('Common_Required')),
            continuingTrainingDate: Yup.date().test(
                'continuing-training-date-needed',
                T('Common_Required'),
                (value) =>
                    ((values.application?.continuingTrainingAction ?? false) != 'will' &&
                        (values.application?.continuingTrainingAction ?? false) != 'done') ||
                    !!value
            )
        }),
        attachmentList: Yup.array().min(1).required(T('Common_Required'))
    });
};

interface CredentialApplicationFormProps {
    mode?: 'new' | 'renew';
    setToken: Dispatch<SetStateAction<string | undefined>>;
}

const CredentialApplicationForm: FC<CredentialApplicationFormProps> = ({
    mode,
    setToken
}: CredentialApplicationFormProps) => {
    const { T } = useContext(LanguageContext);
    const [sending, setSending] = useState(false);
    const { handleError } = useErrorHandler();
    const { handleSuccess } = useSuccessHandler();
    const [postalCodes, setPostalCodes] = useState<PostOffice[]>([]);
    const cancelRef = useRef<CancelTokenSource | null>(null);
    const [attachmentFiles] = useState<File[]>([]);
    const [editingIndex, setEditingIndex] = useState<number>();

    useEffect(() => {
        !cancelRef.current && (cancelRef.current = axios.CancelToken.source());
        PostalcodeService.getAllPostalCodesAsync().then((result) => {
            if (cancelRef.current) {
                setPostalCodes(result);
            }
        });

        return () => {
            cancelRef.current && cancelRef.current.cancel();
            cancelRef.current = null;
        };
    }, []);

    const initialValues: CredentialApplicationInput = {
        application: {
            firstName: '',
            lastName: '',
            type: 0,
            organizationId: 0,
            newApplication: mode === 'new',
            language: 'fi',
            continuingTrainingAction: mode === 'renew' ? 'not' : undefined
        },
        attachmentList: []
    };

    const saveCredentialApplication = async (
        values: CredentialApplicationInput & { membershipType?: string }
    ): Promise<void> => {
        setSending(true);

        if (!values.application) return;

        if (values.membershipType == '1') {
            values.application.memberOfShk = true;
        }

        // create one timer for report
        const reportToken = uuidv4();
        values.application.reportToken = reportToken;

        try {
            await PublicService.saveCredentialApplicationAsync(values, attachmentFiles);
            setSending(false);
            handleSuccess(T('CredentialApplication_SaveSuccess'));
            setToken(reportToken);
        } catch (error) {
            setSending(false);
            handleError(error, T('CredentialApplication_SaveFailed'));
        }
    };

    const ethcialListItem = (text: string, url: string) => {
        return (
            <ListItem disablePadding>
                <Typography variant="body2" color="secondary">
                    - {text}
                    <a style={{ marginLeft: 8 }} href={url} target="_blank" rel="noreferrer">
                        {url}
                    </a>
                </Typography>
            </ListItem>
        );
    };

    return (
        <Grid item xs={12} container justifyContent="left">
            <Formik
                initialValues={initialValues}
                enableReinitialize
                onSubmit={(values, actions) => {
                    actions.validateForm(values);
                    if (values?.application?.address) {
                        values.application.address.country = '';
                    }
                    saveCredentialApplication(values);
                }}
                validationSchema={() =>
                    Yup.lazy((values) => {
                        if (mode == 'renew') return getRenewValidationSchema(T, postalCodes, values);
                        return getNewValidationSchema(T, postalCodes, values);
                    })
                }
            >
                {({
                    values,
                    dirty,
                    isValid,
                    getFieldHelpers
                }: FormikProps<CredentialApplicationInput & { membershipType?: string }>) => {
                    const helpers = getFieldHelpers('application.address.city');
                    let isCityError = false;
                    let city = '';

                    city = AddressUtils.getCityByZipCode(
                        values.application?.address?.zipCode,
                        postalCodes,
                        T('Error_InvalidZipCode')
                    );

                    if (values.application?.address?.city !== city && city !== T('Error_InvalidZipCode')) {
                        helpers.setValue(city);
                    }

                    if (city === T('Error_InvalidZipCode')) {
                        isCityError = true;
                    }

                    const updateSelection = (event: ChangeEvent<HTMLInputElement>, value: string) => {
                        event.persist();
                        const helpers = getFieldHelpers('application.continuingTrainingAction');
                        helpers.setValue(value);
                    };

                    let titleIndex = 1;
                    const titleFunc = (key: string) => <>{`${(titleIndex++).toString()} ${T(key)}`}</>;

                    return (
                        <Form>
                            <Grid container>
                                <Grid container item xs={12} sx={{ mb: 3, mt: 1 }}>
                                    <Grid item xs={12}>
                                        <Typography variant="h5">
                                            {T(
                                                mode === 'new'
                                                    ? 'CredentialApplication_NewApplication'
                                                    : 'CredentialApplication_RenewApplication'
                                            )}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography color="secondary" variant="body2">
                                            {T('CredentialApplication_DataCollectionInfo')}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid
                                    container
                                    item
                                    xs={12}
                                    sx={{ mb: 3, pl: 2, backgroundColor: '#00A8DD', borderRadius: 1 }}
                                >
                                    <Typography variant="h6" color="white">
                                        {titleFunc('CredentialApplication_SubTitle1')}
                                    </Typography>
                                </Grid>
                                <Grid container item xs={12} sx={{ mb: 3 }}>
                                    <Grid item xs={12} container spacing={2} sx={{ pb: 3, pr: 1 }}>
                                        <Grid item md={4}>
                                            <FormFieldText
                                                name="application.firstName"
                                                label={T('Person_FieldFirstName')}
                                            />
                                        </Grid>
                                        <Grid item md={4}>
                                            <FormFieldText
                                                name="application.lastName"
                                                label={T('Person_FieldLastName')}
                                            />
                                        </Grid>
                                        <Grid item md={4}>
                                            <FormFieldText name="application.ssn" label={T('Person_FieldSsn')} />
                                        </Grid>
                                        <Grid item md={5}>
                                            <FormFieldText
                                                name="application.address.street"
                                                label={T('Person_FieldAddressStreet')}
                                            />
                                        </Grid>
                                        <Grid item md={3}>
                                            <FormFieldNumber
                                                name="application.address.zipCode"
                                                label={T('Person_FieldAddressZipCode')}
                                            />
                                        </Grid>
                                        <Grid item md={4}>
                                            <FormFieldReadOnly
                                                label={T('Person_FieldAddressCity')}
                                                value={isCityError ? ' ' : values.application?.address?.city}
                                                error={isCityError}
                                                helperText={isCityError ? T('Error_InvalidZipCode') : undefined}
                                            />
                                        </Grid>
                                        <Grid item md={5}>
                                            <FormFieldNumber
                                                name="application.phone"
                                                label={T('Person_FieldPhoneHome')}
                                            />
                                        </Grid>
                                        <Grid item md={7}>
                                            <FormFieldText name="application.email" label={T('Person_FieldEmail')} />
                                        </Grid>
                                        <Grid item md={5}>
                                            <FormFieldDropDownOrganization
                                                name="application.organizationId"
                                                label={T('Common_Church')}
                                                placeholder={T('Report_SelectChurch')}
                                                showContacts
                                            />
                                        </Grid>
                                        <Grid item md={7}>
                                            <FormFieldText
                                                name="application.hyryOrganization"
                                                label={T('CredentialApplication_HyryOrganization')}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid
                                container
                                item
                                xs={12}
                                sx={{ mb: 3, pl: 2, backgroundColor: '#00A8DD', borderRadius: 1 }}
                            >
                                <Typography variant="h6" color="white">
                                    {titleFunc(
                                        mode == 'new'
                                            ? 'CredentialApplication_SubTitle2'
                                            : 'CredentialApplication_SubTitle2Renew'
                                    )}
                                </Typography>
                            </Grid>
                            <Grid container item xs={12} sx={{ mb: 3, pl: 2 }}>
                                <Grid item xs={12} container spacing={2}>
                                    <Grid item md={10}>
                                        <Typography variant="body2" color="secondary">
                                            Katso lomakkeen täyttöohje
                                            <a
                                                style={{ marginLeft: 8 }}
                                                href="https://suomenhelluntaikirkko.fi/valtakirja_dokumentteja/"
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                https://suomenhelluntaikirkko.fi/valtakirja_dokumentteja/
                                            </a>
                                        </Typography>
                                        <Typography variant="body2" color="secondary">
                                            Nimikkeellä tarkoitetaan tehtävänimikettä, jota varten valtakirjaa haetaan.
                                            Katso lisää valtakirjahakemuksen täyttöohjeesta
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid container item xs={12} sx={{ mb: 3, pl: 2 }}>
                                <Grid item xs={12} container spacing={2} sx={{ pb: 3, pr: 1 }}>
                                    <Grid item md={5}>
                                        <FormFieldDropDownCodeSet
                                            name="application.type"
                                            label={T(
                                                mode == 'new'
                                                    ? 'CredentialApplication_Type'
                                                    : 'CredentialApplication_TypeCurrent'
                                            )}
                                            placeholder={T('CredentialApplication_SelectType')}
                                            codeSet="credentialType"
                                        />
                                    </Grid>
                                    <Grid item md={7}>
                                        <FormFieldText
                                            name="application.title"
                                            label={
                                                mode == 'new'
                                                    ? T('CredentialApplication_TypeTitle')
                                                    : T('CredentialApplication_TypeTitleCurrent')
                                            }
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} container spacing={2} sx={{ pb: 3, pr: 1 }}>
                                    <Grid item md={5}>
                                        <FormFieldDropDownLanguage
                                            name="application.language"
                                            label={T('CredentialApplication_Language')}
                                            hiddenValues={['en']}
                                        />
                                    </Grid>
                                    <Grid item md={7} />
                                    <Grid item md={12}>
                                        <Typography>
                                            <ExtraCardInfo language={values.application?.language} />
                                        </Typography>
                                    </Grid>
                                    <Grid item md={2}>
                                        <FormFieldCheckbox
                                            name="application.extraCard"
                                            label={T('CredentialApplication_ExtraCard')}
                                        />
                                    </Grid>
                                    <Grid item md={3}>
                                        {values.application?.extraCard && (
                                            <FormFieldDropDownLanguage
                                                name="application.extraLanguage"
                                                label={T('CredentialApplication_ExtraCardLanguage')}
                                                hiddenValues={values.application.language == 'fi' ? ['fi'] : ['sv']}
                                            />
                                        )}
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} container spacing={2} sx={{ pb: 3, pr: 1 }}>
                                    {mode == 'renew' && (
                                        <Grid item md={10}>
                                            <FormFieldTextArea
                                                name="application.titleChange"
                                                label={T('CredentialApplication_TypeTitleChange')}
                                                rows={5}
                                            />
                                        </Grid>
                                    )}
                                </Grid>
                            </Grid>
                            {mode == 'renew' && (
                                <>
                                    <Grid
                                        container
                                        item
                                        xs={12}
                                        sx={{ mb: 3, pl: 2, backgroundColor: '#00A8DD', borderRadius: 1 }}
                                    >
                                        <Typography variant="h6" color="white">
                                            {titleFunc('CredentialApplication_SubTitle3aRenew')}
                                        </Typography>
                                    </Grid>
                                    <Grid container item xs={12} sx={{ mb: 3, pl: 2 }}>
                                        <Grid item md={10}>
                                            <Typography variant="body2" color="secondary">
                                                Valtakirjan uusijoiden tulee osallistua täydennyskoulutukseen.
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid container item xs={12} sx={{ mb: 3, pl: 2 }}>
                                        <Grid item md={5} sx={{ pr: 3 }}>
                                            <FormControl>
                                                <RadioGroup
                                                    name="application.continuingTrainingAction"
                                                    value={values.application?.continuingTrainingAction}
                                                    onChange={updateSelection}
                                                >
                                                    <FormControlLabel
                                                        value="will"
                                                        control={<Radio />}
                                                        label="Tulen osallistumaan täydennyskoulutukseen"
                                                    />
                                                    <FormControlLabel
                                                        value="done"
                                                        control={<Radio />}
                                                        label="Olen osallistunut täydennyskoulutukseen"
                                                    />
                                                    <FormControlLabel
                                                        value="not"
                                                        control={<Radio />}
                                                        label="En ole osallistunut täydennyskoulutukseen"
                                                    />
                                                </RadioGroup>
                                            </FormControl>
                                        </Grid>
                                        <Grid item md={6} sx={{ pr: 3 }}>
                                            <Grid item xs={12} sx={{ pr: 3 }}>
                                                {values.application?.continuingTrainingAction == 'will' && (
                                                    <FormFieldDate
                                                        name="application.continuingTrainingDate"
                                                        label={T('CredentialType_RightToOfficiateWeddingsTrainingDate')}
                                                        disableFuture
                                                    />
                                                )}
                                                {values.application?.continuingTrainingAction == 'done' && (
                                                    <Box sx={{ pt: 5 }} />
                                                )}
                                            </Grid>
                                            <Grid item xs={12} sx={{ pr: 3 }}>
                                                {values.application?.continuingTrainingAction == 'done' && (
                                                    <FormFieldDate
                                                        name="application.continuingTrainingDate"
                                                        label={T('CredentialType_RightToOfficiateWeddingsTrainingDate')}
                                                        disableFuture
                                                    />
                                                )}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </>
                            )}
                            <Grid
                                container
                                item
                                xs={12}
                                sx={{ mb: 3, pl: 2, backgroundColor: '#00A8DD', borderRadius: 1 }}
                            >
                                <Typography variant="h6" color="white">
                                    {titleFunc('CredentialApplication_SubTitle3')}
                                </Typography>
                            </Grid>
                            <Grid container item xs={12} sx={{ mb: 3, pl: 2 }}>
                                <Grid item xs={12} container spacing={2}>
                                    <Grid item md={10}>
                                        <Typography variant="body2" color="secondary">
                                            Vihkimisoikeus voidaan hakea samalla kuin valtakirjaakin, mutta se voidaan
                                            hakea myös myöhemmin erikseen. HUOM: jos olet uusimassa valtakirjaa, ja
                                            sinulla on ollut vihkimisoikeus, sitä ei tarvitse hakea nyt uudelleen.
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid container item xs={12} sx={{ mb: 3, pl: 2 }}>
                                <Grid item xs={12} container spacing={2} sx={{ pb: 3 }}>
                                    <Grid item md={5}>
                                        <FormFieldCheckbox
                                            name="application.askingRightToOfficiateWeddings"
                                            label={T('CredentialType_AskingRightToOfficiateWeddings')}
                                        />
                                    </Grid>
                                    {values.application?.askingRightToOfficiateWeddings && (
                                        <>
                                            <Grid item md={12} container>
                                                <Grid item md={4} sx={{ pt: 1 }}>
                                                    <Typography>{T('CredentialType_MemberOfShk')}</Typography>
                                                </Grid>
                                                <Grid item md={4}>
                                                    <FormFieldDropDown
                                                        name="membershipType"
                                                        label=""
                                                        values={[
                                                            {
                                                                key: '1',
                                                                value: 'CredentialType_MemberOfShk_HomeChruch'
                                                            },
                                                            { key: '2', value: 'CredentialType_RegisterMemberOfShkBy' },
                                                            { key: '3', value: 'CredentialType_MemberOfShk_No' }
                                                        ]}
                                                        placeholder={T('CredentialType_SelectMembershipForm')}
                                                    />
                                                </Grid>
                                            </Grid>
                                            {values.membershipType == '2' && (
                                                <Grid item xs={12} container>
                                                    <Grid item md={4}></Grid>
                                                    <Grid item md={4}>
                                                        <FormFieldDropDownOrganization
                                                            name="application.registerMemberOrganizationId"
                                                            label={T('CredentialApplicatione_RegistryChurch')}
                                                            placeholder={T(
                                                                'CredentialApplicatione_SelectRegistryChurch'
                                                            )}
                                                            showChurches
                                                        />
                                                    </Grid>
                                                </Grid>
                                            )}
                                            <Grid item xs={12} container>
                                                <Grid item md={4} sx={{ pt: 1, pr: 2 }}>
                                                    <Typography>
                                                        {T('CredentialType_RightToOfficiateWeddingsTraining')}
                                                    </Typography>
                                                </Grid>
                                                <Grid item md={1}>
                                                    <FormFieldCheckbox
                                                        name="application.rightToOfficiateWeddingsTrainingDone"
                                                        label={T('Common_Yes')}
                                                    />
                                                </Grid>
                                                <Grid item md={3} sx={{ pr: 3 }}>
                                                    <FormFieldDate
                                                        name="application.rightToOfficiateWeddingsTrainingDate"
                                                        label={T('CredentialType_RightToOfficiateWeddingsTrainingDate')}
                                                        disableFuture
                                                    />
                                                </Grid>
                                                <Grid item>
                                                    <FormFieldCheckbox
                                                        name="application.rightToOfficiateWeddingsTrainingDone_no"
                                                        label={T('Common_No')}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </>
                                    )}
                                </Grid>
                            </Grid>
                            <Grid
                                container
                                item
                                xs={12}
                                sx={{ mb: 3, pl: 2, backgroundColor: '#00A8DD', borderRadius: 1 }}
                            >
                                <Typography variant="h6" color="white">
                                    {titleFunc('CredentialApplication_SubTitle4')}
                                </Typography>
                            </Grid>
                            <Grid container item xs={12} sx={{ mb: 3, pl: 2 }}>
                                <Grid item xs={12} container spacing={2} sx={{ pb: 3, pr: 1 }}>
                                    <Grid item xs={12}>
                                        <Typography variant="body2" color="secondary">
                                            Valtakirjan edellytyksenä on sitoutuminen seuraavissa asiakirjoissa
                                            kuvattuihin eettisiin ja opillisiin periaatteisiin:
                                        </Typography>
                                        <List dense>
                                            {ethcialListItem(
                                                'Helluntaiseurakuntien uskon pääkohdat',
                                                'https://suomenhelluntaikirkko.fi/helluntaikirkko/uskon-paakohdat/'
                                            )}
                                            {ethcialListItem(
                                                'Helluntaikirkon yhdyskuntajärjestyksen kohdat 39 § ja 40 §, jotka koskevat vaitiolovelvollisuutta ja rippisalaisuutta',
                                                'https://suomenhelluntaikirkko.fi/helluntaikirkko/yhdyskuntajarjestys/'
                                            )}
                                            {ethcialListItem(
                                                'HELMET-menettelytapaohjeisto, kirja ostettavissa',
                                                'https://aikashop.fi/product/167/helmet--menettelytapaohjeisto'
                                            )}
                                            {ethcialListItem(
                                                'Valtakirjan eettiset periaatteet, kts. valtakirjan esite luku 7.',
                                                'https://suomenhelluntaikirkko.fi/valtakirja_dokumentteja/'
                                            )}
                                            {ethcialListItem(
                                                'Helluntaikirkon julkaisemat kannanotot ja julkilausumat',
                                                'https://suomenhelluntaikirkko.fi/kannanottoja/'
                                            )}
                                        </List>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} container spacing={2} sx={{ pb: 3, pr: 1 }}>
                                    <Grid item xs={12}>
                                        <FormFieldCheckbox
                                            name="application.commitToEthicalPrinciples"
                                            label={T('CredentialType_CommitToEthicalPrinciples')}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid
                                container
                                item
                                xs={12}
                                sx={{ mb: 3, pl: 2, backgroundColor: '#00A8DD', borderRadius: 1 }}
                            >
                                <Typography variant="h6" color="white">
                                    {titleFunc('CredentialApplication_SubTitle5')}
                                </Typography>
                            </Grid>
                            <Grid container item xs={12} sx={{ mb: 3, pl: 2 }}>
                                <Grid item xs={12} container spacing={2} sx={{ pb: 1, pr: 1 }}>
                                    <Grid item md={10}>
                                        <Typography variant="body2" color="secondary" sx={{ mb: 1 }}>
                                            Hakemuslomakkeen allekirjoittaa hakija itse sekä hänen kotiseurakuntansa.
                                            Jos valtakirjaa haetaan HYRY-yhdistyksen työntekijälle, lomakkeen
                                            allekirjoittaa myös HYRY-yhteisö.
                                        </Typography>
                                        <Typography variant="body2" color="secondary" sx={{ mb: 1 }}>
                                            Valtakirjahakemus allekirjoitetaan sähköisesti, eli Helluntaikirkko lähettää
                                            allekirjoituspyynnön annettuihin sähköpostiosoitteisiin
                                            VismaSign-järjestelmästä. Allekirjoitus vahvistetaan pankkitunnuksilla.
                                            (Valtakirjaa uusittaessa allekirjoituskutsu lähetetään täydennyskoulutuksen
                                            jälkeen.)
                                        </Typography>
                                        <Typography variant="body2" color="secondary" sx={{ mb: 1 }}>
                                            Seurakunnan puolesta hakemuksen allekirjoittaa ensisijaisesti vanhimmiston
                                            puheenjohtaja tai johtava pastori. Allekirjoittaja ei kuitenkaan voi olla
                                            hakija itse eikä hänen lähisukulaisensa.
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid container item xs={12} sx={{ mb: 3, pl: 2 }}>
                                <Grid item xs={12} container spacing={2} sx={{ pb: 3, pr: 1 }}>
                                    <Grid item md={6} sx={{ mb: 3 }}>
                                        <FormFieldReadOnly
                                            value={`${values.application?.firstName} ${values.application?.lastName}`}
                                            label={T('CredentialApplication_signatureApplicantName')}
                                        />
                                    </Grid>
                                    <Grid item md={6}></Grid>
                                    <Grid item md={6} container spacing={2}>
                                        <Grid item xs={12}>
                                            <FormFieldText
                                                name="application.signatureOrganizationName"
                                                label={T('CredentialApplication_signatureOrganizationName')}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <FormFieldText
                                                name="application.signatureOrganizationPosition"
                                                label={T('CredentialApplication_signatureOrganizationPosition')}
                                            />
                                        </Grid>
                                        <Grid item md={6}>
                                            <FormFieldText
                                                name="application.signatureOrganizationPhone"
                                                label={T('Common_Phone')}
                                            />
                                        </Grid>
                                        <Grid item md={6}>
                                            <FormFieldText
                                                name="application.signatureOrganizationEmail"
                                                label={T('Common_Email')}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid item md={6} container spacing={2}>
                                        <Grid item xs={12}>
                                            <FormFieldText
                                                name="application.signatureHyryName"
                                                label={T('CredentialApplication_signatureHyryName')}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <FormFieldText
                                                name="application.signatureHyryPosition"
                                                label={T('CredentialApplication_signatureHyryPosition')}
                                            />
                                        </Grid>
                                        <Grid item md={6}>
                                            <FormFieldText
                                                name="application.signatureHyryPhone"
                                                label={T('Common_Phone')}
                                            />
                                        </Grid>
                                        <Grid item md={6}>
                                            <FormFieldText
                                                name="application.signatureHyryEmail"
                                                label={T('Common_Email')}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            {mode == 'new' && (
                                <>
                                    <Grid
                                        container
                                        item
                                        xs={12}
                                        sx={{ mb: 3, pl: 2, backgroundColor: '#00A8DD', borderRadius: 1 }}
                                    >
                                        <Typography variant="h6" color="white">
                                            {titleFunc('CredentialApplication_SubTitle6')}
                                        </Typography>
                                    </Grid>
                                    <Grid container item xs={12} sx={{ mb: 3, pl: 2 }}>
                                        <Grid item xs={12} container spacing={2}>
                                            <Grid item md={10}>
                                                <Typography variant="body2" color="secondary">
                                                    Kerro tässä työkokemuksestasi seurakuntatyössä. Jos lähetät
                                                    liitteenä ansioluettelon (CV), josta työkokemus käy ilmi, ei tätä
                                                    kohtaa täytetä.
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid container item xs={12} sx={{ mb: 3, pl: 2 }}>
                                        <Grid item xs={12} container spacing={2} sx={{ pb: 3, pr: 1 }}>
                                            <Grid item md={10}>
                                                <FormFieldTextArea
                                                    name="application.workExperience"
                                                    label={T('CredentialApplication_workExperience')}
                                                    rows={6}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid
                                        container
                                        item
                                        xs={12}
                                        sx={{ mb: 3, pl: 2, backgroundColor: '#00A8DD', borderRadius: 1 }}
                                    >
                                        <Typography variant="h6" color="white">
                                            {titleFunc('CredentialApplication_SubTitle7')}
                                        </Typography>
                                    </Grid>
                                    <Grid container item xs={12} sx={{ mb: 3, pl: 2 }}>
                                        <Grid item xs={12} container spacing={2}>
                                            <Grid item md={10}>
                                                <Typography variant="body2" color="secondary">
                                                    Kerro tässä, mitä kuuluu sen tehtävän toimenkuvaan, jota varten
                                                    valtakirjaa haetaan.
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid container item xs={12} sx={{ mb: 3, pl: 2 }}>
                                        <Grid item xs={12} container spacing={2} sx={{ pb: 3, pr: 1 }}>
                                            <Grid item md={10}>
                                                <FormFieldTextArea
                                                    name="application.jobDescription"
                                                    label={T('CredentialApplication_jobDescription')}
                                                    rows={6}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </>
                            )}
                            <Grid
                                container
                                item
                                xs={12}
                                sx={{ mb: 3, pl: 2, backgroundColor: '#00A8DD', borderRadius: 1 }}
                            >
                                <Typography variant="h6" color="white">
                                    {titleFunc('CredentialApplication_Attachments')}
                                </Typography>
                            </Grid>
                            <Grid container item xs={12} sx={{ mb: 3, pl: 2 }}>
                                <Grid item xs={12} sx={{ mb: 2 }}>
                                    <Typography variant="body2" color="secondary">
                                        Liitteitä tarvitaan seuraavasti:
                                    </Typography>
                                    <List dense>
                                        <ListItem disablePadding>
                                            <Typography variant="body2" color="secondary">
                                                - Valokuva: tuore valokuva tarvitaan kaikkiin hakemuksiin, myös
                                                valtakirjaa uusittaessa
                                            </Typography>
                                        </ListItem>
                                        <ListItem disablePadding>
                                            <Typography variant="body2" color="secondary">
                                                - Ansioluettelo CV (ei lähetetä, jos työkokemus on jo kuvattu kohdassa
                                                17, eikä valtakirjaa uusittaessa)
                                            </Typography>
                                        </ListItem>
                                        <ListItem disablePadding>
                                            <Typography variant="body2" color="secondary">
                                                - Todistus raamattukouluopinnoista (ei tarvita seurakuntatyöntekijän
                                                valtakirjaan eikä valtakirjaa uusittaessa)
                                            </Typography>
                                        </ListItem>
                                        <ListItem disablePadding>
                                            <Typography variant="body2" color="secondary">
                                                - Todistus turvakoulutuksesta: tarvitaan kaikkiin uusiin valtakirjoihin
                                            </Typography>
                                        </ListItem>
                                        <ListItem disablePadding>
                                            <Typography variant="body2" color="secondary">
                                                - Todistus vihkikoulutuksesta: tarvitaan, jos haetaan uutta
                                                vihkioikeutta
                                            </Typography>
                                        </ListItem>
                                        <ListItem disablePadding>
                                            <Typography variant="body2" color="secondary">
                                                - Suostumus vihkijänä toimimiseen: tarvitaan, jos haetaan uutta
                                                vihkioikeutta
                                            </Typography>
                                        </ListItem>
                                    </List>
                                </Grid>
                                <Grid item xs={12} container sx={{ pb: 1 }}>
                                    <Grid item md={4}>
                                        <Typography variant="body1" color="secondary">
                                            {T('Tyyppi')}
                                        </Typography>
                                    </Grid>
                                    <Grid item md={6}>
                                        <Typography variant="body1" color="secondary">
                                            {T('Tiedosto')}
                                        </Typography>
                                    </Grid>
                                    <Grid item md={2}>
                                        <Typography variant="body1" color="secondary">
                                            {T('Toiminnot')}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Divider variant="fullWidth" />
                                    </Grid>
                                </Grid>
                                <FieldArray name="attachmentList">
                                    {({ remove, push }) => (
                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                {values.attachmentList &&
                                                    values.attachmentList.map((item, index) => (
                                                        <CredentialAttachmentLine
                                                            attachment={item}
                                                            key={index}
                                                            edit={editingIndex === index}
                                                            index={index}
                                                            onEdit={(index) => setEditingIndex(index)}
                                                            onRemove={(index) => {
                                                                remove(index);
                                                                setEditingIndex(undefined);
                                                            }}
                                                            onSelectFile={(index, file) => {
                                                                if (attachmentFiles.length > index) {
                                                                    attachmentFiles[index] = file;
                                                                } else {
                                                                    attachmentFiles.push(file);
                                                                }
                                                            }}
                                                            removeDisabled={editingIndex != undefined}
                                                        />
                                                    ))}
                                                {(!values.attachmentList || values.attachmentList.length == 0) && (
                                                    <Typography variant="body2" color="secondary">
                                                        {T('CredentialAttachment_NoAttachments')}
                                                    </Typography>
                                                )}
                                            </Grid>
                                            <Grid item>
                                                <ButtonAdd
                                                    onClick={() => {
                                                        setEditingIndex(values.attachmentList?.length);
                                                        push({
                                                            type: 0,
                                                            document: {}
                                                        });
                                                    }}
                                                    title={T('CredentialAttachment_AddAttachment')}
                                                    color="secondary"
                                                    small
                                                    disabled={editingIndex != undefined}
                                                />
                                            </Grid>
                                        </Grid>
                                    )}
                                </FieldArray>
                            </Grid>
                            <Grid
                                container
                                item
                                xs={12}
                                sx={{ mb: 3, pl: 2, backgroundColor: '#00A8DD', borderRadius: 1 }}
                            >
                                <Typography variant="h6" color="white">
                                    {titleFunc('CredentialApplication_SubTitle8')}
                                </Typography>
                            </Grid>
                            <Grid container item xs={12} sx={{ mb: 3, pl: 2 }}>
                                <Grid item xs={12} container spacing={2} sx={{ pb: 3, pr: 1 }}>
                                    <Grid item md={10}>
                                        <FormFieldTextArea
                                            name="application.info"
                                            label={T('CredentialApplication_SubTitle8')}
                                            rows={6}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item xs={12} sx={{ mt: 4, mb: 2 }}>
                                <LoadButton
                                    disabled={!dirty || !isValid || editingIndex != undefined}
                                    variant="contained"
                                    type="submit"
                                    loading={sending}
                                >
                                    {T('CredentialApplication_SaveApplication')}
                                </LoadButton>
                            </Grid>
                        </Form>
                    );
                }}
            </Formik>
        </Grid>
    );
};

export default CredentialApplicationForm;

interface ExtraCardInfoProps {
    language?: string;
}

const ExtraCardInfo: FC<ExtraCardInfoProps> = ({ language }: ExtraCardInfoProps) => {
    const { T, TExt } = useContext(LanguageContext);
    const [info, setInfo] = useState('');

    const getExtraCardInfo = (lang?: string) => {
        if (lang === 'sv') {
            TExt('CredentialApplication_ExtraCardIntro', AppLanguage.Swedish).then((text) => setInfo(text));
        }
        setInfo(T('CredentialApplication_ExtraCardIntro'));
    };

    useEffect(() => {
        getExtraCardInfo(language);
    }, [language]);

    return <>{info}</>;
};
