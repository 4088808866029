import { Person } from 'types/sp-api';

type personToFullNameFunc = (person?: Person, separator?: string) => string;
type personToFullNameForFilenameFunc = (person?: Person) => string;
type personQueryFunc = (filter?: string, usePerson?: boolean, forceOrgId?: number) => URLSearchParams | undefined;

interface PersonUtilsContext {
    FullName: personToFullNameFunc;
    FullNameForFilename: personToFullNameForFilenameFunc;
    CreatePersonQuery: personQueryFunc;
}

const getPersonFullName = (person?: Person, separator = ' '): string => {
    if (!person) return '';

    return `${person?.lastName}${separator}${
        person?.nickName && person?.nickName.length > 0 ? person?.nickName : person?.firstName.split(' ')[0]
    }`;
};

const getPersonFullNameForFilename = (person?: Person): string => {
    if (!person) return '';

    const fullname = getPersonFullName(person, '_');

    return fullname
        .replace(/å/g, 'a')
        .replace(/ä/g, 'a')
        .replace(/ö/g, 'o')
        .replace(/Å/g, 'A')
        .replace(/Ä/g, 'A')
        .replace(/Ö/g, 'O')
        .replace(/è/g, 'e');
};

const createPersonQuery = (filter?: string, usePerson?: boolean, forceOrgId?: number): URLSearchParams | undefined => {
    if (!filter) return undefined;

    const params = new URLSearchParams();
    const email = /.*@.*/;
    const hetu = /([\d]{6})([-+A])[\d]{3}[a-zA-Z0-9]/; // TODO tarkista hetu laskenta
    const birthday = /([\d]{6})/;
    const phone = /[+]([\d]{6})/;
    const prefix = usePerson ? 'person.' : '';
    let query = forceOrgId ? `organizationId==${forceOrgId};(` : '';

    if (filter) {
        if (email.test(filter)) {
            query += `${prefix}email==*${filter}*`;
        } else if (phone.test(filter)) {
            const localPhone = filter.substring(4).replace(/(.{1})/g, '$1*');
            query += `${prefix}phoneHome==*${localPhone}`;
        } else if (hetu.test(filter)) {
            query += `${prefix}ssn==${filter}`;
        } else if (birthday.test(filter)) {
            query += `${prefix}ssn==${filter}*`;
        } else {
            const split = filter.split(' ');

            if (split.length > 1) {
                query += `(${prefix}lastName==${split[0]}*;${prefix}firstName==${split[1]}*),(${prefix}lastName==${split[1]}*;${prefix}firstName==${split[0]}*)`;
            } else {
                query += `${prefix}lastName==${filter}*,${prefix}firstName==${filter}*`;
            }
        }
    }

    query += forceOrgId ? `)` : '';

    params.append('query', query);
    params.append('size', '100');

    return params;
};

const personHelper: PersonUtilsContext = {
    FullName: getPersonFullName,
    FullNameForFilename: getPersonFullNameForFilename,
    CreatePersonQuery: createPersonQuery
};

export default personHelper;
