import React, { CSSProperties, FC, useContext, MouseEventHandler } from 'react';
import { IconButton } from '@mui/material';
import DownloadIcon from '@mui/icons-material/GetApp';
import { LanguageContext } from 'contexts/languageContext';

interface ButtonDownloadProps {
    color?: 'primary' | 'secondary' | 'default';
    small?: boolean;
    tooltip?: string;
    onClick?: MouseEventHandler<HTMLButtonElement>;
    style?: CSSProperties;
    disabled?: boolean;
}

const ButtonDownload: FC<ButtonDownloadProps> = ({
    onClick,
    color = 'default',
    small,
    tooltip,
    style,
    disabled
}: ButtonDownloadProps) => {
    const { T } = useContext(LanguageContext);

    return (
        <IconButton
            size={small === true ? 'small' : 'medium'}
            color={color}
            onClick={(event) => {
                onClick && onClick(event);
                event.preventDefault();
                event.stopPropagation();
            }}
            title={tooltip ? tooltip : T('Common_Download')}
            style={style}
            disabled={disabled}
        >
            <DownloadIcon fontSize={small === true ? 'small' : 'medium'} />
        </IconButton>
    );
};

export default ButtonDownload;
