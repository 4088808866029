import React, { FC, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { PersonTabProps } from './PersonTabBasic';
import { useErrorHandler } from 'components/hooks/useErrorHandler';
import { LanguageContext } from 'contexts/languageContext';
import axios, { CancelTokenSource } from 'axios';
import { Grid, Typography } from '@mui/material';
import PersonsService from 'services/personsService';
import UserOrganizationList from './UserOrganizationList';
import { User } from 'types/sp-api';
import LoadButton from 'components/common/LoadButton';
import EmailService from 'services/mailService';
import { useSuccessHandler } from 'components/hooks/useSuccessHandler';
import { UserItem } from './UserItem';
import PersonUtils from 'utils/personHelper';
import DialogConfirmation from 'components/common/DialogConfirmation';
import { v4 as uuidv4 } from 'uuid';
import { AuthContext } from 'contexts/authContext';
import Utils from 'utils/typeHelper';
import WaitScreen from 'components/common/WaitScreen';
import { UserRole } from 'types/auth';
import { AppLanguage } from 'types/common';
import config from 'config/config';
import constants from 'config/constants';

const PersonTabAccessRights: FC<PersonTabProps> = ({ person }: PersonTabProps) => {
    const { T, TExt } = useContext(LanguageContext);
    const { user, hasRole } = useContext(AuthContext);
    const [userAccount, setUserAccount] = useState<User>();
    const { handleError } = useErrorHandler();
    const [sendingInvite, setSendingInvite] = useState(false);
    const [sendingResetEmail, setSendingResetEmail] = useState(false);
    const { handleSuccess } = useSuccessHandler();
    const [registrationSendDialog, setRegistrationSendDialog] = useState(false);
    const [resetEmailSendDialog, setResetEmailSendDialog] = useState(false);
    const cancelRef = useRef<CancelTokenSource | null>(null);
    const [loaded, setLoaded] = useState(false);

    // only own role or lower can be edited and needs to be admin or super user
    const allowedEdit = () => {
        if (config.CUSTOMER === constants.Customers.Shk) {
            return (
                Utils.AllowedRole(Utils.RoleIdToRole(userAccount?.roleId), user?.role) &&
                (user?.role == UserRole.Admin || user?.role == UserRole.SuperUser)
            );
        }

        if (config.CUSTOMER === constants.Customers.Advk && hasRole(UserRole.Admin)) {
            return true;
        }

        return false;
    };

    const allowedAdd = () => {
        if (config.CUSTOMER === constants.Customers.Shk && (hasRole(UserRole.Admin) || hasRole(UserRole.SuperUser))) {
            return true;
        }

        if (config.CUSTOMER === constants.Customers.Advk && hasRole(UserRole.Admin)) {
            return true;
        }

        return false;
    };

    const getUserByPerson = useCallback(
        async (personId?: number): Promise<void> => {
            try {
                cancelRef.current = axios.CancelToken.source();
                const personUser = await PersonsService.getUserForPersonAsync(personId, cancelRef.current.token);
                if (cancelRef.current) {
                    setUserAccount(personUser);
                    setLoaded(true);
                }
            } catch (error) {
                setLoaded(true);
                handleError(error, T('Error_UserQueryFailed'));
            }
        },
        [handleError, T]
    );

    useEffect(() => {
        getUserByPerson(person.id);

        return () => {
            cancelRef.current && cancelRef.current.cancel();
            cancelRef.current = null;
        };
    }, [getUserByPerson, person.id]);

    const sendInvite = async () => {
        if (!userAccount?.id || !person.id) return;

        try {
            setSendingInvite(true);
            await EmailService.sendRegistrationMailAsync(
                person.id,
                userAccount.id,
                PersonUtils.FullName(person),
                TExt,
                person.language as AppLanguage
            );
            setSendingInvite(false);
            handleSuccess(T('User_RegistrationInviteSent'));
        } catch (error) {
            setSendingInvite(false);
            handleError(error, T('Error_UserRegistrationInviteFailed'));
        }
    };

    const sendResetEmail = async () => {
        if (!userAccount?.id || !person.id) return;

        try {
            setSendingResetEmail(true);
            await EmailService.sendResetPasswordMailAsync(
                person.id,
                userAccount.id,
                TExt,
                person.language as AppLanguage
            );
            setSendingResetEmail(false);
            handleSuccess(T('User_ResetPasswordMailSent'));
        } catch (error) {
            setSendingResetEmail(false);
            handleError(error, T('Error_UserResetPasswordMailFailed'));
        }
    };

    const addUser = () => {
        setUserAccount({
            id: -1,
            name: `NS_${uuidv4()}`,
            personId: person?.id ?? 0,
            actionToken: uuidv4(),
            beginDate: new Date(),
            roleId: 0
        });
    };

    return (
        <Grid container spacing={5}>
            {!loaded && <WaitScreen />}
            {!userAccount && loaded && (
                <>
                    <Grid item xs={12}>
                        <Typography variant="body1" color="textSecondary">
                            {T('User_NoUserExists')}
                        </Typography>
                    </Grid>
                    {allowedAdd() && (
                        <Grid item xs={12}>
                            <LoadButton onClick={addUser}>{T('User_AddNew')}</LoadButton>
                        </Grid>
                    )}
                </>
            )}
            {userAccount && (
                <>
                    <Grid container item xs={6} alignContent="flex-start">
                        <UserItem
                            user={userAccount}
                            readonly={!allowedEdit()}
                            refreshUser={() => getUserByPerson(person.id)}
                        />
                    </Grid>
                    <Grid container item xs={6} alignContent="flex-start">
                        <UserOrganizationList
                            userId={userAccount.id}
                            personName={PersonUtils.FullName(person)}
                            readonly={!allowedEdit()}
                        />
                    </Grid>
                    <Grid container item xs={12}>
                        {userAccount?.id && userAccount.id > 0 && allowedEdit() && (
                            <LoadButton
                                onClick={() => setRegistrationSendDialog(true)}
                                loading={sendingInvite}
                                disabled={userAccount === undefined || user?.personId === person.id}
                            >
                                {T('User_SendRegistrationInvite')}
                            </LoadButton>
                        )}
                    </Grid>
                    <Grid container item xs={12}>
                        {userAccount?.id && userAccount.id > 0 && allowedEdit() && (
                            <LoadButton
                                onClick={() => setResetEmailSendDialog(true)}
                                loading={sendingResetEmail}
                                disabled={userAccount === undefined || user?.personId === person.id}
                            >
                                {T('User_SendPasswordResetEmail')}
                            </LoadButton>
                        )}
                    </Grid>
                    <DialogConfirmation
                        title={T('User_ConfirmRegistrationSendingTitle')}
                        message={T('User_ConfirmRegistrationSending')
                            .replace('{0}', PersonUtils.FullName(person))
                            .replace('{1}', person.email ?? '')}
                        show={registrationSendDialog}
                        onClose={() => setRegistrationSendDialog(false)}
                        onOk={sendInvite}
                        warning
                    />
                    <DialogConfirmation
                        title={T('User_ConfirmResetEmailSendingTitle')}
                        message={T('User_ConfirmResetEmailSending')
                            .replace('{0}', PersonUtils.FullName(person))
                            .replace('{1}', person.email ?? '')}
                        show={resetEmailSendDialog}
                        onClose={() => setResetEmailSendDialog(false)}
                        onOk={sendResetEmail}
                        warning
                    />
                </>
            )}
        </Grid>
    );
};

export default PersonTabAccessRights;
