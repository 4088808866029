import React, { FC, useContext } from 'react';
import { Box, Button, Container, Grid, Paper, Typography } from '@mui/material';
import LogoBroken from 'assets/sp_logo_broken.png';
import BrokenImageIcon from '@mui/icons-material/BrokenImage';
import ViewTitle from 'components/common/ViewTitle';
import { useNavigate } from 'react-router-dom';
import { LanguageContext } from 'contexts/languageContext';
import BreadcrumbBar from 'components/common/BreadcrumbBar';

const PageNotFound: FC = () => {
    const { T } = useContext(LanguageContext);
    const navigate = useNavigate();

    return (
        <Container>
            <Grid container>
                <BreadcrumbBar />
                <ViewTitle title={T('Common_PageNotFound')} icon={BrokenImageIcon} />
            </Grid>
            <Box m={2} />
            <Paper elevation={4} style={{ padding: 24, height: 250 }}>
                <Grid container spacing={3}>
                    <Grid item container justifyContent="center" style={{ marginBottom: -16 }}>
                        <img className="logo-large" src={LogoBroken} alt="logo" />
                    </Grid>
                    <Grid item container justifyContent="center">
                        <Typography variant="h5">{T('Common_NotFoundInfoText')}</Typography>
                    </Grid>
                    <Grid item container justifyContent="center">
                        <Button variant="contained" color="secondary" onClick={() => navigate('/')}>
                            {T('Common_ReturnToDashboard')}
                        </Button>
                    </Grid>
                </Grid>
            </Paper>
        </Container>
    );
};

export default PageNotFound;
