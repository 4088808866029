import React, { FC, ReactNode, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { LanguageContext, LanguageTextFunc } from 'contexts/languageContext';
import {
    Grid,
    Typography,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from '@mui/material';
import WaitScreen from 'components/common/WaitScreen';
import ViewSubtitle from 'components/common/ViewSubtitle';
import { UserOrganization } from 'types/sp-api';
import FormFieldDropDownOrganization from 'components/common/FormFieldDropDownOrganization';
import UsersService from 'services/usersService';
import * as Yup from 'yup';
import axios, { CancelTokenSource } from 'axios';
import ButtonDelete from 'components/common/ButtonDelete';
import { Form, Formik, FormikProps } from 'formik';
import DialogBase from 'components/common/DialogBase';
import DialogConfirmation from 'components/common/DialogConfirmation';
import { useErrorHandler } from 'components/hooks/useErrorHandler';
import { useSuccessHandler } from 'components/hooks/useSuccessHandler';
import { TableHeadCell } from 'components/common/TableHeadCell';
import ButtonAdd from 'components/common/ButtonAdd';
import { DeleteDialogState } from 'types/common';

const getValidationSchema = (T: LanguageTextFunc) => {
    return Yup.object().shape({
        organizationId: Yup.string().test('not-0-value', T('Common_Required'), (value) => value !== '0')
    });
};

interface UserOrganizationListProps {
    userId?: number;
    personName: string;
    readonly?: boolean;
}

const UserOrganizationList: FC<UserOrganizationListProps> = ({
    userId,
    personName,
    readonly
}: UserOrganizationListProps) => {
    const { T } = useContext(LanguageContext);
    const [userOrganizationList, setUserOrganizationList] = useState<UserOrganization[]>();
    const { handleError } = useErrorHandler();
    const { handleSuccess } = useSuccessHandler();
    const [openAddDialog, setOpenAddDialog] = useState(false);
    const [deleteDialogState, setDeleteDialogState] = useState<DeleteDialogState>();
    const cancelRef = useRef<CancelTokenSource | null>(null);

    const getNewOrganization = (): UserOrganization => ({
        id: undefined,
        organizationId: 0,
        userId: userId ?? 0,
        beginDate: new Date()
    });

    const [selectedOrganization, setSelectedOrganization] = useState<UserOrganization>(getNewOrganization());

    const orgSortFunc = (a: UserOrganization, b: UserOrganization): number => {
        if (!a.organization?.name || !b.organization?.name) return 0;
        if (a.organization?.name > b.organization?.name) return 1;
        if (a.organization?.name < b.organization?.name) return -1;
        return 0;
    };

    const getUserOrganizations = useCallback(
        async (id?: number): Promise<void> => {
            try {
                cancelRef.current = axios.CancelToken.source();
                const userOrganizations = await UsersService.getUserOrganizationsAsync(id, cancelRef.current.token);
                if (cancelRef.current) {
                    userOrganizations?.sort(orgSortFunc);
                    setUserOrganizationList(userOrganizations);
                }
            } catch (error) {
                handleError(error, T('Error_UserOrganizationsQueryFailed'));
            }
        },
        [handleError, T]
    );

    useEffect(() => {
        if (userId) {
            getUserOrganizations(userId);
        }

        return () => {
            cancelRef.current && cancelRef.current.cancel();
            cancelRef.current = null;
        };
    }, [getUserOrganizations, userId]);

    const refreshUserOrganizations = () => {
        // refresh list
        getUserOrganizations(userId);
    };

    const deleteUserOrganization = async () => {
        try {
            await UsersService.deleteUserOrganizationAsync(userId, deleteDialogState?.id);
            handleSuccess(T('UserOrganization_DeleteSuccess'));
            refreshUserOrganizations();
        } catch (error) {
            handleError(error, T('UserOrganization_DeleteFailed'));
        }
    };

    const saveUserOrganization = async (organization: UserOrganization) => {
        try {
            await UsersService.saveUserOrganizationAsync(userId, organization);
            handleSuccess(T('UserOrganization_SaveSuccess'));
            refreshUserOrganizations();
        } catch (error) {
            handleError(error, T('UserOrganization_SaveFailed'));
        }
    };

    const addUserOrganization = () => {
        setSelectedOrganization(getNewOrganization());
        setOpenAddDialog(true);
    };

    if (!userOrganizationList) return <WaitScreen />;

    if (userId === -1) return null;

    return (
        <>
            <Grid item xs={12}>
                <ViewSubtitle small title={T('UserOrganization_ListTitle')} />
            </Grid>
            <Grid item xs={12}>
                <TableContainer component={Paper}>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableHeadCell>{T('UserOrganization_Organization')}</TableHeadCell>
                                <TableHeadCell sx={{ width: 30, textAlign: 'center' }} />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {!userOrganizationList && (
                                <TableRow>
                                    <TableCell>
                                        <WaitScreen />
                                    </TableCell>
                                </TableRow>
                            )}
                            {userOrganizationList && userOrganizationList.length === 0 && (
                                <TableRow>
                                    <TableCell>
                                        <Typography variant="body1" color="textSecondary">
                                            {T('UserOrganization_NotExists')}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            )}
                            {userOrganizationList &&
                                userOrganizationList.length > 0 &&
                                userOrganizationList.map(
                                    (item): ReactNode => (
                                        <TableRow key={item.id}>
                                            <TableCell>{item.organization?.name}</TableCell>
                                            <TableCell sx={{ width: 30, textAlign: 'center' }}>
                                                <ButtonDelete
                                                    disabled={readonly}
                                                    onClick={() =>
                                                        setDeleteDialogState({
                                                            show: true,
                                                            message: T('UserOrganization_DeleteMessage')
                                                                .replace('{0}', personName)
                                                                .replace('{1}', item.organization?.name ?? ''),
                                                            id: item.id ?? 0
                                                        })
                                                    }
                                                    small
                                                />
                                            </TableCell>
                                        </TableRow>
                                    )
                                )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
            {!readonly && (
                <Grid item xs={12} container justifyContent="flex-end">
                    <ButtonAdd
                        small
                        title={T('UserOrganization_AddNew')}
                        variant="text"
                        onClick={() => addUserOrganization()}
                        color="secondary"
                        disabled={readonly}
                    />
                </Grid>
            )}
            <Formik
                initialValues={selectedOrganization}
                enableReinitialize
                validationSchema={getValidationSchema(T)}
                onSubmit={saveUserOrganization}
            >
                {({ dirty, isValid, isSubmitting, submitForm, resetForm }: FormikProps<UserOrganization>) => (
                    <DialogBase
                        show={openAddDialog}
                        title={T('UserOrganization_AddDialogTitle')}
                        onOk={async () => {
                            await submitForm();
                            resetForm();
                            refreshUserOrganizations();
                        }}
                        onClose={() => setOpenAddDialog(false)}
                        okDisabled={isSubmitting || !dirty || !isValid}
                        okTitle={T('Common_Save')}
                    >
                        <Form>
                            <Grid container spacing={3} sx={{ pt: 1 }}>
                                <Grid item xs={12}>
                                    <FormFieldDropDownOrganization
                                        name="organizationId"
                                        label={T('UserOrganization_FieldOrganization')}
                                        placeholder={T('UserOrganization_SelectOrganization')}
                                        showAllAccessable
                                    />
                                </Grid>
                            </Grid>
                        </Form>
                    </DialogBase>
                )}
            </Formik>
            <DialogConfirmation
                title={T('UserOrganization_ConfirmDelete')}
                message={deleteDialogState?.message}
                show={deleteDialogState?.show}
                onClose={() => setDeleteDialogState(undefined)}
                onOk={deleteUserOrganization}
                warning
            />
        </>
    );
};

export default UserOrganizationList;
