import axios, { AxiosResponse, CancelToken } from 'axios';
import { SaveResult } from 'types/common';
import { Credential, CredentialResult, Person } from 'types/sp-api';

class CredentialService {
    public async getPersonByCredentialApplicationAsync(
        id?: number,
        cancelToken?: CancelToken
    ): Promise<CredentialResult> {
        const response = await axios.get(`credentials/personByApplication/${id}`, { cancelToken: cancelToken });
        return response.data;
    }

    public async getCredentialByApplicationIdAsync(id?: number, cancelToken?: CancelToken): Promise<Credential> {
        const response = await axios.get<Credential>(`credentials/byApplication/${id}`, { cancelToken: cancelToken });
        return response.data;
    }

    public async getCredentialAsync(id: number, cancelToken?: CancelToken): Promise<Credential> {
        const response = await axios.get(`credentials/${id}`, { cancelToken: cancelToken });
        return response.data;
    }

    public async saveCredentialAsync(credential: Credential): Promise<SaveResult> {
        if (credential.id && credential.id > 0) {
            await axios.put<Credential, AxiosResponse<Credential>>(`credentials/${credential.id}`, credential);
            return { new: false, id: credential.id };
        } else {
            const result = await axios.post(`credentials/`, credential);
            return { new: true, id: result.data.id ?? 0 };
        }
    }

    public async deleteCredentialAsync(credentialId?: number): Promise<void> {
        if (!credentialId) return;

        await axios.delete(`credentials/${credentialId}`);
    }

    public async getPotentialPersonsAsync(id?: number, cancelToken?: CancelToken): Promise<Person[]> {
        const response = await axios.get<Person[]>(`credentials/${id}/potentialPersons`, { cancelToken: cancelToken });
        return response.data;
    }
}

export default new CredentialService();
