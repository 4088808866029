import axios, { CancelToken } from 'axios';
import { PagedResult } from 'types/common';
import { Notification } from 'types/sp-api';

class NotificationsService {
    public async getUnreadCountAsync(cancelToken?: CancelToken): Promise<number> {
        const params = new URLSearchParams();
        params.append('query', 'unread==true');

        const response = await axios.get<number>(`notifications/count/`, {
            params: params,
            cancelToken: cancelToken
        });
        return response.data;
    }

    public async getAllAsync(cancelToken?: CancelToken): Promise<Notification[]> {
        const params = new URLSearchParams();
        params.append('sort', 'unread,desc');
        params.append('sort', 'createdDate,desc');

        const response = await axios.get<PagedResult<Notification[]>>(`notifications/`, {
            params: params,
            cancelToken: cancelToken
        });
        return response.data.content;
    }

    public async getPreviewUnreadAsync(cancelToken?: CancelToken): Promise<Notification[]> {
        const params = new URLSearchParams();
        params.append('query', 'unread==true');
        params.append('size', '5');
        params.append('sort', 'createdDate,desc');

        const response = await axios.get<PagedResult<Notification[]>>(`notifications/`, {
            params: params,
            cancelToken: cancelToken
        });
        return response.data.content;
    }

    public async getNotificationAsync(notificationId: number, cancelToken?: CancelToken): Promise<Notification> {
        const response = await axios.get<Notification>(`notifications/${notificationId}`, {
            cancelToken: cancelToken
        });
        return response.data;
    }

    public async saveNotificationAsync(notification: Notification): Promise<void> {
        if (notification?.id && notification.id > 0) {
            await axios.put<Notification>(`notifications/${notification?.id}`, notification);
        } else {
            await axios.post<Notification>(`notifications/`, notification);
        }
    }

    public async deleteNotificationAsync(notificationId: number): Promise<void> {
        await axios.delete<void>(`notifications/${notificationId}`);
    }
}

export default new NotificationsService();
