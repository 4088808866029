import React, { FC, useContext, useEffect, useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import DialogConfirmation from 'components/common/DialogConfirmation';
import { LanguageContext } from 'contexts/languageContext';
import { AuthContext } from 'contexts/authContext';
import { Typography } from '@mui/material';

interface AppIdleTimerProps {
    idleTimeout: number;
    logoutTimeout: number;
}

const AppIdleTimer: FC<AppIdleTimerProps> = ({ idleTimeout, logoutTimeout }: AppIdleTimerProps) => {
    const { T } = useContext(LanguageContext);
    const { isAuthenticated, logout } = useContext(AuthContext);
    const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
    const [remaining, setRemaining] = useState(0);

    const timeout = idleTimeout * 1000;
    const promptBeforeIdle = logoutTimeout * 1000;

    const onPrompt = () => {
        setOpenConfirmDialog(true);
        setRemaining(promptBeforeIdle);
    };

    const onIdle = () => {
        setOpenConfirmDialog(false);
        setRemaining(0);
        logout();
        console.warn(`User logged out automatically after ${idleTimeout / 60} minutes of inactivity`);
    };

    const onActive = () => {
        if (openConfirmDialog) {
            setOpenConfirmDialog(false);
        }
        setRemaining(0);
    };

    const { getRemainingTime, isPrompted, reset } = useIdleTimer({
        timeout,
        promptBeforeIdle,
        onPrompt,
        onIdle,
        onActive
    });

    useEffect(() => {
        const interval = setInterval(() => {
            if (isPrompted()) {
                setRemaining(Math.ceil(getRemainingTime() / 1000));
            }
        }, 1000);
        return () => {
            clearInterval(interval);
        };
    }, [getRemainingTime, isPrompted]);

    if (isAuthenticated() === false) return null;

    return (
        <DialogConfirmation
            title={T('Common_ConfirmIdleTitle')}
            message={
                <>
                    <Typography>{T('Common_ConfirmIdleMessage')}</Typography>
                    <Typography>{T('Common_AutomaticIdleLogout').replace('{0}', remaining.toString())}</Typography>
                </>
            }
            show={openConfirmDialog}
            onCancel={() => {
                setOpenConfirmDialog(false);
                reset();
            }}
            onOk={() => {
                setOpenConfirmDialog(false);
                logout();
            }}
            okTitle={T('Common_Logout')}
            warning
        />
    );
};

export default AppIdleTimer;
