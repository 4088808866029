import React, { FC, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Container, Grid, Paper, Tab, Tabs } from '@mui/material';
import axios, { CancelTokenSource } from 'axios';
import ContactService from 'services/contactService';
import WaitScreen from 'components/common/WaitScreen';
import BreadcrumbBar from 'components/common/BreadcrumbBar';
import ViewTitle from 'components/common/ViewTitle';
import ChurchIcon from '../icons/ChurchIcon';
import { LanguageContext } from 'contexts/languageContext';
import TabPanel from 'components/common/TabPanel';
import { useErrorHandler } from 'components/hooks/useErrorHandler';
import { OrganizationContact } from 'types/sp-api';
import ChurchTabPublic from './ChurchTabPublic';
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import { OrganizationType } from 'types/common';

interface PublicChurchItemProps {
    churchId: string;
}

export const PublicChurchItem: FC<PublicChurchItemProps> = ({ churchId }: PublicChurchItemProps) => {
    const { T } = useContext(LanguageContext);
    const [organization, setOrganization] = useState<OrganizationContact>();
    const { handleError } = useErrorHandler();
    const cancelRef = useRef<CancelTokenSource | null>(null);

    const getChurch = useCallback(
        async (id: number): Promise<void> => {
            try {
                !cancelRef.current && (cancelRef.current = axios.CancelToken.source());
                const organizationList = await ContactService.getOrganizationsAsync(
                    undefined,
                    cancelRef.current.token,
                    true,
                    true,
                    false
                );
                if (cancelRef.current) {
                    setOrganization(organizationList.find((item) => item.churchId === id));
                }
            } catch (error) {
                handleError(error, T('Error_ChurchQueryFailed'));
            }
        },
        [handleError, T]
    );

    useEffect(() => {
        getChurch(parseInt(churchId));

        return () => {
            cancelRef.current && cancelRef.current.cancel();
            cancelRef.current = null;
        };
    }, [getChurch, churchId]);

    if (!organization) return <WaitScreen />;

    return (
        <Container>
            <Grid container>
                <BreadcrumbBar />
                <ViewTitle
                    title={organization.name}
                    icon={organization.type === OrganizationType.ContactInfo ? ContactPhoneIcon : ChurchIcon}
                    iconColor={organization.type === OrganizationType.ContactInfo ? 'info' : 'primary'}
                />
            </Grid>
            <Paper elevation={3}>
                <Tabs value={0} indicatorColor="primary" textColor="primary">
                    <Tab label={T('ChurchTab_Public')} />
                </Tabs>
                <TabPanel index={0} value={0}>
                    <ChurchTabPublic organization={organization} />
                </TabPanel>
            </Paper>
        </Container>
    );
};

export default PublicChurchItem;
