import React, { FC, ChangeEvent, ReactNode } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Box, Grid, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import classNames from 'classnames';

interface ReportAccordionProps {
    title: string;
    helpText: string;
    name: string;
    expanded: string | false;
    onChange: (panel: string) => (_: ChangeEvent<unknown>, isExpanded: boolean) => void;
    children: ReactNode;
}

const ReportAccordion: FC<ReportAccordionProps> = ({
    title,
    helpText,
    name,
    expanded,
    onChange,
    children
}: ReportAccordionProps) => {
    return (
        <Accordion expanded={expanded === name} onChange={onChange(name)}>
            <AccordionSummary
                className={classNames(
                    { 'accordion-item': expanded !== name },
                    { 'accordion-selected': expanded === name }
                )}
                expandIcon={<ExpandMoreIcon fontSize="large" />}
            >
                <Grid container spacing={3}>
                    <Grid item xs={4}>
                        <Typography variant="h6" color="textPrimary">
                            {title}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="body2" color="textSecondary" style={{ marginTop: 8 }}>
                            {helpText}
                        </Typography>
                    </Grid>
                </Grid>
            </AccordionSummary>
            <AccordionDetails>
                <Box padding={3}>{expanded === name && children}</Box>
            </AccordionDetails>
        </Accordion>
    );
};

export default ReportAccordion;
