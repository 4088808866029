import React, { FC, useCallback, useContext, useEffect } from 'react';
import { AppBar, Toolbar, IconButton, Badge, ButtonGroup, Button, Box } from '@mui/material';
import {
    AccountCircle,
    Mail as MailIcon,
    Notifications as NotificationsIcon,
    SvgIconComponent
} from '@mui/icons-material';
import { LanguageContext } from 'contexts/languageContext';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from 'contexts/authContext';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import NotificationsService from 'services/notificationsService';
import SecureMessagesService from 'services/secureMessagesService';
import { NotificationsContext } from 'contexts/notificationsContext';
import { SecureMessagesContext } from 'contexts/secureMessagesContext';
import { UserRole } from 'types/auth';
import ButtonWithMenu from 'components/common/ButtonWithMenu';
import { MainMenuItem } from 'types/common';
import config from 'config/config';
import constants from 'config/constants';
import SettingsIcon from '@mui/icons-material/Settings';

const MainMenu: FC = () => {
    const { T } = useContext(LanguageContext);
    const { user, hasRole } = useContext(AuthContext);
    const { notificationCount, setNotificationCount } = useContext(NotificationsContext);
    const { messageCount, setMessageCount } = useContext(SecureMessagesContext);
    const navigate = useNavigate();

    const getNotificationCount = useCallback(async () => {
        const count = await NotificationsService.getUnreadCountAsync();
        setNotificationCount(count);
    }, [setNotificationCount]);

    const getMessageCount = useCallback(async () => {
        const count = await SecureMessagesService.getUnreadCountAsync(0);
        setMessageCount(count);
    }, [setMessageCount]);

    useEffect(() => {
        if (user) {
            getNotificationCount();
            getMessageCount();
        }
    }, [getNotificationCount, getMessageCount, user]);

    if (!user) return null;

    const menuItems: MainMenuItem[] = [
        { title: T('Common_Persons'), url: '/persons' },
        { title: T('Common_Churches'), url: '/churches' },
        { title: T('Common_Reports'), url: '/reports', class: 'main-menu-right-separator' },
        {
            title: T('Common_Communication'),
            url: '/communication',
            submenu: [
                { title: T('Common_SecureMessages'), url: '/communication/securemessages' },
                { title: T('Common_SmsMessages'), url: '/communication/smsmessages/new' }
            ]
        },
        {
            title: T('Common_Registers'),
            url: '/registers',
            submenu: [
                { title: T('CredentialRegister_Title'), url: '/registers/credentials', role: UserRole.Admin },
                { title: T('GlobalGroupRegister_Title_senior'), url: '/registers/seniors' },
                { title: T('GlobalGroupRegister_Title_prisonEvangelist'), url: '/registers/prisonEvangelists' },
                { title: T('GlobalGroupRegister_Title_webinarMember'), url: '/registers/webinarMembers' },
                { title: T('CongregationRegister_Title'), url: '/registers/congregations' }
            ],
            role: [UserRole.Admin, UserRole.Clerk],
            hidden: config.CUSTOMER == constants.Customers.Advk
        },
        { title: T('Common_Archive'), url: '/archive', role: UserRole.Admin, class: 'archive-background' },
        {
            iconMenu: true,
            icon: MailIcon,
            title: T('SecureMessages_Title'),
            url: '/communication/securemessages',
            badgeContent: messageCount
        },
        {
            iconMenu: true,
            icon: NotificationsIcon,
            title: T('Common_Notifications'),
            url: '/notifications',
            badgeContent: notificationCount
        },
        {
            iconMenu: true,
            icon: SettingsIcon,
            title: T('Common_Settings'),
            url: '/settings',
            submenu: [
                { title: T('SettingsTab_MemberTagTitles'), url: '/settings/memberTagTitles' },
                {
                    title: T('SettingsTab_CommunityTagTitles'),
                    url: '/settings/communityTagTitles',
                    role: UserRole.Admin
                },
                { title: T('SettingsTab_SmsSender'), url: '/settings/smsSender' },
                { title: T('Common_AccessRights'), url: '/accessrights' },
                { title: T('Common_AdminTools'), url: '/admintools' }
            ]
        },
        {
            iconMenu: true,
            icon: AccountCircle,
            title: user?.fullname,
            url: '/profile'
        },
        {
            iconMenu: true,
            icon: ExitToAppIcon,
            title: T('Common_Logout'),
            url: '/logout'
            //edge: 'end'
        }
    ];

    return (
        <AppBar position="static">
            <Toolbar variant="dense">
                <ButtonGroup variant="text">
                    {menuItems
                        .filter((item) => item.hidden !== true && item.iconMenu !== true)
                        .map(
                            (item, index) =>
                                (!item.role || hasRole(item.role)) &&
                                (!item.submenu ? (
                                    <Button
                                        key={item.url}
                                        className={item.class}
                                        sx={{ pr: 3, pl: 3 }}
                                        onClick={() => navigate(item.url)}
                                    >
                                        {item.title}
                                    </Button>
                                ) : (
                                    <ButtonWithMenu
                                        key={item.url}
                                        title={item.title}
                                        submenu={item.submenu}
                                        showBorder={index < menuItems.length}
                                    />
                                ))
                        )}
                </ButtonGroup>
                <Box sx={{ flexGrow: 1 }} />
                {menuItems
                    .filter((item) => item.hidden !== true && item.iconMenu === true)
                    .map((item) =>
                        !item.submenu ? (
                            <IconButton
                                key={item.url}
                                color="inherit"
                                onClick={() => navigate(item.url)}
                                title={item.title}
                            >
                                <Badge badgeContent={item.badgeContent} className="main-menu-badge">
                                    <IconMenuItem icon={item.icon} />
                                </Badge>
                            </IconButton>
                        ) : (
                            <ButtonWithMenu
                                key={item.url}
                                title={item.title}
                                submenu={item.submenu}
                                iconOnly
                                icon={item.icon}
                            />
                        )
                    )}
            </Toolbar>
        </AppBar>
    );
};

interface IconMenuItemProps {
    icon?: SvgIconComponent;
}

const IconMenuItem: FC<IconMenuItemProps> = ({ icon }: IconMenuItemProps) => {
    const Icon = icon as SvgIconComponent;
    return <Icon htmlColor="white" />;
};

export default MainMenu;
