import React, { FC, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { LanguageContext } from 'contexts/languageContext';
import { Divider, Grid } from '@mui/material';
import { Credential, CredentialApplication, Person } from 'types/sp-api';
import DialogBase from 'components/common/DialogBase';
import { Formik, FormikProps, Form } from 'formik';
import { useErrorHandler } from 'components/hooks/useErrorHandler';
import { useSuccessHandler } from 'components/hooks/useSuccessHandler';
import CredentialService from 'services/credentialService';
import axios, { CancelTokenSource } from 'axios';
import FormFieldDropDown from 'components/common/FormFieldDropDown';
import Utils from 'utils/personHelper';
import ViewSubtitle from 'components/common/ViewSubtitle';
import WaitScreen from 'components/common/WaitScreen';
import SelectedPersonInfo from './SelectedPersonInfo';

interface DialogSelectPersonProps {
    credential: Credential;
    application: CredentialApplication;
    show?: boolean;
    onClose: () => void;
    onUpdate: () => void;
}

const DialogSelectPerson: FC<DialogSelectPersonProps> = ({
    credential,
    application,
    show,
    onClose,
    onUpdate
}: DialogSelectPersonProps) => {
    const { T } = useContext(LanguageContext);
    const { handleSuccess } = useSuccessHandler();
    const { handleError } = useErrorHandler();
    const cancelRef = useRef<CancelTokenSource | null>(null);
    const [potentialPersons, setPotentialPersons] = useState<Person[]>();

    const getPotentialPersons = useCallback(
        async (id?: number): Promise<void> => {
            try {
                !cancelRef.current && (cancelRef.current = axios.CancelToken.source());
                const persons = await CredentialService.getPotentialPersonsAsync(id, cancelRef.current.token);

                if (cancelRef.current) {
                    setPotentialPersons(persons);
                }
            } catch (error) {
                console.error(error);
                handleError(error, T('Error_CredentialApplicationPersonsQueryFailed'));
            }
        },
        [handleError, T]
    );

    const changePerson = async (values: Credential) => {
        try {
            await CredentialService.saveCredentialAsync(values);
            // refresh application
            onUpdate();
            handleSuccess(T('CredentialApplication_PersonSaveSuccess'));
        } catch (error) {
            handleError(T('CredentialApplication_PersonSaveFailed'));
        }
    };

    useEffect(() => {
        if (show) {
            getPotentialPersons(credential.id);
        }

        return () => {
            cancelRef.current && cancelRef.current.cancel();
            cancelRef.current = null;
        };
    }, [credential.id, show]);

    if (!potentialPersons) return <WaitScreen />;

    return (
        <Formik
            initialValues={credential}
            onSubmit={() => {
                // DO nothing
            }}
            enableReinitialize
        >
            {({ dirty, isValid, isSubmitting, resetForm, values }: FormikProps<Credential>) => (
                <DialogBase
                    show={show}
                    title={T('CredentialApplication_ChangeCredentialPersonTitle')}
                    onOk={async () => {
                        changePerson(values);
                        resetForm();
                        onClose();
                    }}
                    onClose={onClose}
                    okDisabled={isSubmitting || !dirty || !isValid}
                    okTitle={T('Common_Save')}
                >
                    <Form>
                        <Grid container spacing={3} sx={{ pt: 1 }}>
                            <Grid container item xs={6} alignContent="start">
                                <Grid item xs={12} sx={{ mb: 1 }}>
                                    <ViewSubtitle
                                        title={T('CredentialApplication_ApplicationPersonInfoTitle')}
                                        small
                                        noMargin
                                    />
                                </Grid>
                                <Grid container item xs={12}>
                                    <Grid item xs={6}>
                                        <Grid>{`${application.lastName} ${application.firstName}`}</Grid>
                                        <Grid>{application.address?.street}</Grid>
                                        <Grid>{`${application.address?.zipCode} ${application.address?.city}`}</Grid>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Grid>{application.phone}</Grid>
                                        <Grid>{application.ssn}</Grid>
                                        <Grid>{application.email}</Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Divider />
                            </Grid>
                            <Grid item xs={10}>
                                <FormFieldDropDown
                                    name="personId"
                                    values={potentialPersons?.map((person) => ({
                                        key: person.id ?? 0,
                                        value: `${Utils.FullName(person)}, ${person.address?.city}`
                                    }))}
                                    label={T('CredentialApplication_PotentialPersons')}
                                    placeholder={T('CredentialApplication_SelectPerson')}
                                />
                            </Grid>
                            <Grid item xs={10}>
                                <SelectedPersonInfo personId={values.personId} persons={potentialPersons} />
                            </Grid>
                        </Grid>
                    </Form>
                </DialogBase>
            )}
        </Formik>
    );
};

export default DialogSelectPerson;
