import React, { FC, useContext } from 'react';
import {
    FormControl,
    MenuItem,
    Select,
    FormHelperText,
    ListItemText,
    Checkbox,
    Typography,
    InputLabel
} from '@mui/material';
import { useField } from 'formik';
import { KeyValue } from 'types/common';
import { LanguageContext } from 'contexts/languageContext';

interface FormFieldDropDownMultiselectProps {
    label?: string;
    name: string;
    values: KeyValue[];
    placeholder?: string;
    disabledValues?: number | string | (number | string)[];
    hiddenValues?: (number | string)[];
    autoFocus?: boolean;
    readonly?: boolean;
}

const FormFieldDropDownMultiselect: FC<FormFieldDropDownMultiselectProps> = ({
    label,
    name,
    values,
    placeholder,
    disabledValues,
    hiddenValues,
    autoFocus,
    readonly
}: FormFieldDropDownMultiselectProps) => {
    const [field, meta] = useField(name);
    const { T } = useContext(LanguageContext);
    const DEFAULT = 'no_set';

    const isDisabled = (key?: string | number): boolean => {
        if (!disabledValues || !key) return false;

        if (Array.isArray(disabledValues)) {
            return disabledValues.indexOf(key) !== -1;
        }

        return disabledValues === key;
    };

    return (
        <>
            <FormControl size="small" variant="outlined" fullWidth error={!!meta.error}>
                {label && <InputLabel>{label}</InputLabel>}
                <Select
                    label={label}
                    style={{ minWidth: 150 }}
                    labelId={`select-${name}`}
                    variant="outlined"
                    onBlur={field.onBlur}
                    onChange={field.onChange}
                    value={field.value}
                    name={field.name}
                    autoFocus={autoFocus}
                    disabled={readonly}
                    multiple
                    renderValue={(items: unknown) => {
                        const vals = items as Array<unknown>;
                        if (vals.length === 0) {
                            return <Typography color="textSecondary">{T(placeholder)}</Typography>;
                        }
                        return vals.map((item) => T(values.find((v) => v.key === item)?.value)).join('; ');
                    }}
                    placeholder={placeholder}
                    displayEmpty
                >
                    {values
                        .filter((item) => item.key !== DEFAULT)
                        .filter((item) => !item.key || !hiddenValues || !hiddenValues?.includes(item.key))
                        .map((item, index) => (
                            <MenuItem key={index} value={item.key} disabled={isDisabled(item.key)}>
                                <Checkbox checked={field.value.indexOf(item.key ?? '') > -1} color="primary" />
                                <ListItemText primary={item.value ? T(item.value) : item.text} />
                            </MenuItem>
                        ))}
                </Select>
                {meta.error && <FormHelperText>{meta.error}</FormHelperText>}
            </FormControl>
        </>
    );
};

export default FormFieldDropDownMultiselect;
