import { LangString } from 'contexts/languageContext';

export const initLangString = (): LangString[] => {
    return [
        {
            key: 'Common_ApplicationTitle',
            value: 'Sähköpaimen'
        },
        {
            key: 'Common_Required',
            value: 'Pakollinen tieto'
        },
        {
            key: 'Common_CookieConsentButton',
            value: 'Asia selvä'
        },
        {
            key: 'Common_CookieConsentMessage',
            value: 'Tämä verkkosivusto tallentaa tietoja, kuten evästeitä, sivuston tärkeiden toimintojen mahdollistamiseksi.'
        },
        {
            key: 'Common_Contact',
            value: 'Yhteystiedot'
        },
        {
            key: 'Common_PrivacyPolicy',
            value: 'Rekisteriseloste'
        },
        {
            key: 'Common_CookiePolicy',
            value: 'Evästekäytäntö'
        },
        {
            key: 'Common_Required',
            value: 'Pakollinen tieto'
        },
        {
            key: 'Common_Required',
            value: 'Pakollinen tieto'
        },
        {
            key: 'Common_TestEnvironment',
            value: 'Testiympäristö'
        },
        {
            key: 'Login_Title',
            value: 'Kirjaudu sisään'
        },
        {
            key: 'Login_Username',
            value: 'Käyttäjätunnus'
        },
        {
            key: 'Login_Password',
            value: 'Salasana'
        },
        {
            key: 'Login_LoginButtonTitle',
            value: 'Kirjaudu'
        },
        {
            key: 'Login_ResetPasswordText',
            value: 'Unohditko salasanan tai käyttäjätunnuksen?'
        },
        {
            key: 'Login_Failed',
            value: 'Väärä käyttäjätunnus tai salasana'
        },
        {
            key: 'Login_OtpFailed',
            value: 'Väärä vahvistuskoodi'
        },
        {
            key: 'Login_ErrorFailed',
            value: 'Kirjautuminen epäonnistui'
        },
        {
            key: 'Login_OtpTitle',
            value: 'Vahvista kirjautuminen'
        },
        {
            key: 'Login_OtpTitleHelpText',
            value: 'Saat vahvistuskoodin puhelimesi Sähköpaimen sÄppi -sovelluksesta'
        },
        {
            key: 'Login_Otp',
            value: 'Anna vahvistuskoodi'
        },
        {
            key: 'Login_LoginButtonTitle',
            value: 'Vahvista'
        },
        {
            key: 'Common_Error',
            value: 'Virhe'
        },
        {
            key: 'Contact_Footer_Title',
            value: 'DataCodex Oy'
        },
        {
            key: 'Contact_Footer_Email',
            value: 'sahkopaimen@datacodex.fi'
        },
        { key: 'CredentialApplication_Title', value: 'Valtakirjahakemus' },
        { key: 'CredentialApplication_IntroductionFile', value: 'Työntekijän valtakirjan esittely' },
        { key: 'CredentialApplication_HelpDocumentationFile', value: 'Työntekijän valtakirjahakemuksen täyttöohjeet' },
        { key: 'CredentialApplication_HelpDocsTitle', value: 'Ohjeet' },
        { key: 'CredentialApplication_CreateNewApplication', value: 'Hakemus uudesta valtakirjasta' },
        { key: 'CredentialApplication_CreateRenewApplication', value: 'Päättyvän valtakirjan uusintahakemus' },
        { key: 'LegacyReportLogin_Title', value: 'Vanhan jäsenrekisterin raporttien tallennus' },
        { key: 'LegacyReportLogin_Failed', value: 'Väärä salasana' }
    ];
};
