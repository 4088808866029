import React, { MouseEvent } from 'react';
import { TableHead, TableRow, TableSortLabel } from '@mui/material';
import { Order } from 'types/common';
import { TableHeadCell } from 'components/common/TableHeadCell';

export interface TableHeadCellDef<T> {
    id: keyof T;
    label: string;
    numeric?: boolean;
    disablePadding?: boolean;
}

interface EnhancedTableHeadProps<T> {
    onRequestSort: (event: MouseEvent<unknown>, property: keyof T) => void;
    order: Order;
    orderBy: keyof T;
    headCells: TableHeadCellDef<T>[];
}

const EnhancedTableHead = <T,>(props: EnhancedTableHeadProps<T>): JSX.Element => {
    const { order, orderBy, onRequestSort, headCells } = props;
    const createSortHandler = (property: keyof T) => (event: MouseEvent<unknown>) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell, index) => (
                    <TableHeadCell
                        key={index}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                        </TableSortLabel>
                    </TableHeadCell>
                ))}
            </TableRow>
        </TableHead>
    );
};

export default EnhancedTableHead;
