import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import Config from 'config/config';
import { LanguageContextInterface } from 'contexts/languageContext';
import {
    AuthContextInterface,
    AuthenticatedUser,
    JwtPayload,
    JwtWithCustomAttributes,
    KeycloakTokenResult,
    LoginContext,
    LoginResult
} from 'types/auth';
import jwt from 'jsonwebtoken';
import { AppLanguage } from 'types/common';
import moment from 'moment';
import PersonsService from './personsService';
import defaults from 'config/defaults';
import UsersService from './usersService';
import { Organization } from 'types/sp-api';

class AuthService {
    private STORAGE_REFRESH_KEY = 'sp_urtk';
    private STORAGE_ACCESS_TOKEN_KEY = 'sp_atc';
    private TOKEN_URL = `${Config.IDP_URL}/realms/${Config.IDP_REALM}/protocol/openid-connect/token`;
    private LOGOUT_URL = `${Config.IDP_URL}/realms/${Config.IDP_REALM}/protocol/openid-connect/logout`;
    private refreshTimeout?: NodeJS.Timeout = undefined;
    private authAxiosInstance = axios.create(); // create separate instance to avoid interceptors

    public async loginAsync(
        authCtx: AuthContextInterface,
        languageCtx: LanguageContextInterface,
        loginCtx: LoginContext
    ): Promise<LoginResult> {
        const params = this.getAuthParams(loginCtx);

        let result;
        try {
            // Cleanup before new login
            this.deleteRefreshToken();
            this.deleteAccessToken();

            result = await this.authAxiosInstance.post<URLSearchParams, AxiosResponse<KeycloakTokenResult>>(
                this.TOKEN_URL,
                params,
                this.getAxiosConfig()
            );
        } catch (error: unknown) {
            if (axios.isAxiosError(error)) {
                const err: AxiosError<KeycloakTokenResult> = error as AxiosError<KeycloakTokenResult, never>;
                if (err && err.request.status === 401) {
                    if (err.response?.data?.error === 'invalid_otp') {
                        // login ok, otp needed
                        return { otpRequired: true, success: false };
                    }
                    console.error('Login failed: ' + err.response?.data?.error);
                    return { success: false, message: err.response?.data?.error };
                }
            } else {
                console.error('Generic login error: ', error);
            }
            return { success: false, message: (error as Error).message };
        }

        if (loginCtx.otp?.length === 0) {
            return { otpRequired: true, success: false };
        }

        const user = await this.setUserFromToken(result.data);
        authCtx.setUser(user);

        // Set language
        const person = await PersonsService.getPersonAsync(user.personId);
        if (person) languageCtx.setLanguage((person.language as AppLanguage) ?? defaults.Language);

        return { success: true };
    }

    public getAccessToken(): string | undefined {
        const accessToken = this.retrieveAccessToken();
        if (!accessToken) return undefined;

        return this.isExpiredToken(accessToken) ? undefined : accessToken;
    }

    public logout(): void {
        if (this.refreshTimeout) {
            clearTimeout(this.refreshTimeout);
        }

        const refreshToken = this.retrieveRefreshToken();

        if (refreshToken) {
            const params = this.getLogoutParams(refreshToken);

            try {
                this.authAxiosInstance.post(this.LOGOUT_URL, params, this.getAxiosConfig());
            } catch (error) {
                console.error('Generic logout error: ', error);
            }
        }

        this.deleteRefreshToken();
        this.deleteAccessToken();
    }

    public async refreshUserAsync(): Promise<AuthenticatedUser | undefined> {
        const refreshToken = this.retrieveRefreshToken();
        if (!refreshToken) return undefined; // logged out

        // if refresh token is expired, redirect to login
        if (this.isExpiredToken(refreshToken)) {
            console.warn('Refresh token expired');
            return undefined;
        }

        // refresh token
        const params = this.getRefreshParams(refreshToken);

        try {
            const result = await this.authAxiosInstance.post<URLSearchParams, AxiosResponse<KeycloakTokenResult>>(
                this.TOKEN_URL,
                params,
                this.getAxiosConfig()
            );

            return await this.setUserFromToken(result.data);
        } catch (error) {
            console.error('Token refresh error: ', error);
        }

        console.warn('User automatic refresh failed');
        return undefined;
    }

    public isExpiredToken(token?: string | null): boolean {
        if (!token) return true;

        const payload = jwt.decode(token) as JwtPayload;
        return moment.unix(payload.exp) < moment();
    }

    public isAuthenticated() {
        const at = this.retrieveAccessToken();
        const rt = this.retrieveRefreshToken();

        const at_expired = this.isExpiredToken(at);
        const rt_expired = this.isExpiredToken(rt);

        if (at_expired) this.deleteAccessToken();
        if (rt_expired) this.deleteRefreshToken();

        return !at_expired || !rt_expired;
    }

    public refreshPossible() {
        const rt = this.retrieveRefreshToken();
        const rt_expired = this.isExpiredToken(rt);

        if (rt_expired) {
            this.deleteAccessToken();
            this.deleteRefreshToken();
        }

        return !rt_expired;
    }

    public async legacyReportLogin(password: string): Promise<boolean> {
        return password.length > 1 && password == Config.LEGACY_REPORT_PASSWORD;
    }

    private organizationSorter = (a: Organization, b: Organization): number => {
        // sort local community before assosiations
        if (!a || !b) return 0;
        if (!a.type || !b.type) return 0;
        if (a.type > b.type) return 1;
        if (a.type < b.type) return -1;

        // sort then by name
        if (!a.name || !b.name) return 0;
        if (a.name > b.name) return 1;
        if (a.name < b.name) return -1;

        return 0;
    };

    private async setUserFromToken(token: KeycloakTokenResult): Promise<AuthenticatedUser> {
        // Parse token to user
        const user = this.crateUserFromToken(token);

        this.storeRefreshToken(user.refreshToken);
        this.storeAccessToken(user.accessToken);

        try {
            // After tokens are stored get user membership
            const allUsersMemberships = await PersonsService.getPersonMembershipsAsync(user.personId);
            const membership = allUsersMemberships.find((item) => item.organization?.memberTransferAllowed);

            if (membership && membership.organization) {
                user.homeOrganizationId = membership.organizationId;
                user.churchId = membership.organization.churchId;
            }
        } catch (error) {
            throw new Error('Error_PersonMembersipQueryFailed');
        }

        const userOrganizations = await UsersService.getUserOrganizationsAsync(user.userId);

        user.organizations = userOrganizations
            .map((item) => item.organization)
            .filter((item): item is Organization => item !== undefined);

        user.organizations.sort(this.organizationSorter);

        return user;
    }

    private crateUserFromToken(tokenResult: KeycloakTokenResult): AuthenticatedUser {
        const payload = jwt.decode(tokenResult.access_token) as JwtWithCustomAttributes;

        return {
            userId: payload.user_id,
            username: payload.preferred_username,
            accessToken: tokenResult.access_token,
            refreshToken: tokenResult.refresh_token,
            role: payload.realm_access.roles[0],
            personId: payload.person_id,
            email: payload.email,
            firstname: payload.given_name,
            lastname: payload.family_name,
            fullname: `${payload.family_name} ${payload.given_name}`,
            authType: payload.typ,
            churchId: 0,
            homeOrganizationId: 0,
            organizations: []
        };
    }

    private getAuthParams(loginCtx: LoginContext): URLSearchParams {
        const params = new URLSearchParams();
        params.append('client_id', Config.IDP_CLIENT_ID);
        params.append('client_secret', Config.IDP_CLIENT_SECRET);
        params.append('grant_type', 'password');
        params.append('username', loginCtx.username);
        params.append('password', loginCtx.password);
        if (loginCtx.otp) {
            params.append('otp', loginCtx.otp);
        }
        params.append('scope', 'sahkopaimen-api-scope');

        return params;
    }

    private getRefreshParams(refreshToken: string): URLSearchParams {
        const params = new URLSearchParams();
        params.append('client_id', Config.IDP_CLIENT_ID);
        params.append('client_secret', Config.IDP_CLIENT_SECRET);
        params.append('grant_type', 'refresh_token');
        params.append('refresh_token', refreshToken);

        return params;
    }

    private getLogoutParams(refreshToken: string): URLSearchParams {
        const params = new URLSearchParams();
        params.append('client_id', Config.IDP_CLIENT_ID);
        params.append('client_secret', Config.IDP_CLIENT_SECRET);
        params.append('refresh_token', refreshToken);

        return params;
    }

    private getAxiosConfig(): AxiosRequestConfig {
        return {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        };
    }

    private storeRefreshToken(token: string) {
        localStorage.setItem(this.STORAGE_REFRESH_KEY, token);
    }

    private retrieveRefreshToken(): string | null {
        return localStorage.getItem(this.STORAGE_REFRESH_KEY);
    }

    private deleteRefreshToken = () => {
        localStorage.removeItem(this.STORAGE_REFRESH_KEY);
    };

    private storeAccessToken(token: string) {
        localStorage.setItem(this.STORAGE_ACCESS_TOKEN_KEY, token);
    }

    private retrieveAccessToken(): string | null {
        return localStorage.getItem(this.STORAGE_ACCESS_TOKEN_KEY);
    }

    private deleteAccessToken = () => {
        localStorage.removeItem(this.STORAGE_ACCESS_TOKEN_KEY);
    };
}

export default new AuthService();
