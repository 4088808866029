import React, { FC, useContext } from 'react';
import { LanguageContext } from 'contexts/languageContext';
import {
    TableContainer,
    Paper,
    Table,
    TableBody,
    TableRow,
    TableCell,
    TableHead,
    Grid,
    Typography,
    IconButton
} from '@mui/material';
import WaitScreen from 'components/common/WaitScreen';
import { PersonWithMembership } from 'types/sp-api';
import { TableHeadCell } from 'components/common/TableHeadCell';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { useNavigate } from 'react-router-dom';
import Utils from 'utils/personHelper';

interface GlobalGroupRegisterListProps {
    personList?: PersonWithMembership[];
}

const GlobalGroupRegisterList: FC<GlobalGroupRegisterListProps> = ({ personList }: GlobalGroupRegisterListProps) => {
    const { T } = useContext(LanguageContext);
    const navigate = useNavigate();

    return (
        <Grid item xs={12}>
            <TableContainer component={Paper}>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableHeadCell sx={{ width: '25%', pl: 1 }}>{T('Common_Name')}</TableHeadCell>
                            <TableHeadCell sx={{ width: '20%' }}>{T('Person_FieldAddressCity')}</TableHeadCell>
                            <TableHeadCell sx={{ width: '15%' }}>{T('Person_FieldPhoneHome')}</TableHeadCell>
                            <TableHeadCell sx={{ width: '30%' }}>{T('Common_Church')}</TableHeadCell>
                            <TableHeadCell sx={{ width: 80, textAlign: 'center' }} />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {!personList && (
                            <TableRow>
                                <TableCell>
                                    <WaitScreen />
                                </TableCell>
                            </TableRow>
                        )}
                        {personList &&
                            personList.length > 0 &&
                            personList.map((row) => (
                                <TableRow key={row.id} hover>
                                    <TableCell>{Utils.FullName(row)}</TableCell>
                                    <TableCell>{row.address?.city}</TableCell>
                                    <TableCell>{row.phoneHome}</TableCell>
                                    <TableCell>{row.organizationContact?.name}</TableCell>
                                    <TableCell>
                                        <Grid container>
                                            <Grid item xs={3}>
                                                <IconButton
                                                    size="small"
                                                    title={T('GlobalGroupRegistry_OpenPerson')}
                                                    onClick={() => navigate(`/persons/${row.id}`)}
                                                >
                                                    <OpenInNewIcon fontSize="small" />
                                                </IconButton>
                                            </Grid>
                                        </Grid>
                                    </TableCell>
                                </TableRow>
                            ))}
                        {personList && personList.length === 0 && (
                            <TableRow>
                                <TableCell colSpan={2}>
                                    <Typography variant="body1" color="textSecondary">
                                        {T('GlobalGroupRegistry_NoMembers')}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </Grid>
    );
};

export default GlobalGroupRegisterList;
