import React, { FC, useContext, useState } from 'react';
import { Grid } from '@mui/material';
import DownloadFile from 'components/common/DownloadFile';
import LoadButton from 'components/common/LoadButton';
import { LanguageContext } from 'contexts/languageContext';
import ReportService from 'services/reportService';
import { AxiosResponse } from 'axios';

interface ReportOrganizationBoardMemberReportProps {
    organizationId: number;
    organizationName: string;
}

const ReportOrganizationBoardMemberReport: FC<ReportOrganizationBoardMemberReportProps> = ({
    organizationId,
    organizationName
}: ReportOrganizationBoardMemberReportProps) => {
    const [downloadFile, setDownloadFile] = useState(false);
    const { T } = useContext(LanguageContext);
    const filename = T('Report_OrganizationBoardMemberReportPdfFile').replace('{0}', organizationName);

    const fetchFile = async (): Promise<AxiosResponse> => {
        return ReportService.organizationBoardMemberAsync(organizationId, filename);
    };

    return (
        <Grid container item xs={12} alignContent="flex-start" spacing={3}>
            <Grid container item xs={12} spacing={3} justifyContent="space-between">
                <Grid item>
                    <LoadButton
                        onClick={() => {
                            setDownloadFile(true);
                        }}
                        loading={downloadFile}
                    >
                        {T('Report_OrganizationBoardMemberReportBtn')}
                    </LoadButton>
                    {downloadFile && (
                        <DownloadFile
                            fetchFile={() => fetchFile()}
                            filename={filename}
                            onSuccess={() => setDownloadFile(false)}
                        />
                    )}
                </Grid>
            </Grid>
        </Grid>
    );
};

export default ReportOrganizationBoardMemberReport;
