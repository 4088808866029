import React, { FC, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { FormHelperText, Grid } from '@mui/material';
import DownloadFile from 'components/common/DownloadFile';
import { LanguageContext } from 'contexts/languageContext';
import ReportService from 'services/reportService';
import { Form, Formik, FormikProps } from 'formik';
import PersonUtils from 'utils/personHelper';
import { AuthContext } from 'contexts/authContext';
import { AppLanguage, BaptismCertificate, InfoType, MembershipListItem } from 'types/common';
import { useErrorHandler } from 'components/hooks/useErrorHandler';
import axios, { CancelTokenSource } from 'axios';
import PersonsService from 'services/personsService';
import { Person, PersonReligiousInfo } from 'types/sp-api';
import ButtonLanguage from 'components/common/ButtonLanguage';

interface ReportBaptismCertificateProps {
    person: Person;
    membership?: MembershipListItem;
}

const ReportBaptismCertificate: FC<ReportBaptismCertificateProps> = ({
    person,
    membership
}: ReportBaptismCertificateProps) => {
    const { T, TExt } = useContext(LanguageContext);
    const { user } = useContext(AuthContext);
    const [download, setDownload] = useState({ language: '', filename: '' });
    const { handleError } = useErrorHandler();
    const cancelRef = useRef<CancelTokenSource | null>(null);
    const [religiousInfos, setReligiousInfos] = useState<PersonReligiousInfo[]>();

    // full member and member no vote only
    const disableReport = (religiousInfos?.length ?? 0) == 0;

    const getReligiousInfo = useCallback(
        async (id?: number): Promise<void> => {
            if (!id) return;

            try {
                !cancelRef.current && (cancelRef.current = axios.CancelToken.source());
                const personReligiousInfos = await PersonsService.getPersonReligiousInfosAsync(
                    id,
                    InfoType.Baptism,
                    cancelRef.current.token
                );

                if (cancelRef.current) {
                    setReligiousInfos(personReligiousInfos);
                }
            } catch (error) {
                handleError(error, T('Error_PersonReligiousInfoQueryFailed'));
            }
        },
        [handleError, T]
    );

    useEffect(() => {
        getReligiousInfo(person.id);

        return () => {
            cancelRef.current && cancelRef.current.cancel();
            cancelRef.current = null;
        };
    }, [getReligiousInfo, person.id]);

    if (!membership) {
        return null;
    }

    return (
        <Formik
            initialValues={{
                person: person,
                filename: '',
                senderOrganizationId: user?.homeOrganizationId ?? 0,
                membershipId: membership.id ?? 0,
                endMembership: false
            }}
            onSubmit={() => {
                /* do nothing */
            }}
            enableReinitialize
        >
            {({ values }: FormikProps<BaptismCertificate>) => {
                const fetchFile = async () => {
                    return ReportService.generateBaptismCertificateAsync(values, download.language);
                };

                const handleMenuItemClick = async (lang: AppLanguage) => {
                    let filename = await TExt('Report_BaptismFile', lang);
                    filename = filename.replace('{0}', PersonUtils.FullNameForFilename(person));

                    setDownload({ language: lang, filename: filename });
                };

                return (
                    <Form>
                        <Grid container item xs={12} alignContent="flex-start" spacing={3}>
                            <Grid item xs={12}>
                                <ButtonLanguage
                                    title={T('Report_BaptismCertificateBtn')}
                                    onClick={handleMenuItemClick}
                                    disabled={disableReport}
                                    loading={download.language != ''}
                                />
                                {disableReport && (
                                    <FormHelperText>{T('Report_BabtismCertificateDisabled')}</FormHelperText>
                                )}
                                {download.language != '' && (
                                    <DownloadFile
                                        fetchFile={fetchFile}
                                        filename={download.filename}
                                        onSuccess={() => {
                                            setDownload({ language: '', filename: '' });
                                        }}
                                    />
                                )}
                            </Grid>
                        </Grid>
                    </Form>
                );
            }}
        </Formik>
    );
};

export default ReportBaptismCertificate;
