import React, { FC } from 'react';
import { Typography } from '@mui/material';
import { PersonTabProps } from './PersonTabBasic';

const PersonTabDocuments: FC<PersonTabProps> = ({ person }: PersonTabProps) => {
    return (
        <Typography>
            {person.lastName} {person.firstName}
            <br />
            TODO PersonTabDocuments
        </Typography>
    );
};

export default PersonTabDocuments;
