import React, { CSSProperties, FC, ReactNode, MouseEventHandler } from 'react';
import { Button, CircularProgress, SxProps, Theme } from '@mui/material';

interface LoadButtonProps {
    children: ReactNode;
    loading?: boolean;
    disabled?: boolean;
    color?: 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning';
    type?: 'button' | 'submit' | 'reset';
    onClick?: MouseEventHandler<HTMLButtonElement>;
    variant?: 'text' | 'outlined' | 'contained';
    style?: CSSProperties;
    className?: string;
    small?: boolean;
    sx?: SxProps<Theme>;
}

const LoadButton: FC<LoadButtonProps> = ({
    children,
    loading,
    disabled,
    type,
    onClick,
    color = 'primary',
    variant = 'contained',
    style,
    className,
    small,
    sx
}: LoadButtonProps) => {
    return (
        <Button
            onClick={onClick}
            type={type}
            disabled={loading || disabled}
            variant={variant}
            color={color}
            style={style}
            className={className}
            size={small ? 'small' : 'medium'}
            sx={sx}
        >
            {loading && <CircularProgress size={24} style={{ marginRight: 5 }} />}
            {children}
        </Button>
    );
};

export default LoadButton;
