import React, { FC, ReactNode, useContext, MouseEventHandler, useState, useRef } from 'react';
import {
    Button,
    ButtonGroup,
    ClickAwayListener,
    Grid,
    Grow,
    MenuItem,
    MenuList,
    Paper,
    Popper,
    Typography
} from '@mui/material';
import { LanguageContext } from 'contexts/languageContext';
import { SvgIconComponent } from '@mui/icons-material';
import ButtonAdd from './ButtonAdd';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

interface ViewTitleProps {
    title: string | ReactNode;
    onAddClick?: MouseEventHandler<HTMLButtonElement>;
    icon?: unknown;
    addButtonTitle?: string;
    iconTooltip?: string;
    iconColor?: 'info' | 'primary';
    onMenuBtnClick?: MouseEventHandler<HTMLLIElement>;
    menuBtnTitle?: string;
}

export const ViewTitle: FC<ViewTitleProps> = ({
    title,
    onAddClick,
    icon,
    addButtonTitle,
    iconTooltip,
    iconColor,
    onMenuBtnClick,
    menuBtnTitle
}: ViewTitleProps) => {
    const { T } = useContext(LanguageContext);
    const Icon = icon as SvgIconComponent;
    const [menuOpen, setMenuOpen] = useState(false);
    const anchorRef = useRef(null);

    return (
        <Grid container justifyContent="space-between" style={{ marginBottom: 16 }}>
            <Grid container justifyContent="flex-start" item xs={9} alignItems="center">
                <Grid item>
                    <Typography variant="h5" component="h2" style={{ marginTop: 8 }}>
                        {!!icon && <Icon fontSize="large" color={iconColor ?? 'primary'} titleAccess={iconTooltip} />}
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography component="h1" style={{ fontSize: 24, marginLeft: 16 }}>
                        {title}
                    </Typography>
                </Grid>
            </Grid>
            <Grid container justifyContent="flex-end" item xs={3}>
                {onAddClick && !onMenuBtnClick && (
                    <Grid item>
                        <ButtonAdd title={addButtonTitle ?? T('Common_Add')} color="secondary" onClick={onAddClick} />
                    </Grid>
                )}
                {onMenuBtnClick && (
                    <Grid item>
                        <ButtonGroup variant="contained" color="secondary" size="small" ref={anchorRef}>
                            <ButtonAdd
                                title={addButtonTitle ?? T('Common_Add')}
                                color="secondary"
                                onClick={onAddClick}
                            />
                            <Button color="secondary" size="small" onClick={() => setMenuOpen((prevOpen) => !prevOpen)}>
                                <ArrowDropDownIcon />
                            </Button>
                        </ButtonGroup>
                        <Popper
                            open={menuOpen}
                            anchorEl={anchorRef.current}
                            role={undefined}
                            transition
                            disablePortal
                            placement="bottom-end"
                            style={{ zIndex: 999 }}
                            nonce={undefined}
                        >
                            {({ TransitionProps, placement }) => (
                                <Grow
                                    {...TransitionProps}
                                    style={{
                                        transformOrigin: placement === 'bottom' ? 'top-end' : 'bottom-end'
                                    }}
                                >
                                    <Paper>
                                        <ClickAwayListener onClickAway={() => setMenuOpen(false)}>
                                            <MenuList id="split-button-menu">
                                                <MenuItem onClick={onMenuBtnClick}>{menuBtnTitle}</MenuItem>
                                            </MenuList>
                                        </ClickAwayListener>
                                    </Paper>
                                </Grow>
                            )}
                        </Popper>
                    </Grid>
                )}
            </Grid>
        </Grid>
    );
};

export default ViewTitle;
