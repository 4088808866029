import React, { FC, ReactNode, useCallback, useContext, useEffect, useRef, useState } from 'react';
import {
    Grid,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from '@mui/material';
import { LanguageContext, LanguageTextFunc } from 'contexts/languageContext';
import { useErrorHandler } from 'components/hooks/useErrorHandler';
import axios, { CancelTokenSource } from 'axios';
import DialogBase from 'components/common/DialogBase';
import ButtonAdd from '../common/ButtonAdd';
import { Form, Formik, FormikProps } from 'formik';
import FormFieldDropDown from 'components/common/FormFieldDropDown';
import FormFieldText from 'components/common/FormFieldText';
import * as Yup from 'yup';
import DialogConfirmation from 'components/common/DialogConfirmation';
import { useSuccessHandler } from 'components/hooks/useSuccessHandler';
import MemberTagService from 'services/memberTagService';
import { MemberTag, Tag } from 'types/sp-api';
import WaitScreen from 'components/common/WaitScreen';
import { DeleteDialogState, DialogOpenState } from 'types/common';
import RowMenu from 'components/common/RowMenu';
import { TableHeadCell } from 'components/common/TableHeadCell';
import constants from 'config/constants';

const getValidationSchema = (T: LanguageTextFunc) => {
    return Yup.object().shape({
        titleId: Yup.string().test('not-0-value', T('Common_Required'), (value) => value !== '0')
    });
};

const getNewTitleValidationSchema = (T: LanguageTextFunc) => {
    return Yup.object().shape({
        name: Yup.string().required(T('Common_Required'))
    });
};

interface MemberTagListProps {
    personId: number;
    organizationId: number;
    membershipId: number;
    archive?: boolean;
    allowedEdit: boolean;
}

const MemberTagList: FC<MemberTagListProps> = ({
    personId,
    organizationId,
    membershipId,
    archive,
    allowedEdit
}: MemberTagListProps) => {
    const { T } = useContext(LanguageContext);
    const [tagList, setTagList] = useState<MemberTag[]>();
    const [openAddDialog, setOpenAddDialog] = useState<DialogOpenState>(false);
    const [deleteDialogState, setDeleteDialogState] = useState<DeleteDialogState>();
    const { handleError } = useErrorHandler();
    const [tagTitles, setTagTitles] = useState<Tag[]>([]);
    const { handleSuccess } = useSuccessHandler();
    const [openAddNewTitleDialog, setOpenAddNewTitleDialog] = useState(false);
    const cancelRef = useRef<CancelTokenSource | null>(null);

    const getNewTag = (): MemberTag => ({
        id: undefined,
        tagId: 0,
        value: ''
    });

    const [selectedTag, setSelectedTag] = useState<MemberTag>(getNewTag());

    const getTagTitles = useCallback(
        async (orgId: number, forceUpdate?: boolean): Promise<void> => {
            try {
                !cancelRef.current && (cancelRef.current = axios.CancelToken.source());
                const orgTagTitles = await MemberTagService.getMemberTagTitlesAsync(
                    orgId,
                    cancelRef.current.token,
                    forceUpdate
                );
                if (cancelRef.current) {
                    const tagSorter = (a: Tag, b: Tag): number => {
                        if (!a.name || !b.name) return 0;
                        if (a.name > b.name) return 1;
                        if (a.name < b.name) return -1;
                        return 0;
                    };

                    orgTagTitles.sort(tagSorter);
                    setTagTitles(orgTagTitles);
                }
            } catch (error) {
                handleError(error, T('Error_OrganizationTagTitleQueryFailed'));
            }
        },
        [handleError, T]
    );

    const getMemberTags = useCallback(
        async (id: number, memberId: number): Promise<void> => {
            try {
                !cancelRef.current && (cancelRef.current = axios.CancelToken.source());
                const memberTags = await MemberTagService.getMemberTagsAsync(id, memberId, cancelRef.current.token);
                if (cancelRef.current) {
                    setTagList(memberTags);
                }
            } catch (error) {
                handleError(error, T('Error_MemberTagQueryFailed'));
            }
        },
        [handleError, T]
    );

    useEffect(() => {
        setTagList([]); // empty so it will not be shown while titles are being loaded
        getTagTitles(organizationId);
        getMemberTags(personId, membershipId);

        return () => {
            cancelRef.current && cancelRef.current.cancel();
            cancelRef.current = null;
        };
    }, [getMemberTags, getTagTitles, personId, organizationId, membershipId]);

    const refreshMemberTags = () => {
        // refresh list
        getMemberTags(personId, membershipId);
    };

    const deleteMemberTag = async () => {
        try {
            await MemberTagService.deleteMemberTagAsync(personId, membershipId, deleteDialogState?.id);
            handleSuccess(T('MemberTag_DeleteSuccess'));
            refreshMemberTags();
        } catch (error) {
            handleError(error, T('MemberTag_DeleteFailed'));
        }
    };

    const saveMemberTag = async (tag: MemberTag) => {
        try {
            await MemberTagService.saveMemberTagAsync(personId, membershipId, tag);
            handleSuccess(T('MemberTag_SaveSuccess'));
            refreshMemberTags();
        } catch (error) {
            handleError(error, T('MemberTag_SaveFailed'));
        }
    };

    const addMemberTag = () => {
        setSelectedTag(getNewTag());
        setOpenAddDialog(true);
    };

    const editMemberTag = (tag: MemberTag) => {
        setSelectedTag(tag);
        setOpenAddDialog('edit');
    };

    const getTitle = (titleId?: number) => {
        return tagTitles.find((item) => item.id === titleId)?.name ?? '';
    };

    const saveNewTagTitle = async (orgId: number, title: Tag) => {
        try {
            await MemberTagService.addMemberTagTitleAsync(orgId, title);
            handleSuccess(T('AddNewTag_SaveSuccess'));
            // refresh list
            getTagTitles(orgId, true);
        } catch (error) {
            handleError(error, T('AddNewTag_SaveFailed'));
        }
    };

    return (
        <>
            <Grid item xs={12}>
                <TableContainer component={Paper}>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableHeadCell>{T('MemberTag_ListTitle')}</TableHeadCell>
                                <TableHeadCell sx={{ width: '5%' }} />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {tagList &&
                                tagTitles &&
                                tagList.length > 0 &&
                                tagList.map(
                                    (item): ReactNode => (
                                        <TableRow key={item.id}>
                                            <TableCell>
                                                <Typography>{getTitle(item.tagId)}</Typography>
                                                <Typography color="textSecondary" variant="body2">
                                                    {item.value ? `${T('MemberTag_FieldValue')}: ${item.value}` : null}
                                                </Typography>
                                            </TableCell>
                                            <TableCell sx={{ width: '5%' }}>
                                                {!archive && allowedEdit && (
                                                    <RowMenu
                                                        onEdit={() => editMemberTag(item)}
                                                        onDelete={() =>
                                                            setDeleteDialogState({
                                                                show: true,
                                                                message: T('MemberTag_DeleteMessage').replace(
                                                                    '{0}',
                                                                    getTitle(item.tagId)
                                                                ),
                                                                id: item.id ?? 0
                                                            })
                                                        }
                                                    />
                                                )}
                                            </TableCell>
                                        </TableRow>
                                    )
                                )}
                            {tagList && tagTitles && tagList.length === 0 && (
                                <TableRow>
                                    <TableCell>
                                        <Typography variant="body1" color="textSecondary">
                                            {T('MemberTag_NoTags')}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            )}
                            {(!tagList || !tagTitles) && (
                                <TableRow>
                                    <TableCell>
                                        <WaitScreen />
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
            <Grid item xs={12} container justifyContent="flex-end">
                {allowedEdit && (
                    <ButtonAdd
                        variant="text"
                        color="secondary"
                        onClick={() => addMemberTag()}
                        small
                        title={T('MemberTag_AddNew')}
                        disabled={archive}
                    />
                )}
            </Grid>
            <Formik
                initialValues={selectedTag}
                enableReinitialize
                validationSchema={getValidationSchema(T)}
                onSubmit={saveMemberTag}
            >
                {({ dirty, isValid, isSubmitting, submitForm, resetForm }: FormikProps<MemberTag>) => (
                    <DialogBase
                        show={!!openAddDialog}
                        title={
                            openAddDialog === 'edit' ? T('MemberTag_EditDialogTitle') : T('MemberTag_AddDialogTitle')
                        }
                        onOk={async () => {
                            await submitForm();
                            resetForm();
                            setOpenAddDialog(false);
                        }}
                        onClose={() => setOpenAddDialog(false)}
                        okDisabled={isSubmitting || !dirty || !isValid}
                        okTitle={T('Common_Save')}
                    >
                        <Form>
                            <Grid container spacing={3} sx={{ pt: 1 }}>
                                <Grid item xs={10}>
                                    <FormFieldDropDown
                                        name="tagId"
                                        label={T('MemberTag_FieldTitle')}
                                        values={tagTitles.map((item) => ({ key: item.id, text: item.name }))}
                                        placeholder={T('MemberTag_SelectTitle')}
                                    />
                                </Grid>
                                <Grid item xs={2}>
                                    <ButtonAdd
                                        onClick={() => setOpenAddNewTitleDialog(true)}
                                        tooltip={T('AddNewTagTitle_OpenButtonTooltip')}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormFieldText name="value" label={T('MemberTag_FieldValue')} />
                                </Grid>
                            </Grid>
                        </Form>
                    </DialogBase>
                )}
            </Formik>
            <DialogConfirmation
                title={T('MemberTag_ConfirmDelete')}
                message={deleteDialogState?.message}
                show={deleteDialogState?.show}
                onClose={() => setDeleteDialogState(undefined)}
                onOk={deleteMemberTag}
                warning
            />
            <Formik
                initialValues={{ organizationId: organizationId, type: constants.TagTypes.Member, lock: false }}
                validationSchema={getNewTitleValidationSchema(T)}
                onSubmit={(values) => saveNewTagTitle(organizationId, values)}
                enableReinitialize
            >
                {({ dirty, isValid, isSubmitting, submitForm, resetForm }: FormikProps<Tag>) => (
                    <DialogBase
                        show={openAddNewTitleDialog}
                        title={T('AddNewTagTitle_AddNewTitleDialogTitle')}
                        onOk={async () => {
                            await submitForm();
                            resetForm();
                            setOpenAddNewTitleDialog(false);
                        }}
                        onClose={() => setOpenAddNewTitleDialog(false)}
                        okDisabled={isSubmitting || !dirty || !isValid}
                        okTitle={T('Common_Save')}
                    >
                        <Form>
                            <Grid container spacing={3} sx={{ pt: 1 }}>
                                <Grid item xs={10}>
                                    <FormFieldText name="name" label={T('AddNewTagTitle_FieldName')} autoFocus />
                                    <Typography variant="caption" color="GrayText">
                                        {T('AddNewTagTitle_HelperText')}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Form>
                    </DialogBase>
                )}
            </Formik>
        </>
    );
};

export default MemberTagList;
