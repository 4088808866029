import React, { FC, useContext, useEffect, useState } from 'react';
import { Box, Button, Grid } from '@mui/material';
import { LanguageContext, LanguageTextFunc } from 'contexts/languageContext';
import { useErrorHandler } from 'components/hooks/useErrorHandler';
import MembershipsService from 'services/membershipsService';
import { Membership, MembershipTransfer, MembershipWithTransferInfo, Person } from 'types/sp-api';
import FormFieldDate from 'components/common/FormFieldDate';
import FormFieldDropDownCodeSet from 'components/common/FormFieldDropDownCodeSet';
import FormFieldText from 'components/common/FormFieldText';
import { Form, Formik, FormikProps, useFormikContext } from 'formik';
import * as Yup from 'yup';
import LoadButton from 'components/common/LoadButton';
import LastModified from 'components/common/LastModified';
import { useSuccessHandler } from 'components/hooks/useSuccessHandler';
import FormFieldReadOnly from 'components/common/FormFieldReadonly';
import DialogBase from 'components/common/DialogBase';
import FormFieldDropDownOrganization from 'components/common/FormFieldDropDownOrganization';
import TypeUtils from 'utils/typeHelper';
import { MemberPrerequisite, MembershipType } from 'types/common';
import constants from 'config/constants';
import config from 'config/config';
import FormFieldNumber from 'components/common/FormFieldNumber';
import FormFieldTextArea from 'components/common/FormFieldTextArea';
import Typography from '@mui/material/Typography';
import ReportTransferCertificate from './ReportTransferCertificate';

const getTransferValidationSchema = (T: LanguageTextFunc, values: MembershipTransfer) => {
    return Yup.object().shape({
        targetOrgId: Yup.string().test('not-0-value', T('Common_Required'), (value) => value !== '0'),
        homeOrgId: Yup.string().test(
            'not-0-value',
            T('Common_Required'),
            (value) => values.targetOrgId != constants.RegionalCommunity.RegisterChurchId || value !== '0'
        )
    });
};

interface MembershipFormProps {
    person: Person;
    membership: MembershipWithTransferInfo;
    transferMember: () => void;
    cancelAdd: () => void;
    sending: boolean;
    archive?: boolean;
    allowedEdit: boolean;
}

const MembershipForm: FC<MembershipFormProps> = ({
    person,
    membership,
    transferMember,
    cancelAdd,
    sending,
    archive,
    allowedEdit
}: MembershipFormProps) => {
    const { T } = useContext(LanguageContext);
    const { handleError } = useErrorHandler();
    const { handleSuccess } = useSuccessHandler();
    const { values, dirty, isValid, resetForm, submitForm, setFieldValue } = useFormikContext<Membership>();
    const [transfering, setTransfering] = useState(false);
    const [openTransferDialog, setOpenTransferDialog] = useState(false);
    const [openTransferCertificateDialog, setOpenTransferCertificateDialog] = useState(false);

    const transferMembership = async (newValues: MembershipTransfer): Promise<void> => {
        setTransfering(true);
        try {
            await MembershipsService.transferMembershipAsync(person.id, newValues);
            transferMember();
            setTransfering(false);
            handleSuccess(T('Membership_TransferSuccess'));
        } catch (error) {
            setTransfering(false);
            handleError(error, T('Membership_TransferFailed'));
        }
    };

    useEffect(() => {
        if (values.type === MembershipType.Interested) {
            setFieldValue('beginDate', constants.Settings.InterestedMembershipStart);
            setFieldValue('prerequisite', MemberPrerequisite.Interested);
        }
    }, [values.type, setFieldValue]);

    return (
        <>
            <Grid item xs={12}>
                <FormFieldReadOnly label={T('Membership_FieldOrganization')} value={membership?.organization?.name} />
            </Grid>
            {config.CUSTOMER == constants.Customers.Shk &&
                values.organizationId == constants.RegionalCommunity.RegisterChurchId && (
                    <Grid item xs={12}>
                        <FormFieldDropDownOrganization
                            name="homeOrganizationId"
                            label={T('Membership_FieldRegisterHomeChurch')}
                            placeholder={T('Membership_SelectRegisterHomeChurch')}
                            showContacts
                        />
                        <Typography variant="body2" color="secondary">
                            {T('Membership_HelpTextRegisterHomeChurch')}
                        </Typography>
                    </Grid>
                )}
            {TypeUtils.IsLocalYhdyskunta(membership.organization?.type) && (
                <Grid item xs={12}>
                    <FormFieldDropDownCodeSet
                        name="type"
                        label={T('Membership_FieldMembershipType')}
                        codeSet="membershipType"
                        placeholder={T('Membership_SelectType')}
                        keyOrder
                        hiddenValues={[6]}
                    />
                </Grid>
            )}
            {values.type !== MembershipType.Interested && (
                <>
                    <Grid item xs={12}>
                        <FormFieldDropDownCodeSet
                            name="prerequisite"
                            label={T('Membership_FieldPrerequisite')}
                            codeSet="membershipPrerequisite"
                            placeholder={T('Membership_SelectPrerequisite')}
                        />
                    </Grid>
                    {values.prerequisite == MemberPrerequisite.Recognition && (
                        <Grid item xs={12}>
                            <FormFieldText name="prerequisiteText" label={T('Membership_FieldPrerequisiteText')} />
                        </Grid>
                    )}
                    <Grid item xs={6}>
                        <FormFieldDate label={T('Membership_FieldBeginDate')} name="beginDate" />
                    </Grid>
                    <Grid item xs={6}>
                        <FormFieldDate label={T('Membership_FieldEndDate')} name="endDate" />
                    </Grid>
                    <Grid item xs={6}>
                        <FormFieldDropDownCodeSet
                            name="terminationReason"
                            label={T('Membership_FieldTerminationReason')}
                            codeSet="terminationReason"
                            placeholder={T('Membership_SelectTerminationReason')}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <FormFieldText
                            name="terminationReasonText"
                            label={T('Membership_FieldTerminationReasonText')}
                        />
                    </Grid>
                    {config.CUSTOMER === constants.Customers.Advk && (
                        <Grid item xs={12}>
                            <FormFieldNumber name="previousChurch" label={T('Membership_FieldPreviousChurch')} />
                        </Grid>
                    )}
                    <Grid item xs={12}>
                        <FormFieldTextArea name="info" rows={4} label={T('Membership_FieldInfo')} />
                    </Grid>
                </>
            )}
            <Grid item xs={12} container justifyContent="space-between">
                <Grid item>
                    <LoadButton
                        style={{ marginRight: 8 }}
                        loading={sending}
                        disabled={!dirty || !isValid || archive || !allowedEdit}
                        type="submit"
                    >
                        {T('Common_Save')}
                    </LoadButton>
                    <Button
                        disabled={!dirty && !!values.id}
                        style={{ marginLeft: 16 }}
                        onClick={() => {
                            if (values.id) resetForm();
                            else cancelAdd();
                        }}
                        color="secondary"
                    >
                        {T('Common_Cancel')}
                    </Button>
                </Grid>
                <Grid item>
                    {values.endDate !== null && allowedEdit && (
                        <Button
                            disabled={dirty && !!values.id}
                            style={{ marginLeft: 16 }}
                            onClick={() => {
                                setFieldValue('terminationReason', null);
                                setFieldValue('terminationReasonText', null);
                                setFieldValue('endDate', null);
                                submitForm();
                            }}
                            color="secondary"
                            title={T('Membership_RemoveEndingDateAndReasonHelpText')}
                        >
                            {T('Membership_RemoveEndingDateAndReasonBtn')}
                        </Button>
                    )}
                </Grid>
                <Grid item xs={12}>
                    <Box margin={2} />
                    <LastModified by={values.lastModifiedBy} date={values.lastModifiedDate} />
                </Grid>
            </Grid>
            {!values.endDate && allowedEdit && (
                <Grid item xs={12} container justifyContent="space-between">
                    <Grid item xs={5}>
                        <LoadButton
                            loading={transfering}
                            variant="contained"
                            color="secondary"
                            onClick={() => setOpenTransferDialog(true)}
                            disabled={archive}
                        >
                            {T('Membership_Transfer')}
                        </LoadButton>
                        <Box m={1} />
                        <Typography variant="body1" color="GrayText">
                            {T('Membership_TransferBtnHelpText')}
                        </Typography>
                    </Grid>
                    <Grid item xs={5} marginLeft={2}>
                        <LoadButton
                            loading={transfering}
                            variant="contained"
                            color="secondary"
                            onClick={() => setOpenTransferCertificateDialog(true)}
                            disabled={archive}
                        >
                            {T('Report_TransferCertificateBtn')}
                        </LoadButton>
                        <Box m={1} />
                        <Typography variant="body1" color="GrayText">
                            {T('Membership_TransferCertificateBtnHelpText')}
                        </Typography>
                    </Grid>
                </Grid>
            )}
            <Formik
                initialValues={{ membershipId: membership.id, targetOrgId: 0, homeOrgId: 0 }}
                enableReinitialize
                validationSchema={() => Yup.lazy((values) => getTransferValidationSchema(T, values))}
                onSubmit={transferMembership}
            >
                {({ values, dirty, isValid, isSubmitting, submitForm, resetForm }: FormikProps<MembershipTransfer>) => (
                    <DialogBase
                        show={openTransferDialog}
                        title={T('Membership_TransgerDialogTitle')}
                        onOk={async () => {
                            await submitForm();
                            resetForm();
                        }}
                        onClose={() => setOpenTransferDialog(false)}
                        okDisabled={isSubmitting || !dirty || !isValid}
                        okTitle={T('Membership_Transfer')}
                    >
                        <Form>
                            <Grid container spacing={3} sx={{ pt: 1 }}>
                                <Grid item xs={12}>
                                    <Typography variant="body1" color="GrayText">
                                        {T('Membership_TransferDialogHelpText')}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="body1" color="GrayText">
                                        {T('Membership_TransferDialogHelpText2')}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormFieldDropDownOrganization
                                        name="targetOrgId"
                                        label={T('Membership_FieldTargetChurch')}
                                        placeholder={T('Membership_SelectTargetChurch')}
                                        disabledValues={membership.organizationId}
                                        showChurches
                                    />
                                </Grid>
                                {values.targetOrgId == constants.RegionalCommunity.RegisterChurchId && (
                                    <Grid item xs={12}>
                                        <FormFieldDropDownOrganization
                                            name="homeOrgId"
                                            label={T('Membership_FieldRegisterHomeChurch')}
                                            placeholder={T('Membership_SelectRegisterHomeChurch')}
                                            disabledValues={[
                                                membership.organizationId,
                                                constants.RegionalCommunity.RegisterChurchId
                                            ]}
                                            showContacts
                                        />
                                        <Typography variant="body2" color="secondary">
                                            {T('Membership_HelpTextRegisterHomeChurch')}
                                        </Typography>
                                    </Grid>
                                )}
                            </Grid>
                        </Form>
                    </DialogBase>
                )}
            </Formik>
            <DialogBase
                show={openTransferCertificateDialog}
                title={T('Report_TransferCertificateTitle')}
                onOk={() => setOpenTransferCertificateDialog(false)}
                onClose={() => setOpenTransferCertificateDialog(false)}
                okTitle={T('Common_Close')}
                hideOk
                hideCancel
            >
                <ReportTransferCertificate
                    person={person}
                    membership={membership}
                    onCancel={() => setOpenTransferCertificateDialog(false)}
                />
            </DialogBase>
        </>
    );
};

export default MembershipForm;
