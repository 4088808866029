import { PostOffice } from 'types/sp-api';

const getCityByZipCode = (zipCode?: string, postalCodes?: PostOffice[], notFoundText = ''): string => {
    if (!zipCode || !postalCodes) return '';

    let city = undefined;

    if (zipCode.startsWith('FI-')) {
        const code = zipCode.split('-')[1];
        city = postalCodes.find((item) => item.zipCode === code)?.city;
    } else if (/^[0-9]{5}$/.test(zipCode)) {
        city = postalCodes.find((item) => item.zipCode === zipCode)?.city;
    }

    return city ?? notFoundText;
};

const isDomesticZipCode = (zipCode?: string): boolean => {
    if (!zipCode) return false;

    return zipCode.startsWith('FI-') || /^[0-9]{5}$/.test(zipCode);
};

const isForeignZipCode = (zipCode?: string): boolean => {
    if (!zipCode) return false;

    return zipCode.substring(2, 3) === '-' && !zipCode.startsWith('FI-');
};

const validateZipCode = (zipCode?: string, postalCodes?: PostOffice[]): boolean => {
    if (!zipCode || !postalCodes) return false;
    if (postalCodes.length === 0) return true; // no way to validate, accept

    if (/^[0-9]{5}$/.test(zipCode) && postalCodes.find((item) => item.zipCode === zipCode) !== undefined) {
        return true;
    }

    if (zipCode.startsWith('FI-') && postalCodes.find((item) => item.zipCode === zipCode.split('-')[1]) !== undefined) {
        return true;
    }

    if (!zipCode.startsWith('FI-') && /^([A-Z]{2}-)+[0-9]+$/.test(zipCode)) {
        return true;
    }

    return false;
};

export default { getCityByZipCode, isDomesticZipCode, validateZipCode, isForeignZipCode };
