import React, { FC, Fragment, ReactNode, useContext } from 'react';
import { Box, Divider, Grid, List, ListItem, Typography } from '@mui/material';
import { LanguageContext } from 'contexts/languageContext';
import { SmsMessage } from 'types/sp-api';
import classnames from 'classnames';
import WaitScreen from 'components/common/WaitScreen';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';

interface SmsMessageListProps {
    messageList?: SmsMessage[];
    selectedId?: number;
}

const SmsMessageList: FC<SmsMessageListProps> = ({ messageList, selectedId }: SmsMessageListProps) => {
    const { T } = useContext(LanguageContext);
    const navigate = useNavigate();

    return (
        <>
            {!messageList && <WaitScreen />}
            {messageList && messageList.length > 0 && (
                <>
                    <List>
                        <ListItem
                            style={{
                                marginTop: -8,
                                borderBottomStyle: 'solid',
                                borderBottomWidth: 1,
                                borderBottomColor: '#0000008a'
                            }}
                        >
                            <Typography variant="h6" color="textSecondary">
                                {T('SmsMessages_HistoryTitle')}
                            </Typography>
                        </ListItem>
                        {messageList.map(
                            (item, index): ReactNode => (
                                <Fragment key={index}>
                                    {index > 0 && <Divider variant="middle" component="li" />}
                                    <ListItem
                                        className={classnames({ 'selected-row': selectedId === item.id }, 'pointer')}
                                        onClick={() => navigate(`/communication/smsmessages/${item.id}`)}
                                    >
                                        <Grid container>
                                            <Grid container item justifyContent="space-between">
                                                <Grid container item justifyContent="flex-start" xs={8}>
                                                    <Typography sx={{ width: 370 }} noWrap color="primary">
                                                        {item.title}
                                                    </Typography>
                                                </Grid>
                                                <Grid container item justifyContent="flex-end" xs={4}>
                                                    <Typography variant="subtitle2" color="textSecondary" noWrap>
                                                        {`${moment(item.createdDate).format('l')} ${moment(
                                                            item.createdDate
                                                        ).format('LT')}`}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                            <Grid container item>
                                                <Typography variant="subtitle2" color="textSecondary" noWrap>
                                                    {T('SmsMessage_HistorySentCount').replace(
                                                        '{0}',
                                                        item.msgCount?.toString() ?? '-'
                                                    )}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </ListItem>
                                </Fragment>
                            )
                        )}
                    </List>
                </>
            )}
            {messageList && messageList.length === 0 && (
                <Box p={2}>
                    <Typography variant="body1" color="textSecondary">
                        {T('SmsMessages_NoSmsMessages')}
                    </Typography>
                </Box>
            )}
        </>
    );
};

export default SmsMessageList;
