import React, { FC, useContext } from 'react';
import { LanguageContext, LanguageTextFunc } from 'contexts/languageContext';
import { Grid } from '@mui/material';
import { CredentialApplicationNote } from 'types/sp-api';
import DialogBase from 'components/common/DialogBase';
import { Formik, FormikProps, Form } from 'formik';
import FormFieldDropDownCodeSet from 'components/common/FormFieldDropDownCodeSet';
import { useErrorHandler } from 'components/hooks/useErrorHandler';
import { useSuccessHandler } from 'components/hooks/useSuccessHandler';
import CredentialApplicationService from 'services/credentialApplicationService';
import * as Yup from 'yup';
import FormFieldDate from 'components/common/FormFieldDate';
import FormFieldTextArea from 'components/common/FormFieldTextArea';

const getNewNoteValidationSchema = (T: LanguageTextFunc) => {
    return Yup.object().shape({
        notesDate: Yup.date().typeError(T('Common_Required')).required(T('Common_Required')),
        type: Yup.number().min(1, T('Common_Required')),
        notes: Yup.string().required(T('Common_Required'))
    });
};

interface DialogUpsertNoteProps {
    applicationId: number;
    note?: CredentialApplicationNote;
    show?: boolean;
    onClose: () => void;
    onUpdate: () => void;
}

const DialogUpsertNote: FC<DialogUpsertNoteProps> = ({
    applicationId,
    note,
    show,
    onClose,
    onUpdate
}: DialogUpsertNoteProps) => {
    const { T } = useContext(LanguageContext);
    const { handleSuccess } = useSuccessHandler();
    const { handleError } = useErrorHandler();

    const saveNote = async (note: CredentialApplicationNote) => {
        try {
            await CredentialApplicationService.saveNoteAsync(note);
            handleSuccess(T('CredentialApplicationNote_SaveSuccess'));
            // refresh list
            onUpdate();
        } catch (error) {
            handleError(error, T('CredentialApplicationNote_SaveFailed'));
        }
    };

    return (
        <Formik
            initialValues={note ?? { applicationId: applicationId, type: 0 }}
            validationSchema={getNewNoteValidationSchema(T)}
            onSubmit={() => {
                // DO nothing
            }}
            enableReinitialize
        >
            {({ dirty, isValid, isSubmitting, resetForm, values }: FormikProps<CredentialApplicationNote>) => (
                <DialogBase
                    show={show}
                    title={
                        !values.id
                            ? T('CredentialApplication_AddNoteDialogTitle')
                            : T('CredentialApplication_EditNoteDialogTitle')
                    }
                    onOk={async () => {
                        saveNote(values);
                        resetForm();
                        onClose();
                    }}
                    onClose={onClose}
                    okDisabled={isSubmitting || !dirty || !isValid}
                    okTitle={T('Common_Save')}
                >
                    <Form>
                        <Grid container spacing={3} sx={{ pt: 1 }} style={{ paddingBottom: 0 }}>
                            <Grid item xs={6}>
                                <FormFieldDropDownCodeSet
                                    codeSet="credentialNote"
                                    name="type"
                                    label={T('CredentialApplicationNote_FieldType')}
                                    placeholder={T('CredentialApplicationNote_SelectType')}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <FormFieldDate
                                    name="notesDate"
                                    label={T('CredentialApplicationNote_FieldNoteDate')}
                                    autoFocus
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormFieldTextArea
                                    name="notes"
                                    label={T('CredentialApplicationNote_FieldNotes')}
                                    autoFocus
                                    rows={6}
                                />
                            </Grid>
                        </Grid>
                    </Form>
                </DialogBase>
            )}
        </Formik>
    );
};

export default DialogUpsertNote;
