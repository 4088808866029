import React, { FC, useRef, useState } from 'react';
import {
    Paper,
    Typography,
    CircularProgress,
    Button,
    ButtonGroup,
    ClickAwayListener,
    Grow,
    MenuItem,
    MenuList,
    Popper
} from '@mui/material';
import CredentialApplicationService from 'services/credentialApplicationService';
import FlagOutlinedIcon from '@mui/icons-material/FlagOutlined';
import FlagIcon from '@mui/icons-material/Flag';
import BlockIcon from '@mui/icons-material/Block';
import { SvgIconComponent } from '@mui/icons-material';

interface FlagMenuProps {
    flag?: number;
    applicationId?: number;
    updateList?: () => void;
    loading?: boolean;
}

const FlagMenu: FC<FlagMenuProps> = ({ flag, applicationId, updateList, loading }: FlagMenuProps) => {
    const [menuOpen, setMenuOpen] = useState(false);
    const anchorRef = useRef(null);

    const handleMenuItemClick = async (type: number) => {
        setMenuOpen(false);
        await CredentialApplicationService.setFlagAsync(applicationId, type == -1 ? 0 : type);
        updateList && updateList();
    };

    return (
        <>
            <ButtonGroup variant="text" color="primary" aria-label="split button" ref={anchorRef} disabled={loading}>
                <Button variant="text" onClick={() => setMenuOpen((prevOpen) => !prevOpen)} disabled={loading}>
                    {loading ? <CircularProgress color="primary" size={20} /> : createFlag(flag)}
                </Button>
            </ButtonGroup>
            <Popper
                open={menuOpen}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                placement="bottom-end"
                style={{ zIndex: 999 }}
                nonce={undefined}
            >
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin: placement === 'bottom' ? 'top-end' : 'bottom-end'
                        }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={() => setMenuOpen(false)}>
                                <MenuList id="flag-button-menu">
                                    {[1, 2, 3, 4, 5, -1].map((n, index) => (
                                        <MenuItem key={index} onClick={() => handleMenuItemClick(n)}>
                                            {createFlag(n, true)}
                                        </MenuItem>
                                    ))}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </>
    );
};

interface flagProps {
    type: number;
    icon: SvgIconComponent;
    color?: 'disabled' | 'action' | 'primary' | 'inherit' | 'secondary' | 'error' | 'info' | 'success' | 'warning';
    htmlColor?: string;
    title: string;
}

const flagList: flagProps[] = [
    { type: -1, icon: BlockIcon, color: 'secondary', title: 'Poista lippu' },
    { type: 1, icon: FlagIcon, color: 'action', title: 'Valittu' },
    { type: 2, icon: FlagOutlinedIcon, color: 'info', title: 'Toiminta' },
    { type: 3, icon: FlagOutlinedIcon, color: 'warning', title: 'Varoitus' },
    { type: 4, icon: FlagOutlinedIcon, color: 'error', title: 'Virhe' },
    { type: 5, icon: FlagIcon, color: 'success', title: 'Valmis' },
    { type: 6, icon: FlagOutlinedIcon, htmlColor: 'white', title: '' }
];

export const createFlag = (type?: number, showName?: boolean) => {
    const selectedFlag = flagList.find((flag) => flag.type === type) ?? flagList[6];
    const SelectedIcon = selectedFlag.icon as SvgIconComponent;

    return (
        <>
            <SelectedIcon
                fontSize="small"
                color={selectedFlag.color}
                htmlColor={selectedFlag.htmlColor}
                titleAccess={selectedFlag.title}
            />
            {showName && (
                <Typography variant="body2" sx={{ ml: 1 }}>
                    {selectedFlag.title}
                </Typography>
            )}
        </>
    );
};

export default FlagMenu;
