import React, { FC, useContext, useState, MouseEvent, ChangeEvent } from 'react';
import { LanguageContext } from 'contexts/languageContext';
import { PersonWithMembership } from 'types/sp-api';
import { useNavigate } from 'react-router-dom';
import {
    Box,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TablePagination,
    TableRow,
    Typography
} from '@mui/material';
import PersonUtils from 'utils/personHelper';
import { Order } from 'types/common';
import TableUtils from 'utils/tableHelper';
import EnhancedTableHead, { TableHeadCellDef } from 'components/common/EnhancedTableHead';
import { useAudit } from 'components/hooks/useAudit';
import WaitScreen from 'components/common/WaitScreen';
import NoAccountsIcon from '@mui/icons-material/NoAccounts';
import DeceasedIcon from 'assets/images/cross.png';
import format from 'date-fns/format';
import { parseISO } from 'date-fns';

interface ArchiveListProps {
    personsList?: PersonWithMembership[];
    loading?: boolean;
    message?: string;
}

const ArchiveList: FC<ArchiveListProps> = ({ personsList, message, loading }: ArchiveListProps) => {
    const { T } = useContext(LanguageContext);

    if (!personsList) return null;

    const headCells: TableHeadCellDef<Data>[] = [
        { id: 'name', label: T('Common_Name') },
        { id: 'address', label: T('Person_FieldAddressCity') },
        { id: 'member', label: T('Membership_Organization') },
        { id: 'phone', label: T('Common_Phone') },
        { id: 'birthdate', label: T('Person_FieldBirthDate'), disablePadding: true }
    ];

    return (
        <EnhancedTable
            headCells={headCells}
            rows={personsList.map(
                (person): Data => ({
                    id: person.id ?? 0,
                    name: PersonUtils.FullName(person),
                    address: person?.address?.city ?? '',
                    member:
                        person?.memberships?.find((member) => !member.endDate)?.organization?.name ??
                        person?.memberships?.find((member) => member.endDate)?.organization?.name ??
                        '',
                    memberExpired:
                        !!person?.memberships &&
                        person?.memberships?.length > 0 &&
                        !person?.memberships?.find((member) => !member.endDate),
                    phone: person?.phoneHome ?? '',
                    deceasedDate:
                        person?.deceasedDate != null
                            ? format(parseISO(person?.deceasedDate.toString()), 'd.M.yyyy')
                            : '',
                    birthdate:
                        person?.birthDate != null ? format(parseISO(person?.birthDate.toString()), 'd.M.yyyy') : ''
                })
            )}
            initialOrder="asc"
            initialOrderBy="name"
            loading={loading}
            message={message}
        />
    );
};

export default ArchiveList;

interface Data {
    id: number;
    name: string;
    address: string;
    member: string;
    memberExpired: boolean;
    phone: string;
    deceasedDate: string;
    birthdate: string;
}

interface EnhancedTableProps {
    initialOrder: Order;
    initialOrderBy: keyof Data;
    headCells: TableHeadCellDef<Data>[];
    rows: Data[];
    loading?: boolean;
    message?: string;
}

const EnhancedTable = (props: EnhancedTableProps) => {
    const { headCells, rows, initialOrder, initialOrderBy } = props;
    const [order, setOrder] = useState<Order>(initialOrder);
    const [orderBy, setOrderBy] = useState<keyof Data>(initialOrderBy);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(6);
    const navigate = useNavigate();
    const { audit } = useAudit();
    const { T } = useContext(LanguageContext);

    const handleRequestSort = (_: MouseEvent<unknown>, property: keyof Data) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleClick = (id: number) => {
        const url = `/archive/persons/${id}`;
        audit('archive person select', url);
        navigate(url);
    };

    const handleChangePage = (_: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return (
        <>
            <TableContainer component={Paper}>
                <Table>
                    <EnhancedTableHead
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={handleRequestSort}
                        headCells={headCells}
                    />
                    <TableBody>
                        <TableRow>
                            <TableCell colSpan={4} style={{ height: 0, padding: 0 }}></TableCell>
                        </TableRow>
                        {props.message ? (
                            <TableRow>
                                <TableCell colSpan={4}>
                                    <Box m={2} component="span">
                                        <Typography>{props.message}</Typography>
                                    </Box>
                                </TableCell>
                            </TableRow>
                        ) : (
                            TableUtils.stableSort<Data>(rows, TableUtils.getComparator(order, orderBy))
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row) => {
                                    return (
                                        <TableRow
                                            hover
                                            onClick={() => handleClick(row.id)}
                                            key={row.id}
                                            className="pointer"
                                        >
                                            <TableCell sx={{ fontSize: 'body1.fontSize' }}>
                                                {row.name}
                                                {row.deceasedDate.length > 1 && (
                                                    <img
                                                        src={DeceasedIcon}
                                                        style={{ color: 'black', height: 20 }}
                                                        alt={T('Person_Deceased')}
                                                    />
                                                )}
                                            </TableCell>
                                            <TableCell sx={{ fontSize: 'body1.fontSize' }}>{row.address}</TableCell>
                                            <TableCell sx={{ fontSize: 'body1.fontSize' }}>
                                                {row.memberExpired && (
                                                    <NoAccountsIcon
                                                        color="error"
                                                        sx={{ marginRight: 1 }}
                                                        titleAccess={T('Membership_Expired')}
                                                    />
                                                )}
                                                {row.member}
                                            </TableCell>
                                            <TableCell sx={{ fontSize: 'body1.fontSize' }}>{row.phone}</TableCell>
                                            <TableCell sx={{ fontSize: 'body1.fontSize', textAlign: 'right' }}>
                                                {row.birthdate}
                                            </TableCell>
                                        </TableRow>
                                    );
                                })
                        )}
                        {props.loading && (
                            <TableRow>
                                <TableCell colSpan={4}>
                                    <Box m={2} component="span">
                                        <WaitScreen />
                                    </Box>
                                </TableCell>
                            </TableRow>
                        )}
                        {addEmptyRows(rows.length, rowsPerPage, page)}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                component="div"
                rowsPerPageOptions={[6, 12, 30]}
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </>
    );
};

const addEmptyRows = (rowCount: number, rowsPerPage: number, page: number) => {
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, rowCount - page * rowsPerPage);

    return (
        emptyRows > 0 && (
            <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell sx={{ fontSize: 'body1.fontSize' }} colSpan={4} />
            </TableRow>
        )
    );
};
