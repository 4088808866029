import React, { FC, useContext } from 'react';
import { Button } from '@mui/material';
import { LanguageContext } from 'contexts/languageContext';
import { useSnackbar } from 'notistack';

interface ErrorToastProps {
    title: string;
    message?: string;
}

const ErrorToast: FC<ErrorToastProps> = ({ title, message }: ErrorToastProps) => {
    const { T } = useContext(LanguageContext);
    const { closeSnackbar } = useSnackbar();

    return (
        <>
            <div>
                {title}
                {message && (
                    <>
                        <br />
                        {T('Common_Error')}: {message}
                    </>
                )}
            </div>
            <Button variant="text" style={{ marginLeft: 16 }} onClick={() => closeSnackbar()}>
                Ok
            </Button>
        </>
    );
};

export default ErrorToast;
