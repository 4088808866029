import React, { FC, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Button, Container, Grid, Grow, MenuList, Paper, Popper, ButtonGroup, ClickAwayListener } from '@mui/material';
import { LanguageContext } from 'contexts/languageContext';
import BadgeIcon from '@mui/icons-material/Badge';
import BreadcrumbBar from 'components/common/BreadcrumbBar';
import ViewTitle from 'components/common/ViewTitle';
import ViewSearchBar from 'components/common/ViewSearchBar';
import { useParams } from 'react-router-dom';
import axios, { AxiosResponse, CancelTokenSource } from 'axios';
import { useErrorHandler } from 'components/hooks/useErrorHandler';
import { CredentialApplication } from 'types/sp-api';
import CredentialApplicationService from 'services/credentialApplicationService';
import CredentialApplicationList from './CredentialApplicationList';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ReportService from 'services/reportService';
import LoadButton from 'components/common/LoadButton';
import DownloadFile from 'components/common/DownloadFile';
import moment from 'moment';
import FlaggedMenuItem from './FlaggedMenuItem';
import FilterDropDown from './FilterDropDown';

const CredentialRegisterView: FC = () => {
    const { T } = useContext(LanguageContext);
    const { filter } = useParams<'filter'>();
    const { flag } = useParams<'flag'>();
    const [applicationsList, setApplicationsList] = useState<CredentialApplication[]>();
    const { handleError } = useErrorHandler();
    const cancelRef = useRef<CancelTokenSource | null>(null);
    const clearDownload = { type: undefined, filename: '' };
    const [download, setDownload] = useState<{ type?: number; filename: string }>(clearDownload);
    const anchorRef = useRef(null);
    const [menuOpen, setMenuOpen] = useState(false);
    const [loading, setLoading] = useState(false);

    const getCredentialApplications = useCallback(
        async (filterStr?: string, flag?: string): Promise<void> => {
            try {
                setLoading(true);
                cancelRef.current = axios.CancelToken.source();
                const appsList = await CredentialApplicationService.getAllApplicationsAsync(
                    filterStr,
                    cancelRef.current.token
                );
                if (cancelRef.current) {
                    const listOrder = (a: CredentialApplication, b: CredentialApplication): number => {
                        if (a.newApplication === true && b.newApplication === false) return -1;
                        if (a.newApplication === false && b.newApplication === true) return 1;

                        if (a.lastName.toLocaleLowerCase() < b.lastName.toLocaleLowerCase()) return -1;
                        if (a.lastName.toLocaleLowerCase() > b.lastName.toLocaleLowerCase()) return 1;
                        if (a.firstName.toLocaleLowerCase() < b.firstName.toLocaleLowerCase()) return -1;
                        if (a.firstName.toLocaleLowerCase() > b.firstName.toLocaleLowerCase()) return 1;

                        return 0;
                    };

                    appsList.sort(listOrder);

                    if (flag) {
                        const flagNumber = parseInt(flag);
                        setApplicationsList(
                            appsList.filter(
                                (item) =>
                                    item.flag === flagNumber ||
                                    (flagNumber === 0 && item.flag === null) ||
                                    (flagNumber === 0 && item.flag === undefined)
                            )
                        );
                    } else {
                        setApplicationsList(appsList);
                    }

                    setLoading(false);
                }
            } catch (error) {
                setLoading(false);
                handleError(error, T('Error_CredentialsQueryFailed'));
            }
        },
        [handleError, T]
    );

    useEffect(() => {
        getCredentialApplications(filter, flag);

        return () => {
            cancelRef.current && cancelRef.current.cancel();
            cancelRef.current = null;
        };
    }, [getCredentialApplications, filter, flag]);

    const fetchNotesCsvReport = async (type?: number, filename?: string): Promise<AxiosResponse> => {
        !cancelRef.current && (cancelRef.current = axios.CancelToken.source());
        return ReportService.credentialApplicationCsvReportAsync(type, filename, cancelRef.current.token);
    };

    return (
        <Container>
            <Grid container>
                <BreadcrumbBar />
                <ViewTitle title={T('CredentialRegister_Title')} icon={BadgeIcon} />
                <Grid container item xs={3}>
                    <FilterDropDown selectedFlag={parseInt(flag ?? '')} />
                </Grid>
                <Grid container item xs={4}>
                    <ViewSearchBar
                        filter={filter ?? undefined}
                        baseUrl="/registers/credentials/search"
                        clearUrl="/registers/credentials"
                        searchTitle={T('Placeholder_CredentialSearch')}
                        auditMsg="credential application search"
                        minWidth={380}
                    />
                </Grid>
                <Grid item xs={5} sx={{ textAlign: 'right' }}>
                    <ButtonGroup
                        variant="contained"
                        color="primary"
                        aria-label="split button"
                        ref={anchorRef}
                        disabled={download.filename != ''}
                    >
                        <LoadButton
                            loading={download.filename != ''}
                            type="button"
                            onClick={() => {
                                const filename = T('CredentialApplication_ApplicationCsvFilename')
                                    .replace('{0}', 'kaikki')
                                    .replace('{1}', moment().format('L LT'));
                                setDownload({ type: -1, filename: filename });
                            }}
                            color="secondary"
                        >
                            {T('CredentialApplication_PrintNotesCsv')}
                        </LoadButton>
                        <Button
                            color="secondary"
                            size="small"
                            onClick={() => setMenuOpen((prevOpen) => !prevOpen)}
                            disabled={download.filename != ''}
                        >
                            <ArrowDropDownIcon />
                        </Button>
                    </ButtonGroup>
                    <Popper
                        open={menuOpen}
                        anchorEl={anchorRef.current}
                        role={undefined}
                        transition
                        disablePortal
                        placement="bottom-end"
                        style={{ zIndex: 999 }}
                        nonce={undefined}
                    >
                        {({ TransitionProps, placement }) => (
                            <Grow
                                {...TransitionProps}
                                style={{
                                    transformOrigin: placement === 'bottom' ? 'top-end' : 'bottom-end'
                                }}
                            >
                                <Paper>
                                    <ClickAwayListener onClickAway={() => setMenuOpen(false)}>
                                        <MenuList id="split-button-menu" sx={{ borderRadius: 1 }}>
                                            <FlaggedMenuItem type={0} title="ei lippua" setDownload={setDownload} />
                                            <FlaggedMenuItem type={1} title="valittu" setDownload={setDownload} />
                                            <FlaggedMenuItem type={2} title="toiminta" setDownload={setDownload} />
                                            <FlaggedMenuItem type={3} title="varoitus" setDownload={setDownload} />
                                            <FlaggedMenuItem type={4} title="virhe" setDownload={setDownload} />
                                            <FlaggedMenuItem type={5} title="valmis" setDownload={setDownload} />
                                        </MenuList>
                                    </ClickAwayListener>
                                </Paper>
                            </Grow>
                        )}
                    </Popper>
                </Grid>
            </Grid>
            <CredentialApplicationList
                applicationList={applicationsList}
                loading={loading}
                updateApplicationList={getCredentialApplications}
            />
            {download.filename != '' && (
                <DownloadFile
                    fetchFile={() => fetchNotesCsvReport(download.type, download.filename)}
                    filename={download.filename}
                    onSuccess={() => setDownload(clearDownload)}
                />
            )}
        </Container>
    );
};

export default CredentialRegisterView;
