import React, { FC, ReactNode, useCallback, useContext, useEffect, useRef, useState } from 'react';
import {
    Grid,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from '@mui/material';
import { LanguageContext } from 'contexts/languageContext';
import { User } from 'types/sp-api';
import { useErrorHandler } from 'components/hooks/useErrorHandler';
import axios, { CancelTokenSource } from 'axios';
import OrganizationsService from 'services/organizationsService';
import WaitScreen from 'components/common/WaitScreen';
import PersonUtils from 'utils/personHelper';
import { TableHeadCell } from 'components/common/TableHeadCell';
import Utils from 'utils/typeHelper';

interface OrganizationMainUsersProps {
    organizationId: number;
}

const OrganizationMainUsers: FC<OrganizationMainUsersProps> = ({ organizationId }: OrganizationMainUsersProps) => {
    const { T } = useContext(LanguageContext);
    const [mainUsersList, setMainUsersList] = useState<User[]>();
    const { handleError } = useErrorHandler();
    const cancelRef = useRef<CancelTokenSource | null>(null);

    const getOrganizationMainUsers = useCallback(
        async (id: number): Promise<void> => {
            try {
                !cancelRef.current && (cancelRef.current = axios.CancelToken.source());
                const organizationMainUsers = await OrganizationsService.getOrganizationMainUsersAsync(
                    id,
                    cancelRef.current.token
                );

                const sortPositions = (a: User, b: User): number => {
                    if (!a.person || !b.person) return 0;
                    if (a.person.lastName > b.person.lastName) return 1;
                    if (a.person.lastName < b.person.lastName) return -1;
                    return 0;
                };

                if (cancelRef.current) {
                    organizationMainUsers.sort(sortPositions);
                    setMainUsersList(organizationMainUsers);
                }
            } catch (error) {
                handleError(error, T('Error_OrganizationPositionQueryFailed'));
            }
        },
        [handleError, T]
    );

    useEffect(() => {
        getOrganizationMainUsers(organizationId);

        return () => {
            cancelRef.current && cancelRef.current.cancel();
            cancelRef.current = null;
        };
    }, [getOrganizationMainUsers, organizationId]);

    if (organizationId === -1) return null;

    return (
        <>
            <Grid container item xs={12} spacing={1}>
                <Grid item xs={12}>
                    <TableContainer component={Paper}>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableHeadCell>{T('Organization_PositionPerson')}</TableHeadCell>
                                    <TableHeadCell sx={{ width: '45%' }}>
                                        {T('Organization_PositionPosition')}
                                    </TableHeadCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {mainUsersList && mainUsersList.length == 0 && (
                                    <TableRow>
                                        <TableCell>
                                            <Typography variant="body1" color="textSecondary">
                                                {T('Organization_NoPositionsSet')}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                )}
                                {mainUsersList &&
                                    mainUsersList.length > 0 &&
                                    mainUsersList.map(
                                        (item): ReactNode => (
                                            <TableRow key={item.id}>
                                                <TableCell>
                                                    <Typography>{PersonUtils.FullName(item.person)}</Typography>
                                                </TableCell>
                                                <TableCell>{T(Utils.RoleIdToName(item.roleId))}</TableCell>
                                            </TableRow>
                                        )
                                    )}
                                {!mainUsersList && (
                                    <TableRow>
                                        <TableCell>
                                            <WaitScreen />
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
        </>
    );
};

export default OrganizationMainUsers;
