import React, { FC, useContext, useState } from 'react';
import { Button, Grid, Typography } from '@mui/material';
import { LanguageContext, LanguageTextFunc } from 'contexts/languageContext';
import PersonIcon from '@mui/icons-material/Person';
import LockIcon from '@mui/icons-material/Lock';
import FormFieldText from 'components/common/FormFieldText';
import { Formik, Form, FormikProps } from 'formik';
import LoadButton from 'components/common/LoadButton';
import { useErrorHandler } from 'components/hooks/useErrorHandler';
import { useNavigate } from 'react-router-dom';
import { LoginContext, LoginPhase } from 'types/auth';
import * as Yup from 'yup';
import AuthService from 'services/authService';
import LanguageSelectionFlags from 'components/common/LanguageSelectionFlags';
import { AuthContext } from 'contexts/authContext';

const getValidationSchema = (T: LanguageTextFunc) => {
    return Yup.object().shape({
        username: Yup.string().required(T('Common_Required')),
        password: Yup.string().required(T('Common_Required'))
    });
};

interface LoginPhaseLoginProps {
    loginCtx: LoginContext;
    setLoginCtx: (ctx: LoginContext) => void;
}

const LoginPhaseLogin: FC<LoginPhaseLoginProps> = ({ loginCtx, setLoginCtx }: LoginPhaseLoginProps) => {
    const languageCtx = useContext(LanguageContext);
    const { T } = languageCtx;
    const [sending, setSending] = useState(false);
    const { handleError } = useErrorHandler();
    const authCtx = useContext(AuthContext);
    const navigate = useNavigate();

    return (
        <Formik
            initialValues={loginCtx}
            enableReinitialize
            onSubmit={async (values, formikHelpers) => {
                setSending(true);
                try {
                    const result = await AuthService.loginAsync(authCtx, languageCtx, values);
                    if (result.otpRequired === true) {
                        setLoginCtx({ ...values, phase: LoginPhase.Otp });
                    } else if (result.message === 'invalid_grant') {
                        setSending(false);
                        formikHelpers.setErrors({ password: T('Login_Failed') });
                    } else {
                        throw new Error(result.message);
                    }
                } catch (error) {
                    setSending(false);
                    handleError(error, T('Login_ErrorFailed'));
                }
            }}
            validationSchema={getValidationSchema(T)}
        >
            {({ dirty, isValid, resetForm }: FormikProps<LoginContext>) => {
                return (
                    <Form>
                        <Grid container spacing={3} style={{ maxWidth: 350 }} alignItems="center">
                            <Grid item xs={12} container justifyContent="center">
                                <Typography variant="h4">{T('Login_Title')}</Typography>
                            </Grid>
                            <Grid item xs={2} container justifyContent="flex-end">
                                <PersonIcon />
                            </Grid>
                            <Grid item xs={10}>
                                <FormFieldText name="username" label={T('Login_Username')} autoFocus />
                            </Grid>
                            <Grid item xs={2} container justifyContent="flex-end">
                                <LockIcon />
                            </Grid>
                            <Grid item xs={10}>
                                <FormFieldText name="password" label={T('Login_Password')} password />
                            </Grid>
                            <Grid item xs={12} container justifyContent="center">
                                <LoadButton loading={sending} disabled={!dirty || !isValid} type="submit">
                                    {T('Login_LoginButtonTitle')}
                                </LoadButton>
                            </Grid>
                            <Grid item xs={12} container justifyContent="center">
                                <Button
                                    variant="text"
                                    onClick={() => {
                                        resetForm();
                                        navigate('/forgottenPassword');
                                    }}
                                >
                                    <Typography color="GrayText" sx={{ fontSize: 12, textTransform: 'none' }}>
                                        {T('Login_ResetPasswordText')}
                                    </Typography>
                                </Button>
                            </Grid>
                            <LanguageSelectionFlags />
                        </Grid>
                    </Form>
                );
            }}
        </Formik>
    );
};

export default LoginPhaseLogin;
