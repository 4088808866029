import React, { FC, useContext } from 'react';
import { Grid, Typography, Breadcrumbs } from '@mui/material';
import Link, { LinkProps } from '@mui/material/Link';
import { LanguageContext } from 'contexts/languageContext';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import ChurchName from 'components/app/ChurchName';

interface LinkRouterProps extends LinkProps {
    to: string;
    replace?: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const LinkRouter = (props: LinkRouterProps) => <Link {...props} component={RouterLink as any} />;

interface BreadcrumbBarProps {
    hideChurchName?: boolean;
}

const BreadcrumbBar: FC<BreadcrumbBarProps> = ({ hideChurchName }: BreadcrumbBarProps) => {
    const { T } = useContext(LanguageContext);
    const location = useLocation();
    const pathnames = location.pathname.split('/').filter((x) => x);

    const breadcrumbNameMap: { [key: string]: string } = {
        '/persons': T('Common_Persons'),
        '/churches': T('Common_Churches'),
        '/reports': T('Common_Reports'),
        '/communication': T('Common_Communication'),
        '/communication/securemessages': T('SecureMessages_Title'),
        '/communication/smsmessages': T('SmsMessages_Title'),
        '/accessrights': T('Common_AccessRights'),
        '/settings': T('Common_Settings'),
        '/notifications': T('Common_Notifications'),
        '/account': T('Common_Account'),
        '/persons/search': T('Common_SearchResults'),
        '/profile': T('Common_Profile'),
        '/contact': T('Common_Contact'),
        '/registration': T('Common_Registration'),
        '/pageNotFound': T('Common_PageNotFound'),
        '/cookiePolicy': T('Common_CookiePolicy'),
        '/archive': T('Common_Archive'),
        '/archive/persons': T('Common_ArchivePersons'),
        '/archive/persons/search': T('Common_ArchiveSearchResults'),
        '/registers/credentials': T('CredentialRegister_Title'),
        '/registers/credentials/search/:filter': T('Common_CredentialRegisterSearchResults'),
        '/registers/credentials/flag/:flag': T('Common_CredentialRegisterFlagResults'),
        '/registers/seniors': T('GlobalGroupRegister_Title_senior'),
        '/registers/seniors/search/:filter': T('Common_SeniorRegisterSearchResults'),
        '/registers/prisonEvangelists': T('GlobalGroupRegister_Title_prisonEvangelist'),
        '/registers/prisonEvangelists/search/:filter': T('Common_PrisonEvangelistRegisterSearchResults'),
        '/registers/webinarMembers': T('GlobalGroupRegister_Title_webinarMembers'),
        '/registers/webinarMembers/search/:filter': T('Common_WebinarMemberSearchResults'),
        '/registers/congregations': T('CongregationRegister_Title'),
        '/registers/congregations/search/:filter': T('Common_CongregationRegisterSearchResults')
    };

    const lastTo = `/${pathnames.slice(0, pathnames.length).join('/')}`;

    const personSearch = /^\/persons\/search\//;
    const personItem = /^\/persons\/[\d].*/;
    const personNew = /^\/persons\/new/;

    const churchSearch = /^\/churches\/search\//;
    const churchItem = /^\/churches\/[\d].*/;
    const churchNew = /^\/churches\/new/;

    return (
        <Grid container style={{ marginTop: 20, marginBottom: 20 }}>
            <Grid item xs={6}>
                <Breadcrumbs aria-label="breadcrumb">
                    <LinkRouter color="inherit" to="/">
                        {T('Common_Home')}
                    </LinkRouter>
                    {pathnames.map((_, index) => {
                        const last = index === pathnames.length - 1;
                        const to = `/${pathnames.slice(0, index + 1).join('/')}`;
                        const nextTo = `/${pathnames.slice(0, index + 2).join('/')}`;

                        // if next is unknown, its probably search key
                        if (breadcrumbNameMap[nextTo] === undefined) return null;

                        if (breadcrumbNameMap[to] === undefined || last) {
                            return null;
                        }

                        return (
                            <LinkRouter color="inherit" to={to} key={to}>
                                {breadcrumbNameMap[to]}
                            </LinkRouter>
                        );
                    })}
                    {personNew.test(lastTo) && (
                        <LinkRouter color="inherit" to={'/persons'} key="search_results">
                            {T('Common_PersonCreation')}
                        </LinkRouter>
                    )}
                    {(personSearch.test(lastTo) || personItem.test(lastTo)) && (
                        <LinkRouter color="inherit" to={'/persons'} key="search_results">
                            {T('Common_SearchResults')}
                        </LinkRouter>
                    )}
                    {!personSearch.test(lastTo) &&
                        !personItem.test(lastTo) &&
                        !churchSearch.test(lastTo) &&
                        !churchItem.test(lastTo) && (
                            <Typography color="textPrimary" key={lastTo}>
                                {breadcrumbNameMap[lastTo]}
                            </Typography>
                        )}
                    {personItem.test(lastTo) && (
                        <Typography color="textPrimary" key={lastTo}>
                            {T('Common_Person')}
                        </Typography>
                    )}
                    {churchNew.test(lastTo) && (
                        <LinkRouter color="inherit" to={'/churches'} key="search_results">
                            {T('Common_ChurchCreation')}
                        </LinkRouter>
                    )}
                    {churchSearch.test(lastTo) && (
                        <LinkRouter color="inherit" to={'/churches'} key="search_results">
                            {T('Common_SearchResults')}
                        </LinkRouter>
                    )}
                    {churchItem.test(lastTo) && (
                        <Typography color="textPrimary" key={lastTo}>
                            {T('Common_Church')}
                        </Typography>
                    )}
                </Breadcrumbs>
            </Grid>
            <Grid container item xs={6} justifyContent="flex-end">
                {!hideChurchName && <ChurchName />}
            </Grid>
        </Grid>
    );
};

export default BreadcrumbBar;
