import React, { FC, useContext } from 'react';
import { LanguageContext } from 'contexts/languageContext';
import FormFieldDropDown from './FormFieldDropDown';
import { KeyValue } from 'types/common';

interface FormFieldDropDownLanguageProps {
    label: string;
    name: string;
    autoFocus?: boolean;
    disabledValues?: number | string | (number | string)[];
    hiddenValues?: (number | string)[];
}

const FormFieldDropDownLanguage: FC<FormFieldDropDownLanguageProps> = ({
    label,
    name,
    autoFocus,
    disabledValues,
    hiddenValues
}: FormFieldDropDownLanguageProps) => {
    const { T, languageCodes } = useContext(LanguageContext);

    const values: KeyValue[] = languageCodes.map((item) => ({ key: item.lan, value: `Language_${item.value}` }));

    return (
        <FormFieldDropDown
            name={name}
            label={label}
            values={values}
            placeholder={T('Common_SelectLanguage')}
            autoFocus={autoFocus}
            disabledValues={disabledValues}
            hiddenValues={hiddenValues}
        />
    );
};

export default FormFieldDropDownLanguage;
