import React, { FC, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { LanguageContext } from 'contexts/languageContext';
import { ReportFilter as ReportFilterType, MemberList } from 'types/sp-api';
import { useErrorHandler } from 'components/hooks/useErrorHandler';
import { Grid, ListItem, ListItemText, Typography } from '@mui/material';
import axios, { CancelTokenSource } from 'axios';
import DialogBase from 'components/common/DialogBase';
import ReportService from 'services/reportService';
import { FixedSizeList, ListChildComponentProps } from 'react-window';
import useWindowDimensions from 'components/hooks/useWindowDimensions';

interface DialogShowRecipientsProps {
    show: boolean;
    organizationId: number;
    filter: ReportFilterType;
    onClose?: () => void;
}

const DialogShowRecipients: FC<DialogShowRecipientsProps> = ({
    show,
    organizationId,
    filter,
    onClose
}: DialogShowRecipientsProps) => {
    const { T } = useContext(LanguageContext);
    const cancelRef = useRef<CancelTokenSource | null>(null);
    const { handleError } = useErrorHandler();
    const [recipients, setRecipients] = useState<MemberList[]>();
    const { height } = useWindowDimensions();

    const getRecipients = useCallback(
        async (orgId: number, inFilter: ReportFilterType): Promise<void> => {
            try {
                !cancelRef.current && (cancelRef.current = axios.CancelToken.source());
                const members = await ReportService.getMemberListAsync(
                    orgId,
                    inFilter,
                    undefined,
                    cancelRef.current.token
                );

                if (cancelRef.current) {
                    setRecipients(members.filter((item) => item.phone && item.phone.length > 0));
                }
            } catch (error) {
                handleError(error, T('Error_OrganizationMemberQueryFailed'));
            }
        },
        [handleError, T]
    );

    useEffect(() => {
        if (show) {
            getRecipients(organizationId, filter);
        }

        return () => {
            cancelRef.current && cancelRef.current.cancel();
            cancelRef.current = null;
        };
    }, [show, organizationId, filter, getRecipients]);

    function renderRow(props: ListChildComponentProps) {
        const { index, style } = props;
        const person = (recipients && recipients[index]) ?? { name: '', phone: '' };

        return (
            <ListItem style={style} key={index} component="div" disablePadding>
                <ListItemText>
                    <Grid container>
                        <Grid item xs={6}>
                            {person.name}
                        </Grid>
                        <Grid item xs={6}>
                            {person.phone}
                        </Grid>
                    </Grid>
                </ListItemText>
            </ListItem>
        );
    }

    if (!recipients) {
        return null;
    }

    return (
        <DialogBase
            show={show}
            title={T('SmsMessage_RecipientListDialogTitle')}
            onOk={() => {
                onClose && onClose();
            }}
            okTitle={T('Common_Close')}
            hideCancel
        >
            <Grid container sx={{ mb: -2 }}>
                <Grid item xs={12}>
                    <FixedSizeList
                        height={height - 270}
                        width={'100%'}
                        itemSize={28}
                        itemCount={recipients.length}
                        overscanCount={5}
                    >
                        {renderRow}
                    </FixedSizeList>
                </Grid>
                <Grid item xs={12} sx={{ mt: 1 }}>
                    <Typography variant="body2" color="secondary">
                        {T('SmsMessage_RecipientListHelpText')}
                    </Typography>
                </Grid>
            </Grid>
        </DialogBase>
    );
};

export default DialogShowRecipients;
