import React, { FC, useContext, useState } from 'react';
import { Grid, Typography } from '@mui/material';
import { LanguageContext } from 'contexts/languageContext';
import { useErrorHandler } from 'components/hooks/useErrorHandler';
import MembershipsService from 'services/membershipsService';
import { Membership, MembershipWithTransferInfo, Person } from 'types/sp-api';
import FormFieldDate from 'components/common/FormFieldDate';
import FormFieldDropDownCodeSet from 'components/common/FormFieldDropDownCodeSet';
import FormFieldText from 'components/common/FormFieldText';
import { useFormikContext } from 'formik';
import LoadButton from 'components/common/LoadButton';
import { useSuccessHandler } from 'components/hooks/useSuccessHandler';
import FormFieldReadOnly from 'components/common/FormFieldReadonly';
import TypeUtils from 'utils/typeHelper';
import { AppLanguage, MemberPrerequisite } from 'types/common';
import { useWarningHandler } from 'components/hooks/useWarningHandler';
import { StatusCodes } from 'http-status-codes';
import MailService from 'services/mailService';
import constants from 'config/constants';
import FormFieldDropDownOrganization from 'components/common/FormFieldDropDownOrganization';
import Divider from '@mui/material/Divider';

interface MembershipFormTransferInProps {
    person: Person;
    membership: MembershipWithTransferInfo;
    acceptMember: () => void;
    allowedEdit: boolean;
}

const MembershipFormTransferIn: FC<MembershipFormTransferInProps> = ({
    person,
    membership,
    acceptMember,
    allowedEdit
}: MembershipFormTransferInProps) => {
    const { T, TExt } = useContext(LanguageContext);
    const { values, dirty, isValid } = useFormikContext<Membership>();
    const [accepting, setAccepting] = useState(false);
    const { handleError } = useErrorHandler();
    const { handleSuccess } = useSuccessHandler();
    const { handleWarning } = useWarningHandler();

    const transferMembershipAccepted = async (newValues: Membership): Promise<void> => {
        setAccepting(true);
        try {
            await MembershipsService.transferMembershipAcceptedAsync(person.id, newValues);

            acceptMember();
            setAccepting(false);
            handleSuccess(T('Membership_TransferAcceptSuccess'));
        } catch (error) {
            setAccepting(false);
            handleError(error, T('Membership_TransferAcceptFailed'));
        }

        try {
            // notify user
            await MailService.sendTransferAcceptedMailAsync(
                person.id ?? 0,
                TExt,
                (person.language as AppLanguage) ?? AppLanguage.Finnish
            );
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (error: any) {
            if (error.response?.status === StatusCodes.NOT_FOUND) {
                handleWarning(T('Membership_TransferAcceptEmailMissing'));
            } else {
                handleError(error, T('Membership_TransferAcceptEmailFailed'));
            }
        }
    };

    return (
        <>
            <Grid item xs={12}>
                <Typography variant="h6">{T('Membership_TransferInTitle')}</Typography>
            </Grid>
            <Grid item xs={12}>
                <FormFieldReadOnly
                    label={T('Membership_FieldSenderOrganization')}
                    value={membership.senderOrganization?.name}
                />
            </Grid>
            <Grid item xs={12}>
                <Divider />
            </Grid>
            <Grid item xs={12}>
                <FormFieldReadOnly label={T('Membership_FieldOrganization')} value={membership.organization?.name} />
            </Grid>
            {membership.organizationId == constants.RegionalCommunity.RegisterChurchId && (
                <Grid item xs={12}>
                    <FormFieldDropDownOrganization
                        name="homeOrganizationId"
                        label={T('Membership_FieldRegisterHomeChurch')}
                        placeholder={T('Membership_SelectRegisterHomeChurch')}
                        showContacts
                    />
                </Grid>
            )}
            {TypeUtils.IsLocalYhdyskunta(membership.organization?.type) && (
                <Grid item xs={12}>
                    <FormFieldDropDownCodeSet
                        name="type"
                        label={T('Membership_FieldMembershipType')}
                        codeSet="membershipType"
                        placeholder={T('Membership_SelectType')}
                        hiddenValues={[6]}
                    />
                </Grid>
            )}
            <Grid item xs={12}>
                <FormFieldDropDownCodeSet
                    name="prerequisite"
                    label={T('Membership_FieldPrerequisite')}
                    codeSet="membershipPrerequisite"
                    placeholder={T('Membership_SelectPrerequisite')}
                />
            </Grid>
            {values.prerequisite == MemberPrerequisite.Recognition && (
                <Grid item xs={12}>
                    <FormFieldText name="prerequisiteText" label={T('Membership_FieldPrerequisiteText')} />
                </Grid>
            )}
            <Grid item xs={6}>
                <FormFieldDate label={T('Membership_FieldBeginDate')} name="beginDate" />
            </Grid>
            <Grid item xs={12} container justifyContent="space-between">
                {allowedEdit && (
                    <Grid item>
                        <LoadButton
                            style={{ marginRight: 8 }}
                            loading={accepting}
                            disabled={!dirty || !isValid}
                            onClick={() => transferMembershipAccepted(values)}
                        >
                            {T('Membership_TransferAcceptedBtn')}
                        </LoadButton>
                    </Grid>
                )}
            </Grid>
        </>
    );
};

export default MembershipFormTransferIn;
