declare global {
    interface Config extends EnvConfig, CommonConfig {}

    interface EnvConfig {
        APP_URL: string;
        API_BASE_URL: string;
        IDP_URL: string;
        IDP_REALM: string;
        IDP_CLIENT_ID: string;
        IDP_CLIENT_SECRET: string;
        KEY_VAULT: string;
        STAGE: string;
        SGID_REGISTRATION: string; // SendGrid template id for registration email
        SGID_RESETPASSWORD: string; // SendGrid template id for reset password email
        SGID_PERSONCONSENTS: string; // SendGrid template id for person consents email
        SGID_GENERIC: string; // SendGrid template id for generic email
        CUSTOMER: string;
        TEST_CD: string;
        LEGACY_REPORT_PASSWORD: string;
    }

    interface CommonConfig {
        NODE_ENV: string;
        PERSON_GUID_HEADER: string;
        ACTION_TOKEN_HEADER: string;
        MaxAttachmentSize: number;
    }
}

const dev: EnvConfig = {
    APP_URL: 'http://localhost:3000',
    API_BASE_URL: 'http://localhost:8080', //'https://sahkopaimen-api-dev.azurewebsites.net' 'http://localhost:8080'
    IDP_URL: 'http://localhost:8083/auth', //'https://sahkopaimen-idp-dev.azurewebsites.net/auth' 'http://localhost:8083/auth'
    IDP_REALM: 'sahkopaimen',
    IDP_CLIENT_ID: 'sahkopaimen-app',
    IDP_CLIENT_SECRET: 'd70a443e-a63c-4137-a603-b0db22be451e',
    KEY_VAULT: 'sp-api-dev',
    STAGE: process.env.REACT_APP_STAGE ?? 'test',
    SGID_REGISTRATION: 'd-0721e62a09a44fda897c6b9f5e3d568c',
    SGID_RESETPASSWORD: 'd-07f7090ea134455799f8064c7dadf10b',
    SGID_PERSONCONSENTS: 'd-bffad80dd7e54959bb8327dba8e787f8',
    SGID_GENERIC: 'd-49fe5c78d7304968883d75b62f99747a',
    CUSTOMER: 'shk',
    TEST_CD: process.env.REACT_APP_TEST_CD ?? 'ei löytynyt',
    LEGACY_REPORT_PASSWORD: 'test123'
};

const prod: EnvConfig = {
    APP_URL: process.env.REACT_APP_APP_URL ?? '',
    API_BASE_URL: process.env.REACT_APP_API_URL ?? '',
    IDP_URL: process.env.REACT_APP_IDP_URL ?? '',
    IDP_REALM: process.env.REACT_APP_IDP_REALM ?? '',
    IDP_CLIENT_ID: process.env.REACT_APP_IDP_CLIENT_ID ?? '',
    IDP_CLIENT_SECRET: process.env.REACT_APP_IDP_CLIENT_SECRET ?? '',
    KEY_VAULT: process.env.REACT_APP_KEY_VAULT ?? '',
    STAGE: process.env.REACT_APP_STAGE ?? 'production',
    SGID_REGISTRATION: process.env.REACT_APP_SGID_REGISTRATION ?? '',
    SGID_RESETPASSWORD: process.env.REACT_APP_SGID_RESETPASSWORD ?? '',
    SGID_PERSONCONSENTS: process.env.REACT_APP_SGID_PERSONCONSENTS ?? '',
    SGID_GENERIC: process.env.REACT_APP_SGID_GENERIC ?? '',
    CUSTOMER: process.env.REACT_APP_CUSTOMER ?? '',
    TEST_CD: process.env.REACT_APP_TEST_CD ?? 'ei löytynyt',
    LEGACY_REPORT_PASSWORD: process.env.REACT_APP_LEGACY_REPORT_PASSWORD ?? ''
};

const common: CommonConfig = {
    NODE_ENV: process.env.NODE_ENV,
    MaxAttachmentSize: 10000000,
    PERSON_GUID_HEADER: 'X-PERSON-TOKEN',
    ACTION_TOKEN_HEADER: 'X-ACTION-TOKEN'
};

const envConfig = process.env.NODE_ENV === 'development' ? dev : prod;
const config: Config = {
    ...common,
    ...envConfig
};

if (process.env.NODE_ENV === 'development') {
    console.log('config: ', config);
}

export default config;
