import React, { FC, ReactNode } from 'react';
import { Box } from '@mui/material';

interface TabPanelProps {
    children?: ReactNode;
    index: number;
    value: number;
    padding?: number;
}

const TabPanel: FC<TabPanelProps> = (props: TabPanelProps) => {
    const { children, value, index, padding, ...other } = props;

    return (
        <div role="tabpanel" hidden={value !== index} id={`tabpanel-${index}`} {...other} className="tab-panel">
            {value === index && <Box padding={!padding ? 5 : padding}>{children}</Box>}
        </div>
    );
};

export default TabPanel;
