import React, { FC, useCallback, useContext, useEffect, useRef, useState } from 'react';
import {
    Box,
    Container,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    SelectChangeEvent,
    Typography
} from '@mui/material';
import { LanguageContext } from 'contexts/languageContext';
import { SmsMessage } from 'types/sp-api';
import axios, { CancelTokenSource } from 'axios';
import { useErrorHandler } from 'components/hooks/useErrorHandler';
import SmsMessagesService from 'services/smsMessagesService';
import SmsMessageItem from './SmsMessageItem';
import { useNavigate, useParams } from 'react-router-dom';
import SmsMessageNewItem from './SmsMessageNewItem';
import SmsMessageList from './SmsMessageList';
import { Mail } from '@mui/icons-material';
import ViewTitle from 'components/common/ViewTitle';
import BreadcrumbBar from 'components/common/BreadcrumbBar';
import { AuthContext } from 'contexts/authContext';
import ApartmentIcon from '@mui/icons-material/Apartment';
import Helper from 'utils/typeHelper';
import { OrganizationType } from 'types/common';

const SmsMessagesView: FC = () => {
    const { T } = useContext(LanguageContext);
    const { user } = useContext(AuthContext);
    const [messageList, setMessageList] = useState<SmsMessage[]>();
    const { handleError } = useErrorHandler();
    const navigate = useNavigate();
    const cancelRef = useRef<CancelTokenSource | null>(null);
    const { messageId } = useParams<'messageId'>();
    const { copyMessageId } = useParams<'copyMessageId'>();
    const [selectedOrganizationId, setSelectedOrganizationId] = useState(user?.homeOrganizationId ?? 0);

    const messageIdNumber = !isNaN(Number(messageId)) ? parseInt(messageId ?? '') : undefined;
    const copyMessageIdNumber = !isNaN(Number(copyMessageId)) ? parseInt(copyMessageId ?? '') : undefined;

    const getSmsMessages = useCallback(
        async (orgId: number): Promise<void> => {
            try {
                cancelRef.current = axios.CancelToken.source();
                const smsmessages = await SmsMessagesService.getAllAsync(orgId, cancelRef.current.token);
                if (cancelRef.current) {
                    smsmessages.sort(listSort);
                    setMessageList(smsmessages);
                }
            } catch (error) {
                handleError(error, T('Error_SmsMessagesQueryFailed'));
            }
        },
        [handleError, T]
    );

    useEffect(() => {
        getSmsMessages(selectedOrganizationId);

        return () => {
            cancelRef.current && cancelRef.current.cancel();
            cancelRef.current = null;
        };
    }, [getSmsMessages, selectedOrganizationId]);

    const getAllSmsMessages = async () => {
        await getSmsMessages(selectedOrganizationId);
    };

    const listSort = (a: SmsMessage, b: SmsMessage): number => {
        // date order then
        if (!a.createdDate || !b.createdDate) return 0;
        if (a.createdDate < b.createdDate) return 1;
        if (a.createdDate > b.createdDate) return -1;

        return 0;
    };

    return (
        <Container>
            <Grid container>
                <BreadcrumbBar />
                <ViewTitle
                    icon={Mail}
                    title={T('SmsMessages_Title')}
                    addButtonTitle={T('SmsMessage_AddNew')}
                    onAddClick={() => {
                        navigate('/communication/smsmessages/new');
                    }}
                />
            </Grid>
            <Grid container spacing={3}>
                {user && user.organizations.length > 1 && (
                    <Grid container item xs={6} spacing={2}>
                        <Grid item xs={12}>
                            <Typography>{T('Report_SelectReportTargetChurch')}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl size="small" variant="outlined" fullWidth>
                                <InputLabel variant="outlined">{T('Report_ReportTargetChurch')}</InputLabel>
                                <Select
                                    variant="outlined"
                                    onChange={(event: SelectChangeEvent<number>) => {
                                        setSelectedOrganizationId(event.target.value as number);
                                    }}
                                    value={selectedOrganizationId}
                                    label={T('Report_ReportTargetChurch')}
                                >
                                    {user?.organizations
                                        .filter((o) => o.type != OrganizationType.ContactInfo)
                                        .map((item, index) => (
                                            <MenuItem key={index} value={item.id}>
                                                {item.name}
                                                {Helper.IsYhdyskunta(item.type) && (
                                                    <ApartmentIcon
                                                        fontSize="small"
                                                        style={{ marginLeft: 8 }}
                                                        titleAccess={T('Report_ReportTargetWholeCommunity')}
                                                    />
                                                )}
                                            </MenuItem>
                                        ))}
                                </Select>
                            </FormControl>
                            <Box m={3} />
                        </Grid>
                    </Grid>
                )}
                <Grid container item xs={7}>
                    {messageId === 'new' && (
                        <SmsMessageNewItem
                            copyMessageId={copyMessageIdNumber}
                            closeMessage={() => navigate('/communication/smsmessages')}
                            updateList={getAllSmsMessages}
                            organizationId={selectedOrganizationId}
                        />
                    )}
                    {messageIdNumber && (
                        <SmsMessageItem
                            organizationId={selectedOrganizationId}
                            messageId={messageIdNumber ?? 0}
                            closeMessage={() => navigate('/communication/smsmessages')}
                            updateList={getAllSmsMessages}
                        />
                    )}
                </Grid>
                <Grid item xs={5} alignContent="flex-start">
                    <Paper elevation={3}>
                        <SmsMessageList messageList={messageList} selectedId={messageIdNumber} />
                    </Paper>
                </Grid>
            </Grid>
        </Container>
    );
};

export default SmsMessagesView;
