import React, { useCallback } from 'react';
import { useSnackbar } from 'notistack';
import WarningToast from 'components/common/WarningToast';

type warningHandlerFunc = (title: string, message?: string, key?: string) => void;

export interface WarningHandlerContext {
    handleWarning: warningHandlerFunc;
}

export const useWarningHandler = (): WarningHandlerContext => {
    const { enqueueSnackbar } = useSnackbar();

    const handleWarningInternal = (title: string, message?: string, key?: string) => {
        enqueueSnackbar(<WarningToast title={title} message={message} />, {
            variant: 'warning',
            preventDuplicate: true,
            key: key,
            autoHideDuration: 7000
        });
    };

    return { handleWarning: useCallback(handleWarningInternal, []) };
};
