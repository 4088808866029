import React, { FC } from 'react';
import { TextField } from '@mui/material';
import { useField } from 'formik';

interface FormFieldTextProps {
    label: string;
    name: string;
    readonly?: boolean;
    helperText?: string;
    autoFocus?: boolean;
    rows?: number;
    rowsMax?: number;
}

const FormFieldText: FC<FormFieldTextProps> = ({
    label,
    name,
    readonly,
    helperText,
    autoFocus,
    rows,
    rowsMax
}: FormFieldTextProps) => {
    const [field, meta] = useField(name);

    return (
        <TextField
            id={name}
            label={label}
            error={!!meta.error && meta.touched}
            helperText={(!!meta.error && meta.touched && meta.error) ?? helperText}
            fullWidth
            value={field.value ?? ''}
            onBlur={field.onBlur}
            onChange={field.onChange}
            name={field.name}
            disabled={readonly}
            variant="outlined"
            size="small"
            autoFocus={autoFocus}
            minRows={rows}
            maxRows={rowsMax}
            multiline
        />
    );
};

export default FormFieldText;
