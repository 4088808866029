import React, { FC, Fragment, ReactNode, useCallback, useContext, useEffect, useRef, useState } from 'react';
import {
    Box,
    Container,
    Divider,
    Grid,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Paper,
    Typography
} from '@mui/material';
import { Notifications as NotificationIcon } from '@mui/icons-material';
import { LanguageContext } from 'contexts/languageContext';
import BreadcrumbBar from 'components/common/BreadcrumbBar';
import ViewTitle from 'components/common/ViewTitle';
import { Notification } from 'types/sp-api';
import axios, { CancelTokenSource } from 'axios';
import { useErrorHandler } from 'components/hooks/useErrorHandler';
import NotificationsService from 'services/notificationsService';
import classnames from 'classnames';
import WaitScreen from 'components/common/WaitScreen';
import LightTooltip from 'components/common/LightTooltip';
import moment from 'moment';
import NotificationItem from './NotificationItem';
import { useNavigate, useParams } from 'react-router-dom';
import { NotificationsContext } from 'contexts/notificationsContext';

const NotificationsView: FC = () => {
    const { T } = useContext(LanguageContext);
    const [notificationList, setNotificationList] = useState<Notification[]>();
    const { handleError } = useErrorHandler();
    const navigate = useNavigate();
    const { setNotificationCount } = useContext(NotificationsContext);
    const cancelRef = useRef<CancelTokenSource | null>(null);
    const { notificationId } = useParams<'notificationId'>();

    const getNotifications = useCallback(async (): Promise<void> => {
        try {
            cancelRef.current = axios.CancelToken.source();
            const notifications = await NotificationsService.getAllAsync(cancelRef.current.token);
            if (cancelRef.current) {
                notifications.sort(listSort);
                setNotificationList(notifications);
            }
        } catch (error) {
            handleError(error, T('Error_NotificationsQueryFailed'));
        }
    }, [handleError, T, setNotificationCount]);

    useEffect(() => {
        getNotifications();

        return () => {
            cancelRef.current && cancelRef.current.cancel();
            cancelRef.current = null;
        };
    }, [getNotifications]);

    const getUpdatedCount = useCallback(async (): Promise<void> => {
        try {
            cancelRef.current = axios.CancelToken.source();
            const count = await NotificationsService.getUnreadCountAsync(cancelRef.current.token);
            if (cancelRef.current) {
                setNotificationCount(count);
            }
        } catch (error) {
            handleError(error, T('Error_SecureMessagesQueryFailed'));
        }
    }, [handleError, T]);

    const listSort = (a: Notification, b: Notification): number => {
        // unreaded first
        if (a.unread > b.unread) return -1;
        if (a.unread < b.unread) return 1;

        // date order then
        if (!a.createdDate || !b.createdDate) return 0;
        if (a.createdDate < b.createdDate) return 1;
        if (a.createdDate > b.createdDate) return -1;

        return 0;
    };

    const updateNotifications = async () => {
        await getNotifications();
        await getUpdatedCount();
    };

    return (
        <Container>
            <Grid container>
                <BreadcrumbBar />
                <ViewTitle title={T('Common_Notifications')} icon={NotificationIcon} />
            </Grid>
            <Grid container spacing={3}>
                <Grid item xs={5}>
                    <Paper elevation={3}>
                        {!notificationList && <WaitScreen />}
                        {notificationList && notificationList.length > 0 && (
                            <List>
                                {notificationList.map(
                                    (item, index): ReactNode => (
                                        <Fragment key={index}>
                                            {index > 0 && <Divider variant="middle" component="li" />}
                                            <ListItem
                                                className={classnames(
                                                    { 'selected-row': notificationId === item.id?.toString() },
                                                    'pointer'
                                                )}
                                                onClick={() => navigate(`/notifications/${item.id}`)}
                                            >
                                                <ListItemIcon sx={{ minWidth: '45px' }}>
                                                    {item.unread && (
                                                        <LightTooltip title={T('Notifications_Unread')}>
                                                            <NotificationIcon color="primary" />
                                                        </LightTooltip>
                                                    )}
                                                </ListItemIcon>
                                                <ListItemText
                                                    disableTypography
                                                    primary={moment(item.createdDate).format('l')}
                                                    sx={
                                                        item.unread
                                                            ? {
                                                                  minWidth: '100px',
                                                                  maxWidth: '100px',
                                                                  fontWeight: 600
                                                              }
                                                            : { minWidth: '100px', maxWidth: '100px' }
                                                    }
                                                />
                                                <ListItemText
                                                    disableTypography
                                                    primary={item.title}
                                                    sx={item.unread ? { fontWeight: 600 } : undefined}
                                                />
                                            </ListItem>
                                        </Fragment>
                                    )
                                )}
                            </List>
                        )}
                        {notificationList && notificationList.length === 0 && (
                            <Box p={2}>
                                <Typography variant="body1" color="textSecondary">
                                    {T('Notifications_NoNotifications')}
                                </Typography>
                            </Box>
                        )}
                    </Paper>
                </Grid>
                <Grid container item xs={7}>
                    <NotificationItem
                        notificationId={parseInt(notificationId ?? '')}
                        closeNotification={() => navigate('/notifications')}
                        updateList={updateNotifications}
                    />
                </Grid>
            </Grid>
        </Container>
    );
};

export default NotificationsView;
