import React, { FC, useContext } from 'react';
import { Grid, Paper, Divider, Box, Typography, Link } from '@mui/material';
import DialogBase from 'components/common/DialogBase';
import FormFieldSwitch from 'components/common/FormFieldSwitch';
import TermsAndConditionsText from './TermsAndConditionsText';
import GetAppIcon from '@mui/icons-material/GetApp';
import { LanguageContext } from 'contexts/languageContext';
import config from 'config/config';

interface DialogTermsAndConditionsProps {
    show: boolean;
    onOk: () => void;
    onClose: () => void;
    disabled: boolean;
}

const DialogTermsAndConditions: FC<DialogTermsAndConditionsProps> = ({
    show,
    onOk,
    onClose,
    disabled
}: DialogTermsAndConditionsProps) => {
    const { T } = useContext(LanguageContext);
    const kayttoehdot = `/documents/kayttoehdot_${config.CUSTOMER}.pdf`;

    return (
        <DialogBase
            show={show}
            title={T('Registration_TermsDialogTitle')}
            titleHelpText={'15.6.2021'}
            onOk={onOk}
            onClose={onClose}
            okDisabled={disabled}
            okTitle={T('Common_Agree')}
            hideCancel
            size="md"
            style={{ height: '100vh' }}
        >
            <Paper
                elevation={0}
                style={{
                    paddingTop: 8,
                    paddingLeft: 8,
                    paddingRight: 8,
                    paddingBottom: 0,
                    height: '45vh',
                    overflowY: 'auto',
                    overflowX: 'hidden'
                }}
            >
                <Grid container>
                    <Grid item xs={12}>
                        <TermsAndConditionsText />
                        <Box m={2} />
                    </Grid>
                    <Grid item xs={12}>
                        <Divider />
                    </Grid>
                    <Grid item xs={12}>
                        <Box marginTop={2} marginBottom={2}>
                            <Typography variant="body2" color="textSecondary">
                                {T('Registration_DownloadEula') + ' '}
                                <Link href={kayttoehdot} download>
                                    {T('Registration_Eula')}{' '}
                                    <GetAppIcon fontSize="small" style={{ marginBottom: -5 }} />
                                </Link>
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider />
                        <Box m={2} />
                    </Grid>
                    <Grid item xs={9}>
                        <Typography variant="body1">{T('Registration_PasswordAgreement')}</Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <FormFieldSwitch name="passwordAgreement" label={''} />
                    </Grid>
                    <Grid item xs={9}>
                        <Typography variant="body1">{T('Registration_TermsAndConditionsAccepted')}</Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <FormFieldSwitch name="termsAccepted" label={''} />
                        <Box m={5} />
                    </Grid>
                </Grid>
            </Paper>
        </DialogBase>
    );
};

export default DialogTermsAndConditions;
