import axios, { CancelToken } from 'axios';
import { Membership, Person, PersonWithMembership } from 'types/sp-api';
import PersonUtils from 'utils/personHelper';

class ArchivesService {
    public async getPersonsAsync(filter?: string, cancelToken?: CancelToken): Promise<PersonWithMembership[]> {
        const params = PersonUtils.CreatePersonQuery(filter);

        const response = await axios.get(`archive/persons`, {
            params: params,
            cancelToken: cancelToken
        });
        return response.data;
    }

    public async getPersonAsync(id: number, cancelToken?: CancelToken): Promise<Person> {
        const response = await axios.get(`archive/persons/${id}`, { cancelToken: cancelToken });
        return response.data;
    }

    public async getPersonMembershipsAsync(id?: number, cancelToken?: CancelToken): Promise<Membership[]> {
        if (!id) return [];

        const response = await axios.get(`archive/persons/${id}/memberships?history=true`, {
            cancelToken: cancelToken
        });
        return response.data;
    }
}

export default new ArchivesService();
