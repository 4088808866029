import axios from 'axios';
import moment from 'moment';
import { AuthenticatedUser } from 'types/auth';
import { AuditInfoType } from 'types/common';
import { AuditInfo, KeyValue } from 'types/sp-api';

class AuditService {
    public async sendAuditAsync(
        message: string,
        url: string,
        data?: KeyValue[],
        user?: AuthenticatedUser
    ): Promise<void> {
        const entries = [];
        entries.push(['url', url]);

        if (data) {
            data.map((item) => entries.push([item.key, item.value]));
        }

        const audit: AuditInfo = {
            guid: '',
            ipAddress: '',
            operation: 'ui event',
            direction: 'I',
            message: message,
            userId: user?.userId,
            personId: user?.personId,
            type: AuditInfoType.UI,
            timestamp: moment().utc().toDate(),
            data: Object.fromEntries(entries)
        };
        await axios.post(`audit/`, audit);
    }
}

export default new AuditService();
