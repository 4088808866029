import React, { ChangeEvent, FC, useContext, useEffect, useState } from 'react';
import { Container, Grid, Paper, Tab, Tabs } from '@mui/material';
import { Settings } from '@mui/icons-material';
import BreadcrumbBar from '../common/BreadcrumbBar';
import ViewTitle from '../common/ViewTitle';
import { LanguageContext } from 'contexts/languageContext';
import TabPanel from 'components/common/TabPanel';
import { TabItem } from 'types/common';
import { AuthContext } from 'contexts/authContext';
import SettingsTabMemberTagTitles from './SettingsTabMemberTagTitles';
import SettingsTabSmsSender from './SettingsTabSmsSender';
import { useParams } from 'react-router-dom';
import SettingsTabCommunityTagTitles from './SettingsTabCommunityTagTitles';

const SettingsView: FC = () => {
    const { page } = useParams<'page'>();
    const [selectedTab, setSelectedTab] = useState(page === 'smsSender' ? 1 : 0);
    const { T } = useContext(LanguageContext);
    const { user, isAdmin } = useContext(AuthContext);

    const handleChange = (_: ChangeEvent<unknown>, newValue: number) => {
        setSelectedTab(newValue);
    };

    const tabs: TabItem[] = [
        {
            id: 0,
            title: 'MemberTagTitles',
            hidden: false,
            content: <SettingsTabMemberTagTitles organizationId={user?.homeOrganizationId ?? 0} />
        },
        {
            id: 1,
            title: 'CommunityTagTitles',
            hidden: !isAdmin(),
            content: <SettingsTabCommunityTagTitles />
        },
        {
            id: 2,
            title: 'SmsSender',
            hidden: false,
            content: <SettingsTabSmsSender organizationId={user?.homeOrganizationId ?? 0} />
        }
    ];

    useEffect(() => {
        let tab;
        switch (page) {
            case 'smsSender':
                tab = 2;
                break;
            case 'communityTagTitles':
                tab = 1;
                break;
            case 'memberTagTitles':
            default:
                tab = 0;
                break;
        }
        setSelectedTab(tab);
    }, [page]);

    return (
        <Container>
            <Grid container>
                <BreadcrumbBar />
                <ViewTitle title={T('Common_Settings')} icon={Settings} />
            </Grid>
            <Paper elevation={3}>
                <Tabs
                    value={selectedTab}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="scrollable"
                    scrollButtons="auto"
                    onChange={handleChange}
                >
                    {tabs
                        .filter((tab) => !tab.hidden)
                        .map((item) => (
                            <Tab label={T(`SettingsTab_${item.title}`)} key={item.id} value={item.id} />
                        ))}
                </Tabs>
                {tabs.map((item) => (
                    <TabPanel key={item.id} value={selectedTab} index={item.id}>
                        {item.content}
                    </TabPanel>
                ))}
            </Paper>
        </Container>
    );
};

export default SettingsView;
