import React, { FC, useContext } from 'react';
import { Box } from '@mui/material';
import CookieConsent from 'react-cookie-consent';
import { LanguageContext } from 'contexts/languageContext';
import { Link } from 'react-router-dom';
import LunchBack from 'assets/images/lunch_back_transparent.png';

const AppCookieConsent: FC = () => {
    const { T } = useContext(LanguageContext);
    return (
        <CookieConsent
            location="bottom"
            cookieName="sp-cookie-consent"
            style={{ background: 'silver', color: '#000000' }}
            disableButtonStyles
            buttonClasses="cookie_consent_btn"
            buttonStyle={{ marginRight: 20 }}
            buttonText={T('Common_CookieConsentButton')}
        >
            <Box p={2}>
                <img className="logo-consent" src={LunchBack} alt="logo" />
                {T('Common_CookieConsentMessage')} <Link to="/cookiePolicy">{T('Common_CookiePolicy')}</Link>
            </Box>
        </CookieConsent>
    );
};

export default AppCookieConsent;
