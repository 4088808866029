import React, { FC, useContext } from 'react';
import { Box, Button, Card, CardContent, CardHeader, Grid, Typography } from '@mui/material';
import { LanguageContext } from 'contexts/languageContext';
import { useCookies } from 'react-cookie';
import CloseIcon from '@mui/icons-material/Close';

const DataProtectionNotice: FC = () => {
    const cookieName = 'sp-data-protection-notice';
    const { T } = useContext(LanguageContext);
    const [cookies, setCookie] = useCookies([cookieName]);

    const enableClose = false;

    if (enableClose && cookies[cookieName]) {
        return null;
    }

    return (
        <Grid item xs={12}>
            <Card>
                <CardHeader
                    className="card-notice"
                    action={
                        enableClose ? (
                            <Button
                                variant="contained"
                                color="primary"
                                style={{ marginTop: 5 }}
                                startIcon={<CloseIcon />}
                                onClick={() => setCookie(cookieName, true)}
                            >
                                <Typography className="card-notice-button">{T('Asia selvä')}</Typography>
                            </Button>
                        ) : undefined
                    }
                    title={<Typography variant="h5">{T('Report_DataProtectionNotice')}</Typography>}
                />
                <CardContent className="card-notice-text">
                    <Typography variant="body1">{T('Report_DataProtectionNoticeText')}</Typography>
                </CardContent>
            </Card>
            <Box m={2} />
        </Grid>
    );
};

export default DataProtectionNotice;
