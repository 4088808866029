import React, { FC, useContext } from 'react';
import { Card, CardContent, Grid, Typography } from '@mui/material';
import { LanguageContext } from 'contexts/languageContext';
import FormFieldDropDown from 'components/common/FormFieldDropDown';
import { KeyValue } from 'types/sp-api';

interface ReportOrderProps {
    name: string;
}

const ReportOrder: FC<ReportOrderProps> = ({ name }: ReportOrderProps) => {
    const { T } = useContext(LanguageContext);

    const orders: KeyValue[] = ['name', 'age', 'zipcode', 'birthday'].map((item) => ({
        key: item,
        value: `Report_Order_${item}`
    }));

    return (
        <Card>
            <CardContent>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Typography color="primary" variant="h6">
                            {T('Report_OrderTitle')}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <FormFieldDropDown name={name} label={T('Report_SelectOrder')} values={orders} />
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

export default ReportOrder;
