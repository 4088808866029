import React, { FC, ReactNode } from 'react';
import { Switch, FormControlLabel, FormControl, FormHelperText } from '@mui/material';
import { useField } from 'formik';

interface FormFieldSwitchProps {
    label: string | ReactNode;
    name: string;
    readonly?: boolean;
    autoFocus?: boolean;
}

const FormFieldSwitch: FC<FormFieldSwitchProps> = ({ label, name, readonly, autoFocus }: FormFieldSwitchProps) => {
    const [field, meta, helpers] = useField(name);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        field.onChange(event);
        helpers.setValue(event.target.checked);
    };

    return (
        <FormControl error={!!meta.error}>
            <FormControlLabel
                id={field.name}
                control={
                    <Switch
                        checked={field.value}
                        onChange={handleChange}
                        autoFocus={autoFocus}
                        name={field.name}
                        onBlur={field.onBlur}
                        color="primary"
                    />
                }
                label={label}
                disabled={readonly}
            />
            {meta.error && <FormHelperText>{meta.error}</FormHelperText>}
        </FormControl>
    );
};

export default FormFieldSwitch;
