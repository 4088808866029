import React, { FC, useContext, useState } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import LoadButton from 'components/common/LoadButton';
import LastModified from 'components/common/LastModified';
import moment from 'moment';
import { LanguageContext, LanguageTextFunc } from 'contexts/languageContext';
import { Form, Formik, FormikProps } from 'formik';
import FormFieldSwitch from 'components/common/FormFieldSwitch';
import { Person } from 'types/sp-api';
import { ConsentList } from 'types/common';
import * as Yup from 'yup';

const getValidationSchema = (T: LanguageTextFunc) => {
    return Yup.object().shape({
        someConsent: Yup.bool().test('not-0-value', T('Common_Required'), (value) => value !== null)
    });
};

interface PersonConsentsGridProps {
    person?: Person;
    insideTab?: boolean;
    personId?: number;
    validationToken?: string;
}

export const PersonConsentsGrid: FC<PersonConsentsGridProps> = ({ person, insideTab }: PersonConsentsGridProps) => {
    const [sending, setSending] = useState(false);
    const { T } = useContext(LanguageContext);

    const initialValue: ConsentList = { nameConsent: true, phoneConsent: true, addressConsent: false };

    const saveConsentList = async (values: ConsentList): Promise<void> => {
        setSending(true);

        try {
            // TODO tallennus, jos saa???
            values;
            person;
            //await ChurchesService.saveChurchAsync(values);
            //updateChurch();
            setSending(false);
            //handleSuccess(T('Church_SaveSuccess'));
        } catch (error) {
            setSending(false);
            //handleError(error, T('Church_SaveFailed'));
        }
    };

    return (
        <Formik
            initialValues={initialValue}
            enableReinitialize
            onSubmit={saveConsentList}
            validationSchema={getValidationSchema(T)}
        >
            {({ dirty, isValid }: FormikProps<ConsentList>) => {
                return (
                    <Form>
                        <Grid container spacing={10} alignItems="flex-start">
                            <Grid container item xs={6} spacing={3}>
                                <Grid item xs={12}>
                                    <FormFieldSwitch name="nameConsent" label={T('PersonConsent_FieldPublicName')} />
                                    <Typography variant="body2" color="textSecondary">
                                        {T('PersonConsent_FieldPublicNameInfo')}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormFieldSwitch
                                        name="addressConsent"
                                        label={T('PersonConsent_FieldPublicAddress')}
                                    />
                                    <Typography variant="body2" color="textSecondary">
                                        {T('PersonConsent_FieldPublicAddressInfo')}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormFieldSwitch name="phoneConsent" label={T('PersonConsent_FieldPublicPhone')} />
                                    <Typography variant="body2" color="textSecondary">
                                        {T('PersonConsent_FieldPublicPhoneInfo')}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormFieldSwitch name="emailConsent" label={T('PersonConsent_FieldPublicEmail')} />
                                    <Typography variant="body2" color="textSecondary">
                                        {T('PersonConsent_FieldPublicEmailInfo')}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <LoadButton loading={sending} disabled={!dirty || !isValid} type="submit">
                                        {T('Common_Save')}
                                    </LoadButton>
                                    <Box margin={2} />
                                    {!insideTab && <LastModified by={'Pekka Puupää'} date={moment().format()} />}
                                </Grid>
                            </Grid>
                            <Grid container item xs={6} spacing={3}>
                                <Grid item xs={12}>
                                    <FormFieldSwitch name="tagConsent" label={T('PersonConsent_FieldTag')} />
                                    <Typography variant="body2" color="textSecondary">
                                        {T('PersonConsent_FieldTagInfo')}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormFieldSwitch name="proxiesConsent" label={T('PersonConsent_FieldProxies')} />
                                    <Typography variant="body2" color="textSecondary">
                                        {T('PersonConsent_FieldProxiesInfo')}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Form>
                );
            }}
        </Formik>
    );
};
