import axios, { CancelToken } from 'axios';
import { ActionTokenInput, Role, RoleId, User, UserOrganization, UserOtpInfo } from 'types/sp-api';

class UsersService {
    public async getAllUsersAsync(cancelToken?: CancelToken): Promise<User[]> {
        const response = await axios.get<User[]>(`users/`, { cancelToken: cancelToken });

        const sortUsers = (a: User, b: User): number => {
            if (!a.person || !b.person || !a.organizations || !b.organizations) return 0;

            // order first organization name
            if (a.organizations[0].name > b.organizations[0].name) return 1;
            if (a.organizations[0].name < b.organizations[0].name) return -1;

            // then role
            if (a.roleId > b.roleId) return 1;
            if (a.roleId < b.roleId) return -1;

            // then person's name
            if (a.person.lastName > b.person.lastName) return 1;
            if (a.person.lastName < b.person.lastName) return -1;

            return 0;
        };

        return response.data.sort(sortUsers);
    }

    public async getUserAsync(id: number, cancelToken?: CancelToken): Promise<User> {
        const response = await axios.get(`users/${id}`, { cancelToken: cancelToken });
        return response.data;
    }

    public async saveUserAsync(user: User): Promise<void> {
        if (user.id && user.id > 0) {
            await axios.put(`users/${user.id}`, user);
        } else {
            await axios.post(`users/`, user);
        }
    }

    public async deleteUserAsync(userId?: number): Promise<void> {
        if (!userId) return;

        await axios.delete(`users/${userId}`);
    }

    public async getUserOrganizationsAsync(userId?: number, cancelToken?: CancelToken): Promise<UserOrganization[]> {
        if (!userId) return [];

        const response = await axios.get(`users/${userId}/organizations`, { cancelToken: cancelToken });
        return response.data;
    }

    public async saveUserOrganizationAsync(userId?: number, organization?: UserOrganization): Promise<void> {
        if (!userId) return;

        await axios.post(`users/${userId}/organizations`, organization);
    }

    public async deleteUserOrganizationAsync(userId?: number, organizationId?: number): Promise<void> {
        if (!userId || !organizationId) return;

        await axios.delete(`users/${userId}/organizations/${organizationId}`);
    }

    public async getUserRoleAsync(userId?: number, cancelToken?: CancelToken): Promise<Role | undefined> {
        if (!userId) return undefined;

        const response = await axios.get(`users/${userId}/role`, { cancelToken: cancelToken });
        return response.data;
    }

    public async saveUserRoleAsync(userId?: number, roleId?: number, cancelToken?: CancelToken): Promise<void> {
        if (!userId || !roleId) return;

        const data: RoleId = { roleId: roleId };
        return axios.put<RoleId, void>(`users/${userId}/role`, data, { cancelToken: cancelToken });
    }

    public async saveUserActionTokenAsync(userId: number, actionToken: string): Promise<void> {
        const data: ActionTokenInput = { actionToken: actionToken };
        await axios.post(`users/${userId}/actionToken`, data);
    }

    public async getUserOtpInfoAsync(cancelToken?: CancelToken): Promise<UserOtpInfo> {
        const response = await axios.get(`users/otpInfo`, { cancelToken: cancelToken });
        return response.data;
    }
}

export default new UsersService();
