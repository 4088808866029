import React, { FC, useState } from 'react';
import { Container, Grid, Paper } from '@mui/material';
import { LoginContext, LoginPhase } from 'types/auth';
import LoginPhaseOtp from './LoginPhaseOtp';
import LoginPhaseLogin from './LoginPhaseLogin';

const LoginView: FC = () => {
    const [loginCtx, setLoginCtx] = useState<LoginContext>({
        username: '',
        password: '',
        otp: '',
        phase: LoginPhase.Login
    });

    return (
        <Container>
            <Grid container justifyContent="center" alignItems="center">
                <Grid container justifyContent="center" item>
                    <Paper style={{ margin: 140, padding: 16 }}>
                        {loginCtx.phase === LoginPhase.Login && (
                            <LoginPhaseLogin loginCtx={loginCtx} setLoginCtx={setLoginCtx} />
                        )}
                        {loginCtx.phase === LoginPhase.Otp && <LoginPhaseOtp loginCtx={loginCtx} />}
                    </Paper>
                </Grid>
            </Grid>
        </Container>
    );
};

export default LoginView;
