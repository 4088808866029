import axios, { AxiosResponse, CancelToken } from 'axios';
import { UserRole } from 'types/auth';
import { MemberPosition, Membership, Organization, Document, Contract, User, CoOperationArea } from 'types/sp-api';
import PersonUtils from 'utils/personHelper';
import Utils from 'utils/typeHelper';

class OrganizationsService {
    public async getOrganizationAsync(id: number): Promise<Organization> {
        const response = await axios.get(`organizations/${id}`);
        return response.data;
    }

    public async saveOrganizationAsync(org: Organization): Promise<void> {
        if (org.id && org.id > 0) {
            await axios.put(`organizations/${org.id}`, org);
        } else {
            org.id = undefined;
            await axios.post(`organizations/`, org);
        }
    }

    public async getMembersAsync(
        organizationId?: number,
        filter?: string,
        extendedResults?: boolean,
        cancelToken?: CancelToken,
        isAdmin?: boolean
    ): Promise<Membership[]> {
        if (!organizationId) return [];

        const params = PersonUtils.CreatePersonQuery(filter, true, isAdmin ? undefined : organizationId);
        if (extendedResults) {
            params?.append('showExtended', 'true');
        }

        const url = `organizations/${organizationId}/members`;
        const response = await axios.get(url, {
            params: params,
            cancelToken: cancelToken
        });
        return response.data;
    }

    /* Positions */

    public async getOrganizationPositionsAsync(
        organizationId: number,
        cancelToken?: CancelToken
    ): Promise<MemberPosition[]> {
        const response = await axios.get(`organizations/${organizationId}/positions`, { cancelToken: cancelToken });
        return response.data;
    }

    public async savePositionAsync(organizationId: number, memberPosition: MemberPosition): Promise<void> {
        const baseUrl = this.createPositionsBaseUrl(organizationId, memberPosition);

        if (memberPosition?.id && memberPosition.id > 0) {
            await axios.put(`${baseUrl}/${memberPosition.id}`, memberPosition);
        } else {
            await axios.post(`${baseUrl}`, memberPosition);
        }
    }

    public async deletePositionAsync(organizationId: number, memberPosition: MemberPosition): Promise<void> {
        const baseUrl = this.createPositionsBaseUrl(organizationId, memberPosition);

        await axios.delete(`${baseUrl}/${memberPosition.id}`);
    }

    private createPositionsBaseUrl(organizationId: number, memberPosition: MemberPosition) {
        const membershipId = memberPosition.membershipId;
        return `organizations/${organizationId}/members/${membershipId}/positions`;
    }

    /* Documents */

    public async getDocumentsAsync(organizationId: number, cancelToken?: CancelToken): Promise<Document[]> {
        const response = await axios.get(`organizations/${organizationId}/contracts`, { cancelToken: cancelToken });
        return response.data;
    }

    public async getDocumentAsync(
        organizationId: number,
        documentId: number,
        cancelToken?: CancelToken
    ): Promise<Document> {
        const response = await axios.get(`organizations/${organizationId}/documents/${documentId}`, {
            cancelToken: cancelToken
        });
        return response.data;
    }

    public async saveDocumentAsync(organizationId: number, document: Document): Promise<void> {
        if (document?.id && document.id > 0) {
            await axios.put(`organizations/${organizationId}/documents/${document.id}`, document);
        } else {
            await axios.post(`organizations/${organizationId}/documents`, document);
        }
    }

    public async deleteDocumentAsync(organizationId: number, documentId?: number): Promise<void> {
        if (!documentId) return;

        await axios.delete(`organizations/${organizationId}/documents/${documentId}`);
    }

    public async getDocumentFileAsync(
        organizationId: number,
        documentId: number,
        cancelToken?: CancelToken
    ): Promise<AxiosResponse> {
        return axios.get(`organizations/${organizationId}/documents/${documentId}/file`, {
            responseType: 'blob',
            cancelToken: cancelToken
        });
    }

    public async uploadDocumentFileAsync(
        organizationId: number,
        documentId: number,
        file: File,
        cancelToken?: CancelToken
    ): Promise<AxiosResponse> {
        const formData = new FormData();
        formData.append('file', file);

        return axios.post(`organizations/${organizationId}/documents/${documentId}/file`, formData, {
            headers: { 'content-type': 'multipart/form-data' },
            cancelToken: cancelToken
        });
    }

    /* Contracts */

    public async getContractsAsync(organizationId: number, cancelToken?: CancelToken): Promise<Contract[]> {
        const response = await axios.get(`organizations/${organizationId}/contracts`, { cancelToken: cancelToken });
        return response.data;
    }

    public async getContractAsync(
        organizationId: number,
        contractId: number,
        cancelToken?: CancelToken
    ): Promise<Contract> {
        const response = await axios.get(`organizations/${organizationId}/contracts/${contractId}`, {
            cancelToken: cancelToken
        });
        return response.data;
    }

    public async saveContractAsync(
        organizationId: number,
        contract: Contract,
        file?: File,
        cancelToken?: CancelToken
    ): Promise<void> {
        if (contract?.id && contract.id > 0) {
            await axios.put(`organizations/${organizationId}/contracts/${contract.id}`, contract);
        } else {
            const response = await axios.post(`organizations/${organizationId}/contracts`, contract);
            if (file) {
                const responseContract: Contract = response.data;
                await this.uploadContractFileAsync(organizationId, responseContract.id, file, cancelToken);
            }
        }
    }

    public async deleteContractAsync(organizationId: number, contractId?: number): Promise<void> {
        if (!contractId) return;

        await axios.delete(`organizations/${organizationId}/contracts/${contractId}`);
    }

    public async getContractFileAsync(
        organizationId: number,
        contractId: number,
        cancelToken?: CancelToken
    ): Promise<AxiosResponse> {
        return axios.get(`organizations/${organizationId}/contracts/${contractId}/file`, {
            responseType: 'blob',
            cancelToken: cancelToken
        });
    }

    public async uploadContractFileAsync(
        organizationId: number,
        contractId?: number,
        file?: File,
        cancelToken?: CancelToken
    ): Promise<void> {
        if (!contractId || !file) return;

        const formData = new FormData();
        formData.append('file', file);

        await axios.post(`organizations/${organizationId}/contracts/${contractId}/file`, formData, {
            headers: { 'content-type': 'multipart/form-data' },
            cancelToken: cancelToken
        });
    }

    public async getOrganizationMainUsersAsync(id: number, cancelToken: CancelToken) {
        const params = new URLSearchParams();
        params.append('query', `organizationId==${id};user.roleId==${Utils.RoleToId(UserRole.SuperUser)}`);

        const response = await axios.get<User[]>(`users/`, {
            params: params,
            cancelToken: cancelToken
        });

        return response.data;
    }

    public async getCoOperationAreasAsync(cancelToken: CancelToken): Promise<CoOperationArea[]> {
        const response = await axios.get(`organizations/cooperationAreas`, { cancelToken: cancelToken });
        return response.data;
    }
}

export default new OrganizationsService();
