import React, { useState, useEffect, FC } from 'react';
import { Backdrop, CircularProgress } from '@mui/material';

// Wait delay (in ms) before spinner is shown
const SPINNER_DELAY = 250;

interface WaitScreenProps {
    backdrop?: boolean;
}

const WaitScreen: FC<WaitScreenProps> = ({ backdrop }: WaitScreenProps) => {
    const [showSpinner, setShowSpinner] = useState(false);

    useEffect(() => {
        const enableSpinner = (): void => {
            setShowSpinner(true);
        };

        const timer = setTimeout(enableSpinner, SPINNER_DELAY);

        return (): void => {
            clearTimeout(timer);
        };
    }, []);

    if (showSpinner) {
        if (backdrop) {
            return (
                <Backdrop
                    sx={{ zIndex: 'drawer + 1', color: '#fff' }}
                    open={showSpinner}
                    onClick={(): void => setShowSpinner(false)}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
            );
        } else {
            return <CircularProgress color="inherit" />;
        }
    }

    return null;
};

export default WaitScreen;
