import React, { FC, useContext } from 'react';
import { Card, CardContent, Typography } from '@mui/material';
import { LanguageContext } from 'contexts/languageContext';
import FormFieldCheckbox from 'components/common/FormFieldCheckbox';

interface ReportIncludeProps {
    name: string;
}

const ReportInclude: FC<ReportIncludeProps> = ({ name }: ReportIncludeProps) => {
    const { T } = useContext(LanguageContext);

    return (
        <Card>
            <CardContent>
                <Typography color="primary" variant="h6" gutterBottom>
                    {T('Report_IncludeTitle')}
                </Typography>
                <FormFieldCheckbox name={`${name}.phone`} label={T('Report_IncludePhone')} />
                <br />
                <FormFieldCheckbox name={`${name}.email`} label={T('Report_IncludeEmail')} />
                <br />
                <FormFieldCheckbox name={`${name}.birthDate`} label={T('Report_IncludeBirthday')} />
                <br />
                <FormFieldCheckbox name={`${name}.age`} label={T('Report_IncludeAge')} />
            </CardContent>
        </Card>
    );
};

export default ReportInclude;
