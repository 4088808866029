import React, { FC, useCallback, useContext, useEffect, useRef, useState } from 'react';
import {
    Button,
    ButtonGroup,
    ClickAwayListener,
    Container,
    Grid,
    Grow,
    MenuItem,
    MenuList,
    Paper,
    Popper
} from '@mui/material';
import { LanguageContext } from 'contexts/languageContext';
import BadgeIcon from '@mui/icons-material/Badge';
import BreadcrumbBar from 'components/common/BreadcrumbBar';
import ViewTitle from 'components/common/ViewTitle';
import ViewSearchBar from 'components/common/ViewSearchBar';
import axios, { AxiosResponse, CancelTokenSource } from 'axios';
import { useErrorHandler } from 'components/hooks/useErrorHandler';
import ReportService from 'services/reportService';
import LoadButton from 'components/common/LoadButton';
import DownloadFile from 'components/common/DownloadFile';
import moment from 'moment';
import { OrganizationContact, ReportFilter, ReportInclude } from 'types/sp-api';
import { CongregationRegisterReportForm } from 'types/common';
import CongregationRegisterList from 'components/congregationRegister/CongregationRegisterList';
import ContactService from 'services/contactService';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useParams } from 'react-router-dom';

type ReportType = 'csv' | 'pdf';
type DownloadModel = { type: ReportType; filename: string };

const CongregationRegisterView: FC = () => {
    const { T } = useContext(LanguageContext);
    const [organizationList, setOrganizationList] = useState<OrganizationContact[]>();
    const { handleError } = useErrorHandler();
    const cancelRef = useRef<CancelTokenSource | null>(null);
    const clearDownload: DownloadModel = { type: 'pdf', filename: '' };
    const [download, setDownload] = useState<DownloadModel>(clearDownload);
    const [menuOpen, setMenuOpen] = useState(false);
    const anchorRef = useRef(null);
    const { filter } = useParams<'filter'>();

    const getOrganizations = useCallback(
        async (filterStr?: string): Promise<void> => {
            try {
                cancelRef.current = axios.CancelToken.source();
                const orgList = await ContactService.getOrganizationsAsync(
                    filterStr,
                    cancelRef.current.token,
                    false,
                    true,
                    false
                );
                if (cancelRef.current) {
                    orgList.sort((a: OrganizationContact, b: OrganizationContact) => {
                        if (!a || !b || !a.name || !b.name) return 0;
                        if (a.name.toLocaleLowerCase() > b.name.toLocaleLowerCase()) return 1;
                        if (a.name.toLocaleLowerCase() < b.name.toLocaleLowerCase()) return -1;
                        return 0;
                    });
                    setOrganizationList(orgList);
                }
            } catch (error) {
                handleError(error, T('Error_ChurchQueryFailed'));
            }
        },
        [handleError, T]
    );

    useEffect(() => {
        getOrganizations(filter);

        return () => {
            cancelRef.current && cancelRef.current.cancel();
            cancelRef.current = null;
        };
    }, [getOrganizations, filter]);

    const fetchReport = async (filename?: string, filterText?: string, type?: ReportType): Promise<AxiosResponse> => {
        !cancelRef.current && (cancelRef.current = axios.CancelToken.source());

        const reportFilter: ReportFilter = {};

        const reportInclude: ReportInclude = {
            age: false,
            birthDate: false,
            email: false,
            phone: true
        };

        const form: CongregationRegisterReportForm = {
            filename: filename ?? '',
            filterText: filterText,
            filter: reportFilter,
            include: reportInclude
        };

        if (type == 'csv') {
            return ReportService.congregationRegisterCsvReportAsync(form, cancelRef.current.token);
        }

        return ReportService.congregationRegisterPdfReportAsync(form, cancelRef.current.token);
    };

    return (
        <Container>
            <Grid container>
                <BreadcrumbBar />
                <ViewTitle title={T('CongregationRegister_Title')} icon={BadgeIcon} />
                <Grid container item xs={6}>
                    <ViewSearchBar
                        filter={filter ?? undefined}
                        baseUrl={'/registers/congregations/search'}
                        clearUrl={'/registers/congregations'}
                        searchTitle={T('Placeholder_CongregationRegisterSearch')}
                        auditMsg={'congregations register search'}
                        minWidth={380}
                    />
                </Grid>
                <Grid item xs={6} sx={{ textAlign: 'right' }}>
                    <ButtonGroup variant="contained" color="primary" ref={anchorRef} disabled={download.filename != ''}>
                        <LoadButton
                            onClick={() => {
                                const filename = T('CongregationRegister_PdfFilename').replace(
                                    '{0}',
                                    moment().format('L')
                                );
                                setDownload({ type: 'pdf', filename: filename });
                            }}
                            loading={download.filename != ''}
                        >
                            {T('CongregationRegister_PrintPdfReport')}
                        </LoadButton>
                        <Button
                            color="primary"
                            size="small"
                            onClick={() => setMenuOpen((prevOpen) => !prevOpen)}
                            disabled={download.filename != ''}
                        >
                            <ArrowDropDownIcon />
                        </Button>
                    </ButtonGroup>
                    <Popper
                        open={menuOpen}
                        anchorEl={anchorRef.current}
                        role={undefined}
                        transition
                        disablePortal
                        placement="bottom-end"
                        style={{ zIndex: 999 }}
                        nonce={undefined}
                    >
                        {({ TransitionProps, placement }) => (
                            <Grow
                                {...TransitionProps}
                                style={{
                                    transformOrigin: placement === 'bottom' ? 'top-end' : 'bottom-end'
                                }}
                            >
                                <Paper>
                                    <ClickAwayListener onClickAway={() => setMenuOpen(false)}>
                                        <MenuList id="split-button-menu">
                                            <MenuItem
                                                onClick={() => {
                                                    const filename = T('CongregationRegister_CsvFilename').replace(
                                                        '{0}',
                                                        moment().format('L')
                                                    );
                                                    setDownload({ type: 'csv', filename: filename });
                                                }}
                                            >
                                                {T('CongregationRegister_PrintCsvReport')}
                                            </MenuItem>
                                        </MenuList>
                                    </ClickAwayListener>
                                </Paper>
                            </Grow>
                        )}
                    </Popper>
                </Grid>
            </Grid>
            <CongregationRegisterList organizationList={organizationList} />
            {download.filename != '' && (
                <DownloadFile
                    fetchFile={() => fetchReport(filter, download.filename, download.type)}
                    filename={download.filename}
                    onSuccess={() => setDownload(clearDownload)}
                />
            )}
        </Container>
    );
};

export default CongregationRegisterView;
