import React, { ChangeEvent, FC, useContext, useEffect, useState, MouseEvent } from 'react';
import { LanguageContext } from 'contexts/languageContext';
import { Address, Membership, PostOffice } from 'types/sp-api';
import { useNavigate } from 'react-router-dom';
import {
    Box,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TablePagination,
    TableRow,
    Typography
} from '@mui/material';
import PersonUtils from 'utils/personHelper';
import AddressUtils from 'utils/addressHelper';
import PostalcodeService from 'services/postalcodeService';
import { MembershipType_FakeQueryResultMember, Order } from 'types/common';
import TableUtils from 'utils/tableHelper';
import EnhancedTableHead, { TableHeadCellDef } from 'components/common/EnhancedTableHead';
import { useAudit } from 'components/hooks/useAudit';
import WaitScreen from 'components/common/WaitScreen';
import { Warning } from '@mui/icons-material';
import LightTooltip from 'components/common/LightTooltip';

interface PersonListProps {
    memberList?: Membership[];
    loading?: boolean;
    message?: string;
}

const PersonList: FC<PersonListProps> = ({ memberList, message, loading }: PersonListProps) => {
    const { T } = useContext(LanguageContext);
    const [postalCodes, setPostalCodes] = useState<PostOffice[]>([]);

    useEffect(() => {
        PostalcodeService.getAllPostalCodesAsync().then((result) => {
            setPostalCodes(result);
        });
    }, []);

    if (!memberList) return null;

    const getAddress = (address?: Address) => {
        if (!address) return '';
        let city = address.city;

        if (!address.city || address.city?.length === 0) {
            city = AddressUtils.getCityByZipCode(address.zipCode, postalCodes);
        }

        return `${address.zipCode} ${city}`;
    };

    const headCells: TableHeadCellDef<Data>[] = [
        { id: 'name', label: T('Common_Name') },
        { id: 'address', label: T('Common_Address') },
        { id: 'email', label: T('Common_Email') },
        { id: 'phone', label: T('Common_Phone') }
    ];

    return (
        <EnhancedTable
            headCells={headCells}
            rows={memberList.map(
                (member): Data => ({
                    id: member.personId ?? 0,
                    name: PersonUtils.FullName(member.person),
                    address: getAddress(member.person?.address),
                    email: member.person?.email ?? '',
                    phone: member.person?.phoneHome ?? '',
                    membershipType: member.type,
                    phoneSecret: member.person?.phoneHomeSecret ?? false
                })
            )}
            initialOrder="asc"
            initialOrderBy="name"
            loading={loading}
            message={message}
        />
    );
};

export default PersonList;

interface Data {
    id: number;
    name: string;
    address: string;
    email: string;
    phone: string;
    membershipType: number;
    phoneSecret: boolean;
}

interface EnhancedTableProps {
    initialOrder: Order;
    initialOrderBy: keyof Data;
    headCells: TableHeadCellDef<Data>[];
    rows: Data[];
    loading?: boolean;
    message?: string;
}

const EnhancedTable = (props: EnhancedTableProps) => {
    const { T } = useContext(LanguageContext);
    const { headCells, rows, initialOrder, initialOrderBy } = props;
    const [order, setOrder] = useState<Order>(initialOrder);
    const [orderBy, setOrderBy] = useState<keyof Data>(initialOrderBy);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(6);
    const navigate = useNavigate();
    const { audit } = useAudit();

    const handleRequestSort = (_: MouseEvent<unknown>, property: keyof Data) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleClick = (id: number) => {
        const url = `/persons/${id}`;
        audit('person select', url);
        navigate(url);
    };

    const handleChangePage = (_: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return (
        <>
            <TableContainer component={Paper}>
                <Table>
                    <EnhancedTableHead
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={handleRequestSort}
                        headCells={headCells}
                    />
                    <TableBody>
                        <TableRow>
                            <TableCell colSpan={4} style={{ height: 0, padding: 0 }}></TableCell>
                        </TableRow>
                        {props.message ? (
                            <TableRow>
                                <TableCell colSpan={4}>
                                    <Box m={2} component="span">
                                        <Typography>{props.message}</Typography>
                                    </Box>
                                </TableCell>
                            </TableRow>
                        ) : (
                            TableUtils.stableSort<Data>(rows, TableUtils.getComparator(order, orderBy))
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row, index) => {
                                    return (
                                        <TableRow
                                            hover
                                            onClick={() => handleClick(row.id)}
                                            key={index}
                                            className="pointer"
                                        >
                                            <TableCell sx={{ fontSize: 'body1.fontSize' }}>
                                                {row.name}
                                                {row.membershipType === MembershipType_FakeQueryResultMember ? (
                                                    <LightTooltip title={T('Person_MembershipMissing')}>
                                                        <Warning
                                                            style={{ marginLeft: 8, marginBottom: -3 }}
                                                            className="warning-icon"
                                                            fontSize="small"
                                                        />
                                                    </LightTooltip>
                                                ) : null}
                                            </TableCell>
                                            <TableCell sx={{ fontSize: 'body1.fontSize' }}>{row.address}</TableCell>
                                            <TableCell sx={{ fontSize: 'body1.fontSize' }}>{row.email}</TableCell>
                                            <TableCell sx={{ fontSize: 'body1.fontSize' }}>
                                                {row.phoneSecret ? (
                                                    <Warning
                                                        color="warning"
                                                        titleAccess={T('Person_FieldPhoneHomeSecret')}
                                                    />
                                                ) : (
                                                    row.phone
                                                )}
                                            </TableCell>
                                        </TableRow>
                                    );
                                })
                        )}
                        {props.loading && (
                            <TableRow>
                                <TableCell colSpan={4}>
                                    <Box m={2} component="span">
                                        <WaitScreen />
                                    </Box>
                                </TableCell>
                            </TableRow>
                        )}
                        {addEmptyRows(rows.length, rowsPerPage, page)}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                component="div"
                rowsPerPageOptions={[6, 12, 30]}
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </>
    );
};

const addEmptyRows = (rowCount: number, rowsPerPage: number, page: number) => {
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, rowCount - page * rowsPerPage);

    return (
        emptyRows > 0 && (
            <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell sx={{ fontSize: 'body1.fontSize' }} colSpan={4} />
            </TableRow>
        )
    );
};
