import axios, { CancelToken } from 'axios';
import { Church, ChurchTypes, MembershipTypes, Organization } from 'types/sp-api';
import LocalCache from './localCache';

class ChurchesService {
    public async getChurchesCountAsync(cancelToken?: CancelToken): Promise<ChurchTypes> {
        const url = 'churches/types';
        return LocalCache.cachedRequest<ChurchTypes>(url, async () => {
            const response = await axios.get<ChurchTypes>(url, { cancelToken: cancelToken });
            return response.data;
        });
    }

    public async getChurchesMembersCountAsync(churchId?: number, cancelToken?: CancelToken): Promise<MembershipTypes> {
        if (!churchId) return { type1: 0, type2: 0, type3: 0, type4: 0, total: 0 };

        const response = await axios.get(`churches/${churchId}/members/types`, { cancelToken: cancelToken });

        return response.data;
    }

    public async getChurchAsync(id: number, cancelToken?: CancelToken): Promise<Church> {
        const response = await axios.get(`churches/${id}`, {
            cancelToken: cancelToken
        });

        return response.data;
    }

    public async getChurchOrganizationsAsync(churchId?: number, cancelToken?: CancelToken): Promise<Organization[]> {
        if (!churchId) return [];

        const response = await axios.get(`churches/${churchId}/organizations`, { cancelToken: cancelToken });
        return response.data;
    }
}

export default new ChurchesService();
