import React, { FC, useEffect, useState } from 'react';
import qrCode from 'qrcode';

interface QRCodeImageProps {
    username?: string;
    otpToken?: string;
}

const QRCodeImage: FC<QRCodeImageProps> = ({ username, otpToken }: QRCodeImageProps) => {
    const [dataUrl, setDataUrl] = useState('');

    useEffect(() => {
        const url = `/${username}/${otpToken}`;
        qrCode.toDataURL(url, { errorCorrectionLevel: 'H', width: 120, margin: 2 }).then((value) => {
            setDataUrl(value);
        });
    }, [username, otpToken]);

    return <img src={dataUrl} alt="qr-code" />;
};

export default QRCodeImage;
