import React, { FC } from 'react';

interface WarningToastProps {
    title: string;
    message?: string;
}

const WarningToast: FC<WarningToastProps> = ({ title, message }: WarningToastProps) => (
    <>
        <div>
            {title}
            {message && (
                <>
                    <br />
                    {message}
                </>
            )}
        </div>
    </>
);

export default WarningToast;
