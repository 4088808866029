import React, { FC, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { KeyValue } from 'types/common';
import axios, { CancelTokenSource } from 'axios';
import { LanguageContext } from 'contexts/languageContext';
import FormFieldDropDown from './FormFieldDropDown';
import { useErrorHandler } from '../hooks/useErrorHandler';
import WaitScreen from './WaitScreen';
import organizationsService from 'services/organizationsService';
import { CoOperationArea } from 'types/sp-api';

interface FormFieldDropDownCoOperationAreaProps {
    label: string;
    name: string;
    placeholder?: string;
    autoFocus?: boolean;
    disabledValues?: number | string | (number | string)[];
    readonly?: boolean;
}

const FormFieldDropDownCoOperationArea: FC<FormFieldDropDownCoOperationAreaProps> = ({
    label,
    name,
    placeholder,
    autoFocus,
    disabledValues,
    readonly
}: FormFieldDropDownCoOperationAreaProps) => {
    const [values, setValues] = useState<KeyValue[]>([]);
    const { T } = useContext(LanguageContext);
    const { handleError } = useErrorHandler();
    const cancelRef = useRef<CancelTokenSource | null>(null);

    const getCoOperationAreas = useCallback(async (): Promise<void> => {
        try {
            cancelRef.current = axios.CancelToken.source();

            const coOperationAreas = await organizationsService.getCoOperationAreasAsync(cancelRef.current.token);

            if (cancelRef.current) {
                coOperationAreas.sort((a: CoOperationArea, b: CoOperationArea) => {
                    return (a.id ?? 0) - (b.id ?? 0);
                });

                const areas = coOperationAreas.map((item) => ({ key: item.id, text: item.name }));
                setValues(areas);
            }
        } catch (error) {
            handleError(error, T('Error_CodeSetQueryFailed'));
        }
    }, [handleError, T]);

    useEffect(() => {
        getCoOperationAreas();

        return () => {
            cancelRef.current?.cancel();
            cancelRef.current = null;
        };
    }, [getCoOperationAreas]);

    if (values.length === 0) return <WaitScreen />;

    return (
        <FormFieldDropDown
            name={name}
            label={label}
            values={values}
            placeholder={placeholder}
            autoFocus={autoFocus}
            disabledValues={disabledValues}
            readonly={readonly}
        />
    );
};

export default FormFieldDropDownCoOperationArea;
