import React, { FC, useCallback, useContext, useRef, useState } from 'react';
import { Button, ButtonGroup, ClickAwayListener, Grow, MenuItem, MenuList, Paper, Popper } from '@mui/material';
import LoadButton from 'components/common/LoadButton';
import { LanguageContext } from 'contexts/languageContext';
import { AppLanguage } from 'types/common';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

interface ButtonLanguageProps {
    onClick: (lang: AppLanguage) => void;
    title: string;
    disabled?: boolean;
    loading?: boolean;
    color?: 'primary' | 'secondary';
    direction?: 'bottom-end' | 'top-end';
}

const ButtonLanguage: FC<ButtonLanguageProps> = ({
    onClick,
    title,
    disabled,
    loading,
    color,
    direction
}: ButtonLanguageProps) => {
    const { T } = useContext(LanguageContext);
    const [menuOpen, setMenuOpen] = useState(false);
    const anchorRef = useRef(null);

    const handleMenuItemClick = async (lang: AppLanguage) => {
        onClick(lang);
        setMenuOpen(false);
    };

    const closeMenu = useCallback(() => {
        setMenuOpen(false);
    }, []);

    const toggleMenu = useCallback(() => {
        setMenuOpen((prevOpen) => !prevOpen);
    }, []);

    const clickFi = useCallback(() => {
        handleMenuItemClick(AppLanguage.Finnish);
    }, []);

    const clickSv = useCallback(() => {
        handleMenuItemClick(AppLanguage.Swedish);
    }, []);

    const clickEn = useCallback(() => {
        handleMenuItemClick(AppLanguage.English);
    }, []);

    return (
        <>
            <ButtonGroup
                variant="contained"
                color={color ?? 'primary'}
                aria-label="split button"
                ref={anchorRef}
                disabled={disabled}
            >
                <LoadButton onClick={clickFi} loading={loading} color={color ?? 'primary'}>
                    {title}
                </LoadButton>
                <Button color={color ?? 'primary'} size="small" onClick={toggleMenu} disabled={loading}>
                    <ArrowDropDownIcon />
                </Button>
            </ButtonGroup>
            <Popper
                open={menuOpen}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                placement={direction ?? 'bottom-end'}
                style={{ zIndex: 999 }}
                nonce={undefined}
            >
                {({ TransitionProps }) => (
                    <Grow {...TransitionProps}>
                        <Paper>
                            <ClickAwayListener onClickAway={closeMenu}>
                                <MenuList id="split-button-menu">
                                    <MenuItem onClick={clickSv}>{T('Report_Swedish')}</MenuItem>
                                    <MenuItem onClick={clickEn}>{T('Report_English')}</MenuItem>
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </>
    );
};

export default ButtonLanguage;
