import React, { FC, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Button, Grid, Typography } from '@mui/material';
import { LanguageContext, LanguageTextFunc } from 'contexts/languageContext';
import * as Yup from 'yup';
import { useErrorHandler } from 'components/hooks/useErrorHandler';
import axios, { CancelTokenSource } from 'axios';
import CommunityTagService from 'services/communityTagService';
import { Tag } from 'types/sp-api';
import SelectTagTitle from './SelectTagTitle';
import EditTagTitle from './EditTagTitle';
import CommunityTagMembers from './CommunityTagMembers';

export const getValidationSchema = (T: LanguageTextFunc): Yup.AnyObjectSchema => {
    return Yup.object().shape({
        name: Yup.string().required(T('Common_Required'))
    });
};

const SettingsTabCommunityTagTitles: FC = () => {
    const { T } = useContext(LanguageContext);
    const [selectedTagId, setSelectedTagId] = useState<number>();
    const { handleError } = useErrorHandler();
    const [tagTitles, setTagTitles] = useState<Tag[]>([]);
    const cancelRef = useRef<CancelTokenSource | null>(null);
    const [showPersons, setShowPersons] = useState(false);

    const getCommunityTagTitles = useCallback(
        async (forceUpdate?: boolean): Promise<void> => {
            try {
                !cancelRef.current && (cancelRef.current = axios.CancelToken.source());
                const orgTagTitles = await CommunityTagService.getCommunityTagTitlesAsync(
                    cancelRef.current.token,
                    forceUpdate
                );
                if (cancelRef.current) {
                    const tagSorter = (a: Tag, b: Tag): number => {
                        if (!a.name || !b.name) return 0;
                        if (a.name > b.name) return 1;
                        if (a.name < b.name) return -1;
                        return 0;
                    };

                    orgTagTitles.sort(tagSorter);
                    setTagTitles(orgTagTitles);
                }
            } catch (error) {
                handleError(error, T('Error_CommunityTagTitleQueryFailed'));
            }
        },
        [handleError, T]
    );

    useEffect(() => {
        getCommunityTagTitles();

        return () => {
            cancelRef.current && cancelRef.current.cancel();
            cancelRef.current = null;
        };
    }, [getCommunityTagTitles]);

    const selectTagId = (id?: number) => {
        setSelectedTagId(id);
        getCommunityTagTitles(true);
    };

    return (
        <Grid container spacing={5}>
            <Grid container item xs={5} spacing={3} alignContent="flex-start">
                <Grid item>
                    <Typography color="textSecondary">{T('SettingsTabCommunityTagTitle_SelectTitle')}</Typography>
                </Grid>
                <Grid container item>
                    <SelectTagTitle selectedTagId={selectedTagId} selectTagId={selectTagId} tagTitles={tagTitles} />
                </Grid>
                <Grid container item>
                    {selectedTagId && <EditTagTitle tagId={selectedTagId} selectTagId={selectTagId} />}
                </Grid>
            </Grid>
            <Grid container item xs={6} spacing={3} alignContent="flex-start">
                <Grid item xs={12}>
                    <Typography color="textSecondary">{T('SettingsTabCommunityTagTitle_ShowPersonsTitle')}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => setShowPersons(!showPersons)}
                        disabled={!selectedTagId}
                    >
                        {showPersons
                            ? T('SettingsTabCommunityTagTitle_HidePersonsBtn')
                            : T('SettingsTabCommunityTagTitle_ShowPersonsBtn')}
                    </Button>
                </Grid>
                <Grid item xs={12}>
                    {showPersons && selectedTagId && <CommunityTagMembers tagId={selectedTagId} />}
                </Grid>
            </Grid>
        </Grid>
    );
};

export default SettingsTabCommunityTagTitles;
