import moment from 'moment';

const zeroPad = (num: number, places: number) => {
    const zero = places - num.toString().length + 1;
    return Array(+(zero > 0 && zero)).join('0') + num;
};

const isUnderAge = (ssn?: string): boolean | undefined => {
    if (!ssn) return undefined;

    const age = getAgeBySsn(ssn);
    if (age === -1) return undefined;

    return age < 18;
};

const getAgeBySsn = (ssn?: string): number => {
    if (ssn === undefined || ssn.length < 11) return -1;

    let century;

    switch (ssn[6].toUpperCase()) {
        case '+':
            century = 1800;
            break;
        case '-':
            century = 1900;
            break;
        case 'A':
            century = 2000;
            break;

        default:
            return -1;
    }

    const year = century + parseInt(ssn[4]) * 10 + parseInt(ssn[5]);
    const month = parseInt(ssn[2]) * 10 + parseInt(ssn[3]);
    const day = parseInt(ssn[0]) * 10 + parseInt(ssn[1]);

    return moment().diff(moment(`${year}-${zeroPad(month, 2)}-${zeroPad(day, 2)}`), 'years');
};

const isValidBirthDay = (ssn?: string): boolean => {
    if (ssn === undefined || ssn.length !== 6) return false;

    // Check date validity
    const year = parseInt(ssn[4]) * 10 + parseInt(ssn[5]);
    const month = parseInt(ssn[2]) * 10 + parseInt(ssn[3]);
    const day = parseInt(ssn[0]) * 10 + parseInt(ssn[1]);

    const thisYear = moment().year();

    const century = year > parseInt(thisYear.toString().substring(3, 5)) ? 1900 : 2000;

    return moment(`${century + year}-${zeroPad(month, 2)}-${zeroPad(day, 2)}`).isValid();
};

const isValidSsn = (ssn?: string): boolean => {
    if (ssn === undefined || ssn === null) return false;

    if (ssn.length === 6) return isValidBirthDay(ssn);

    // Check string length
    if (ssn.length != 11) return false;

    const ssnRegex = /^\d{6}[A+-]\d{3}[0-9A-FHJ-NPR-Y]$/;

    if (!ssnRegex.test(ssn.toLocaleUpperCase())) return false;

    let century;

    // Check century mark
    switch (ssn[6].toUpperCase()) {
        case '+':
            century = 1800;
            break;
        case '-':
            century = 1900;
            break;
        case 'A':
            century = 2000;
            break;

        default:
            return false;
    }

    // Check date validity
    const year = parseInt(ssn[4]) * 10 + parseInt(ssn[5]);
    const month = parseInt(ssn[2]) * 10 + parseInt(ssn[3]);
    const day = parseInt(ssn[0]) * 10 + parseInt(ssn[1]);

    if (moment(`${century + year}-${zeroPad(month, 2)}-${zeroPad(day, 2)}`).isValid() === false) return false;

    // Check the checksum
    const checksumStr = ssn.substring(7, 10);
    const baseStr = ssn.substring(0, 6);

    const baseNumber = parseInt(`${baseStr}${checksumStr}`);
    const remainder = baseNumber % 31;

    let checksum;
    if (remainder < 10) checksum = remainder.toString();
    else {
        checksum = [
            'A',
            'B',
            'C',
            'D',
            'E',
            'F',
            'H',
            'J',
            'K',
            'L',
            'M',
            'N',
            'P',
            'R',
            'S',
            'T',
            'U',
            'V',
            'W',
            'X',
            'Y'
        ][remainder - 10];
    }

    return ssn[10].toUpperCase() === checksum;
};

export default { isUnderAge, getAgeBySsn, isValidSsn };
