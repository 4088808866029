import React, { FC, useContext, MouseEventHandler } from 'react';
import { Button, IconButton } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { LanguageContext } from 'contexts/languageContext';

interface ButtonAddProps {
    title?: string;
    color?: 'primary' | 'secondary' | 'inherit' | 'success' | 'error' | 'info' | 'warning';
    small?: boolean;
    tooltip?: string;
    onClick?: MouseEventHandler<HTMLButtonElement>;
    variant?: 'text' | 'outlined' | 'contained';
    disabled?: boolean;
}

const ButtonAdd: FC<ButtonAddProps> = ({
    title,
    onClick,
    color = 'primary',
    small,
    tooltip,
    variant = 'contained',
    disabled
}: ButtonAddProps) => {
    const { T } = useContext(LanguageContext);

    const doClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        onClick && onClick(event);
        event.preventDefault();
        event.stopPropagation();
    };

    return (
        <>
            {title && (
                <Button
                    startIcon={<AddIcon />}
                    variant={variant}
                    size={small === true ? 'small' : 'medium'}
                    color={color}
                    onClick={doClick}
                    disabled={disabled}
                    title={tooltip ? tooltip : T('Common_Add')}
                >
                    {title}
                </Button>
            )}
            {!title && (
                <IconButton
                    color="primary"
                    onClick={doClick}
                    title={tooltip ? tooltip : T('Common_Add')}
                    disabled={disabled}
                >
                    <AddIcon fontSize={small === true ? 'small' : 'medium'} />
                </IconButton>
            )}
        </>
    );
};

export default ButtonAdd;
