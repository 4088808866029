import axios from 'axios';
import { AppLanguage } from 'types/common';
import { LangString, CountryCode, Language } from '../contexts/languageContext';
import LocalCache from './localCache';
import config from 'config/config';

class LanguageService {
    public async getLanguageStringsAsync(language: AppLanguage): Promise<LangString[]> {
        const url = `texts/${language}`;

        if (config.STAGE != 'production') {
            // reread language strings always on development
            const axiosInstance = axios.create(); // create separate instance to avoid interceptors
            const response = await axiosInstance.get(url);
            return response.data;
        }

        return LocalCache.cachedRequest<LangString[]>(url, async () => {
            const axiosInstance = axios.create(); // create separate instance to avoid interceptors
            const response = await axiosInstance.get(url);
            return response.data;
        });
    }

    public getCountryCodes(): CountryCode[] {
        return [
            { code: AppLanguage.Finnish, value: 'Finland' },
            { code: AppLanguage.Swedish, value: 'Sweden' },
            { code: AppLanguage.English, value: 'England' }
        ];
    }

    public getLanguageCodes(): Language[] {
        return [
            { lan: AppLanguage.Finnish, value: 'Finnish' },
            { lan: AppLanguage.Swedish, value: 'Swedish' },
            { lan: AppLanguage.English, value: 'English' }
        ];
    }
}

export default new LanguageService();
