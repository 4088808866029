import React, { ChangeEvent, FC, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Container, Grid, Paper, Tab, Tabs } from '@mui/material';
import axios, { CancelTokenSource } from 'axios';
import PersonsService from 'services/personsService';
import WaitScreen from 'components/common/WaitScreen';
import { Person } from 'types/sp-api';
import PersonTabBasic from './PersonTabBasic';
import { useParams } from 'react-router-dom';
import BreadcrumbBar from 'components/common/BreadcrumbBar';
import ViewTitle from 'components/common/ViewTitle';
import { Person as PersonIcon } from '@mui/icons-material';
import { LanguageContext } from 'contexts/languageContext';
import TabPanel from 'components/common/TabPanel';
import PersonTabMembership from './PersonTabMembership';
import PersonTabAccessRights from './PersonTabAccessRights';
import PersonTabFamily from './PersonTabFamily';
import PersonTabCredentials from './PersonTabCredentials';
import PersonTabReports from './PersonTabReports';
import PersonTabDocuments from './PersonTabDocuments';
import { useErrorHandler } from 'components/hooks/useErrorHandler';
import { AddressType, MembershipType, TabItem } from 'types/common';
import PersonUtils from 'utils/personHelper';
import PersonTabConsents from './PersonTabConsents';
import PersonTabAdditionalInfo from './PersonTabAdditionalInfo';
import LightTooltip from 'components/common/LightTooltip';
import { WarningRounded } from '@mui/icons-material';
import config from 'config/config';
import constants from 'config/constants';

const PersonItem: FC = () => {
    const [person, setPerson] = useState<Person>();
    const [selectedTab, setSelectedTab] = useState(0);
    const [hasMemberships, setHasMemberships] = useState(false);
    const { T } = useContext(LanguageContext);
    const { handleError } = useErrorHandler();
    const cancelRef = useRef<CancelTokenSource | null>(null);
    const { personId } = useParams<'personId'>();
    const [registerContactOrgId, setRegisterContactOrgId] = useState<number>();

    const getPerson = useCallback(
        async (id: number): Promise<void> => {
            try {
                cancelRef.current = axios.CancelToken.source();
                const personData = await PersonsService.getPersonAsync(id, cancelRef.current.token);
                if (cancelRef.current) {
                    setPerson(personData);
                }

                const memberships = await PersonsService.getPersonMembershipsAsync(id, cancelRef.current.token);
                if (cancelRef.current) {
                    setHasMemberships(memberships.length > 0);
                    setRegisterContactOrgId(
                        memberships.find((m) => m.type == MembershipType.RegisterContact)?.organizationId
                    );
                }
            } catch (error) {
                handleError(error, T('Error_PersonQueryFailed'));
            }
        },
        [handleError, T]
    );

    useEffect(() => {
        if (personId !== 'new' && personId !== 'newContact' && personId) {
            getPerson(parseInt(personId));
        } else {
            // init new person
            setPerson({
                id: -1,
                lastName: '',
                firstName: '',
                ssn: '',
                address: { street: '', zipCode: '', country: 'Suomi', type: AddressType.Domestic }
            });
            if (personId === 'newContact') {
                setRegisterContactOrgId(0);
            }
        }
        return () => {
            cancelRef.current && cancelRef.current.cancel();
            cancelRef.current = null;
        };
    }, [getPerson, personId]);

    const handleChange = (_: ChangeEvent<unknown>, newValue: number) => {
        setSelectedTab(newValue);
    };

    const updatePerson = () => {
        getPerson(parseInt(personId ?? '0'));
    };

    if (!person)
        return (
            <Container>
                <Grid container>
                    <BreadcrumbBar />
                    <ViewTitle title={T('Common_Loading')} icon={PersonIcon} />
                </Grid>
                <WaitScreen />
            </Container>
        );

    const tabs: TabItem[] = [
        {
            id: 0,
            title: 'Basic',
            content: (
                <PersonTabBasic
                    person={{ ...person, organizationId: registerContactOrgId }}
                    refreshPerson={updatePerson}
                    registerContact={registerContactOrgId != undefined}
                />
            )
        },
        {
            id: 1,
            title: 'Membership',
            hidden: registerContactOrgId != undefined,
            content: <PersonTabMembership person={person} refreshPerson={updatePerson} />
        },
        {
            id: 2,
            title: 'AdditionalInfo',
            hidden: !hasMemberships || registerContactOrgId != undefined,
            content: <PersonTabAdditionalInfo person={person} />
        },
        {
            id: 3,
            title: 'Family',
            hidden: !hasMemberships || registerContactOrgId != undefined,
            content: <PersonTabFamily person={person} setActiveTab={setSelectedTab} />
        },
        { id: 4, title: 'Consents', hidden: true, content: <PersonTabConsents person={person} /> },
        {
            id: 5,
            title: 'Reports',
            hidden: !hasMemberships || registerContactOrgId != undefined,
            content: <PersonTabReports person={person} />
        },
        { id: 6, title: 'Documents', hidden: true, content: <PersonTabDocuments person={person} /> },
        {
            id: 7,
            title: 'Credentials',
            hidden: !hasMemberships || config.CUSTOMER == constants.Customers.Advk || registerContactOrgId != undefined,
            content: <PersonTabCredentials person={person} />
        },
        {
            id: 8,
            title: 'AccessRights',
            hidden: !hasMemberships || registerContactOrgId != undefined,
            content: <PersonTabAccessRights person={person} />
        }
    ];

    return (
        <Container>
            <Grid container>
                <BreadcrumbBar />
                <ViewTitle
                    title={
                        <>
                            {PersonUtils.FullName(person)}
                            {person.id != -1 && !hasMemberships && (
                                <LightTooltip title={T('PersonTab_NonMembersHidden')}>
                                    <WarningRounded
                                        className="warning-icon"
                                        style={{ marginLeft: 16, marginBottom: -3 }}
                                    />
                                </LightTooltip>
                            )}
                        </>
                    }
                    iconTooltip={T('Person_IconTooltip').replace('{0}', person.id?.toString() ?? '')}
                    icon={PersonIcon}
                />
            </Grid>
            <Paper elevation={3}>
                <Tabs
                    value={selectedTab}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="scrollable"
                    scrollButtons="auto"
                    onChange={handleChange}
                >
                    {tabs
                        .filter((tab) => !tab.hidden)
                        .map((item, index) => (
                            <Tab
                                label={T(`PersonTab_${item.title}`)}
                                key={item.id}
                                value={item.id}
                                disabled={index > 0 && personId === 'new'}
                            />
                        ))}
                </Tabs>
                {tabs
                    .filter((tab) => !tab.hidden)
                    .map((item) => (
                        <TabPanel key={item.id} value={selectedTab} index={item.id}>
                            {item.content}
                        </TabPanel>
                    ))}
            </Paper>
        </Container>
    );
};

export default PersonItem;
