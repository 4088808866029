import React, { FC } from 'react';
import { ReactComponent as SwedensFlagSvg } from './flag_se.svg';
import { getThemeColor } from 'config/theme';

interface FlagSEProps {
    fontSize?: 'large' | 'small' | 'default';
    color?: 'primary' | 'secondary' | 'default' | 'alert';
}

const FlagSE: FC<FlagSEProps> = ({ fontSize, color }: FlagSEProps) => {
    let width = 24;

    if (fontSize === 'large') {
        width = 30;
    } else if (fontSize === 'small') {
        width = 20;
    }

    return (
        <div style={{ width: width, height: width }}>
            <SwedensFlagSvg fill={getThemeColor(color)} />
        </div>
    );
};

export default FlagSE;
