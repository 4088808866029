import React, { FC, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { LanguageContext, LanguageTextFunc } from 'contexts/languageContext';
import { Grid, Typography } from '@mui/material';
import {
    Credential,
    CredentialApplication,
    CredentialApplicationAttachment,
    CredentialApplicationNote
} from 'types/sp-api';
import DialogBase from 'components/common/DialogBase';
import { Formik, FormikProps, Form } from 'formik';
import FormFieldDropDownCodeSet from 'components/common/FormFieldDropDownCodeSet';
import { useErrorHandler } from 'components/hooks/useErrorHandler';
import { useSuccessHandler } from 'components/hooks/useSuccessHandler';
import axios, { AxiosResponse, CancelTokenSource } from 'axios';
import { EditDialogState } from './CredentialApplicationList';
import CredentialService from 'services/credentialService';
import CredentialApplicationService from 'services/credentialApplicationService';
import LoadButton from 'components/common/LoadButton';
import FormFieldText from 'components/common/FormFieldText';
import Utils from 'utils/typeHelper';
import ViewSubtitle from 'components/common/ViewSubtitle';
import FormFieldTextArea from 'components/common/FormFieldTextArea';
import FormFieldDate from 'components/common/FormFieldDate';
import * as Yup from 'yup';
import moment from 'moment';
import DownloadFile from 'components/common/DownloadFile';
import ReportService from 'services/reportService';
import CredentialApplicationNotes from './CredentialApplicationNotes';
import ButtonEdit from 'components/common/ButtonEdit';
import CredentialApplicationAttachmentLine from './CredentialApplicationAttachmentLine';
import DialogAddAttachment from './DialogAddAttachment';
import DialogChangeType from './DialogChangeType';
import DialogUpsertNote from './DialogUpsertNote';
import DialogSelectPerson from './DialogSelectPerson';
import { Warning } from '@mui/icons-material';

const getValidationSchema = (T: LanguageTextFunc) => {
    return Yup.object().shape({
        organizationId: Yup.number().required(T('Common_Required')),
        applicationId: Yup.number().required(T('Common_Required')),
        status: Yup.number().min(1, T('Common_Required'))
    });
};

interface EditDialogContext {
    application: CredentialApplication;
    credential: Credential;
    attachments: CredentialApplicationAttachment[];
}

interface DialogCredentialApplicationEditProps {
    state?: EditDialogState;
    onClose?: () => void;
    updateApplicationList: () => void;
}

const DialogCredentialApplicationEdit: FC<DialogCredentialApplicationEditProps> = ({
    state,
    onClose,
    updateApplicationList
}: DialogCredentialApplicationEditProps) => {
    const { T } = useContext(LanguageContext);
    const { show, applicationId } = state ?? { show: false, applicationId: 0 };
    const [context, setContext] = useState<EditDialogContext>();
    const { handleError } = useErrorHandler();
    const { handleSuccess } = useSuccessHandler();
    const cancelRef = useRef<CancelTokenSource | null>(null);
    const [notes, setNotes] = useState<CredentialApplicationNote[]>();
    const [selectedNote, setSelectedNote] = useState<CredentialApplicationNote>();
    const [selectedApplication, setSelectedApplication] = useState<CredentialApplication>();
    const [downloadNotesReportFilename, setDownloadNotesReportFilename] = useState<string>();
    const [downloadCardFilename, setDownloadCardFilename] = useState<string>();
    const [downloadExtraCardFilename, setDownloadExtraCardFilename] = useState<string>();
    const [showAddAttachment, setShowAddAttachment] = useState(false);
    const [showSelectPerson, setShowSelectPerson] = useState(false);

    const getContext = useCallback(
        async (appId: number): Promise<void> => {
            try {
                !cancelRef.current && (cancelRef.current = axios.CancelToken.source());
                const applicationPromise = CredentialApplicationService.getApplicationAsync(
                    appId,
                    cancelRef.current.token
                );
                const credentialPromise = CredentialService.getCredentialByApplicationIdAsync(
                    appId,
                    cancelRef.current.token
                );
                const attachmentPromise = CredentialApplicationService.getAttachmentsAsync(
                    appId,
                    cancelRef.current.token
                );

                const [application, credential, attachments] = await Promise.all([
                    applicationPromise,
                    credentialPromise,
                    attachmentPromise
                ]);

                if (cancelRef.current) {
                    setContext({
                        application: application,
                        credential: credential,
                        attachments: attachments
                    });
                }
            } catch (error) {
                handleError(error, T('Error_CredentialApplicationQueryFailed'));
            }
        },
        [handleError, T]
    );

    useEffect(() => {
        if (show) {
            getContext(applicationId);
        }

        return () => {
            cancelRef.current && cancelRef.current.cancel();
            cancelRef.current = null;
        };
    }, [getContext, applicationId]);

    const getNotes = useCallback(
        async (appId: number): Promise<number> => {
            try {
                !cancelRef.current && (cancelRef.current = axios.CancelToken.source());
                const notes = await CredentialApplicationService.getNotesAsync(appId, cancelRef.current.token);

                if (cancelRef.current) {
                    notes.sort((a, b) => {
                        if (!a || !b) return 0;
                        if (!a.notesDate || !b.notesDate) return 0;
                        if (!a.lastModifiedDate || !b.lastModifiedDate) return 0;

                        if (a.notesDate > b.notesDate) return -1;
                        if (a.notesDate < b.notesDate) return 1;

                        if (b.lastModifiedDate > a.lastModifiedDate) return 1;
                        if (b.lastModifiedDate < a.lastModifiedDate) return -1;

                        return 0;
                    });
                    setNotes(notes);
                }

                return notes.length;
            } catch (error) {
                console.error(error);
                handleError(error, T('Error_CredentialApplicationNotesQueryFailed'));
            }

            return -1;
        },
        [handleError, T]
    );

    useEffect(() => {
        if (applicationId) {
            getNotes(applicationId);
        }

        return () => {
            cancelRef.current && cancelRef.current.cancel();
            cancelRef.current = null;
        };
    }, [getNotes, applicationId]);

    const deleteNote = async (id: number) => {
        try {
            await CredentialApplicationService.deleteNoteAsync(applicationId, id);
            handleSuccess(T('CredentialApplicationNote_DeleteSuccess'));
            // refresh list
            const count = await getNotes(applicationId);
            if (count == 0) {
                // now there is no note -> update list to hide date
                updateApplicationList();
            }
        } catch (error) {
            handleError(error, T('CredentialApplicationNote_DeleteFailed'));
        }
    };

    const saveCredential = async (credential: Credential) => {
        try {
            await CredentialService.saveCredentialAsync(credential);
            handleSuccess(T('Credential_SaveSuccess'));
            getContext(applicationId);
        } catch (error) {
            handleError(error, T('Credential_SaveFailed'));
        }
    };

    const getNote = useCallback(
        async (appId: number, noteId: number): Promise<CredentialApplicationNote | undefined> => {
            try {
                !cancelRef.current && (cancelRef.current = axios.CancelToken.source());
                const note = await CredentialApplicationService.getNoteAsync(appId, noteId, cancelRef.current.token);

                if (cancelRef.current) {
                    return note;
                }
            } catch (error) {
                handleError(error, T('Error_CredentialApplicationNotesQueryFailed'));
            }

            return undefined;
        },
        [handleError, T]
    );

    const editNote = (id: number) => {
        getNote(applicationId, id).then((note) => {
            setSelectedNote(note);
        });
    };

    const addNote = () => {
        setSelectedNote({ applicationId: applicationId, type: 0, notesDate: new Date(), notes: '' });
    };

    const fetchNotesReport = async (filename: string): Promise<AxiosResponse> => {
        !cancelRef.current && (cancelRef.current = axios.CancelToken.source());
        return ReportService.credentialApplicationNotesReportAsync(
            applicationId,
            filename,
            'fi',
            cancelRef.current.token
        );
    };

    const fetchCard = async (filename: string): Promise<AxiosResponse> => {
        !cancelRef.current && (cancelRef.current = axios.CancelToken.source());
        return ReportService.credentialApplicationCardAsync(
            applicationId,
            filename,
            'fi',
            false,
            cancelRef.current.token
        );
    };

    const fetchExtraCard = async (filename: string): Promise<AxiosResponse> => {
        !cancelRef.current && (cancelRef.current = axios.CancelToken.source());
        return ReportService.credentialApplicationCardAsync(
            applicationId,
            filename,
            'fi',
            true,
            cancelRef.current.token
        );
    };
    const updateApplications = async () => {
        getContext(applicationId);
        updateApplicationList();
    };

    const updateNotes = async () => {
        const count = await getNotes(applicationId).then();
        if (count == 1) {
            // saved and now there is first note -> update list to show date
            updateApplicationList();
        }
    };

    if (!show || !context) return null;

    interface NotesReportButtonsProps {
        context: EditDialogContext;
    }

    const NotesReportButtons: FC<NotesReportButtonsProps> = ({ context }: NotesReportButtonsProps) => {
        return (
            <>
                <LoadButton
                    loading={!!downloadNotesReportFilename}
                    type="button"
                    onClick={() => {
                        const filename = T('CredentialApplication_ApplicationNotesFilename')
                            .replace('{0}', `${context.application.lastName} ${context.application.firstName}`)
                            .replace('{1}', moment().format('L_LT'));
                        setDownloadNotesReportFilename(filename);
                    }}
                    color="secondary"
                >
                    {T('CredentialApplication_PrintNotes')}
                </LoadButton>
                <LoadButton
                    loading={!!downloadCardFilename}
                    type="button"
                    onClick={() => {
                        const filename = T('CredentialApplication_ApplicationCardFilename')
                            .replace('{0}', `${context.application.lastName} ${context.application.firstName}`)
                            .replace('{1}', moment().format('L_LT'));
                        setDownloadCardFilename(filename);
                    }}
                    color="secondary"
                    sx={{ ml: 2 }}
                    disabled={
                        context.attachments.findIndex((item) => item.type === 8) < 0 ||
                        !context.credential.beginDate ||
                        (context.credential.status != 3 && context.credential.status != 4)
                    }
                >
                    {T('CredentialApplication_PrintCard')}
                </LoadButton>
                {context.application.extraCard && (
                    <LoadButton
                        loading={!!downloadExtraCardFilename}
                        type="button"
                        onClick={() => {
                            const filename = T('CredentialApplication_ApplicationCardFilename')
                                .replace('{0}', `${context.application.lastName} ${context.application.firstName}`)
                                .replace('{1}', moment().format('L_LT'));
                            setDownloadExtraCardFilename(filename);
                        }}
                        color="secondary"
                        sx={{ ml: 2 }}
                        disabled={
                            context.attachments.findIndex((item) => item.type === 8) < 0 ||
                            !context.credential.beginDate ||
                            (context.credential.status != 3 && context.credential.status != 4)
                        }
                    >
                        {T('CredentialApplication_PrintExtraCard')}
                    </LoadButton>
                )}
            </>
        );
    };

    return (
        <>
            <Formik
                initialValues={context.credential}
                enableReinitialize
                validationSchema={getValidationSchema(T)}
                onSubmit={saveCredential}
            >
                {({ dirty, isValid, isSubmitting }: FormikProps<Credential>) => {
                    return (
                        <DialogBase
                            fullScreen
                            show={show}
                            title={T('CredentialApplication_EditDialogTitle').replace(
                                '{0}',
                                `${context.application.lastName} ${context.application.firstName}`
                            )}
                            onOk={() => {
                                onClose && onClose();
                            }}
                            onClose={onClose}
                            okTitle={T('Common_Close')}
                            size="lg"
                            modal
                            hideCancel
                            style={{ margin: 0 }}
                            actionBarClassName="fullScreenActionBar"
                            extraButton={<NotesReportButtons context={context} />}
                        >
                            <Form>
                                <Grid container spacing={3}>
                                    <Grid container item xs={6} spacing={2} alignContent="start">
                                        <Grid item xs={12} sx={{ pt: 0 }}>
                                            <ViewSubtitle
                                                title={`${T(
                                                    Utils.CredentialApplicationTypeToName(context.application.type)
                                                )}${T('Credential_PostfixTitle')}`}
                                                small
                                                noMargin
                                            />
                                        </Grid>
                                        <Grid item xs={10}>
                                            <FormFieldDropDownCodeSet
                                                codeSet="credentialStatus"
                                                name="status"
                                                label={T('CredentialApplication_Status')}
                                                placeholder={T('CredentialApplication_SelectStatus')}
                                            />
                                        </Grid>
                                        <Grid item xs={10}>
                                            <FormFieldDropDownCodeSet
                                                codeSet="credentialMarriageLicense"
                                                name="marriageLicenseStatus"
                                                label={T('CredentialApplication_MarriageLicense')}
                                                placeholder={T('CredentialApplication_NotSetMarriageLicense')}
                                            />
                                        </Grid>
                                        <Grid item xs={10}>
                                            <FormFieldText
                                                name="assignment"
                                                label={T('CredentialApplication_Assignment')}
                                            />
                                        </Grid>
                                        <Grid item xs={5}>
                                            <FormFieldDate
                                                name="beginDate"
                                                label={T('CredentialApplication_BeginDate')}
                                                minDate={new Date('2020-01-01')}
                                            />
                                        </Grid>
                                        <Grid item xs={5}>
                                            <FormFieldDate
                                                name="endDate"
                                                label={T('CredentialApplication_EndDate')}
                                                minDate={new Date('2020-01-01')}
                                            />
                                        </Grid>
                                        <Grid item xs={5}>
                                            <FormFieldDate
                                                name="weddingsRightBeginDate"
                                                label={T('CredentialApplication_WeddingsRightBeginDate')}
                                                minDate={new Date('2000-01-01')}
                                            />
                                        </Grid>
                                        <Grid item xs={5}></Grid>
                                        <Grid item xs={3}>
                                            <FormFieldText
                                                name="cardNumber"
                                                label={T('CredentialApplication_CardNumber')}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <FormFieldTextArea
                                                name="description"
                                                label={T('CredentialApplication_Description')}
                                                rows={4}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <LoadButton
                                                loading={isSubmitting}
                                                disabled={!dirty || !isValid}
                                                type="submit"
                                                small
                                            >
                                                {T('Common_Save')}
                                            </LoadButton>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <ViewSubtitle
                                                title={T('CredentialApplication_NotesTitle')}
                                                onAddClick={addNote}
                                                small
                                                noMargin
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <CredentialApplicationNotes
                                                notes={notes}
                                                editNote={editNote}
                                                deleteNote={deleteNote}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container item xs={6} alignContent="start">
                                        <Grid container item>
                                            {state?.personUnsure && (
                                                <Warning
                                                    className="warning-icon"
                                                    fontSize="small"
                                                    titleAccess={T('Credential_MultiplePersonByApplication')}
                                                />
                                            )}
                                        </Grid>
                                        <Grid item xs={11} sx={{ mb: 1 }}>
                                            <ViewSubtitle
                                                title={T('CredentialApplication_PersonInfoTitle')}
                                                onEditClick={() => {
                                                    setShowSelectPerson(true);
                                                }}
                                                buttonTitle=""
                                                tooltip="Vaihda henkilö"
                                                small
                                                noMargin
                                            />
                                        </Grid>
                                        <Grid container item xs={12}>
                                            <Grid item xs={6}>
                                                <Grid>{`${context.application.lastName} ${context.application.firstName}`}</Grid>
                                                <Grid>{context.application.address?.street}</Grid>
                                                <Grid>{`${context.application.address?.zipCode} ${context.application.address?.city}`}</Grid>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Grid>{context.application.phone}</Grid>
                                                <Grid>{context.application.ssn}</Grid>
                                                <Grid>{context.application.email}</Grid>
                                            </Grid>
                                            <Grid item xs={12} sx={{ mt: 2, mb: 1 }}>
                                                <Grid>{context.application.organization?.name}</Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} sx={{ mt: 1, mb: 1 }}>
                                            <ViewSubtitle
                                                title={T('CredentialApplication_ApplicationInfoTitle')}
                                                small
                                                noMargin
                                            />
                                        </Grid>
                                        <Grid container item xs={12} spacing={1}>
                                            <Grid item xs={4}>
                                                <Typography color="secondary">
                                                    {T('CredentialApplication_CreatedDate')}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                {moment(context.application.createdDate).format('l LT')}
                                            </Grid>
                                            <Grid item xs={4}>
                                                <Typography color="secondary">
                                                    {T('CredentialApplication_ApplicationType')}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                {T(
                                                    context.application.newApplication
                                                        ? 'CredentialApplication_NewApplication'
                                                        : 'CredentialApplication_RenewApplication'
                                                )}
                                            </Grid>
                                            <Grid item xs={4}>
                                                <Typography color="secondary">
                                                    {T('CredentialApplication_Type')}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                {T(Utils.CredentialApplicationTypeToName(context.application.type))}
                                            </Grid>
                                            <Grid item xs={2}>
                                                <ButtonEdit
                                                    small
                                                    onClick={() => setSelectedApplication(context.application)}
                                                    tooltip={T('CredentialApplication_ChangeApplicationType')}
                                                ></ButtonEdit>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <Typography color="secondary">
                                                    {T('CredentialApplication_TypeTitle')}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={8}>
                                                {T(context.application.title)}
                                            </Grid>
                                            {!context.application.newApplication && (
                                                <>
                                                    <Grid item xs={4}>
                                                        <Typography color="secondary">
                                                            {T('CredentialApplication_TypeChanges')}
                                                        </Typography>
                                                    </Grid>

                                                    <Grid item xs={8}>
                                                        {T(context.application.titleChange)}
                                                    </Grid>
                                                </>
                                            )}
                                            <Grid item xs={4} alignSelf="center">
                                                <Typography color="secondary">
                                                    {T('CredentialApplication_TrainingIntro')}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <FormFieldDate
                                                    name="trainingIntro"
                                                    label=""
                                                    minDate={new Date('2000-01-01')}
                                                />
                                            </Grid>
                                            <Grid item xs={4} />
                                            <Grid item xs={4} alignSelf="center">
                                                <Typography color="secondary">
                                                    {T('CredentialApplication_TrainingSafety')}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <FormFieldDate
                                                    name="trainingSafety"
                                                    label=""
                                                    minDate={new Date('2000-01-01')}
                                                />
                                            </Grid>
                                            <Grid item xs={4} />
                                            {context.credential.marriageLicenseStatus > 0 && (
                                                <>
                                                    <Grid item xs={4} alignSelf="center">
                                                        <Typography color="secondary">
                                                            {T('CredentialApplication_TrainingMarriage')}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        <FormFieldDate name="trainingMarriage" label="" />
                                                    </Grid>
                                                    <Grid item xs={4} />
                                                </>
                                            )}
                                            <Grid item xs={4} alignSelf="center">
                                                <Typography color="secondary">
                                                    {T('CredentialApplication_Language')}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <Typography>
                                                    {T(Utils.LanguageToName(context.application.language))}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={4} />
                                            {context.application.extraCard && (
                                                <>
                                                    <Grid item xs={4} alignSelf="center">
                                                        <Typography color="secondary">
                                                            {T('CredentialApplication_ExtraCard')}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        <Typography>{`${T('Common_Yes')}, ${T(
                                                            Utils.LanguageToName(context.application.extraLanguage)
                                                        )}`}</Typography>
                                                    </Grid>
                                                    <Grid item xs={4} />
                                                </>
                                            )}
                                        </Grid>
                                        <Grid item xs={12} sx={{ mt: 1, mb: 1 }}>
                                            <ViewSubtitle
                                                title={T('CredentialApplication_ApplicationAttachmentsTitle')}
                                                onAddClick={() => {
                                                    setShowAddAttachment(true);
                                                }}
                                                buttonTitle=""
                                                small
                                                noMargin
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Grid item xs={8}>
                                                {context.attachments.map((att, index) => (
                                                    <CredentialApplicationAttachmentLine
                                                        applicationId={context.application.id}
                                                        attachment={att}
                                                        key={index}
                                                        updateContext={() => getContext(applicationId)}
                                                    />
                                                ))}
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} sx={{ mt: 1, mb: 1 }}>
                                            <ViewSubtitle
                                                title={T('CredentialApplication_ApplicationSignaturesTitle')}
                                                small
                                                noMargin
                                            />
                                        </Grid>
                                        <Grid container item xs={12} spacing={1}>
                                            <Grid item xs={6} alignSelf="center">
                                                {`${context.application.lastName} ${context.application.firstName}`}
                                            </Grid>
                                            <Grid item xs={4}>
                                                <FormFieldDate
                                                    name="signaturePerson"
                                                    label={T('CredentialApplication_SignaturePerson')}
                                                    minDate={new Date('2020-01-01')}
                                                />
                                            </Grid>
                                            <Grid item xs={6} alignSelf="center">
                                                {context.application.signatureOrganizationName}
                                            </Grid>
                                            <Grid item xs={4}>
                                                <FormFieldDate
                                                    name="signatureSrk"
                                                    label={T('CredentialApplication_SignatureSrk')}
                                                    minDate={new Date('2020-01-01')}
                                                />
                                            </Grid>
                                            {context.application.signatureHyryName && (
                                                <>
                                                    <Grid item xs={6} alignSelf="center">
                                                        {context.application.signatureHyryName}
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        <FormFieldDate
                                                            name="signatureHyry"
                                                            label={T('CredentialApplication_SignatureHyry')}
                                                        />
                                                    </Grid>
                                                </>
                                            )}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Form>
                        </DialogBase>
                    );
                }}
            </Formik>
            <DialogUpsertNote
                applicationId={applicationId}
                note={selectedNote}
                show={!!selectedNote}
                onClose={() => setSelectedNote(undefined)}
                onUpdate={updateNotes}
            />
            <DialogChangeType
                applicationId={applicationId}
                application={selectedApplication}
                show={!!selectedApplication}
                onClose={() => setSelectedApplication(undefined)}
                onUpdate={updateApplications}
            />
            <DialogAddAttachment
                applicationId={applicationId}
                show={showAddAttachment}
                onClose={() => setShowAddAttachment(false)}
                onUpdate={() => getContext(applicationId)}
            />
            <DialogSelectPerson
                application={context.application}
                credential={context.credential}
                show={showSelectPerson}
                onClose={() => setShowSelectPerson(false)}
                onUpdate={updateApplications}
            />

            {downloadNotesReportFilename && (
                <DownloadFile
                    fetchFile={() => fetchNotesReport(downloadNotesReportFilename)}
                    filename={downloadNotesReportFilename}
                    onSuccess={() => setDownloadNotesReportFilename(undefined)}
                />
            )}
            {downloadCardFilename && (
                <DownloadFile
                    fetchFile={() => fetchCard(downloadCardFilename)}
                    filename={downloadCardFilename}
                    onSuccess={() => setDownloadCardFilename(undefined)}
                />
            )}
            {downloadExtraCardFilename && (
                <DownloadFile
                    fetchFile={() => fetchExtraCard(downloadExtraCardFilename)}
                    filename={downloadExtraCardFilename}
                    onSuccess={() => setDownloadExtraCardFilename(undefined)}
                />
            )}
        </>
    );
};

export default DialogCredentialApplicationEdit;
