import React, { FC, ReactNode } from 'react';
import { Typography, Grid, Card, CardContent, CardActions } from '@mui/material';
import { SvgIconComponent } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import ViewSearchBar from 'components/common/ViewSearchBar';

interface DashboardButtonProps {
    title: string;
    url: string;
    icon?: unknown;
    search?: boolean;
    searchPlaceholder?: string;
    infoText?: string;
    iconTooltip?: ReactNode;
    preview?: ReactNode;
    fixMargin?: boolean;
}

const DashboardButton: FC<DashboardButtonProps> = ({
    title,
    url,
    icon,
    search,
    searchPlaceholder,
    infoText,
    iconTooltip,
    preview,
    fixMargin
}: DashboardButtonProps) => {
    const navigate = useNavigate();
    const Icon = icon as SvgIconComponent;

    const topMargin = fixMargin ? '2px' : '-2px';
    const bottomMargin = fixMargin ? '12px' : '0px';

    return (
        <Card sx={{ minWidth: '380px', maxWidth: '380px', mt: 4, ml: 3, mr: 3 }} elevation={3}>
            <CardContent onClick={() => navigate(url)}>
                <Grid container spacing={1} justifyContent="center" style={{ marginTop: '8px' }}>
                    <Grid item>
                        <Typography
                            variant="h5"
                            component="h2"
                            style={{ marginTop: topMargin, marginBottom: bottomMargin }}
                        >
                            {!!icon && <Icon fontSize="large" color="primary" />}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography
                            variant="h5"
                            component="h2"
                            sx={{ cursor: 'pointer' }}
                            onClick={() => {
                                if (search) {
                                    navigate(url);
                                }
                            }}
                        >
                            {title}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container spacing={1} justifyContent="center">
                    <Grid item>
                        {!!infoText && (
                            <Typography sx={{ marginBottom: '12px' }} color="textSecondary">
                                {infoText}
                            </Typography>
                        )}
                    </Grid>
                </Grid>
            </CardContent>
            {!!preview && <CardContent>{preview}</CardContent>}
            {!!search && (
                <CardActions>
                    <ViewSearchBar
                        iconTooltip={iconTooltip}
                        searchTitle={searchPlaceholder}
                        baseUrl={`${url}/search`}
                        clearUrl={url}
                        auditMsg={'dashboard search filter'}
                        center
                    />
                </CardActions>
            )}
        </Card>
    );
};

export default DashboardButton;
