import React, { FC, useContext, useState } from 'react';
import { useErrorHandler } from 'components/hooks/useErrorHandler';
import { LanguageContext, LanguageTextFunc } from 'contexts/languageContext';
import { Box, Button, Grid } from '@mui/material';
import UsersService from 'services/usersService';
import { User } from 'types/sp-api';
import LoadButton from 'components/common/LoadButton';
import { useSuccessHandler } from 'components/hooks/useSuccessHandler';
import { Form, Formik, FormikProps } from 'formik';
import FormFieldReadOnly from 'components/common/FormFieldReadonly';
import LastModified from 'components/common/LastModified';
import FormFieldDropDownUserRole from 'components/common/FormFieldDropDownUserRole';
import * as Yup from 'yup';
import ValidationService from 'services/validationService';
import moment from 'moment';
import LightTooltip from 'components/common/LightTooltip';
import InfoIcon from '@mui/icons-material/Info';
import Utils from 'utils/typeHelper';
import DialogConfirmation from 'components/common/DialogConfirmation';
import PersonUtils from 'utils/personHelper';
import { DeleteDialogState } from 'types/common';

const getValidationSchema = (T: LanguageTextFunc, newPerson: boolean) => {
    return Yup.object().shape({
        personId: Yup.string().test('not-0-value', T('Common_Required'), (value) => value !== '0'),
        name: Yup.string()
            .test(
                'username-exists',
                T('Error_UsernameExist'),
                async (value) => !newPerson || (await ValidationService.isValidUsernameAsync(value))
            )
            .nullable()
    });
};

interface UserItemProps {
    user: User;
    readonly?: boolean;
    refreshUser: () => void;
}

export const UserItem: FC<UserItemProps> = ({ user, readonly, refreshUser }: UserItemProps) => {
    const { T } = useContext(LanguageContext);
    const { handleError } = useErrorHandler();
    const { handleSuccess } = useSuccessHandler();
    const [sending, setSending] = useState(false);
    const [deleting, setDeleting] = useState(false);
    const [deleteDialogState, setDeleteDialogState] = useState<DeleteDialogState>();

    const saveUser = async (values: User): Promise<void> => {
        setSending(true);

        try {
            if (user.id === -1) {
                await UsersService.saveUserAsync(values);
            } else {
                await UsersService.saveUserRoleAsync(user.id, values.roleId);
            }
            setSending(false);
            handleSuccess(T('User_SaveSuccess'));
            refreshUser();
        } catch (error) {
            setSending(false);
            handleError(error, T('User_SaveFailed'));
        }
    };

    const deleteUser = async () => {
        if (deleteDialogState?.id) {
            try {
                setDeleting(true);
                await UsersService.deleteUserAsync(user.id);
                refreshUser();
                handleSuccess(T('User_DeleteSuccess'));
                setDeleting(false);
            } catch (error) {
                setDeleting(false);
                handleError(error, T('User_DeleteFailed'));
            }
        }
    };

    return (
        <>
            <Formik
                initialValues={user}
                onSubmit={saveUser}
                validationSchema={getValidationSchema(T, user.id === -1)}
                enableReinitialize
            >
                {({ values, dirty, isValid }: FormikProps<User>) => {
                    return (
                        <Form>
                            <Grid container spacing={3} alignItems="flex-start">
                                <Grid item xs={11}>
                                    <FormFieldReadOnly
                                        value={
                                            values.name.startsWith('NS_') ? T('User_TemporaryUserName') : values.name
                                        }
                                        label={T('User_FieldName')}
                                    />
                                </Grid>
                                {values.name.startsWith('NS_') && (
                                    <Grid item xs={1}>
                                        <LightTooltip title={T('User_NameTemporaryForRegistration')}>
                                            <InfoIcon color="info" />
                                        </LightTooltip>
                                    </Grid>
                                )}
                                <Grid item xs={11}>
                                    {readonly && (
                                        <FormFieldReadOnly
                                            value={T(Utils.RoleIdToName(values.roleId))}
                                            label={T('User_FieldRole')}
                                        />
                                    )}
                                    {!readonly && (
                                        <FormFieldDropDownUserRole name="roleId" label={T('User_FieldRole')} />
                                    )}
                                </Grid>
                                <Grid item xs={11}>
                                    <FormFieldReadOnly
                                        value={
                                            moment(values.beginDate).year() === 9999
                                                ? T('User_NotYetActivatedDate')
                                                : moment(values.beginDate).format('l')
                                        }
                                        label={T('User_FieldBeginDate')}
                                    />
                                </Grid>
                                <Grid item container xs={11} justifyContent="space-between">
                                    {!readonly && (
                                        <Grid item>
                                            <LoadButton loading={sending} disabled={!dirty || !isValid} type="submit">
                                                {T('Common_Save')}
                                            </LoadButton>
                                            <Button
                                                variant="text"
                                                color="secondary"
                                                disabled={!dirty}
                                                type="reset"
                                                style={{ marginLeft: 16 }}
                                            >
                                                {T('Common_Cancel')}
                                            </Button>
                                        </Grid>
                                    )}
                                    {user.id != -1 && !readonly && (
                                        <Grid item>
                                            <LoadButton
                                                loading={deleting}
                                                variant="outlined"
                                                color="error"
                                                onClick={() =>
                                                    setDeleteDialogState({
                                                        show: true,
                                                        message: T('User_ConfirmDeleteMessage').replace(
                                                            '{0}',
                                                            PersonUtils.FullName(user.person)
                                                        ),
                                                        id: values.id ?? 0
                                                    })
                                                }
                                            >
                                                {T('Common_Delete')}
                                            </LoadButton>
                                        </Grid>
                                    )}
                                </Grid>
                                <Grid item xs={12}>
                                    <Box margin={2} />
                                    {user.id !== -1 && (
                                        <LastModified by={values.lastModifiedBy} date={values.lastModifiedDate} />
                                    )}
                                </Grid>
                            </Grid>
                        </Form>
                    );
                }}
            </Formik>
            <DialogConfirmation
                title={T('User_ConfirmDeleteTitle')}
                message={deleteDialogState?.message}
                show={deleteDialogState?.show}
                onClose={() => setDeleteDialogState(undefined)}
                onOk={deleteUser}
                warning
                okTitle={T('Common_Delete')}
            />
        </>
    );
};
