import React, { ChangeEvent, FC, useCallback, useContext, useEffect, useRef, useState } from 'react';
import {
    Box,
    Container,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    SelectChangeEvent,
    Tab,
    Tabs,
    Typography
} from '@mui/material';
import { LanguageContext } from 'contexts/languageContext';
import { SecureMessage } from 'types/sp-api';
import axios, { CancelTokenSource } from 'axios';
import { useErrorHandler } from 'components/hooks/useErrorHandler';
import SecureMessagesService from 'services/secureMessagesService';
import MessageItem from './SecureMessageItem';
import { useNavigate, useParams } from 'react-router-dom';
import { SecureMessagesContext } from 'contexts/secureMessagesContext';
import MessageNewItem from './SecureMessageNewItem';
import MessageList from './SecureMessageList';
import TabPanel from 'components/common/TabPanel';
import BreadcrumbBar from 'components/common/BreadcrumbBar';
import ViewTitle from 'components/common/ViewTitle';
import { Mail } from '@mui/icons-material';
import { AuthContext } from 'contexts/authContext';
import WaitScreen from 'components/common/WaitScreen';
import Helper from 'utils/typeHelper';
import ApartmentIcon from '@mui/icons-material/Apartment';
import constants from 'config/constants';

const SecureMessagesView: FC = () => {
    const { T } = useContext(LanguageContext);
    const { user, isAdmin } = useContext(AuthContext);
    const [messageList, setMessageList] = useState<SecureMessage[]>();
    const [sentList, setSentList] = useState<SecureMessage[]>();
    const { handleError } = useErrorHandler();
    const navigate = useNavigate();
    const { setMessageCount } = useContext(SecureMessagesContext);
    const cancelRef = useRef<CancelTokenSource | null>(null);
    const [selectedTab, setSelectedTab] = useState(0);
    const { messageId } = useParams<'messageId'>();
    const { replyId } = useParams<'replyId'>();
    const [selectedOrganizationId, setSelectedOrganizationId] = useState(
        isAdmin() ? constants.RegionalCommunity.Id : user?.homeOrganizationId ?? 0
    );

    const messageIdNumber = !isNaN(Number(messageId)) ? parseInt(messageId ?? '') : undefined;
    const replyIdNumber = replyId && !isNaN(Number(replyId)) ? parseInt(replyId ?? '') : undefined;

    const getSecureMessages = useCallback(
        async (orgId: number): Promise<void> => {
            try {
                cancelRef.current = axios.CancelToken.source();
                const securemessages = await SecureMessagesService.getAllAsync(orgId, cancelRef.current.token);
                if (cancelRef.current) {
                    securemessages.sort(listSort);
                    setMessageList(securemessages);
                }
            } catch (error) {
                handleError(error, T('Error_SecureMessagesQueryFailed'));
            }
        },
        [handleError, T, setMessageCount]
    );

    const getSentSecureMessages = useCallback(
        async (orgId: number): Promise<void> => {
            try {
                cancelRef.current = axios.CancelToken.source();
                const securemessages = await SecureMessagesService.getAllSentAsync(orgId, cancelRef.current.token);
                if (cancelRef.current) {
                    securemessages.sort(listSort);
                    setSentList(securemessages);
                }
            } catch (error) {
                handleError(error, T('Error_SecureMessagesQueryFailed'));
            }
        },
        [handleError, T]
    );

    const getUpdatedCount = useCallback(
        async (orgId: number): Promise<void> => {
            try {
                cancelRef.current = axios.CancelToken.source();
                const count = await SecureMessagesService.getUnreadCountAsync(orgId, cancelRef.current.token);
                if (cancelRef.current) {
                    setMessageCount(count);
                }
            } catch (error) {
                handleError(error, T('Error_SecureMessagesQueryFailed'));
            }
        },
        [handleError, T]
    );

    useEffect(() => {
        getSecureMessages(selectedOrganizationId);
        getSentSecureMessages(selectedOrganizationId);

        return () => {
            cancelRef.current && cancelRef.current.cancel();
            cancelRef.current = null;
        };
    }, [getSecureMessages, getSentSecureMessages, selectedOrganizationId]);

    const getAllSecureMessages = async () => {
        await getSecureMessages(selectedOrganizationId);
        await getSentSecureMessages(selectedOrganizationId);
        await getUpdatedCount(selectedOrganizationId);
    };

    const handleChange = (_: ChangeEvent<unknown>, newValue: number) => {
        setSelectedTab(newValue);
        navigate('/communication/securemessages');
    };

    const listSort = (a: SecureMessage, b: SecureMessage): number => {
        // unreaded first
        if (a.unread > b.unread) return -1;
        if (a.unread < b.unread) return 1;

        // date order then
        if (!a.createdDate || !b.createdDate) return 0;
        if (a.createdDate < b.createdDate) return 1;
        if (a.createdDate > b.createdDate) return -1;

        return 0;
    };

    if (!user?.homeOrganizationId) {
        return <WaitScreen />;
    }

    return (
        <Container>
            <Grid container>
                <BreadcrumbBar />
                <ViewTitle
                    icon={Mail}
                    title={T('SecureMessages_Title')}
                    addButtonTitle={T('SecureMessage_AddNew')}
                    onAddClick={() => {
                        setSelectedTab(0);
                        navigate('/communication/securemessages/new');
                    }}
                />
            </Grid>
            {user.organizations.length > 1 && (
                <Grid container style={{ marginBottom: 12 }}>
                    <Grid container item xs={6} spacing={2}>
                        <Grid item xs={12}>
                            <Typography>{T('SecureMessage_SelectTargetChurch')}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl size="small" variant="outlined" fullWidth>
                                <InputLabel variant="outlined">{T('SecureMessage_TargetChurch')}</InputLabel>
                                <Select
                                    variant="outlined"
                                    onChange={(event: SelectChangeEvent<number>) => {
                                        setSelectedOrganizationId(event.target.value as number);
                                    }}
                                    value={selectedOrganizationId}
                                    label={T('SecureMessage_TargetChurch')}
                                >
                                    {user.organizations.map((item, index) => (
                                        <MenuItem key={index} value={item.id}>
                                            {item.name}
                                            {Helper.IsYhdyskunta(item.type) && (
                                                <ApartmentIcon fontSize="small" style={{ marginLeft: 8 }} />
                                            )}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <Box m={3} />
                        </Grid>
                    </Grid>
                </Grid>
            )}
            <Grid container spacing={3}>
                <Grid item xs={5}>
                    <Paper elevation={3}>
                        <Tabs
                            value={selectedTab}
                            indicatorColor="primary"
                            textColor="primary"
                            variant="fullWidth"
                            scrollButtons="auto"
                            onChange={handleChange}
                        >
                            <Tab label={T('SecureMessage_TabReceived')} value={0} />
                            <Tab label={T('SecureMessage_TabSent')} value={1} />
                        </Tabs>
                        <TabPanel value={selectedTab} index={0} padding={1}>
                            <MessageList messageList={messageList} selectedId={messageIdNumber} />
                        </TabPanel>
                        <TabPanel value={selectedTab} index={1} padding={1}>
                            <MessageList messageList={sentList} sentList selectedId={messageIdNumber} />
                        </TabPanel>
                    </Paper>
                </Grid>
                <Grid container item xs={7}>
                    {messageId === 'new' && (
                        <MessageNewItem
                            replyId={replyIdNumber}
                            closeMessage={() => navigate(`/communication/securemessages/${replyIdNumber ?? ''}`)}
                            updateList={getAllSecureMessages}
                        />
                    )}
                    {messageIdNumber && (
                        <MessageItem
                            messageId={messageIdNumber ?? 0}
                            closeMessage={() => navigate('/communication/securemessages')}
                            updateList={getAllSecureMessages}
                            sentList={selectedTab === 1}
                        />
                    )}
                </Grid>
            </Grid>
        </Container>
    );
};

export default SecureMessagesView;
