export class Settings {
    static readonly SmsSender: string = 'sms_sender';
    static readonly SmsPrice: number = 0.1;
    static readonly InterestedMembershipStart: string = '1900-01-01';
    static readonly IdleTimeoutSeconds: number = 30 * 60;
    static readonly LongerIdleTimeoutSeconds: number = 60 * 60;
    static readonly IdleTimeoutLogoutSeconds: number = 60;
}

export class StateKeys {
    static readonly ShowContactOrganizations: string = 'sp_showContactOrganizations';
}

export class Customers {
    static readonly Advk: string = 'advk';
    static readonly Shk: string = 'shk';
}

export class TagTypes {
    static readonly Member: number = 0;
    static readonly Community: number = 1;
}

export class RegionalCommunity {
    static readonly Id: number = 1;
    // TODO tämä pitäisi tulla sp_settings taulusta register_church_id-arvosta
    static readonly RegisterChurchId: number = 46;
}

export class RegisterGroupName {
    static readonly Senior: string = 'Seniorityöntekijä';
    static readonly PrisonEvangelist: string = 'Vankilaevankelista';
    static readonly WebinarMembers: string = 'Webinaarien kutsurekisteri';
}

export default {
    Settings,
    StateKeys,
    Customers,
    TagTypes,
    RegionalCommunity,
    RegisterGroupName
};
