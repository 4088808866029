import React, { FC, useContext } from 'react';
import { LanguageContext } from 'contexts/languageContext';
import Helper from 'utils/typeHelper';
import ApartmentIcon from '@mui/icons-material/Apartment';
import ChurchIcon from 'components/icons/ChurchIcon';
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';

interface ChurchTypeIconProps {
    type: number;
}

const ChurchTypeIcon: FC<ChurchTypeIconProps> = ({ type }: ChurchTypeIconProps) => {
    const { T } = useContext(LanguageContext);

    return (
        <>
            {Helper.IsYhdistys(type) && (
                <ApartmentIcon fontSize="small" titleAccess={T('CodeSet_organizationType_yhdistys')} />
            )}
            {Helper.IsLocalYhdyskunta(type) && (
                <ChurchIcon fontSize="small" titleAccess={T('CodeSet_organizationType_yhdyskuntaseurakunta')} />
            )}
            {Helper.IsYhdyskunta(type) && (
                <ChurchIcon fontSize="small" color="primary" titleAccess={T('CodeSet_organizationType_yhdyskunta')} />
            )}
            {Helper.IsContactInfo(type) && (
                <ContactPhoneIcon
                    fontSize="small"
                    color="info"
                    titleAccess={T('CodeSet_organizationType_yhteystieto')}
                />
            )}
        </>
    );
};

export default ChurchTypeIcon;
