import { createContext } from 'react';

export interface SecureMessagesContextInterface {
    messageCount: number;
    setMessageCount: (count: number) => void;
}

const initialState: SecureMessagesContextInterface = {
    messageCount: 0,
    setMessageCount: () => null
};

const ctxt = createContext<SecureMessagesContextInterface>(initialState);
ctxt.displayName = 'SecureMessagesContext';

export const SecureMessagesDefaultState = initialState;
export const SecureMessagesContext = ctxt;

export const SecureMessagesProvider = ctxt.Provider;
export const SecureMessagesConsumer = ctxt.Consumer;
