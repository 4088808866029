import React, { FC, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { LanguageContext, LanguageTextFunc } from 'contexts/languageContext';
import { SecureMessage } from 'types/sp-api';
import { useErrorHandler } from 'components/hooks/useErrorHandler';
import SecureMessagesService from 'services/secureMessagesService';
import { Box, Button, Card, CardActions, CardContent, Grid, Typography } from '@mui/material';
import moment from 'moment';
import LoadButton from 'components/common/LoadButton';
import { Form, Formik, FormikProps } from 'formik';
import * as Yup from 'yup';
import { useSuccessHandler } from 'components/hooks/useSuccessHandler';
import ChurchName from 'components/app/ChurchName';
import FormFieldText from 'components/common/FormFieldText';
import FormFieldTextArea from 'components/common/FormFieldTextArea';
import FormFieldDropDownOrganization from 'components/common/FormFieldDropDownOrganization';
import { AuthContext } from 'contexts/authContext';
import { useNavigate } from 'react-router-dom';
import axios, { CancelTokenSource } from 'axios';
import constants from 'config/constants';

const getValidationSchema = (T: LanguageTextFunc) => {
    return Yup.object().shape({
        title: Yup.string().required(T('Common_Required')),
        message: Yup.string().required(T('Common_Required'))
    });
};

interface SecureMessageNewItemProps {
    replyId?: number;
    closeMessage: () => void;
    updateList: () => Promise<void>;
}

const SecureMessageNewItem: FC<SecureMessageNewItemProps> = ({
    replyId,
    closeMessage,
    updateList
}: SecureMessageNewItemProps) => {
    const { T } = useContext(LanguageContext);
    const { handleError } = useErrorHandler();
    const { handleSuccess } = useSuccessHandler();
    const { user, isAdmin } = useContext(AuthContext);
    const navigate = useNavigate();
    const [message, setMessage] = useState<SecureMessage>();
    const cancelRef = useRef<CancelTokenSource | null>(null);

    const getMessage = useCallback(
        async (id: number): Promise<void> => {
            try {
                cancelRef.current = axios.CancelToken.source();
                const msg = await SecureMessagesService.getMessageAsync(id, cancelRef.current.token);
                if (cancelRef.current) {
                    setMessage(msg);
                }
            } catch (error) {
                handleError(error, T('Error_SecureMessageQueryFailed'));
            }
        },
        [handleError, T]
    );

    useEffect(() => {
        if (replyId) {
            getMessage(replyId);
        }

        return () => {
            cancelRef.current && cancelRef.current.cancel();
            cancelRef.current = null;
        };
    }, [getMessage, replyId]);

    const sendMessage = async (newValues: SecureMessage): Promise<void> => {
        try {
            await SecureMessagesService.saveMessageAsync(newValues);
            handleSuccess(T('SecureMessage_MessageSentSuccess'));
            updateList();
            navigate('/communication/securemessages');
        } catch (error) {
            handleError(error, T('SecureMessage_MessageSentFailed'));
        }
    };

    const createMessageTitle = (msg?: SecureMessage) => {
        if (!msg) return '';
        return msg.title.startsWith('Re:') ? msg.title : `Re: ${msg.title}`;
    };

    return (
        <Formik
            initialValues={{
                title: createMessageTitle(message),
                senderId: 0,
                message: '',
                recipientId: message?.senderId ?? 0,
                unread: true,
                replyId: replyId
            }}
            enableReinitialize
            validationSchema={getValidationSchema(T)}
            onSubmit={sendMessage}
        >
            {({ values, dirty, isValid, isSubmitting, resetForm }: FormikProps<SecureMessage>) => (
                <Form>
                    <Grid item xs={12}>
                        <Card className="message-item">
                            <CardContent
                                sx={{
                                    backgroundColor: 'white',
                                    borderWidth: 0,
                                    borderBottomWidth: 1,
                                    borderBottomColor: 'primary.main',
                                    borderBottomStyle: 'solid'
                                }}
                            >
                                <Grid container spacing={1}>
                                    <Grid container item justifyContent="space-between">
                                        <Grid container item justifyContent="flex-start" xs={8}>
                                            <Typography variant="subtitle1" color="textSecondary" noWrap>
                                                {`${T('SecureMessage_Sender')}: `}
                                                {isAdmin() ? T('Common_ReligionalCommunity') : <ChurchName plainText />}
                                            </Typography>
                                        </Grid>
                                        <Grid container item justifyContent="flex-end" xs={4}>
                                            <Typography variant="subtitle2" color="textSecondary" noWrap>
                                                {`${moment().format('l')} ${moment().format('LT')}`}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid container item>
                                        <FormFieldDropDownOrganization
                                            name="recipientId"
                                            label={T('SecureMessage_Recipient')}
                                            placeholder={T('SecureMessage_SelectRecipient')}
                                            disabledValues={
                                                isAdmin() ? constants.RegionalCommunity.Id : user?.homeOrganizationId
                                            }
                                            readonly={!!message}
                                            includeShk
                                            showChurches
                                        />
                                    </Grid>
                                    <Grid container item>
                                        <FormFieldText name="title" label={T('SecureMessage_Subject')} />
                                    </Grid>
                                </Grid>
                            </CardContent>
                            <CardContent sx={{ minHeight: 150 }}>
                                <FormFieldTextArea name="message" label={T('SecureMessage_Message')} rows={12} />
                            </CardContent>
                            <CardActions>
                                <Box m={1}>
                                    <LoadButton
                                        style={{ marginRight: 8 }}
                                        loading={isSubmitting}
                                        disabled={!dirty || !isValid}
                                        type="submit"
                                    >
                                        {T('SecureMessage_SendMessage')}
                                    </LoadButton>
                                    <Button
                                        disabled={!dirty && !!values.id}
                                        style={{ marginLeft: 16 }}
                                        onClick={() => {
                                            resetForm();
                                            closeMessage();
                                        }}
                                        color="secondary"
                                    >
                                        {T('Common_Cancel')}
                                    </Button>
                                </Box>
                            </CardActions>
                        </Card>
                    </Grid>
                </Form>
            )}
        </Formik>
    );
};

export default SecureMessageNewItem;
