import React, { FC, useContext, useState, MouseEvent, useLayoutEffect } from 'react';
import { LanguageContext } from 'contexts/languageContext';
import { OrganizationContact } from 'types/sp-api';
import { useNavigate } from 'react-router-dom';
import {
    Box,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TablePagination,
    TableRow,
    Typography
} from '@mui/material';
import { Order } from 'types/common';
import TableUtils from 'utils/tableHelper';
import EnhancedTableHead, { TableHeadCellDef } from 'components/common/EnhancedTableHead';
import { useAudit } from 'components/hooks/useAudit';
import ChurchTypeIcon from '../common/ChurchTypeIcon';

interface ChurchListProps {
    organizationList?: OrganizationContact[];
}

const ChurchList: FC<ChurchListProps> = ({ organizationList }: ChurchListProps) => {
    const { T } = useContext(LanguageContext);

    const headCells: TableHeadCellDef<Data>[] = [
        { id: 'type', label: '' },
        { id: 'name', label: T('Common_Name') },
        { id: 'phone', label: T('Common_Phone') },
        { id: 'email', label: T('Common_Email') }
    ];

    if (!organizationList) return null;

    return (
        <EnhancedTable
            headCells={headCells}
            rows={organizationList.map(
                (org): Data => ({
                    churchId: org.churchId,
                    name: org.name,
                    type: org.type,
                    phone: org.phone ?? org.contactPhone ?? '',
                    email: org.email ?? ''
                })
            )}
            initialOrder="asc"
            initialOrderBy="name"
        />
    );
};

export default ChurchList;

interface Data {
    churchId: number;
    name: string;
    type: number;
    phone: string;
    email: string;
}

interface EnhancedTableProps {
    initialOrder: Order;
    initialOrderBy: keyof Data;
    headCells: TableHeadCellDef<Data>[];
    rows: Data[];
}

const EnhancedTable = (props: EnhancedTableProps) => {
    const ROWS_PER_PAGE = 10;
    const { T } = useContext(LanguageContext);
    const { headCells, rows, initialOrder, initialOrderBy } = props;
    const [order, setOrder] = useState<Order>(initialOrder);
    const [orderBy, setOrderBy] = useState<keyof Data>(initialOrderBy);
    const [page, setPage] = useState(0);
    const navigate = useNavigate();
    const { audit } = useAudit();
    const rowsPerPage = Math.min(ROWS_PER_PAGE, props.rows.length);

    const handleRequestSort = (_: MouseEvent<unknown>, property: keyof Data) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleClick = (id: number) => {
        const url = `/churches/${id}`;
        audit('church select', url);
        navigate(url);
    };

    const handleChangePage = (_: unknown, newPage: number) => {
        setPage(newPage);
    };

    useLayoutEffect(() => {
        setPage(0);
    }, [rows]);

    return (
        <>
            <TableContainer component={Paper}>
                <Table>
                    <EnhancedTableHead
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={handleRequestSort}
                        headCells={headCells}
                    />
                    <TableBody>
                        {rows.length == 0 && (
                            <TableRow>
                                <TableCell sx={{ width: '10px' }}></TableCell>
                                <TableCell>
                                    <Box m={2} component="span">
                                        <Typography>{T('Church_SearchResultEmpty')}</Typography>
                                    </Box>
                                </TableCell>
                            </TableRow>
                        )}
                        {TableUtils.stableSort<Data>(rows, TableUtils.getComparator(order, orderBy))
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row, index) => {
                                return (
                                    <TableRow
                                        hover
                                        onClick={() => handleClick(row.churchId)}
                                        key={index}
                                        className="pointer"
                                    >
                                        <TableCell sx={{ width: '10px', fontSize: 'body1.fontSize' }}>
                                            <ChurchTypeIcon type={row.type} />
                                        </TableCell>
                                        <TableCell sx={{ fontSize: 'body1.fontSize' }}>{row.name}</TableCell>
                                        <TableCell sx={{ fontSize: 'body1.fontSize' }}>{row.phone}</TableCell>
                                        <TableCell sx={{ fontSize: 'body1.fontSize' }}>{row.email}</TableCell>
                                    </TableRow>
                                );
                            })}
                        {addEmptyRows(rows.length, rowsPerPage, page)}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[ROWS_PER_PAGE]}
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                component="div"
            />
        </>
    );
};

const addEmptyRows = (rowCount: number, rowsPerPage: number, page: number) => {
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, rowCount - page * rowsPerPage);

    return (
        emptyRows > 0 && (
            <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell sx={{ fontSize: 'body1.fontSize' }} colSpan={4} />
            </TableRow>
        )
    );
};
